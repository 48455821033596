import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Select, Button, Icon } from 'antd';
import qs from 'qs';

import { WorkspacePage, CpRow, Copy2Clipboard } from '../../components';
import { ProductSearchList } from '../components';
import categoriesBlackList from '../BestProductsCategoryBlackList';
import { useFetch, TrackerContext } from '../../common';
import * as productActionsExecutor from '../productActionsExecutor';
import SnsSharer from '../../components/SnsSharer/SnsSharer';

import TopProductsConfig from '../TopProductsConfig';

import HomeIcon from 'react-feather/dist/icons/home';
import LinkIcon from 'react-feather/dist/icons/link';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';

import './PerformantProducts.less';

export default function PerformantProducts() {
    const intl = useIntl();
    const { group } = useParams();
    const history = useHistory();
    const location = useLocation();
    const { trackEvent } = useContext(TrackerContext);
    const [shortUrl, setShortUrl] = useState('');
    const [sharing, setSharing] = useState({});
    const TopProductConfig = TopProductsConfig.find((x) => x.group === group);

    const queryStrings = qs.parse(location.search.substr(1));
    const [categoryFilter, setCategoryFilter] = useState(parseInt(queryStrings.category, 10) || null);
    const [products, setProducts] = useState([]);

    const { res, error, fetching, doFetch } = useFetch(() => TopProductConfig.fetchApi(20, categoryFilter));

    useEffect(() => {
        if (res && res.data && res.data.data) {
            const { products = [], shortUrl, sharing } = res.data.data;
            products.forEach((x, index) => (x.rankNo = index + 1));
            setProducts(products);
            setShortUrl(shortUrl);
            setSharing(sharing);
        }

        if (error) {
            console.error(error);
        }
    }, [res, error]);

    useEffect(() => {
        setCategoryFilter(parseInt(queryStrings.category, 10) || null);
    }, [location.search]);

    useEffect(() => {
        return history.listen(() => {
            setTimeout(() => {
                doFetch();
            }, 0);
        });
    }, []);

    const handleCategoryChange = (value) => {
        history.push({
            pathname: location.pathname,
            search: qs.stringify({ category: value }),
        });
    };

    const handleExecuteProductAction = (action, product) => {
        switch (action) {
            case 'generateLink':
                productActionsExecutor.generateLink(group, product, history);
                trackEvent({ action: 'create_link', value: product.productId });
                break;
            case 'viewDetail':
                productActionsExecutor.viewDetail(product);
                trackEvent({ action: 'view_product_detail', value: product.productId });
                break;
            default:
                break;
        }
    };

    const breadcrumb = [
        {
            title: intl.formatMessage({ id: 'AFFILIATE_HOME' }),
            link: '/affiliate',
            icon: <HomeIcon size={14} />,
        },
        {
            title: intl.formatMessage({ id: 'PRODUCT_LINK' }),
            link: '/affiliate/ws/link',
        },
        { title: intl.formatMessage({ id: 'PRODUCT_LINK_BEST_PRODUCTS' }) },
    ];

    const categories = useSelector((state) => state.config.categories);
    const categoriesWithAll = [{ id: null, name: intl.formatMessage({ id: 'ALL' }) }, ...categories];
    const filteredCategories = categoriesWithAll.filter((x) => !categoriesBlackList.has(x.id));

    return (
        <WorkspacePage
            breadcrumb={breadcrumb}
            title={
                <div className="performant-product-title-bar">
                    <span className="mr1em">{intl.formatMessage({ id: TopProductConfig.title })}</span>
                    {TopProductConfig.coupangLink && (
                        <div className="performant-product-actins">
                            <span className="is-flex-row button-bar">
                                <Button className="icon-btn" href={TopProductConfig.coupangLink} target="blank">
                                    <ExternalLinkIcon size={16} />
                                    {intl.formatMessage({ id: 'VISIT_PAGE' })}
                                </Button>
                                <Copy2Clipboard content={shortUrl}>
                                    <Button className="icon-btn">
                                        <LinkIcon size={16} />
                                        {intl.formatMessage({ id: 'PRODUCT_LINK_GENERATION' })}
                                    </Button>
                                </Copy2Clipboard>
                            </span>
                            <SnsSharer
                                title={sharing.title}
                                description={sharing.description}
                                imageUrl={sharing.imageUrl}
                                link={shortUrl}
                            />
                        </div>
                    )}
                </div>
            }
            loading={false}
            className="best-products">
            <CpRow contentRelative>
                {TopProductConfig.categoryFilter && (
                    <Select className="best-products-categories" value={categoryFilter} onSelect={handleCategoryChange}>
                        {filteredCategories.map((x) => (
                            <Select.Option key={x.id} value={x.id}>
                                {' '}
                                {x.name}{' '}
                            </Select.Option>
                        ))}
                    </Select>
                )}
                {error ? (
                    <div className="is-flex-center">
                        <Icon
                            type="exclamation-circle"
                            className="mr05em"
                            style={{ fontSize: '1.5em', color: '#f56112' }}
                        />
                        {(error.data && error.data.rMessage) || intl.formatMessage({ id: 'MSG_FAILED_TO_LOAD_DATA' })}
                    </div>
                ) : (
                    <ProductSearchList
                        loading={fetching}
                        items={products}
                        pager={false}
                        showRankNo
                        onAction={handleExecuteProductAction}
                    />
                )}
            </CpRow>
        </WorkspacePage>
    );
}
