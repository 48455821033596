import React from 'react';
import { useSelector } from 'react-redux';
import { MessageForm, Emotion } from '../../components';

export default function(){
    const webAccessibleStatus = useSelector(state => state.webAccessibleStatus);
    return (
        <div className="is-flex bg-grey">
            <MessageForm className="mt40 align-center" size="normal" title={<div className="emotion-line">
                <Emotion type="ops" />
            </div>} content={(<div style={{ overflow: 'hidden' }}>
                <div dangerouslySetInnerHTML={{__html:webAccessibleStatus.message}}></div>
            </div>)} />
        </div>
    );
}