import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import HomeIcon from 'react-feather/dist/icons/home';
import { WorkspacePage, CpRow, HTMLCodeViewer } from '../../components';
import { TrackerContext, AffiliateFeeNotice } from '../../common';

import Api from '../api';
import './SearchBar.css';

const SearchBarList = [
    { name: 'Logo 01', type: 'l1', maxWidth: 360, order: 3 },
    { name: 'Logo 02', type: 'l2', maxWidth: 360, order: 4 },
    { name: 'Favicon 01', type: 'f1', maxWidth: 170, order: 1 },
    { name: 'Favicon 02', type: 'f2', maxWidth: 170, order: 2 },
];

class SearchBar extends PureComponent {
    static contextType = TrackerContext;
    types = matchMedia('only screen and (max-width:768px)').matches
        ? SearchBarList.slice(0).sort((a, b) => a.order - b.order)
        : SearchBarList.slice(0);

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            urls: {},
            loading: false,
            codeCopied: false,
        };
    }

    onTabChange = (item) => {
        // this.context.trackEvent({ action: 'pick_searchbar_type', value: `${this.types[item.key].name}` });
        this.setState({
            selectedTab: item.key,
        });
    };

    buildHTML = () => {
        let { selectedTab, htmlTags = {} } = this.state;
        let { type } = this.types[selectedTab];
        return htmlTags[type];
    };

    onCopyCode = () => {
        this.setState({ codeCopied: true });
        this.context.trackEvent({ action: 'copy_searchbar_code', value: 'searchbar' });
    };

    fetchCode = () => {
        this.setState({ loading: true }, () => {
            Api.fetchSearchBarWidgetInstanceId()
                .then((res) => {
                    this.setState({ htmlTags: res.data.data });
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.currentSubId !== this.props.currentSubId) {
            this.fetchCode();
        }
    }

    componentDidMount() {
        this.fetchCode();
    }

    render() {
        const { intl } = this.props;
        const { loading } = this.state;
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_PL_SEARCH_BAR' }) },
        ];
        const html = this.buildHTML();
        let { selectedTab, codeCopied } = this.state;
        const { maxWidth } = this.types[selectedTab];
        return (
            <WorkspacePage
                className="search-bar-generation"
                breadcrumb={breadcrumb}
                withSubId={true}
                title={intl.formatMessage({ id: 'HEADER_PL_SEARCH_BAR' })}
                loading={loading}>
                <CpRow>
                    {/* <div style={{ paddingRight: 21, overflow: 'hidden' }}> */}
                    <div style={{ paddingRight: 21 }}>
                        <Menu
                            mode="horizontal"
                            className="menu-horizontal"
                            selectedKeys={[`${selectedTab}`]}
                            triggerSubMenuAction="click"
                            onClick={this.onTabChange}>
                            {this.types.map((x, index) => (
                                <Menu.Item key={`${index}`}>{`${x.name}`}</Menu.Item>
                            ))}
                        </Menu>
                    </div>
                </CpRow>
                <CpRow className="code-generation-container is-flex bg-grey">
                    <div className="code-generation-content">
                        <div className="code-preview">
                            <h2>{intl.formatMessage({ id: 'CODE_PREVIEW' })}</h2>
                            <div className="code-container">
                                <div
                                    dangerouslySetInnerHTML={{ __html: html }}
                                    style={{ maxWidth: maxWidth, lineHeight: 0 }}></div>
                            </div>
                        </div>
                        <div className="code-viewer">
                            <HTMLCodeViewer
                                height={440}
                                html={html}
                                onCopyCode={this.onCopyCode}
                                bottomChild={codeCopied && <AffiliateFeeNotice className="align-left" />}
                            />
                        </div>
                    </div>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    connect((state) => ({
        currentSubId: state.subId.currentSubId,
    }))(SearchBar)
);
