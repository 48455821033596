import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Icon } from 'antd';
import { injectIntl } from 'react-intl';
import { ResidentialRegisterNumber } from '../../components';
import { requiredMessage } from '../../common';

import './IndividualPayerInformationFieldset.css';

class IndividualPayerInformationFieldset extends Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        validateRRN: PropTypes.func,
        disabled: PropTypes.bool,
        data: PropTypes.shape({
            name: PropTypes.string,
            rrn: PropTypes.string,
        }),
        disableRRN: PropTypes.bool,
        rrnVerified: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            rrnValid: false,
        };
        this.dirty = false;
    }

    validateRRN = (rule, value, callback, field, options) => {
        const { intl, disabled } = this.props;
        let { raw, valid, dirty, message } = value || {};

        if (disabled || (valid === undefined && !!dirty)) {
            callback();
        } else {
            this.setState({
                rrnValid: valid,
            });

            if (valid) {
                callback();
                return;
            }

            if (!raw || raw.length !== 13) {
                callback(intl.formatMessage({ id: 'PAYMENT_MSG_RRN_IS_13_DIGITALS' }));
                return;
            }

            if (dirty || dirty === undefined) {
                callback(intl.formatMessage({ id: 'PAYMENT_MSG_CHECK_RRN_FIRST' }));
                return;
            }

            callback(message || intl.formatMessage({ id: 'PAYMENT_MSG_RRN_ILLEGAL' }));
        }
    };

    verifyRRN = (rrn) => {
        const { validateRRN, rrnVerified } = this.props;

        return new Promise((resolve, reject) => {
            if (validateRRN) {
                validateRRN(rrn)
                    .then((res) => {
                        const { data: { data: { isValid, message } } = { data: { isValid: false } } } = res;

                        this.setState({
                            rrnValid: isValid,
                        });
                        if (isValid) {
                            resolve();
                            rrnVerified && rrnVerified(true);
                        } else {
                            reject(message);
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            rrnValid: false,
                        });
                        reject();
                    });
            }
        });
    };

    render() {
        const {
            intl,
            form: { getFieldDecorator },
            data = {},
            disabled,
            disableRRN,
        } = this.props;
        const rs = {
            Verify: intl.formatMessage({ id: 'VERIFY' }),
        };

        return (
            <fieldset>
                <legend>{intl.formatMessage({ id: 'PAYMENT_SECTION_PAYER_INFORMATION' })}</legend>

                <Form.Item
                    label={
                        <span>
                            {intl.formatMessage({ id: 'PAYMENT_RRN' })}
                            {this.state.rrnValid ? (
                                <Icon className="color-accent ml05em" type="check-circle" theme="filled" />
                            ) : null}
                        </span>
                    }
                    style={{ marginBottom: 6 }}>
                    {getFieldDecorator('rrn', {
                        initialValue: { rrn: data.rrn, valid: !!data.rrn },
                        rules: [
                            {
                                required: true,
                                message: requiredMessage(intl, 'PAYMENT_RRN'),
                            },
                            {
                                validator: this.validateRRN,
                            },
                        ],
                    })(
                        <ResidentialRegisterNumber
                            onVerify={this.verifyRRN}
                            disabled={disabled || disableRRN}
                            rs={rs}
                            ref={(x) => (this.rrnRef = x)}
                        />
                    )}
                </Form.Item>
                <div style={{ marginBottom: 35, fontSize: 12 }}>
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'PAYMENT_RRN_TIP1' }) }}
                    />
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'PAYMENT_RRN_TIP2' }) }}
                    />
                </div>
            </fieldset>
        );
    }
}

export default injectIntl(IndividualPayerInformationFieldset);
