import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { WorkspacePage, CpRow } from '../../components';
import { Consts } from '../../common';
import ChangeBusinessTypeToOtherIndustries from './ChangeBusinessTypeToOtherIndustries';
import ChangeBusinessTypeToIndividual from './ChangeBusinessTypeToIndividual';


const { AffiliateTypes } = Consts;

class ChangeBusinessType extends Component {
    static AffiliateTypes = {
        [AffiliateTypes.Individual]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL',
        [AffiliateTypes.AdvertisingAgency]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL_ADVERTISING',
        [AffiliateTypes.OtherIndustries]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL_OTHERS',
        [AffiliateTypes.Company]: 'PAYMENT_SELECT_TYPE_CORPORATION'
    }

    render() {
        const { intl, affiliate } = this.props;
        const title = intl.formatMessage({ id: 'CHANGE_AFFILIATE_TYPE_TITLE' });
        let subTitle = '';

        let changeBusinessTypeComponent = null;
        switch (affiliate.affiliateType) {
            case AffiliateTypes.Individual:
                changeBusinessTypeComponent = <ChangeBusinessTypeToOtherIndustries />;
                subTitle = intl.formatMessage({ id: 'CHANGE_AFFILIATE_TYPE_INDIVIDUAL_TO_OTHERS' });
                break;
            case AffiliateTypes.OtherIndustries:
                changeBusinessTypeComponent = <ChangeBusinessTypeToIndividual />;
                subTitle = intl.formatMessage({ id: 'CHANGE_AFFILIATE_TYPE_OTHERS_TO_INDIVIDUAL' });
                break;
            default:
                changeBusinessTypeComponent = <h1>Cannot change Affiliate Type {affiliate.affiliateType} to other types!</h1>;
                break;
        }

        return (
            <WorkspacePage mini={true} withHome={true} className="bg-grey is-flex">
                <CpRow style={{ maxWidth: 840 }}>
                    <h1 className="page-title-secondary">{title} <span className="text-secondary">{subTitle}</span></h1>
                    {changeBusinessTypeComponent}
                </CpRow>
            </WorkspacePage>);
    }
}

export default injectIntl(
    connect(state => ({ affiliate: state.affiliate }))(ChangeBusinessType)
)