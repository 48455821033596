import React from 'react';
import cx from 'classnames';

import './UnselectableInput.less';

const UnselectableInput = function ({ className, size, value, ...others }) {
    return (
        <div className={cx("unselectable-input", className, { "large": size === 'large' })} {...others}>
            {value}
        </div>
    );
};


UnselectableInput.TextArea = function ({ className, value }) {
    return (
        <div className={cx("unselectable-textarea", className)}>
            {/* <pre> */}
            {value}
            {/* </pre> */}
        </div>
    );
}

export default UnselectableInput;
