import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { MessageForm, Emotion } from '../../components';
import { TrackerContext } from '../../common';

class Member2AffiliateAlert extends Component {
    static contextType = TrackerContext;
    gotoSignUp = () => {
        const { onGotoJoin } = this.props;
        this.context.trackEvent({ action: 'signup_now' });
        if (onGotoJoin) {
            onGotoJoin();
        }
    };

    componentDidMount() {
        this.context.trackPageView({ action: 'enter_member_to_join' });
    }

    render() {
        const { intl } = this.props;
        const title = (
            <div>
                <div className="emotion-line">
                    <Emotion type="ops" />
                </div>
                <div>{intl.formatMessage({ id: 'MEMBER_AFFILIATE_TITLE' })}</div>
            </div>
        );

        const rs = { OK: intl.formatMessage({ id: 'MEMBER_AFFILIATE_JOIN_NOW' }) };
        return (
            <div className="is-flex bg-grey">
                <MessageForm
                    className="mt40 align-center"
                    title={title}
                    size="normal"
                    content={intl.formatMessage({ id: 'MEMBER_AFFILIATE_REGISTER_TIP' })}
                    rs={rs}
                    onOK={this.gotoSignUp}
                />
            </div>
        );
    }
}

export default withRouter(injectIntl(Member2AffiliateAlert));
