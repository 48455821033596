import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Steps } from 'antd';

import './Steps.css';

export default class WizardSteps extends Component {
    static propTypes = {
        size: PropTypes.oneOf(['small', '']),
        current: PropTypes.number,
        steps: PropTypes.arrayOf(PropTypes.string)
    }

    static defaultProps = {
        current: 0,
        steps: []
    }

    customDot = (dot, { status, index }) => {
        return (
            <span className="ant-steps-icon-dot">
                {index + 1}
            </span>
        );
    }

    render() {
        const { size, current, steps, className, ...others } = this.props;

        return (
            <Steps className={cx('cp-steps', className, size)} {...others} current={current} progressDot={this.customDot}>
                {steps.map(x => (<Steps.Step key={x} title={x} />))}
            </Steps>
        );
    }
}