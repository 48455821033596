import { FETCH_TAX_INVOICES } from './actionTypes';
import { asyncCall } from '../common';
import Api from './api';

import { notification } from 'antd';

export const fetchTaxInvoices = ({ pageNumber, size }) => {
    return asyncCall(FETCH_TAX_INVOICES, () => Api.fetchTaxInvoices({ pageNumber, size }), { notifyError: true });
}

export const openInvoiceApprovalWindow = (settleMonth) => {
    return (dispatch) => {
        Api.getInvoiceUrl(settleMonth).then(res => {
            if (res.data) {
                window.open(res.data.data);
            }
        }).catch(err => {
            if (err.status === 200) {
                notification.error({ message: 'Error', description: err.data ? err.data.rMessage : err.data });
            }
        });
    }
}
