import { asyncCall } from '../common';
import Api from './api';

export const INIT_REPORT_STORE = 'REPORT/INIT';
export const FETCH_REPORT_DATA_DAILY = 'REPORT/FETCH_REPORT_DATA_DAILY';
export const FETCH_REPORT_DATA_DYNAMIC_WIDGET_DAILY = 'REPORT/FETCH_REPORT_DATA_DYNAMIC_WIDGET_DAILY';
export const FETCH_REPORT_DATA_MONTHLY = 'REPORT/FETCH_REPORT_DATA_MONTHLY';
export const FETCH_REPORT_DATA_SETTLEMENT = 'REPORT/FETCH_SETTLEMENT_REPORT';

export const initReportStore = () => {
    return (dispatch) => {
        dispatch({ type: INIT_REPORT_STORE });
    };
};

export const fetchReportDataDaily = (conditions) => {
    return asyncCall(FETCH_REPORT_DATA_DAILY, () => Api.fetchReportDataDaily(conditions), { notifyError: true });
};

export const fetchReportDataDynamicWidgetDaily = (conditions) => {
    return asyncCall(FETCH_REPORT_DATA_DYNAMIC_WIDGET_DAILY, () => Api.fetchReportDataDynamicWidgetDaily(conditions), {
        notifyError: true,
    });
};

export const fetchReportDataMonthly = (conditions) => {
    return asyncCall(FETCH_REPORT_DATA_MONTHLY, () => Api.fetchReportDataMonthly(conditions), { notifyError: true });
};

export const fetchReportDataSettlement = ({ pageNumber, size }) => {
    return asyncCall(FETCH_REPORT_DATA_SETTLEMENT, () => Api.fetchReportDataSettlement({ pageNumber, size }), {
        notifyError: true,
    });
};

export const PICK_REPORT_DATE_RANGE = 'REPORT/PICK_REPORT_DATE_RANGE';
export const pickReportDateRange = ({ startDate, endDate }) => {
    return (dispatch) =>
        dispatch({
            type: PICK_REPORT_DATE_RANGE,
            payload: { startDate, endDate },
        });
};
