import axios from 'axios';

export default class Api {
    static getOpenApiKeys() {
        return axios.get('/api/v1/open-api');
    }

    static applyApiKeys() {
        return axios.get('/api/v1/open-api/apply');
    }
}