// includes bank name and code list, member email address and name etc(or from window global, but prefer this way)
export const FETCH_PAYMENT_FORM_INIT_DATA = 'PAYMENT/FETCH_PAYMENT_FORM_INIT_DATA';

export const VALIDATE_RRN = 'PAYMENT/VALIDATE_RRN';
export const VALIDATE_BRN = 'PAYMENT/VALIDATE_BRN';
export const VALIDATE_BANK_CARD = 'PAYMENT/VALIDATE_BANK_CARD';

export const SAVE_INDIVIDUAL_PAYMENT_INFO = 'PAYMENT/SAVE_INDIVIDUAL_PAYMENT_INFO';
export const SAVE_BUSINESS_PAYMENT_INFO = 'PAYMENT/SAVE_BUSINESS_PAYMENT_INFO';

export const FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA = 'PAYMENT/FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA';
export const UPDATE_INDIVIDUAL_PAYMENT = 'PAYMENT/UPDATE_INDIVIDUAL_PAYMENT';

export const FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA = 'PAYMENT/FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA';
export const UPDATE_BUSINESS_PAYMENT = 'PAYMENT/UPDATE_BUSINESS_PAYMENT';

export const FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA = 'PAYMENT/FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA';
export const UPDATE_OTHER_INDUSTRIES_PAYMENT = 'PAYMENT/UPDATE_OTHER_INDUSTIRES_PAYMENT';

export const FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE = 'PAYMENT/FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE';
export const FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE = 'PAYMENT/FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE';