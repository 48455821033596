import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Upload, Icon, message } from 'antd';
import PlusIcon from 'react-feather/dist/icons/plus';
import FileIcon from 'react-feather/dist/icons/file';
import FormatString from '../FormatString/FormatString';

import './DocumentUploader.css';

export default class DocumentUploader extends Component {

    static propTypes = {
        fileName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        uploadText: PropTypes.string,
        action: PropTypes.string.isRequired,
        accept: PropTypes.string,
        maxSize: PropTypes.number,
        beforeUpload: PropTypes.func, // beforeUpload func must return a promise 
        onSuccess: PropTypes.func,
        onError: PropTypes.func,
        rs: PropTypes.shape({
            FileExceedsMaxSize: PropTypes.string,
            UploadSuccessfully: PropTypes.string,
            UploadFailed: PropTypes.string
        })
    }

    static DefaultRS = {
        FileExceedsMaxSize: 'The file to upload must be smaller than {maxSize} KB',
        UploadSuccessfully: '{name} uploaded successfully',
        UploadFailed: '{name} upload failed'
    }

    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            imageData: null
        };
        this.rs = { ...DocumentUploader.DefaultRS, ...props.rs };
    }

    previewImage(file) {
        if (window.FileReader) {
            const reader = new FileReader();
            reader.addEventListener('load', (e) => {
                this.setState({
                    imageData: e.target.result
                })
            });
            reader.readAsDataURL(file);
        }
    }

    get uploadProps() {
        const { name, action, accept } = this.props;
        return {
            name,
            action,
            accept,
            headers: {
                consumes: 'multipart/form-data',
            },
            beforeUpload: (file) => {
                return new Promise((resolve,reject)=>{
                    const fileSize = file.size / 1024;  // KB
                    const maxSize = this.props.maxSize || 5 * 1024;

                    if (fileSize > maxSize) {
                        message.warn(<FormatString msg={this.rs.FileExceedsMaxSize} values={{ maxSize }} />);
                        reject();
                    } else {
                        // size check pass then handle props.beforeUpload 
                        if(this.props.beforeUpload) {
                            this.props.beforeUpload().then(resolve).catch(reject);
                        } else {
                            resolve();
                        }
                    }
                });
            },
            onChange: (info) => {
                const { onSuccess, onError } = this.props;
                const { status, response } = info.file;
                switch (status) {
                    case 'uploading':
                        this.setState({ uploading: true });
                        break;
                    case 'done':
                        this.setState({ uploading: false });
                        if (response.rCode === '0') {
                            this.previewImage(info.file.originFileObj);
                            message.success(<FormatString msg={this.rs.UploadSuccessfully} values={{ name: info.file.name }} />)
                            if (onSuccess) {
                                onSuccess(`${info.file.name}`);
                            }
                        } else {
                            message.error(this.rs[response.rCode]);
                        }
                        break;
                    case 'error':
                        this.setState({ uploading: false });
                        message.error(<FormatString msg={this.rs.UploadFailed} values={{ name: info.file.name }} />);
                        if (onError) {
                            onError(`${info.file.name}`);
                        }
                        break;
                    default: break;
                }
            }
        }
    }

    render() {
        const { title, uploadText } = this.props;
        let thumbnail = '';
        if (this.state.uploading) {
            thumbnail = <Icon type="loading" style={{ fontSize: 46 }} />;
        } else if (this.state.imageData && window.FileReader) {
            thumbnail = <img className="img-preview" src={this.state.imageData} alt="Preview" />;
        } else {
            thumbnail = <PlusIcon size={60} className="btn-plus" />;
        }

        return (
            <div className="document-uploader">
                <Row gutter={20}>
                    <Col span={12} className="align-left title">
                        <span>{title}</span>
                    </Col>
                    <Col span={12} className="align-right">
                        <Upload showUploadList={false} {...this.uploadProps}>
                            <span className="color-primary btn-upload">
                                <FileIcon size={16} />
                                <span>{uploadText || 'Upload a file'}</span>
                            </span>
                        </Upload>
                    </Col>
                </Row>
                <Row className="thumb-area">
                    <Col span={24}>
                        <Upload.Dragger multiple={false} showUploadList={false} {...this.uploadProps}>
                            {
                                thumbnail
                            }
                        </Upload.Dragger>
                    </Col>
                </Row>
            </div>);
    }
}