import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { MessageForm, Emotion } from '../../components';
import { TrackerContext } from '../../common';

class AffiliateInProgress extends Component {
    static contextType = TrackerContext;
    componentDidMount() {
        this.context.trackPageView({ action: 'enter_application_in_progress' });
    }

    render() {
        const { intl } = this.props;
        const title = (
            <div>
                <div className="emotion-line">
                    <Emotion type="closeup" />
                </div>
                <div>{intl.formatMessage({ id: 'REGISTRATION_AFFILIATE_IN_PROGRESS_TITLE' })}</div>
            </div>
        );
        const content = (
            <FormattedMessage id="REGISTRATION_AFFILIATE_IN_PROGRESS_DETAIL" values={{ newLine: <br /> }} />
        );
        const rs = { OK: intl.formatMessage({ id: 'CONFIRM' }) };
        return (
            <div className="is-flex bg-grey">
                <MessageForm
                    className="mt40 align-center"
                    size="normal"
                    title={title}
                    content={content}
                    rs={rs}
                    onOK={() => {
                        this.context.trackEvent({ action: 'confirm_application_in_progress' });
                        window.nonav = true;
                        window.location.hash = '/';
                    }}
                />
            </div>
        );
    }
}

export default injectIntl(AffiliateInProgress);
