import React, { useState } from 'react';
import antEnUS from 'antd/lib/locale-provider/en_US';
import antKoKR from 'antd/lib/locale-provider/ko_KR';
import enUS from '../../i18n/en';
import koKR from '../../i18n/ko';

const antLocaleResources = {
    en: { ...antEnUS, DataTable: { ...antEnUS.DataTable, FilterApply: 'Apply', FilterReset: 'Reset', TotalItems: '{0}-{1} of {2} items' } },
    ko: { ...antKoKR, DataTable: { ...antKoKR.DataTable, FilterApply: '확인', FilterReset: '초기화', TotalItems: '{2}개 중 {0}-{1}' } },
};

const localeResources = {
    en: enUS,
    ko: koKR,
}

const LocaleContext = React.createContext();
const LocaleProvider = ({ children })=>{
    const [locale, setLocale] = useState('ko');
    return (
        <LocaleContext.Provider value={{locale, localeResource: localeResources[locale], antLocaleResource: antLocaleResources[locale], setLocale}}>
            {children}
        </LocaleContext.Provider>
    );
}
export { LocaleContext, LocaleProvider };