import React, { PureComponent } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';


class HelpDropdown extends PureComponent {
    render() {
        const { items = [], intl, activeMenuItem, onItemClick } = this.props;

        return (
            <Dropdown overlay={
                <Menu className="toolbar-help" selectedKeys={[activeMenuItem]} onClick={onItemClick}>
                    {
                        items.filter(x => x.title).map(x => {
                            if (Array.isArray(x.children) && x.children.length) {
                                return (<Menu.SubMenu className="toolbar-help-submenu" key={x.key} title={<span>{x.title} </span>} >
                                    {
                                        x.children.filter(x => x.title).map(y => {
                                            if (Array.isArray(y.children) && y.children.length) {
                                                return (
                                                    <Menu.SubMenu key={y.key} className="toolbar-help-submenu" title={y.title}>
                                                        {
                                                            y.children.filter(x => x.title).map(z =>
                                                                <Menu.Item key={z.key}><Link to={z.route}>{z.title}</Link></Menu.Item>)
                                                        }
                                                    </Menu.SubMenu>
                                                );
                                            } else {
                                                return <Menu.Item key={y.key}><Link to={y.route}>{y.title}</Link></Menu.Item>;
                                            }
                                        })
                                    }
                                </Menu.SubMenu>);
                            } else {
                                return <Menu.Item key={x.key}><Link to={x.route}>{x.title}</Link></Menu.Item>
                            }
                        })
                    }
                </Menu>}>
                <Button className="btn-link">{intl.formatMessage({ id: 'HEADER_HELP' })}</Button>
            </Dropdown>
        );
    }
}

export default injectIntl(HelpDropdown)