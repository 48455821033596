import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { WorkspacePage, CpRow } from '../../components';
import { Consts } from '../../common';
import EditIndividualPayment from './EditIndividualPayment';
import EditBusinessPayment from './EditBusinessPayment';
import EditOtherIndustriesPayment from './EditOtherIndustriesPayment';

const { AffiliateTypes } = Consts;

class EditPayment extends Component {
    static AffiliateTypes = {
        [AffiliateTypes.Individual]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL',
        [AffiliateTypes.AdvertisingAgency]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL_ADVERTISING',
        [AffiliateTypes.OtherIndustries]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL_OTHERS',
        [AffiliateTypes.Company]: 'PAYMENT_SELECT_TYPE_CORPORATION',
    };

    render() {
        const { intl, affiliate } = this.props;
        const title = intl.formatMessage({ id: 'EDIT_PAYMENT_TITLE' });

        const subTitle = intl.formatMessage({ id: EditPayment.AffiliateTypes[affiliate.affiliateType] });

        let editPaymentComponent = null;
        switch (affiliate.affiliateType) {
            case AffiliateTypes.Individual:
                editPaymentComponent = <EditIndividualPayment />;
                break;
            case AffiliateTypes.AdvertisingAgency:
            case AffiliateTypes.Company:
                editPaymentComponent = <EditBusinessPayment />;
                break;
            case AffiliateTypes.OtherIndustries:
                editPaymentComponent = <EditOtherIndustriesPayment />;
                break;
            default:
                editPaymentComponent = <h1>Affiliate Type {affiliate.affiliateType} not supported!</h1>;
                break;
        }

        return (
            <WorkspacePage mini={true} withHome={true} className="bg-grey is-flex">
                <CpRow style={{ maxWidth: 840 }}>
                    <h1 className="page-title-secondary">
                        {title} <span className="color-accent text-secondary">{subTitle}</span>
                    </h1>
                    {editPaymentComponent}
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(connect((state) => ({ affiliate: state.affiliate }))(EditPayment));
