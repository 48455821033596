import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import qs from 'qs';
import { Select } from 'antd';
import HomeIcon from 'react-feather/dist/icons/home';
import { WorkspacePage, CpRow } from '../../components';
import { ProductSearchList } from '../components';
import { fetchBestRocketProducts } from '../actions';
import * as productActionsExecutor from '../productActionsExecutor';
import categoriesBlackList from '../BestProductsCategoryBlackList';

import './BestProducts.css';
import { TrackerContext } from '../../common';

const mapStateToProps = (state) => ({
    loading: state.productlink.loading,
    categories: state.productlink.categories,
    products: state.productlink.bestProducts,
});

const mapDispatchToProps = {
    fetchBestRocketProducts,
};

class BestProducts extends Component {
    static contextType = TrackerContext;

    constructor(props) {
        super(props);

        this.unlisten = null;

        this.state = {
            category: 0,
            page: 0,
        };
    }

    componentDidMount() {
        const { history } = this.props;
        this.unlisten = history.listen(() => {
            setTimeout(() => this.searchProducts(), 0);
        });
        this.searchProducts();
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    onExecuteProductAction = (action, product) => {
        const { history } = this.props;
        switch (action) {
            case 'generateLink':
                productActionsExecutor.generateLink(undefined, product, history);
                this.context.trackEvent({ action: 'create_link', value: product.productId });
                break;
            case 'viewDetail':
                productActionsExecutor.viewDetail(product);
                this.context.trackEvent({ action: 'view_product_detail', value: product.productId });
                break;
            default:
                break;
        }
    };

    searchProducts = () => {
        const { location, fetchBestRocketProducts } = this.props;
        let queryString = qs.parse(location.search.substr(1));
        queryString.category |= 0;
        queryString.page |= 0;

        this.setState(
            {
                category: queryString.category,
                page: queryString.page,
            },
            () => {
                const { category, page } = this.state;
                fetchBestRocketProducts({
                    categoryId: category,
                    page,
                });
            }
        );
    };

    onCategorySelected = (value, option) => {
        const { history, match } = this.props;
        history.push({
            pathname: match.url,
            search: qs.stringify({ category: value, page: 0 }),
        });
    };

    onPaging = (page) => {
        const { history, match } = this.props;
        const { category } = this.state;
        history.push({
            pathname: match.url,
            search: qs.stringify({ category, page: page - 1 }),
        });
    };

    render() {
        const { intl, loading, products, categories = [] } = this.props;
        const breadcrumb = [
            {
                title: intl.formatMessage({ id: 'AFFILIATE_HOME' }),
                link: '/affiliate',
                icon: <HomeIcon size={14} />,
            },
            {
                title: intl.formatMessage({ id: 'PRODUCT_LINK' }),
                link: '/affiliate/ws/link',
            },
            { title: intl.formatMessage({ id: 'PRODUCT_LINK_BEST_PRODUCTS' }) },
        ];

        const categoriesWithAll = [{ id: 0, name: intl.formatMessage({ id: 'ALL' }) }, ...categories];
        const filteredCategories = categoriesWithAll.filter((x) => !categoriesBlackList.has(x.id));

        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'PRODUCT_LINK_BEST_PRODUCTS' })}
                loading={loading}
                className="best-products">
                <CpRow contentRelative>
                    <Select
                        className="best-products-categories"
                        value={this.state.category}
                        onSelect={this.onCategorySelected}>
                        {filteredCategories.map((x) => (
                            <Select.Option key={x.id} value={x.id}>
                                {x.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <ProductSearchList
                        total={products.total}
                        items={products.items}
                        page={this.state.page + 1}
                        onPaging={this.onPaging}
                        onAction={this.onExecuteProductAction}
                    />
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(BestProducts)));
