import React from 'react';
import moment from 'moment';
import { FormattedNumber, FormattedDate } from 'react-intl';

export function Krw(props) {
    const { value, currency } = props;
    // eslint-disable-next-line
    return value !== undefined && value !== null ? <FormattedNumber value={value} style="currency" currency={currency || 'krw'} /> : null;
}

export function YearMonth(props) {
    let { value } = props;

    if (!value) {
        return null;
    }
    if (typeof value === 'string') {
        let yearMonth = moment(value, 'YYYYMM');
        if (yearMonth.isValid()) {
            value = yearMonth.format('YYYY-MM');
        } else {
            return null;
        }
    }
    return <FormattedDate value={value} year="numeric" month="2-digit" />;
}

export function IntlDate({ value }) {
    return value ? (moment(value).isValid ? <FormattedDate value={moment(value).format('YYYY-MM-DD')} year="numeric" month="2-digit" day="2-digit" /> : null) : null;
}

export function IntlDateTime({ value }) {
    return value ? (moment(value).isValid ?<FormattedDate value={moment(value).format('YYYY-MM-DD HH:mm:ss')} year="numeric" month="2-digit" day="2-digit" hour="2-digit" minute="2-digit" second="2-digit" hourCycle="h23"  /> : null) : null;
}
