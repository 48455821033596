import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Select } from 'antd';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import { switchSubId, pickSubIds } from '../../affiliate/actions';
import './SubIdSelect.less';

const SubIdDefault = { value: 'default', key: 'SUB_ID_DEFAULT' };

class SubIdSelect extends Component {
    constructor(props) {
        super(props);

        const { currentSubId, currentSubIds } = this.props;
        const value = this.isMultiple()
            ? [...(currentSubIds || []), SubIdDefault.value]
            : currentSubId || SubIdDefault.value;
        this.containerRef = React.createRef();
        this.state = {
            value,
            opened: false,
        };
    }
    isMultiple = () => {
        return this.props.mode === 'multiple';
    };

    onChange = (value) => {
        this.setState({ value }, () => {
            if (!this.isMultiple()) {
                const { switchSubId } = this.props;

                switchSubId(value === SubIdDefault.value ? null : value);
            }
        });
    };

    onDropdownVisibleChange = (opened) => {
        this.setState({ opened });
        if (!opened && this.isMultiple()) {
            const { currentSubIds, pickSubIds } = this.props;
            const { value } = this.state;
            const temp = value.filter((item) => item !== SubIdDefault.value);
            if (!isEqual(temp.sort(), currentSubIds.sort())) {
                pickSubIds(temp);
            }
        }
    };

    render() {
        const { intl, mode, list } = this.props;
        const { value, opened } = this.state;
        const maxTagPlaceholder = () => {
            let text;
            if (value.length === 1) {
                text = intl.formatMessage({ id: SubIdDefault.key });
            } else if (value.length === 2) {
                text = value[1];
            } else {
                text = intl.formatMessage({ id: 'N_SELECTED' }, { n: value.length - 1 });
            }
            return (
                <div>
                    {text}
                    <span className={cx('expand-arrow', { opened })}>
                        <ChevronDownIcon size={16} style={{ marginLeft: 4, verticalAlign: '-0.25rem' }} />
                    </span>
                </div>
            );
        };
        return (
            <div className="sub-id-select-container" ref={this.containerRef}>
                {intl.formatMessage({ id: 'SUB_ID' })}:
                <Select
                    size="small"
                    getPopupContainer={() => this.containerRef.current}
                    {...(this.isMultiple() ? { mode, maxTagCount: 0, maxTagPlaceholder } : null)}
                    placeholder="Sub ID"
                    dropdownMatchSelectWidth={false}
                    dropdownClassName="sub-id-select-dropdown"
                    onDropdownVisibleChange={this.onDropdownVisibleChange}
                    className="sub-id-select light-select"
                    value={value}
                    onChange={this.onChange}
                    showSearch={false}>
                    <Select.Option
                        key={SubIdDefault.key}
                        value={SubIdDefault.value}
                        {...(this.isMultiple() ? { style: { display: 'none' } } : null)}>
                        {intl.formatMessage({ id: SubIdDefault.key })}
                    </Select.Option>
                    {list.map((item) => (
                        <Select.Option key={item} value={item}>
                            {item}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        );
    }
}

export default injectIntl(connect((state) => state.subId, { switchSubId, pickSubIds })(SubIdSelect));
