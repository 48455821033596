import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useStore } from 'react-redux';
import HomeIcon from 'react-feather/dist/icons/home';
import { Row, Col, Pagination } from 'antd';
import { WorkspacePage, CpRow, MessageList, FitlerSelector } from '../../components';
import { useActions } from '../../common';
import {
    fetchAllMessages,
    markMessageAsRead,
    markAllMessagesAsRead,
    fetchTop5UnreadMessages,
    setMessagePage,
    setMessageFilter,
    clearPageAndFilter,
} from '../actions';

export default injectIntl((props) => {
    const { intl } = props;
    const { allMessages, totalMessages, loadingAll, page } = useSelector((state) => state.notifications);

    const store = useStore();
    const [
        fetchAllMessagesAction,
        setMessageFilterAction,
        setMessagePageAction,
        clearPageAndFilterAction,
        fetchTop5UnreadMessagesAction,
        markAllMessagesAsReadAction,
        markMessageAsReadAction,
    ] = useActions([
        fetchAllMessages,
        setMessageFilter,
        setMessagePage,
        clearPageAndFilter,
        fetchTop5UnreadMessages,
        markAllMessagesAsRead,
        markMessageAsRead,
    ]);

    useEffect(() => {
        fetchAllMessagesAction(store);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        return () => {
            clearPageAndFilterAction();
        };
    }, []);

    function handleMessageFilterChange(filters) {
        setMessageFilterAction(filters);
        fetchAllMessagesAction(store);
    }

    function handlePaging(page) {
        setMessagePageAction(page);
        fetchAllMessagesAction(store);
    }

    function refresh() {
        fetchTop5UnreadMessagesAction();
        fetchAllMessagesAction(store);
    }

    async function handleMarkAsRead({ receiverId }) {
        await markMessageAsReadAction(receiverId);
        refresh();
    }

    async function handleMarkAllAsRead() {
        await markAllMessagesAsReadAction();
        refresh();
    }

    async function handleLinkClicked(target, receiverId, readFlag) {
        if (!readFlag) {
            await markMessageAsReadAction(receiverId);
            fetchTop5UnreadMessagesAction();
            // Only refresh the whole list when the link opens in a new tab/window
            if (target && target.getAttribute && target.getAttribute('target') === '_blank') {
                fetchAllMessagesAction(store);
            }
        }
    }

    const breadcrumb = [
        { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
        { title: intl.formatMessage({ id: 'NOTIFICATION_TITLE' }) },
    ];

    return (
        <WorkspacePage
            className="notification-messages-page"
            loading={false}
            breadcrumb={breadcrumb}
            title={intl.formatMessage({ id: 'NOTIFICATION_TITLE' })}
            titleActionsOnSameRow
            titleActions={[
                <FitlerSelector
                    style={{ color: '#1890ff', fontWeight: 'normal' }}
                    items={[
                        { id: 'GENERAL_INFO', text: intl.formatMessage({ id: 'NOTIFICATION_TYPE_GENERAL' }) },
                        { id: 'ALERT', text: intl.formatMessage({ id: 'NOTIFICATION_TYPE_ALERT' }) },
                        { id: 'PROMOTION', text: intl.formatMessage({ id: 'NOTIFICATION_TYPE_PROMOTION' }) },
                    ]}
                    allText={intl.formatMessage({ id: 'NOTIFICATION_ANY_TYPE' })}
                    onChange={handleMessageFilterChange}
                />,
                <a className="" onClick={handleMarkAllAsRead}>
                    {intl.formatMessage({ id: 'NOTIFICATION_MARK_ALL_AS_READ' })}
                </a>,
            ]}>
            <CpRow>
                <Row gutter={[0,24]} justify="end">
                    <Col span={24}>
                        <MessageList
                            loading={loadingAll}
                            messages={allMessages}
                            onMarkAsRead={handleMarkAsRead}
                            onLinkClicked={handleLinkClicked}
                        />
                    </Col>
                    <Col span={24} style={{textAlign:'right'}}>
                        <Pagination
                            showSizeChanger={false}
                            total={totalMessages}
                            current={page+1}
                            onChange={(page)=>handlePaging(page-1)}
                        />
                    </Col>
                </Row>
            </CpRow>
        </WorkspacePage>
    );
});
