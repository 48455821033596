import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import AlertCircleIcon from 'react-feather/dist/icons/alert-circle';

class NoResult extends Component {
    render() {
        const { intl, className, style } = this.props;
        return <div className={cx("align-center", className)} style={style}>
            <AlertCircleIcon size={64} color="#efefef" />
            <p>{intl.formatMessage({ id: 'PRODUCT_LINK_NO_RESULT' })}</p>
        </div>;
    }
}

export default injectIntl(NoResult)