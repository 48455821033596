import React, { PureComponent } from 'react';

import { Modal } from 'antd';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PopupNotificationContent from './PopupNotificationContent';
import { normalizeImageUrl } from '../../common';
import DashboardApi from '../api';

import './PopupNotification.css';

class PopupNotification extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        };
        this.ref = null;
    }

    close() {
        this.setState({
            visible: false
        });
        this.onHandled();
    }

    onHandled = () => {
        if (this.props.onHandled) {
            this.props.onHandled();
        }
    }

    handleClick = (e) => {
        const { history, item } = this.props;
        const action = e.target.getAttribute('data-action');
        const actionParams = e.target.getAttribute('data-action-params');

        if (action === 'close') {
            DashboardApi.persistPopupAction(item.contentConfigurationId).then(() => this.close());
        } else if (action === 'navigate') {
            DashboardApi.persistPopupAction(item.contentConfigurationId)
            .then(() => {
                this.close();
                if(actionParams){
                    history.push(actionParams);
                }
            });
        }
    }


    render() {
        const { item: { content: { type, width, style, html } }, imageCdnUrl } = this.props;
        const { visible } = this.state;
        return (
            <Modal visible={visible} closable={false} width={width} style={style} wrapClassName="popup-notification-modal modal no-padding" title={null} footer={null}>
                <PopupNotificationContent>

                    <div className={{ '1': 'notification-content-html-img', '2': 'notification-content-html-advanced' }[type]} dangerouslySetInnerHTML={{ __html: normalizeImageUrl(html, imageCdnUrl) }} onClick={this.handleClick} />

                </PopupNotificationContent>
            </Modal>
        );
    }
}

export default withRouter(connect(state => ({ imageCdnUrl: state.config.imageCdnUrl }))(PopupNotification))

