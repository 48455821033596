import { notification } from 'antd';
import { FETCH_DASHBOARD_INIT_DATA, FETCH_POPUP_NOTIFICATIONS } from './actionTypes';

import { asyncCall, begin, success, fail } from '../common';
import Api from './api';

export const fetchDashboardInitData = () => {
    // return asyncCall(FETCH_DASHBOARD_INIT_DATA, Api.fetchDashboardInitData, { notifyError: true });
    return (dispatch) => {
        dispatch({ type: begin`${FETCH_DASHBOARD_INIT_DATA}` });
        const fetchInitData = Api.fetchDashboardInitData();
        // const fetchBest11 = Api.fetchBest11Products(null);

        Promise.all([fetchInitData]).then(responses => {
            const [resCategory] = responses;
            const initData = resCategory.data.data;

            dispatch({
                type: success`${FETCH_DASHBOARD_INIT_DATA}`, payload: { ...initData }
            });
        }).catch(err => {
            dispatch({ type: fail`${FETCH_DASHBOARD_INIT_DATA}` });
            if (err.data && err.data.rMessage) {
                notification.warn({
                    message: "에러",
                    description: err.data.rMessage
                });
            }
        });
    };
}

// export const fetchBest11Products = (categoryId) => {
//     return asyncCall(FETCH_BEST_11_PRODUCTS, () => {
//         return Api.fetchBest11Products(categoryId)
//     }, { notifyError: true });
// }

export const fecthPopupNotifications = () => {
    return asyncCall(FETCH_POPUP_NOTIFICATIONS, () => {
        return Api.fecthPopupNotifications();
    }, {});
}