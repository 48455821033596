import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { ConsentLinks } from '../../components';

export default function MarketingConsentSubfrom({ form }) {
    const intl = useIntl();
    const [marketingEmailDisabled, setMarketingEmailDisabled] = useState(false);

    const onMarketingPiCollectionChagne = (val) => {
        if (Array.isArray(val) && val.length > 0) {
            form.setFieldsValue({ marketingEmail: ['MARKETING_EMAIL'] });
        } else {
            form.setFieldsValue({ marketingEmail: [] });
        }
    };

    const onMarketingEmailChange = (val) => {
        if (Array.isArray(val) && val.length > 0) {
            form.setFieldsValue({ marketingPiCollection: ['MARKETING_PI_COLLECTION'] });
        } else {
        }
    };

    return (
        <React.Fragment>
            <Form.Item>
                {form.getFieldDecorator('marketingPiCollection')(
                    <ConsentLinks
                        onChange={onMarketingPiCollectionChagne}
                        items={[
                            {
                                key: 'MARKETING_PI_COLLECTION',
                                title: intl.formatMessage({ id: 'MARKETING_PI_COLLECTION' }),
                            },
                        ]}
                        rs={{ Confirm: intl.formatMessage({ id: 'CONFIRM' }) }}
                    />
                )}
            </Form.Item>
            <Form.Item>
                {form.getFieldDecorator('marketingEmail')(
                    <ConsentLinks
                        onChange={onMarketingEmailChange}
                        items={[
                            {
                                key: 'MARKETING_EMAIL',
                                title: intl.formatMessage({ id: 'MARKETING_EMAIL' }),
                            },
                        ]}
                        rs={{ Confirm: intl.formatMessage({ id: 'CONFIRM' }) }}
                    />
                )}
            </Form.Item>
        </React.Fragment>
    );
}
