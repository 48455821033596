
const DimensionList = [
    { id: 'product', key: 'RP_PRODUCT', },
    { id: 'linkType', key: 'RP_LINK_TYPE', },
    { id: 'linkModule', key: 'RP_LINK_MODULE', },
    { id: 'traceId', key: 'RP_TRACE_ID', },
    { id: 'subId', key: 'RP_SUB_ID', },
    { id: 'platform', key: 'RP_PLATFORM', },
    { id: 'pageType', key: 'RP_PAGE_TYPE', },
    { id: 'pageKey', key: 'RP_PAGE_KEY', },
    { id: 'trackingCode', key: 'TRACKING_CODE', },
    { id: 'referralPeriod', key: 'RP_REFERRAL_PERIOD', sortable: true, },
];

export { DimensionList }