import axios from 'axios';

export default class Api {
    static unsubscribe(reason, otherReasonText, payout) {
        return axios.post('/api/v1/affiliate/unsubscribe', {
            reason,
            otherReasonText,
            payout,
        });
    }

    static checkAffiliateStatusForDeactivation() {
        return axios.get('/api/v1/affiliate/unsubscribe');
    }

    static checkAffiliateCodeIsExisting(affiliateCode) {
        return axios.get(`/api/v1/affiliate/registration/referrer/${affiliateCode}`);
    }

    static closeDeactivatedAccount() {
        return axios.post('/api/v1/affiliate/closeDeactivatedAccount');
    }

    static fetchSubIds() {
        return axios.get(`/api/v1/subId/internal`);
    }

    static addSubId(subId) {
        return axios.post(`/api/v1/subId`, { subId });
    }

    static deleteSubId(subId) {
        return axios.post(`/api/v1/subId/delete/${subId}`);
    }

    static getSubIds(payload) {
        return axios.post('/api/v1/subId/all', payload);
    }

    static fetchTop5UnreadMessages() {
        return axios.get('/api/v1/notification/top');
    }

    static fetchAllMessages(requestPayload) {
        return axios.post('/api/v1/notification/all', requestPayload);
    }

    static markAsRead(id) {
        return axios.post(`/api/v1/notification/markAsRead/${id}`);
    }

    static markAllAsRead() {
        return axios.post(`/api/v1/notification/markAllAsRead`);
    }

    static getOptInOutSettings(values) {
        return axios.get(`/api/v1/opt-in-out-settings`, values);
    }

    static updateOptInOutSettings(values) {
        return axios.post(`/api/v1/opt-in-out-settings`, values);
    }
}
