import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Form, Button, notification } from 'antd';
import { CardPage } from '../../components';
import { IndividualPayerInformationFieldset, ContactFieldset, BankAccountFieldset } from '../components';
import {
    validateRRN,
    validateBankAccount,
    fetchOtherIndustriesPaymentForAffiliateTypeChange,
    updateIndividualPayment,
} from '../actions';
import { TrackerContext, Consts } from '../../common';
import MemberInfo from '../../registration/components/MemberInfo';
import Api from '../api';

const mapStateToProps = (state) => ({
    bankNameAndCodes: state.payment.bankNameAndCodes,
    data: state.payment.data,
    loading: state.payment.loading,
    memberInfo: state.payment.memberInfo,
    piiInputStatus: state.payment.piiInputStatus,
    affiliateType: state.affiliate.affiliateType,
    activated: state.affiliate.activated && state.config.maintainingEnabled !== true,
});

const mapDispatchToProps = {
    validateRRN,
    validateBankAccount,
    fetchOtherIndustriesPaymentForAffiliateTypeChange,
    updateIndividualPayment,
};

class ChangeBusinessTypeToIndividual extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
            processing: false,
        };
        this.bankAccountRef = React.createRef();
    }

    onNameChange = (e) => {
        this.setState({
            name: e,
        });
    };

    componentDidMount() {
        this.context.trackPageView({ action: 'change_business_type_to_individual' });

        const { form, fetchOtherIndustriesPaymentForAffiliateTypeChange } = this.props;

        if (fetchOtherIndustriesPaymentForAffiliateTypeChange) {
            fetchOtherIndustriesPaymentForAffiliateTypeChange().then((response) => {
                const { rCode, data } = response.data;
                if (rCode === '0') {
                    const { name, bankCode, account, accountOwner } = data.otherIndustries;
                    this.setState({
                        name,
                    });
                    form.setFieldsValue({ bankCode, account, accountOwner });
                }
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { intl, form, affiliateType, history } = this.props;

        form.validateFields({}, (err, values) => {
            if (!err) {
                values.affiliateType = affiliateType;
                if (values.rrn) {
                    values.rrn = values.rrn.raw;
                }
                values.address = values.address || '';
                values.mobile = values.mobile || '';
                values.contactName = values.name;

                this.setState({ processing: false }, () => {
                    Api.changeOtherIndustriesToIndividual(values)
                        .then((res) => {
                            this.context.trackEvent({ action: 'change_business_type_to_individual_complete' });
                            history.push('/affiliate');
                            window.location.reload();
                        })
                        .catch((err) => {
                            if (err && err.data && err.data.rMessage) {
                                const { rMessage } = err.data;
                                notification.warn({
                                    message: intl.formatMessage({ id: 'NOTICE' }),
                                    description: rMessage,
                                });
                            } else {
                                console.error(err);
                            }
                        })
                        .finally(() => {
                            this.setState({ processing: false });
                        });
                });
            }
        });
    };

    setBankAccountDirty() {
        const { current } = this.bankAccountRef;
        if (current) {
            const bankAccountInst = current.getWrappedInstance();
            bankAccountInst.setDirty();
        }
    }

    handleRRNVerified = () => {
        this.setBankAccountDirty();
    };

    render() {
        const {
            intl,
            form,
            loading,
            activated,
            bankNameAndCodes,
            data,
            validateRRN,
            validateBankAccount,
            piiInputStatus,
            memberInfo,
        } = this.props;
        const { processing } = this.state;

        return (
            <div className="edit-individual-payment">
                <CardPage loading={loading}>
                    <Form layout="vertical" hideRequiredMark={false} onSubmit={this.handleSubmit}>
                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <React.Fragment>
                                {(piiInputStatus === Consts.PiiInputStatus.Opened ||
                                    piiInputStatus === Consts.PiiInputStatus.Completed) && (
                                    <MemberInfo memberInfo={memberInfo} piiInputStatus={piiInputStatus} />
                                )}
                                <IndividualPayerInformationFieldset
                                    form={form}
                                    data={data}
                                    validateRRN={validateRRN}
                                    disabled={piiInputStatus !== Consts.PiiInputStatus.Opened}
                                    rrnVerified={this.handleRRNVerified}
                                />
                            </React.Fragment>
                        )}

                        <ContactFieldset form={form} data={data} includeContactName={false} disabled={!activated} />

                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <BankAccountFieldset
                                type="INDIVIDUAL"
                                ref={this.bankAccountRef}
                                form={form}
                                data={data}
                                valid={true}
                                disabled={!activated}
                                banks={bankNameAndCodes}
                                validateBankAccount={validateBankAccount}
                                useMemberName={true}
                            />
                        )}
                        {activated ? (
                            <div className="button-bar align-center">
                                <Button
                                    size="large"
                                    type="primary"
                                    htmlType="button"
                                    loading={processing}
                                    onClick={this.handleSubmit}>
                                    {intl.formatMessage({ id: 'SAVE' })}
                                </Button>
                            </div>
                        ) : null}
                    </Form>
                </CardPage>
            </div>
        );
    }
}

export default Form.create()(
    injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangeBusinessTypeToIndividual)))
);
