import React, { Component } from 'react';
import { withRouter, useLocation, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as StatusHelper from './Helper';

class ProtectedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            failedCondition: this.calcFailedCondition(),
        };
    }

    calcFailedCondition = () => {
        const { conditions } = this.props;
        let failedCondition = (conditions || []).find((x) => {
            if (typeof x.condition === 'function') {
                return !x.condition();
            } else {
                return !x.condition;
            }
        });
        return failedCondition;
    };

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({
                failedCondition: this.calcFailedCondition(),
            });
        }
    }

    render() {
        const { component: InputComponent, ...rest } = this.props;
        const { failedCondition } = this.state;
        return (
            <Route
                {...rest}
                render={(props) =>
                    !failedCondition ? (
                        <InputComponent {...props} />
                    ) : (
                        <Redirect to={{ pathname: failedCondition.fallback, state: { from: props.location } }} />
                    )
                }
            />
        );
    }
}

export default withRouter(ProtectedRoute);

export const LandingRoute = ({ component: InputComponent, loggedIn, affiliate, ...rest }) => {
    const location = useLocation();
    const { config } = useSelector((state) => state);
    let target = '';
    let search = '';
    if (!window.nonav && loggedIn) {
        const { regFinalStatus } = affiliate || {};

        if (!regFinalStatus && !window.sessionStorage.entrance) {
            // Not registered yet, and no entrance (not from login or signup button)
        } else if (!StatusHelper.isAffiliateApproved(affiliate)) {
            target = '/reg';
        } else {
            target = '/affiliate';
        }
    }
    if (location.state && location.state.from) {
        const { pathname, search } = location.state.from;
        window.sessionStorage.setItem('returnUrl', JSON.stringify({ pathname, search }));
        window.location = config.loginUrl;
        return null;
    }
    if (window.sessionStorage.getItem('returnUrl')) {
        const o = JSON.parse(window.sessionStorage.getItem('returnUrl'));
        target = o.pathname;
        search = o.search;
        window.sessionStorage.removeItem('returnUrl');
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                !target ? (
                    <InputComponent {...props} />
                ) : (
                    <Redirect to={{ pathname: target, search: search, state: { from: props.location } }} />
                )
            }
        />
    );
};

export const AffiliateRoute = ({ component, loggedIn, regFinalStatus, requireLogin, allowedStatuses, ...rest }) => {
    let conditions = [];
    if (requireLogin) {
        conditions.push({ condition: loggedIn, fallback: '/' });
    }
    if (Array.isArray(allowedStatuses)) {
        conditions.push({
            condition: allowedStatuses.includes(regFinalStatus),
            fallback: '/',
        });
    } else if (typeof allowedStatuses === 'function') {
        conditions.push({
            condition: allowedStatuses,
            fallback: '/',
        });
    }
    return <ProtectedRoute {...rest} component={component} conditions={conditions} />;
};

export const CoupangRoute = ({ component: InputComponent, ...rest }) => {
    const location = useLocation();
    const { config, session } = useSelector((state) => state);
    const loggedIn = session.authenticated;
    if (!loggedIn) {
        // window.sessionStorage.setItem('returnUrl', JSON.stringify({ pathname: location.pathname, search: location.search }));
        window.location.replace(config.loginUrl);
        return null;
    }
    return <Route {...rest} render={(props) => <InputComponent {...props} />} />;
};
