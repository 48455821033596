import React, { useState, useRef, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { notification } from 'antd';
import HomeIcon from 'react-feather/dist/icons/home';
import RetryIcon from 'react-feather/dist/icons/refresh-ccw';
import RemoveIcon from 'react-feather/dist/icons/trash-2';
import DownloadIcon from 'react-feather/dist/icons/download';
import { WorkspacePage, DataTable, CpRow, IntlDate, FileSize, confirmHoc } from '../../components';
import { Consts, TrackerContext } from '../../common';

import Api from '../api';

import './DownloadTasks.less';

const { AsyncJobStatus, AsyncJobTypes } = Consts;
const DeleteConfirm = confirmHoc(RemoveIcon);

export default function () {
    const intl = useIntl();
    const { trackEvent } = useContext(TrackerContext);
    const tableRef = useRef(null);
    const [processing, setProcessing] = useState(false);

    const breadcrumb = [
        { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
        { title: intl.formatMessage({ id: 'HEADER_REPORT_DOWNLOAD_REPORT' }) },
    ];
    const columns = [
        {
            title: intl.formatMessage({ id: 'DOWNLOAD_REPORT_COL_ID' }),
            dataIndex: 'jobId',
            width: 100,
        },
        {
            title: intl.formatMessage({ id: 'DOWNLOAD_REPORT_COL_TYPE' }),
            width: 110,
            dataIndex: 'jobType',
            render: (jobType) => {
                return intl.formatMessage({ id: `DOWNLOAD_REPORT_JOB_TYPE_${jobType}` });
            },
        },
        {
            title: intl.formatMessage({ id: 'DOWNLOAD_REPORT_COL_PERIOD' }),
            width: 170,
            align: 'center',
            render: ({ jobParameters: { startDate, endDate } = {}, jobType }) => {
                if (jobType === AsyncJobTypes.AffiliateDailyTrend && startDate && endDate) {
                    return (
                        <React.Fragment>
                            <IntlDate value={startDate} /> ~ <IntlDate value={endDate} />
                        </React.Fragment>
                    );
                }
            },
        },
        {
            title: intl.formatMessage({ id: 'DOWNLOAD_REPORT_COL_SUBIDS' }),
            render: ({ jobParameters: { subIds } = {}, jobType }) => {
                if (jobType === AsyncJobTypes.AffiliateDailyTrend && Array.isArray(subIds)) {
                    return subIds.join(', ');
                }
            },
        },
        {
            title: intl.formatMessage({ id: 'DOWNLOAD_REPORT_COL_STATUS' }),
            dataIndex: 'jobStatus',
            width: 120,
            render: (val) => (
                <span className={cx('job-status', `job-status-${val}`)}>
                    {intl.formatMessage({ id: `DOWNLOAD_REPORT_STATUS_${val}` })}
                </span>
            ),
        },
        {
            title: intl.formatMessage({ id: 'DOWNLOAD_REPORT_COL_SIZE' }),
            width: 95,
            render: ({ jobResult }) => {
                return jobResult && jobResult.size > 0 ? <FileSize val={jobResult.size} /> : '';
            },
        },
        {
            title: intl.formatMessage({ id: 'DOWNLOAD_REPORT_COL_CREATED_DATE' }),
            dataIndex: 'createdDate',
            width: 110,
            align: 'center',
            render: (val) => <IntlDate value={val} />,
        },
        {
            title: intl.formatMessage({ id: 'ACTIONS' }),
            width: 130,
            align: 'center',
            render: (val, { jobId, jobStatus }) => {
                return (
                    <div className="icons-bar">
                        {jobStatus === AsyncJobStatus.Failed && (
                            <RetryIcon
                                size={18}
                                className="clickable"
                                color="#aeb0b4"
                                onClick={() => handleRetry(jobId)}
                            />
                        )}
                        {jobStatus === AsyncJobStatus.Succeed && (
                            <DownloadIcon
                                size={18}
                                className="clickable"
                                color="#aeb0b4"
                                onClick={() => handleDownload(jobId)}
                            />
                        )}
                        {jobStatus !== AsyncJobStatus.Running && (
                            <DeleteConfirm
                                size={18}
                                className="clickable"
                                color="#aeb0b4"
                                confirmOptions={{
                                    title: intl.formatMessage({ id: 'CONFIRM' }),
                                    description: intl.formatMessage(
                                        { id: 'DOWNLOAD_REPORT_JOB_DELETE_CONFIRM' },
                                        { id: jobId }
                                    ),
                                    contentAlignment: 'left',
                                }}
                                onOk={() => handleDeleteDownloadTask(jobId)}
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    function refreshTable() {
        tableRef && tableRef.current && tableRef.current.refresh && tableRef.current.refresh(false);
    }

    function handleRetry(id) {
        setProcessing(true);
        trackEvent({ action: 'download_report_retry_job' });
        Api.retryDownloadJob(id)
            .then(refreshTable)
            .catch((err) => {
                if (err && err.data && err.data.rMessage) {
                    notification.warn({ message: intl.formatMessage({ id: 'ERROR' }), description: err.data.rMessage });
                }
            })
            .finally(() => {
                setProcessing(false);
            });
    }

    function handleDeleteDownloadTask(id) {
        setProcessing(true);
        trackEvent({ action: 'download_report_delete_job' });
        Api.deleteDownloadJob(id)
            .then(refreshTable)
            .catch((err) => {
                if (err && err.data && err.data.rMessage) {
                    notification.warn({ message: intl.formatMessage({ id: 'ERROR' }), description: err.data.rMessage });
                }
            })
            .finally(() => {
                setProcessing(false);
            });
    }

    function handleDownload(id) {
        trackEvent({ action: 'download_report_real_download' });
        window.location = Api.getAsyncDownloadUrl(id);
    }

    useEffect(() => {
        const timer = setInterval(() => {
            refreshTable();
        }, 60000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <WorkspacePage
            breadcrumb={breadcrumb}
            title={intl.formatMessage({ id: 'HEADER_REPORT_DOWNLOAD_REPORT' })}
            subTitle={intl.formatMessage({ id: 'DATA_RETENTION_FOR_180_DAYS' })}
            loading={processing}
            className="report-download-tasks">
            <CpRow>
                <DataTable ref={tableRef} rowKey="jobId" columns={columns} getData={Api.getAsyncDownloadJobs} />
            </CpRow>
        </WorkspacePage>
    );
}
