import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Upload, Modal, Icon } from 'antd';

import './PictureCardUploader.css';

export default class PictureCardUploader extends Component {
    static propTypes = {
        fileList: PropTypes.array,
        length: PropTypes.number,
        disabled: PropTypes.bool,
        accept: PropTypes.string
    };

    static defaultProps = {
        fileList: [],
        length: 1,
        disabled: false,
        accept: '.jpg,.jpeg,.png',
    }

    constructor(props) {
        super(props);
        this.state = {
            fileList: props.fileList,
            previewImage: null,
            previewVisible: false
        };
    }

    beforeUpload = (file) => {
        this.setState(({ fileList }) => ({
            fileList: [...fileList, file],
        }));
        return false;
    }

    onPreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true
        });
    }

    onClosePreview = () => {
        this.setState({
            previewVisible: false
        });
    }

    onChange = ({ fileList }) => {
        this.setState({ fileList });
        const onChange = this.props.onChange;
        if (onChange) {
            onChange([...fileList]);
        }
    }

    componentWillReceiveProps(nextProps) {
        if ('fileList' in nextProps) {
            this.setState({ fileList: nextProps.fileList });
        }
    }

    render() {
        const UploadButton = (
            <div>
                <Icon type="plus" />
            </div>
        );
        const { length, disabled, accept } = this.props;
        const { fileList, previewImage, previewVisible } = this.state;
        return (
            <div>
                <Upload
                    className="picture-card-uploader"
                    disabled={disabled}
                    showUploadList={{
                        showRemoveIcon: !disabled
                    }}
                    accept={accept}
                    listType="picture-card"
                    fileList={fileList}
                    beforeUpload={this.beforeUpload}
                    onPreview={this.onPreview}
                    onChange={this.onChange}>
                    {length > fileList.length ? UploadButton : null}
                </Upload>
                <Modal wrapClassName="picture-uploader-modal" width={560} visible={previewVisible} footer={null} onCancel={this.onClosePreview}>
                    <img alt="" style={{ maxWidth: 560 }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}
