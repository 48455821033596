import React from 'react';
import { useIntl } from 'react-intl';
import { useFetch } from '../../../common';
import Api from '../../api';
import styles from './CommissionCapNotification.module.less';

export default function CommissionCapNotification(){
  const {data} = useFetch(() => Api.fecthCommissionCapNotification());
  const intl = useIntl();
  return (
    // <div style={{border:'1px solid #F0F0F0',borderRadius:8,padding:24,marginBottom:40}}>
    data ? <div className={styles.box}>
      {
        intl.formatMessage({id:'EARNING_NOTIFICATION'}, {
          br:<br />,
          commissionCap: <span className={styles.value}>{intl.formatNumber(data.commissionCap, { style: 'currency', currency: 'KRW' })}</span>,
          remainingCap: <span className={styles.value}>{intl.formatNumber(data.remainingCap, { style: 'currency', currency: 'KRW' })}</span>,
          estimatedCapSpendingRate: <span className={styles.value}>{intl.formatNumber(data.estimatedCapSpendingRate, { style: 'percent' })}</span>,
          avgDailyCommission: <span className={styles.value}>{intl.formatNumber(data.avgDailyCommission, { style: 'currency', currency: 'KRW' })}</span>,
        })
      }
    </div> : null
  );
}