import React from 'react';
import { Switch } from 'antd';
import { useState } from 'react';
import Api from '../api';

export default function DynamicWidgetPassbackSwitch({ widget }) {
    const [enabled, setEnabled] = useState(widget.passbackEnabled);
    const [loading, setLoading] = useState(false);
    const onClick = async (checked) => {
        if (!loading) {
            try {
                setLoading(true);
                const { data } = await Api.enableWidgetInstancePassback(widget.widgetInstanceId, checked);

                const { rCode } = data;
                if (rCode === '0') {
                    setEnabled(checked);
                }
            } catch {
            } finally {
                setLoading(false);
            }
        }
    };
    return <Switch loading={loading} checked={enabled} onClick={onClick} />;
}
