// import { injectIntl } from 'react-intl';

// export const TString = injectIntl(({ intl, id, params }) => {
//     const msg = intl.formatMessage({ id, params });
//     return msg;
// });

export function formatMessage(intl, id) {
    return intl.formatMessage({ id });
}

export function fieldMessage(intl, msgId, values) {
    const translatedValues = {}
    if (values) {
        const valuesType = typeof values;
        if (valuesType === 'object') {
            for (let prop of Object.getOwnPropertyNames(values)) {
                translatedValues[prop] = intl.formatMessage({ id: values[prop] });
            }
        }
        else if (valuesType === 'string') {
            translatedValues['name'] = intl.formatMessage({ id: values, defaultMessage: values });
        }
    }
    return intl.formatMessage({ id: msgId }, translatedValues);
}

export function requiredMessage(intl, values) {
    return fieldMessage(intl, 'VAL_MSG_FIELD_REQUIRED', values);
}

export function invalidMessage(intl, values) {
    return fieldMessage(intl, 'VAL_MSG_INVALID_FIELD', values);
}