import React from 'react';
import { injectIntl } from 'react-intl';
import { DataTable, DimensionList, MetricList, MetricValue } from '../../components';
import Api from '../api';

export default injectIntl(function({
    intl,
    reportName,
    rowKey,
    primaryDimension,
    hidePrimaryDimensionColumn,
    secondaryDimensions = [],
    metrics,
    extraColumns,
    extraFilters,
}) {
    const d = DimensionList.find(item => item.id == primaryDimension);
    let columns = [];
    if (!hidePrimaryDimensionColumn) {
        columns.push({
            title: intl.formatMessage({ id: d.key }),
            dataIndex: primaryDimension,
        });
    }
    secondaryDimensions.forEach(item => {
        let secondaryDimension = DimensionList.find(z => z.id === item);
        columns.push({
            title: intl.formatMessage({ id: secondaryDimension.key }),
            dataIndex: item,
            sorter: secondaryDimension.sortable,
        });
    });
    metrics.forEach(item => {
        columns.push({
            title: intl.formatMessage({ id: MetricList.find(z => z.id === item).key }),
            dataIndex: item,
            sorter: true,
            align: 'right',
            render: value => {
                return <MetricValue metric={item} value={value} />;
            },
        });
    });
    if(Array.isArray(extraColumns)){
        columns = columns.concat(extraColumns)
    }
    const load = Api.makeReportApi(reportName, primaryDimension);
    
    return <DataTable rowKey={rowKey} columns={columns} extraFilters={extraFilters} getData={load} />;
});
