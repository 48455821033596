import {
    SEARCH_PRODUCT,
    FETCH_LINK_GENERATION_FORM_INITDATA,
    // FETCH_BEST_11_PRODUCTS,
    FETCH_BEST_ROCKET_PRODUCTS,
    GENERATE_BANNER_IMAGE,
    FETCH_CATEGORY_BANNER_INITDATA,
} from './actionTypes';

import axios from 'axios';
import { notification } from 'antd';
import { begin, fail, success, asyncCall } from '../common';
import Api from './api';

const ERROR = '에러';


// export const fetchBest11Products = categoryId => {
//     return dispatch => {
//         dispatch({ type: begin`${FETCH_BEST_11_PRODUCTS}` });
//         axios
//             .post('/api/v1/search/best', {
//                 page: { pageNumber: 0, size: 11 },
//                 categoryId: categoryId,
//                 // deliveryTypes: ['ROCKET', 'ROCKET_PLUS_2']
//             })
//             .then(res => {
//                 dispatch({
//                     type: success`${FETCH_BEST_11_PRODUCTS}`,
//                     payload: res.data.data ? res.data.data.content : [],
//                 });
//             })
//             .catch(err => {
//                 dispatch({
//                     type: fail`${FETCH_BEST_11_PRODUCTS}`,
//                     payload: err,
//                 });
//                 if (err.data && err.data.rMessage) {
//                     notification.warn({
//                         message: ERROR,
//                         description: err.data.rMessage,
//                     });
//                 }
//             });
//     };
// };

export const searchProduct = ({ category, term, deliveryTypes, page, loadMore }) => {
    return (dispatch, getState) => {
        dispatch({ type: begin`${SEARCH_PRODUCT}` });
        const criteria = {
            page: { pageNumber: page || 0, size: 36 },
            filter: term,
        };
        if (category) {
            criteria.categoryId = category;
        }
        if (deliveryTypes && Array.isArray(deliveryTypes)) {
            criteria.deliveryTypes = deliveryTypes;
        }
        return axios
            .post('/api/v1/search', criteria)
            .then(res => {
                if (loadMore) {
                    const items = getState().productlink.products.items;
                    res.data.data.content = items.concat(res.data.data.content);
                }
                dispatch({
                    type: success`${SEARCH_PRODUCT}`,
                    payload: res.data.data,
                });
            })
            .catch(err => {
                dispatch({ type: fail`${SEARCH_PRODUCT}`, payload: err });
                if (err.data && err.data.rMessage) {
                    notification.warn({
                        message: ERROR,
                        description: err.data.rMessage,
                    });
                }
            });
    };
};

export const fetchLinkGenerationInitData = (group, product) => {
    return asyncCall(FETCH_LINK_GENERATION_FORM_INITDATA, () => Api.fetchLinkGenerationInitData(group, product), {
        notifyError: true,
    });
};

export const fetchBestRocketProducts = ({ categoryId, page }) => {
    return (dispatch, getState) => {
        dispatch({ type: begin`${FETCH_BEST_ROCKET_PRODUCTS}` });
        let categories = getState().productlink.categories;

        let fetchCategories = null;
        if (!categories || !categories.length) {
            fetchCategories = axios.get('/api/v1/search');
        }

        let fetchBestProducts = axios.post('/api/v1/search/best', {
            page: { pageNumber: page || 0, size: 36 },
            categoryId: categoryId === 0 ? undefined : categoryId,
            // deliveryTypes: ['ROCKET', 'ROCKET_PLUS_2']
        });
        return Promise.all([fetchCategories, fetchBestProducts])
            .then(responses => {
                const [categoriesRes, productsRes] = responses;

                dispatch({
                    type: success`${FETCH_BEST_ROCKET_PRODUCTS}`,
                    payload: {
                        categories: categoriesRes ? categoriesRes.data.data.categories : null,
                        products: productsRes ? productsRes.data.data : { content: [], total: 0 },
                    },
                });
            })
            .catch(err => {
                dispatch({
                    type: fail`${FETCH_BEST_ROCKET_PRODUCTS}`,
                    payload: err,
                });
                if (err.data && err.data.rMessage) {
                    notification.warn({
                        message: ERROR,
                        description: err.data.rMessage,
                    });
                }
            });
    };
};

export const generateBannerImage = (group, product, options) => {
    return asyncCall(GENERATE_BANNER_IMAGE, () => Api.generateBannerImage(group,product, options), { notifyError: true });
};

export const fetchCategoryBannerInitData = ({ datasetId, size }) => {
    return asyncCall(FETCH_CATEGORY_BANNER_INITDATA, () => Api.fetchCategoryBannerInitData({ datasetId, size }), {
        notifyError: true,
    });
};
