import React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

export default function PiiCollectionLaterTips({ className }) {
    const intl = useIntl();
    return (
        <div
            className={cx('dark-grey-box', className)}
        >
            {intl.formatMessage({ id: 'REGISTRATION_PII_COLLECTION_LATER_TIPS' }, { br: <br /> })}
        </div>
    );
}
