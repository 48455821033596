import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import HomeIcon from 'react-feather/dist/icons/home';
import { Row, Col, Button } from 'antd';
import { WorkspacePage, CpRow, Copy2Clipboard, SnsSharer, UnselectableInput, IntlDate } from '../../components';
import { useFetch, TrackerContext, AffiliateFeeNotice } from '../../common';
import Api from '../api';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function () {
    const intl = useIntl();
    const breadcrumb = [
        { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
        { title: intl.formatMessage({ id: 'EVENT_LIST_TITLE' }), link: '/affiliate/ws/events' },
        { title: intl.formatMessage({ id: 'EVENT_TITLE' }) },
    ];
    const currentSubId = useSelector((state) => state.subId.currentSubId);
    const { id } = useParams();
    const { trackEvent } = useContext(TrackerContext);
    const { fetching, res, doFetch } = useFetch(() => Api.getEvent(id), { autoLoad: false });
    const data = res && res.data.data;

    const onCopyTrackingUrl = () => {
        trackEvent({ action: 'copy_event_promotions_short_url', value: id });
    };

    useEffect(() => {
        doFetch();
    }, [currentSubId]);

    return (
        <WorkspacePage
            breadcrumb={breadcrumb}
            className="event"
            title={intl.formatMessage({ id: 'EVENT_TITLE' })}
            subTitle={data && data.eventName}
            withSubId={true}
            loading={fetching}>
            <CpRow>
                {data && (
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Row type="flex" justify="center">
                                <Col lg={12} span={24}>
                                    <img src={data.image} width="100%" />
                                </Col>
                            </Row>
                        </Col>
                        {(data.startDate || data.endDate) && (
                            <Col span={24}>
                                <div className="align-center ft-12">
                                    <span>{intl.formatMessage({ id: 'EVENT_PERIOD' })}:</span>{' '}
                                    <IntlDate value={data.startDate} /> ~ <IntlDate value={data.endDate} />
                                </div>
                            </Col>
                        )}
                        <Col span={24}>
                            <Row type="flex" justify="center">
                                <Col lg={16} span={24}>
                                    <div className="tracking-url">
                                        <UnselectableInput
                                            className="tracking-url-input"
                                            value={data.landingUrl}
                                            disabled={true}
                                            size="large"
                                        />
                                        <div className="tracking-url-controls">
                                            <SnsSharer
                                                title={data.eventName}
                                                description={data.description}
                                                imageUrl={data.image}
                                                link={data.landingUrl}
                                            />
                                            <Copy2Clipboard content={data.landingUrl} popOverTimeout={1000}>
                                                <Button
                                                    className="lg tracking-url-controls-main"
                                                    onClick={onCopyTrackingUrl}>
                                                    {intl.formatMessage({ id: 'LINK_GENERATION_COPY_URL' })}
                                                </Button>
                                            </Copy2Clipboard>
                                        </div>
                                    </div>
                                    <AffiliateFeeNotice />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </CpRow>
        </WorkspacePage>
    );
}
