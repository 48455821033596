import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CoreDataContext = React.createContext();
const CoreDataProvider = ({ children }) => {
    const [data, setData] = useState();

    useEffect(()=>{
        axios.get('/api/v1/config').then(res => {
            const value = {session: {}, ...res.data.data};
            let member = value.member;
            let affiliate = value.affiliate;
            if(member) {
                value.session.authenticated = member.valid;
                value.session.username = member.userName;
                value.session.email = member.email;
            }
            if(affiliate){
                value.session.registrationStatus = affiliate.registrationStatus;
                value.session.regFinalStatus = affiliate.regFinalStatus;
                value.session.affiliateId = affiliate.affiliateId;
            }
            setData(value);
        });
    }, []);

    return data ? (
        <CoreDataContext.Provider value={{...data}}>
            {children}
        </CoreDataContext.Provider>
    ) : null;
}
export { CoreDataContext, CoreDataProvider};