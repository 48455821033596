import React from 'react';
import {Icon} from 'antd';
import cx from 'classnames';
import styles from './TipText.module.less';

const TipText = ({children, className, ...rest})=>{
    return (
        <div className={cx(styles.root,className)}  {...rest}>
            <Icon type="check" className={styles.icon} /><div className={styles.text}>{children}</div>
        </div>
    );
};

export default TipText;