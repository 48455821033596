import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Input, Button, Icon, Tooltip, Checkbox, Upload, message } from 'antd';
import UploadIcon from 'react-feather/dist/icons/upload';
import ImageIcon from 'react-feather/dist/icons/image';
import TrashIcon from 'react-feather/dist/icons/trash-2';
import ImageModalViewer from '../ImageModalViewer/ImageModalViewer';

import './EasyUrlsInput.less';

class UrlsInput extends PureComponent {
    static propTypes = {
        rs: PropTypes.shape({
            placeholder: PropTypes.string,
            ownerTooltip: PropTypes.string,
            owner: PropTypes.string,
        }),
        // checkOwnership: PropTypes.bool,
        uploadScreenshot: PropTypes.bool,
        disabled: PropTypes.bool,
        inputClassName: PropTypes.string,
        inputMaxLength: PropTypes.number,
        validateFunc: PropTypes.func,
        normalizeFunc: PropTypes.func,
    };

    static defaultProps = {
        rs: {
            placeholder: 'http://',
            ownerTooltip: 'Are you the owner of this website?',
            owner: 'Owner',
        },
        inputMaxLength: 200,
        validateFunc: (url) => {
            return new Promise((resolve) => {
                resolve({ url, ownership: false });
            });
        },
        normalizeFunc: (url) => {
            return url.startsWith('http://') || url.startsWith('https://') ? url : 'https://' + url;
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            urls: props.value,
            message: '',
            validating: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.prevUrls) {
            return {
                urls: props.value,
                prevUrls: props.value,
            };
        }
        return state;
    }

    handleInputChange = (e) => {
        const inputValue = e.target.value.trim();
        this.setState({
            inputUrl: inputValue,
            message: !inputValue ? '' : this.state.message,
        });
        // clear the error if the input is empty
        if (!inputValue) {
            const urls = this.state.urls;
            urls.error = null;
            this.triggerChange(urls);
        }
    };

    handleOwnershipChange = (url, checked) => {
        const { urls = [] } = this.state;
        const index = urls.findIndex((x) => x.url === url);

        // NOT to use splice here
        // urls.splice(index, 1, { url, ownership: checked });
        const newUrls = [];
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            if (i !== index) {
                newUrls.push({ ...url, ownership: url.ownership });
            } else {
                newUrls.push({ ...url, ownership: checked });
            }
        }

        this.setState({ urls: newUrls });
        this.triggerChange(newUrls);
    };

    handleAdd = () => {
        const { validateFunc, normalizeFunc } = this.props;
        const { inputUrl, urls = [] } = this.state;
        if (inputUrl && validateFunc) {
            let newUrl = inputUrl;
            if (normalizeFunc) {
                newUrl = normalizeFunc(newUrl);
            }
            const exist = (urls || []).find((x) => x.url === newUrl);
            if (!exist) {
                this.setState(
                    {
                        validating: true,
                    },
                    () => {
                        validateFunc(newUrl)
                            .then((res) => {
                                let { url, ownership } = res || {};
                                const newUrls = [...urls, { url, ownership }];
                                this.setState({
                                    inputUrl: '',
                                    urls: newUrls,
                                    message: '',
                                });
                                this.triggerChange(newUrls);
                            })
                            .catch((err) => {
                                this.setState({
                                    message: err,
                                });
                                urls.error = err;
                                this.triggerChange(urls);
                            })
                            .finally(() => {
                                this.setState({ validating: false });
                            });
                    }
                );
            }
        }
    };

    handleRemove = (url) => {
        const { urls = [] } = this.state;
        const targetIndex = urls.findIndex((x) => x.url === url);
        // urls.splice(targetIndex, 1);
        const newUrls = [];
        urls.forEach((x, i) => {
            if (i !== targetIndex) {
                newUrls.push(x);
            }
        });
        this.setState({
            urls: newUrls,
        });
        this.triggerChange(newUrls);
    };

    triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    };

    beforeUploadScreenshot = (file) => {
        if (file.size / 1024 / 1024 > 5) {
            message.error('파일 size는 총 5Mb 까지만 업로드가 가능합니다.');
            return false;
        }
        return true;
    };

    onUploadScreenshot = async (options, x, index) => {
        const { onSuccess, onError, file } = options;
        try {
            const fd = new FormData();
            fd.append('file', file);
            const response = await axios.post('/api/v1/upload/image', fd);
            const { data, rCode } = response.data;
            if (rCode === '0') {
                const { urls } = this.state;
                urls[index] = { ...x, snapshot: data.imagePath };
                this.triggerChange([...urls]);
            }
            onSuccess('upload success');
        } catch {
            onError(new Error('upload failed'));
        }
    };

    onPreviewScreenshot = (openModal, index) => {
        const { config } = this.props;
        const { urls } = this.state;
        openModal(`${config.imageCdnUrl}/${urls[index].snapshot}`);
    };

    onRemoveScreenshot = (index) => {
        const { urls } = this.state;
        urls[index].snapshot = null;
        this.triggerChange([...urls]);
    };

    render() {
        const {
            rs: { placeholder, ownerTooltip, owner, add },
            inputMaxLength,
            inputClassName,
            disabled,
            // checkOwnership,
            uploadScreenshot,
            formItemHelp,
        } = this.props;
        const { inputUrl, message, urls, validating } = this.state;

        return (
            <div className={cx('urls-input-container', { 'has-error': !!message })}>
                {validating && <div className="validating-mask"></div>}
                <div className={cx('url-input','is-flex-row', { validating: validating })}>
                    <div className="is-flex mr8">
                        <Input
                            autoComplete="off"
                            value={inputUrl}
                            onChange={this.handleInputChange}
                            onPressEnter={this.handleAdd}
                            placeholder={placeholder}
                            maxLength={inputMaxLength}
                            disabled={disabled}
                            className={inputClassName}
                        />
                    </div>
                    <div>
                        <Button
                                    
                            type="primary"
                            loading={validating}
                            disabled={disabled}
                            onClick={this.handleAdd}>
                            {add}
                        </Button>
                    </div>
                </div>

                {/* {
                    message && inputUrl &&
                    <div className="error-message">
                        {message}
                    </div>
                } */}
                {formItemHelp}

                <ul className="url-list">
                    {(urls || []).map((x, index) => (
                        <li className="url-item" key={x.url}>
                            <div className="url-address-box is-flex">
                                <span className="url-pretext">추가완료</span>
                                <span className="is-flex">
                                    <a href={x.url} target="_blank" rel="noopener noreferrer" className="url-address">
                                        {x.url}
                                    </a>
                                </span>
                                
                                <Icon
                                    className="btn-remove"
                                    type="close"
                                    disabled={disabled}
                                    onClick={!disabled && (() => this.handleRemove(x.url))}
                                />
                            </div>
                            {/* {checkOwnership && (
                                <div style={{minWidth: 75}}>
                                <Checkbox
                                    className="sm"
                                    checked={x.ownership}
                                    disabled={disabled}
                                    onChange={(e) => this.handleOwnershipChange(x.url, e.target.checked)}>
                                    <Tooltip title={ownerTooltip}>{owner}</Tooltip>
                                </Checkbox>
                                </div>
                            )} */}
                            {uploadScreenshot && (
                                <div className="url-item-actions">
                                    {!x.snapshot && !disabled && (
                                        
                                            <Upload
                                                showUploadList={false}
                                                accept=".jpg, .jpeg, .png, .bmp"
                                                name="file"
                                                customRequest={(options) => this.onUploadScreenshot(options, x, index)}
                                                beforeUpload={this.beforeUploadScreenshot}>
                                                <Tooltip title="스크린샷 첨부 5Mb이내 / jpg, png, bmp, gif 만 가능">
                                                    <div className="btn-upload-screenshot color-primary ft-12 clickable">
                                                        <span>스크린샷</span><UploadIcon size={16} />
                                                    </div>
                                                </Tooltip>
                                            </Upload>
                                    )}
                                    {x.snapshot && (
                                        <React.Fragment>
                                            <Tooltip title="스크린샷 확인">
                                                <ImageModalViewer>
                                                    {({ openModal }) => (
                                                        <Tooltip title="스크린샷 확인">
                                                            <ImageIcon
                                                                className="clickable color-primary"
                                                                size={16}
                                                                onClick={() =>
                                                                    this.onPreviewScreenshot(openModal, index)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </ImageModalViewer>
                                            </Tooltip>
                                            {!disabled && (
                                                <Tooltip title="스크린샷 삭제">
                                                    <TrashIcon
                                                        className="clickable color-primary"
                                                        size={16}
                                                        onClick={() => this.onRemoveScreenshot(index)}
                                                    />
                                                </Tooltip>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            )}

                            
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default connect((state) => ({ config: state.config }))(UrlsInput);
