// import { put, call } from 'redux-saga/effects';
import { notification } from 'antd';

export default function asyncCall(actionType, api, { success, notifyError, fail }) {
    return (dispatch, getState) => {
        dispatch({ type: `${actionType}_BEGIN` });
        return api().then(res => {
            const retValue = res.data.data;
            if (typeof success === 'function') {
                success({ data: retValue, dispatch, actionType });
            }
            dispatch({ type: `${actionType}_SUCCESS`, payload: retValue });
            return Promise.resolve(res);
        }).catch(err => {
            console.error(`Execute ${actionType} failed: `, err.response || err.data);

            // Only notify our business error (network/server error should be notified by axios interceptors)
            if (err.status === 200) {
                const { rCode } = err.data;
                if (rCode === '403') {
                    // 403 particular global response: password unverified, right now notify nothing
                } else if (rCode === '600') {
                    // 
                } else {
                    if (notifyError) {
                        notification.error({ message: "에러", description: err.data ? err.data.rMessage : err.data });
                    }
                }
            }
            if (typeof fail === 'function') {
                fail({ actionType, dispatch, error: err.data });
            }
            dispatch({ type: `${actionType}_FAILED`, payload: err.response });
            return Promise.reject(err);
        });
    };
}