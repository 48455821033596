import React, { useState } from 'react';
import { Checkbox } from 'antd';
import './DeliveryTypesFilter.less';

export default function DeliveryTypesFilter({ deliveryTypeImages = {}, onChange }) {
    const [filterByRocket, setFilterByRocket] = useState(false);
    const [filterByGlobal, setFilterByGlobal] = useState(false);

    function handleFilterByRocketOnChange(e) {
        setFilterByRocket(e.target.checked);
        triggerOnChange(e.target.checked, filterByGlobal);
    }

    function handleFilterByGlobalOnChange(e) {
        setFilterByGlobal(e.target.checked);
        triggerOnChange(filterByRocket, e.target.checked);
    }

    function triggerOnChange(rocket, global) {
        const deliveryTypes = [];
        if (rocket) {
            deliveryTypes.push('ROCKET');
        }
        if (global) {
            deliveryTypes.push('COUPANG_GLOBAL');
        }
        onChange && onChange(deliveryTypes);
    }

    return (
        <div className="delivery-filter">
            <Checkbox className="sm" checked={filterByRocket} onChange={handleFilterByRocketOnChange}>
                <img src={deliveryTypeImages.ROCKET} alt="" />
            </Checkbox>
            <Checkbox className="sm" checked={filterByGlobal} onChange={handleFilterByGlobalOnChange}>
                <img src={deliveryTypeImages.COUPANG_GLOBAL} alt="" />
            </Checkbox>
        </div>
    );
}