import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Icon } from 'antd';
import './ImageModalViewer.less';

export default function ImageModalViewer({ children }) {
    const intl = useIntl();
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [src, setSrc] = useState('');
    const openModal = (src) => {
        setSrc(src);
        setVisible(true);
        setLoading(true);
    };

    const closeModal = () => {
        setSrc('');
        setVisible(false);
    };

    const onLoad = () => {
        setLoading(false);
    };

    return (
        <React.Fragment>
            {children({ openModal })}

            <Modal
                wrapClassName="image-modal"
                visible={visible}
                title={null}
                width={560}
                footer={null}
                onCancel={closeModal}>
                <img src={src} alt="" style={{ maxWidth: 560, display: loading ? 'none' : 'block' }} onLoad={onLoad} />
                {loading && <Icon type="loading" style={{ fontSize: 36 }} />}
            </Modal>
        </React.Fragment>
    );
}
