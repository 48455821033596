import qs from 'qs';

export function generateLink(group, product, history) {
    const { type, itemId, productId } = product;
    if (type && productId) {
        const finalItemId = itemId ? `/${itemId}` : '';

        history.push({
            pathname: `/affiliate/ws/linkgeneration/${type}/${productId}${finalItemId}`,
            search: qs.stringify({ group, product }),
        });
    }
}

export function viewDetail(product) {
    const { type, itemId, productId } = product;
    let detailUrl = '';
    if (type === 'VENDOR_ITEM_PACKAGE') {
        detailUrl = `http://travel.coupang.com/np/products/${productId}`;
    } else {
        detailUrl = `http://www.coupang.com/vp/products/${productId}?itemId=${itemId}`;
    }
    window.open(detailUrl);
}

export function viewMoreBestProducts(category, history) {
    const categoryParam = category ? `/${category}` : '';
    history.push(`/affiliate/ws/bestproducts${categoryParam}`);
}
