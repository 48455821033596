import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Icon } from 'antd';
import { injectIntl } from 'react-intl';
import { BusinessRegisterNumber } from '../../components';
import { requiredMessage } from '../../common';

import './BusinessPayerInformationFieldset.css';


class BusinessPayerInformationFieldet extends Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        validateBRN: PropTypes.func,
        disabled: PropTypes.bool,
        data: PropTypes.shape({
            name: PropTypes.string,
            brn: PropTypes.string
        })
    };

    constructor(props) {
        super(props);
        this.state = {
            brnValid: false
        };
    }

    validateBRN = (rule, value, callback) => {
        const { intl } = this.props;
        const { raw, valid, dirty, message } = value || {};

        if (this.props.disabled || (valid === undefined && !value.dirty)) {
            callback();
        } else {
            this.setState({
                brnValid: valid
            });

            if (valid) {
                callback();
                return;
            }

            if (!raw || raw.length !== 10) {
                callback(intl.formatMessage({ id: 'PAYMENT_MSG_BRN_IS_10_DIGITALS' }));
                return;
            }

            if (dirty || dirty === undefined) {
                callback(intl.formatMessage({ id: 'PAYMENT_MSG_CHECK_BRN_FIRST' }));
                return;
            }

            callback(message || intl.formatMessage({ id: 'PAYMENT_MSG_INVALID_BRN' }));
        }
    }

    verifyBRN = (brn) => {
        const { form, validateBRN } = this.props;
        const brnName = form.getFieldValue('name');

        return new Promise((resolve, reject) => {
            if (validateBRN) {
                if (!brn) {
                    this.setState({
                        brnValid: false
                    });
                    reject();
                    return;
                }

                validateBRN(brn, brnName).then(res => {
                    const valid = res.data.data;
                    this.setState({
                        brnValid: valid
                    });
                    if (valid) {
                        resolve();
                    } else {
                        reject();
                    }
                }).catch(err => {
                    this.setState({
                        brnValid: false
                    });
                    reject(err.data && err.data.rMessage);
                });
            }
        });
    }

    render() {
        const { intl, form: { getFieldDecorator }, data = {}, disabled } = this.props;
        const rs = {
            Verify: intl.formatMessage({ id: 'VERIFY' })
        };

        return (
            <fieldset>
                <legend>{intl.formatMessage({ id: 'PAYMENT_SECTION_PAYER_INFORMATION' })}</legend>
                <Form.Item label={intl.formatMessage({ id: 'NAME' })}>
                    {
                        getFieldDecorator('name', {
                            initialValue: data.name,
                            rules: [{
                                required: true,
                                message: requiredMessage(intl, 'NAME')
                            }]
                        })(<Input placeholder={intl.formatMessage({ id: 'PAYMENT_COMPANY_NAME_TIP' })} maxLength={80} disabled={disabled} />)
                    }
                </Form.Item>
                <Form.Item label={<span>{intl.formatMessage({ id: 'PAYMENT_BRN' })} {this.state.brnValid ?
                    <Icon className="color-accent" type="check-circle" theme="filled" /> : null}</span>}>
                    {
                        getFieldDecorator('brn', {
                            initialValue: { brn: data.brn },
                            rules: [{
                                required: true,
                                message: requiredMessage(intl, 'PAYMENT_BRN')
                            },
                            {
                                validator: this.validateBRN
                            }]
                        })(<BusinessRegisterNumber onVerify={this.verifyBRN} disabled={disabled} rs={rs} />)
                    }
                </Form.Item>
            </fieldset>
        );
    }
}

export default injectIntl(BusinessPayerInformationFieldet)