import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form, Input, Select, Button, Icon } from 'antd';
import { injectIntl } from 'react-intl';
import { FilterInput } from '../../components';
import { requiredMessage } from '../../common';
import Api from '../api';

export class BankAccountFieldset extends Component {
    static propTypes = {
        type: PropTypes.string,
        form: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        banks: PropTypes.arrayOf(
            PropTypes.shape({
                bankCode: PropTypes.string,
                bankName: PropTypes.string,
            })
        ).isRequired,
        validateBankAccount: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            bankAccountValid: true,
            dirty: false,
        };
    }

    onBankChange = (e) => {
        this.setState({
            dirty: true,
            bankAccountValid: false,
        });
        setTimeout(() => this.props.form.validateFields(['account', 'accountOwner'], { force: true }));
    };

    onAccountChange = (val) => {
        this.setState({
            dirty: true,
            bankAccountValid: false,
        });
    };

    validateAccount = (rule, value, callback) => {
        const { intl, form } = this.props;
        const account = value;
        const bankCode = form.getFieldValue('bankCode');
        if (!this.state.dirty) {
            if (this.state.bankAccountValid) {
                callback();
                return;
            } else {
                if (account) {
                    callback(intl.formatMessage({ id: 'PAYMENT_MSG_INVALID_BANK_ACCOUNT' }));
                    return;
                }
            }
        } else {
            if (account && account.length >= 8) {
                if (!bankCode) {
                    callback(intl.formatMessage({ id: 'PAYMENT_MSG_SELECT_BANK_FIRST' }));
                    return;
                }
                callback(intl.formatMessage({ id: 'PAYMENT_MSG_VERIFY_BANK_ACCOUNT_FIRST' }));
                return;
            }
        }
        callback();
    };

    onVerify = () => {
        const { form, useMemberName } = this.props;

        const bankCode = form.getFieldValue('bankCode');
        const account = form.getFieldValue('account');

        if (bankCode && account) {
            Api.validateBankAccount({ useMemberName, bankCode, account })
                .then((res) => {
                    let { rCode, data } = res.data;
                    let valid = false;
                    if (rCode === '0') {
                        valid = true;
                    }
                    this.setState({
                        dirty: false,
                        bankAccountValid: valid,
                    });
                    form.setFieldsValue({ accountOwner: data.accountOwner });
                    form.validateFields(['account', 'accountOwner'], { force: true });
                })
                .catch((err) => {
                    this.setState({
                        dirty: false,
                        bankAccountValid: false,
                    });
                    form.validateFields(['account', 'accountOwner'], { force: true });
                });
        }
    };

    setDirty() {
        this.setState({
            dirty: true,
            bankAccountValid: false,
        });
    }

    render() {
        const {
            intl,
            form: { getFieldDecorator },
            disabled,
            data = {},
            banks,
            type,
        } = this.props;
        return (
            <fieldset>
                <legend>{intl.formatMessage({ id: 'PAYMENT_SECTION_ACCOUNT_INFORMATION' })}</legend>
                <Form.Item label={intl.formatMessage({ id: 'PAYMENT_BANK' })}>
                    {getFieldDecorator('bankCode', {
                        initialValue: data.bankCode,
                        rules: [
                            {
                                required: true,
                                message: requiredMessage(intl, 'PAYMENT_BANK'),
                            },
                        ],
                    })(
                        <Select
                            onChange={this.onBankChange}
                            disabled={disabled}
                            placeholder={intl.formatMessage({ id: 'PAYMENT_SELECT_A_BANK' })}>
                            {(banks || []).map((item, idx) => (
                                <Select.Option key={item.bankCode} value={item.bankCode}>
                                    {item.bankName}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            {intl.formatMessage({ id: 'PAYMENT_ACCOUNT_NUMBER' })}
                            {this.state.bankAccountValid ? (
                                <Icon className="color-accent ml05em" type="check-circle" theme="filled" />
                            ) : null}
                        </span>
                    }>
                    <div className="is-flex-row">
                        {getFieldDecorator('account', {
                            initialValue: data.account,
                            rules: [
                                {
                                    min: 8,
                                    message: intl.formatMessage({ id: 'BANK_ACCOUNT_LENGTH_AT_LEAST' }, { n: 8 }),
                                },
                                {
                                    validator: this.validateAccount,
                                },
                                { required: true, message: requiredMessage(intl, 'PAYMENT_ACCOUNT_NUMBER') },
                            ],
                        })(
                            <FilterInput
                                onChange={this.onAccountChange}
                                filter={/^[\d*]+$/}
                                maxLength={15}
                                disabled={disabled}
                                autoComplete="off"
                                placeholder={intl.formatMessage({ id: 'PAYMENT_OMIT_MINUS_SYMBOL' })}
                            />
                        )}

                        <Button
                            style={{ marginLeft: 8 }}
                            className="btn-rounded"
                            type="primary"
                            disabled={!this.state.dirty || disabled}
                            onClick={this.onVerify}>
                            {intl.formatMessage({ id: 'VERIFY' })}
                        </Button>
                    </div>
                </Form.Item>

                <Form.Item label={intl.formatMessage({ id: 'PAYMENT_ACCOUNT_HOLDER' })}>
                    {getFieldDecorator('accountOwner', {
                        initialValue: data.accountOwner,
                    })(<Input disabled className="ft-bold" />)}
                </Form.Item>
            </fieldset>
        );
    }
}

export default injectIntl(BankAccountFieldset, { forwardRef: true });
