import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, Button } from 'antd';
import moment from 'moment';
import { CardPage, CpRow, WorkspacePage } from '../../components';
import MarketingConsentSubfrom from '../../registration/components/MarketingConsentSubform';

import Api from '../api';
import { useEffect } from 'react';

function OptInOutSettings({ form }) {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [conset, setConset] = useState({});
    const [modifiedTime, setModifiedTime] = useState();
    const [visible, setVisible] = useState(false);
    const onSubmit = () => {
        form.validateFields((errors, values) => {
            if (!errors) {
                setSubmitting(true);
                const marketingPiCollection =
                    Array.isArray(values.marketingPiCollection) && values.marketingPiCollection.length > 0;
                const marketingEmail = Array.isArray(values.marketingEmail) && values.marketingEmail.length > 0;

                Api.updateOptInOutSettings({
                    marketingPiCollection,
                    marketingEmail,
                })
                    .then((res) => {
                        const { rCode, data } = res.data;
                        if (rCode === '0') {
                            setConset({
                                marketingPiCollection,
                                marketingEmail,
                            });
                            setModifiedTime(data);
                            setVisible(true);
                        }
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        });
    };
    useEffect(() => {
        setLoading(true);
        Api.getOptInOutSettings()
            .then((res) => {
                const { rCode, data } = res.data;
                if (rCode === '0') {
                    form.setFieldsValue({
                        marketingPiCollection: data.marketingPiCollection ? ['MARKETING_PI_COLLECTION'] : [],
                        marketingEmail: data.marketingEmail ? ['MARKETING_EMAIL'] : [],
                    });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    return (
        <WorkspacePage mini={true} withHome={true} className="is-flex bg-grey">
            <CpRow style={{ maxWidth: 840 }}>
                <h1 className="page-title-secondary">{intl.formatMessage({ id: 'OPT_IN_OUT_SETTINGS' })}</h1>
                <CardPage loading={loading}>
                    <Form>
                        <MarketingConsentSubfrom form={form} />
                        <div className="align-center">
                            <Button type="primary" size="large" loading={submitting} onClick={onSubmit}>
                                {intl.formatMessage({ id: 'SAVE' })}
                            </Button>
                        </div>
                    </Form>
                </CardPage>
            </CpRow>
            <Modal visible={visible} closable={false} wrapClassName="modal" title={null} footer={null}>
                <div className="modal-body">
                    {intl.formatMessage({ id: 'SENDER' })}: {intl.formatMessage({ id: 'COUPANG_PARTNERS' })}
                    <br />
                    {intl.formatMessage({ id: 'TIME' })}: {moment(modifiedTime).format('YYYY')}년{' '}
                    {moment(modifiedTime).format('MM')}월 {moment(modifiedTime).format('DD')}일{' '}
                    {moment(modifiedTime).format('HH')}시 {moment(modifiedTime).format('mm')}분 <br />
                    <div class="fw-700">
                        <div>{intl.formatMessage({ id: 'RESULTS' })}</div>
                        <div>
                            1.{' '}
                            {conset.marketingPiCollection
                                ? intl.formatMessage({ id: 'MARKETING_PI_COLLECTION_OPT_IN' })
                                : intl.formatMessage({ id: 'MARKETING_PI_COLLECTION_OPT_OUT' })}
                        </div>
                        <div>
                            2.{' '}
                            {conset.marketingEmail
                                ? intl.formatMessage({ id: 'MARKETING_EMAIL_OPT_IN' })
                                : intl.formatMessage({ id: 'MARKETING_EMAIL_OPT_OUT' })}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Button type="primary" size="large" onClick={() => setVisible(false)}>
                        {intl.formatMessage({ id: 'CONFIRM' })}
                    </Button>
                </div>
            </Modal>
        </WorkspacePage>
    );
}

export default Form.create()(OptInOutSettings);
