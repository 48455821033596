import React, { Component } from 'react';
import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import MediaQuery from 'react-responsive';
import { Button, Card, Row, Col, Divider, Icon } from 'antd';
import { Consts, Footer, TrackerContext, normalizeImageUrl } from '../common';
import { Image, CpRow } from '../components';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import './Landing.css';
import landing_banner_mobile from './banner-mobile.png';
import FocusSlider from '../dashboard/components/FocusSlider/FocusSlider';

import Api from './api';

class Landing extends Component {
    static contextType = TrackerContext;
    state = {
        bannerLoaded: false,
        banner: null,
        homeBanners: null,
    };
    gotoSignup = () => {
        this.context.trackEvent({ action: 'signup' });

        const { history, loggedIn, affiliate } = this.props;
        window.sessionStorage.entrance = Consts.Entrance.Signup;
        if (loggedIn && !affiliate) {
            history.push(`/reg`);
        } else {
            history.push(`/gotologin`);
        }
    };

    componentDidMount() {
        Api.getLandingBanner()
            .then((res) => {
                const { data } = res.data;
                this.setState({ bannerLoaded: true, banner: data.content.html });
            })
            .catch((err) => {
                console.error('### LANDING_BANNER:', err);
            });
        Api.getHomeBanner()
            .then((res)=>{
                const {rCode,data} = res.data;
                if(rCode === '0' && Array.isArray(data) && data.length>0){
                    this.setState({homeBanners: data});
                }
            });
    }

    render() {
        const { affiliate, intl, imageCdnUrl } = this.props;
        const displayRegisterButton = !affiliate || (affiliate && !affiliate.regFinalStatus);
        const imageBaseUrl = window.cpapp.settings.imageCdnUrl;
        const { bannerLoaded, banner, homeBanners } = this.state;

        return (
            <div className="landing-page">
                <MediaQuery minWidth={992}>
                    {(matches) => {
                        if (matches) {
                            return (
                                <CpRow contentPadding={false}>
                                    <Row>
                                        <Col span={24}>
                                            <div className="affiliate-banner">
                                                {/* <Image src={landing_banner} alt="Landing Carousel" />
                                                        <div className="affiliate-slogan">
                                                            <h1>{intl.formatMessage({ id: 'LANDING_SLOGAN' })}</h1>
                                                        </div> */}

                                                {bannerLoaded && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: normalizeImageUrl(banner, imageCdnUrl),
                                                        }}
                                                    />
                                                )}

                                                {displayRegisterButton && (
                                                    <Button
                                                        type="primary"
                                                        className="xl btn-signup"
                                                        style={{ bottom: 20, width: 188, height: 54 }}
                                                        onClick={this.gotoSignup}>
                                                        {intl.formatMessage({ id: 'SIGNUP' })}
                                                        <ChevronRightIcon />
                                                    </Button>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </CpRow>
                            );
                        } else {
                            return (
                                <CpRow contentPadding={false}>
                                    <Row>
                                        <div className="affiliate-video">
                                            <video
                                                autoPlay
                                                muted
                                                controls
                                                playsInline
                                                poster="//partners.coupangcdn.com/web/intro-poster.jpg">
                                                <source
                                                    src="//partners.coupangcdn.com/web/intro3mins.mp4"
                                                    type="video/mp4"
                                                />
                                            </video>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="affiliate-banner-mobile">
                                            <Image src={landing_banner_mobile} alt="" />
                                            {displayRegisterButton && (
                                                <div style={{ position: 'absolute', top: 32, right: 28 }}>
                                                    <Button type="primary" onClick={this.gotoSignup}>
                                                        {intl.formatMessage({ id: 'SIGNUP' })}
                                                        <Icon type="right" />
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </Row>
                                </CpRow>
                            );
                        }
                    }}
                </MediaQuery>

                <CpRow>
                    <Row>
                        <Col span={24}>
                            <h1 className="align-center question-title">
                                {intl.formatMessage({ id: 'LANDING_WHY_AFFILIATE' })}
                            </h1>
                        </Col>
                    </Row>
                    <div className="why-guide">
                        <Card
                            bordered={false}
                            cover={
                                <img
                                    alt="why1"
                                    src={`${imageBaseUrl}/image/affiliate/domain/20180610/home-education-01.svg`}
                                />
                            }>
                            <Card.Meta
                                title={intl.formatMessage({ id: 'LANDING_WHY1_TITLE' })}
                                description={intl.formatMessage({ id: 'LANDING_WHY1_DESCRIPTION' })}
                            />
                        </Card>
                        <Card
                            className="why-guide-2"
                            bordered={false}
                            cover={
                                <img
                                    alt="why2"
                                    src={`${imageBaseUrl}/image/affiliate/domain/20180610/home-education-02.svg`}
                                />
                            }>
                            <Card.Meta
                                title={intl.formatMessage({ id: 'LANDING_WHY2_TITLE' })}
                                description={intl.formatMessage({ id: 'LANDING_WHY2_DESCRIPTION' })}
                            />
                        </Card>
                        <Card
                            bordered={false}
                            cover={
                                <img
                                    alt="why3"
                                    src={`${imageBaseUrl}/image/affiliate/domain/20180610/home-education-03.svg`}
                                />
                            }>
                            <Card.Meta
                                title={intl.formatMessage({ id: 'LANDING_WHY3_TITLE' })}
                                description={intl.formatMessage({ id: 'LANDING_WHY3_DESCRIPTION' })}
                            />
                        </Card>
                    </div>
                    {
                    homeBanners && homeBanners.length && 
                    <Row className="home-banner">
                        <Col span={24}>
                            <h1 className="align-center question-title">{intl.formatMessage({id: 'LANDING_HOME_BANNERS'})}</h1>
                        </Col>
                        <Col span={24}>
                            <FocusSlider items={homeBanners} onClickItem={(link)=>{
                                if (link.indexOf('source=landing_banner') === -1) {
                                    if (link.indexOf('?') === -1) {
                                        link += '?source=landing_banner';
                                    } else {
                                        link += '&source=landing_banner';
                                    }
                                }
                                window.location.href = link;
                            }}/>
                        </Col>
                    </Row>
                    }
                    <Row>
                        <Col span={24} className="divider-row align-center">
                            <Divider />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <h1 className="align-center question-title">
                                {intl.formatMessage({ id: 'LANDING_HOW_TO_USE' })}
                            </h1>
                        </Col>
                    </Row>
                    <div className="how-guide">
                        <Card
                            bordered={false}
                            cover={
                                <img
                                    alt="how1"
                                    src={`${imageBaseUrl}/image/affiliate/domain/20180610/banner-step-b-1.svg`}
                                />
                            }>
                            <Card.Meta
                                description={
                                    <span>
                                        <span className="color-accent no-indicator">1</span>
                                        {intl.formatMessage({ id: 'LANDING_HOW1_DESCRIPTION' })}
                                    </span>
                                }
                            />
                        </Card>

                        <Card
                            bordered={false}
                            cover={
                                <img
                                    alt="how2"
                                    src={`${imageBaseUrl}/image/affiliate/domain/20180610/banner-step-b-2.svg`}
                                />
                            }>
                            <Card.Meta
                                description={
                                    <span>
                                        <span className="color-accent no-indicator">2</span>
                                        {intl.formatMessage({ id: 'LANDING_HOW2_DESCRIPTION' })}
                                    </span>
                                }
                            />
                        </Card>
                        <Card
                            bordered={false}
                            cover={
                                <img
                                    alt="how3"
                                    src={`${imageBaseUrl}/image/affiliate/domain/20180610/banner-step-b-3.svg`}
                                />
                            }>
                            <Card.Meta
                                description={
                                    <span>
                                        <span className="color-accent no-indicator">3</span>
                                        {intl.formatMessage({ id: 'LANDING_HOW3_DESCRIPTION' })}
                                    </span>
                                }
                            />
                        </Card>
                    </div>
                </CpRow>
                <CpRow className="footer-row" contentPadding={false}>
                    <Footer />
                </CpRow>
            </div>
        );
    }
}

export default withRouter(
    injectIntl(
        connect((state) => ({
            loggedIn: state.session.authenticated,
            affiliate: state.affiliate,
            imageCdnUrl: state.config.imageCdnUrl,
        }))(Landing)
    )
);
