import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { WorkspacePage, CpRow, YearMonth } from '../../components';
import { PayoutDetailsDataTable } from '../components';

import HomeIcon from 'react-feather/dist/icons/home';

import Api from '../api';

class PayoutDetails extends PureComponent {
    downloadSettlementDetails = () => {
        const {
            match: {
                params: { payoutMonth },
            },
        } = this.props;
        window.location = Api.getSettlementDetailsDownloadUrl(payoutMonth);
    };

    render() {
        const {
            intl,
            match: {
                params: { payoutMonth, trackingCode },
            },
        } = this.props;

        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_EARNING_REPORT' }) },
            { title: intl.formatMessage({ id: 'HEADER_REPORT_PAYOUT' }), link: '/affiliate/ws/report/payout' },
            { title: intl.formatMessage({ id: 'HEADER_REPORT_PAYOUT_DETAILS' }) },
        ];

        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                title={
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>
                            {intl.formatMessage({ id: 'HEADER_REPORT_PAYOUT_DETAILS' }) + ' '}
                            (<YearMonth value={payoutMonth} />)
                        </span>
                        {/* <Button type="primary" className="lg" onClick={this.downloadSettlementDetails}>
                        {<DownloadIcon size={16} style={{ marginRight: 5 }} />}
                        {intl.formatMessage({ id: 'DOWNLOAD' })}
                    </Button> */}
                    </div>
                }>
                <CpRow>
                    <PayoutDetailsDataTable payoutMonth={payoutMonth} trackingCode={trackingCode} />
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(withRouter(PayoutDetails));
