import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class RsLabel extends PureComponent {
    static propTypes = {
        text: PropTypes.shape({
            koreanName: PropTypes.string,
            englishName: PropTypes.string
        })
    }

    render() {
        const { intl } = this.props;
        const text = this.props.text || {};
        if (intl.locale === 'ko') {
            return text.koreanName || '';
        }
        return text.englishName || '';
    }
}

export default injectIntl(RsLabel)
