import React, { useState, useEffect, useMemo } from 'react';
import FeatCheckerContext from './FeatCheckerContext';

function objToList(parentList, obj) {
    let list = [];
    Object.keys(obj).forEach((item) => {
        let result = [item];
        if (parentList) {
            result = parentList.concat(result);
        }
        list.push(result);
        if (obj[item]) {
            list = list.concat(objToList(result, obj[item]));
        }
    });
    return list;
}

const featObject = {
    hamburger: {
        help: {
            faq: {
                '/help/faq@tab6': {
                    '/help/faq@tab6@question25': null,
                },
            },
            'operating-policy': null,
        },
        productlink: {
            'video-banners': null,
            events: null,
        },
    },
};

const featList = objToList(null, featObject);

function findFeatTree(feat) {
    for (let f of featList) {
        if (f[f.length - 1] === feat) {
            return f;
        }
    }
    return null;
}

export default function ({ children, newFeatMap }) {
    const [checkedFeatList, setCheckedFeatList] = useState(JSON.parse(localStorage.getItem('check_feat_list')) || []);
    const checkFeat = (key) => {
        if (newFeatMap[key]) {
            if (checkedFeatList.findIndex((item) => item.key === key && item.no === newFeatMap[key]) === -1) {
                setCheckedFeatList([...checkedFeatList, { key, no: newFeatMap[key] }]);
            }
        }
    };

    const getUncheckedFeatTreeList = useMemo(
        () => () => {
            let uncheckedFeatList = Object.keys(newFeatMap).filter(
                (item) => checkedFeatList.findIndex((z) => z.key === item && z.no === newFeatMap[item]) === -1
            );
            let uncheckedFeatTreeList = [];
            for (let f of uncheckedFeatList) {
                let z = findFeatTree(f);
                if (z) {
                    uncheckedFeatTreeList.push(z);
                }
            }
            return uncheckedFeatTreeList;
        },
        [newFeatMap, checkedFeatList]
    );
    const isUncheckedFeat = (key) => {
        let uncheckedFeatTreeList = getUncheckedFeatTreeList();
        for (let item of uncheckedFeatTreeList) {
            if (item.includes(key)) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        localStorage.setItem('check_feat_list', JSON.stringify(checkedFeatList));
    }, [checkedFeatList]);

    return (
        <FeatCheckerContext.Provider value={{ isUncheckedFeat, checkFeat }}> {children}</FeatCheckerContext.Provider>
    );
}
