import React, { Component } from 'react';
import { Button } from 'antd';
import CardPage from '../CardPage/CardPage';
import CpRow from '../Layout/CpRow';

import './MessageForm.css'

export default class MessageForm extends Component {
    render() {
        const { className, title, content, size, rs, onOK } = this.props;
        const Header = title && <h1 className="message-form-title">{title}</h1>;
        const Body = <div className="message-form-content ft-16">{content}</div>;
        const Footer = onOK && <div className="button-bar align-center">
            <Button size="large" onClick={onOK}>{rs ? rs.OK : 'OK'}</Button>
        </div>;

        return (
            <CpRow style={{ maxWidth: 840 }}>
                <CardPage className={className} size={size || "small"} header={Header} body={Body} footer={Footer} />
            </CpRow>
        );
    }
}

