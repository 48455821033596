import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import cx from 'classnames';

import './FilterInput.css';

export default class FilterInput extends Component {
    static propTypes = {
        filter: PropTypes.oneOfType([
            PropTypes.oneOf(['digital', 'alphabet']),
            PropTypes.instanceOf(RegExp)
        ]).isRequired,
        size: PropTypes.oneOf(['small', 'large']),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        displayInGroup: PropTypes.bool,
        groupSize: PropTypes.number,
        groupSeperator: PropTypes.string
    };

    preDefinedRegexes = {
        'digital': /^\d*$/,
        'alphabet': /^[a-z|A-Z]*$/
    };

    constructor(props) {
        super(props);
        this.state = {
            focus: false,
            value: this.props.value
        };
    }

    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    }

    onInputChange = (e) => {
        const val = e.target.value;
        let { filter } = this.props;
        if (typeof filter === 'string') {
            filter = this.preDefinedRegexes[filter];
        }

        this.setState((prevState, props) => {
            let nextState = { value: val };
            let changed = true;
            if (val && filter && filter.test) {
                if (filter.test(val)) {
                    nextState = { value: val };
                } else {
                    nextState = prevState;
                    changed = false;
                }
            }
            if (changed) {
                this.triggerChange(val);
            }
            return nextState;
        });
    }

    focus() {
        if (this.input) {
            this.input.focus();
        }
    }

    onFocus = () => {
        this.setState({
            focus: true
        });
    }

    onBlur = () => {
        this.setState({
            focus: false
        });
    }

    get selectionStart() {
        // [NOTICE] Only IE>=11 supports selectionStart, if we need to support IE9 in future, need to polyfill here
        const { input } = this.input;
        if (input && input.selectionStart !== undefined) {
            return input.selectionStart;
        }
        return;
    }

    static formatAsGroup(str, groupSize = 4, groupSeperator = '-') {
        let parts = [];
        let pages = Math.ceil(str.length / groupSize);
        let p = str.length - ((pages - 1) * groupSize);
        let pos = 0;
        for (let i = 0; i < pages; i++) {
            parts.push(str.substring(pos, p));
            pos = p;
            p = pos + groupSize;
        }
        return parts.join(groupSeperator);
    }

    render() {
        // Exclude onChange otherwise onInputChange won't be triggered
        const { filter, onChange, displayInGroup, groupSize, groupSeperator, disabled, size, className, ...others } = this.props;
        const { value } = this.state;

        if (!displayInGroup) {
            return <Input ref={elem => this.input = elem} value={value} disabled={disabled} onChange={this.onInputChange} {...others} onBlur={this.onBlur} />
        } else {
            return <div className={cx("group-display-container", className)}>
                {
                    (
                        !this.state.focus &&
                        value !== undefined &&
                        value !== null
                    ) &&
                    <div className={cx("group-display", { "group-display-disabled": disabled })} onClick={() => this.focus()}>{FilterInput.formatAsGroup(value + '', groupSize, groupSeperator)}
                    </div>
                }
                <Input ref={elem => this.input = elem} value={value} disabled={disabled} onChange={this.onInputChange} size={size} {...others}
                    onBlur={this.onBlur}
                    onFocus={this.onFocus}
                />
            </div>;
        }
    }
}