import {
    GOTO_STEP,
    FETCH_REGISTRATION_INITDATA,
    FETCH_REGISTRATION_FLOW_INITDATA,
    UPDATE_PROFILE_FIELD,
    VALIDATE_AFFILIATE_CODE,
    PERSIST_PROFILE,
    FETCH_EDIT_PROFILE_INITDATA,
    UPDATE_PROFILE,
    REG_UPDATE_STEP,
    REG_UPDATE_ACCEPTED_TERMS,
    REG_UPDATE_PROFILE,
    REG_UPDATE_PAYMENT,
    REG_SELECT_AFFILIATE_TYPE,
    REG_UPDATE_INDIVIDUAL_PAYMENT,
    REG_UPDATE_BUSINESS_PAYMENT,
    REG_FINISH_REGISTRATION,
} from './actionTypes.js';
import { asyncCall } from '../common';
import Api from './api';

export const gotoStep = (direction) => ({
    type: GOTO_STEP,
    payload: direction,
});

export const updateProfileField = (formField) => ({
    type: UPDATE_PROFILE_FIELD,
    payload: formField,
});

export function fetchInitData() {
    return asyncCall(FETCH_REGISTRATION_INITDATA, Api.fetchRegistrationInitData, { notifyError: true });
}

export function fetchRegistrationFlowInitData() {
    return asyncCall(FETCH_REGISTRATION_FLOW_INITDATA, Api.fetchRegistrationFlowInitData, { notifyError: true });
}

export const validateAffiliateCode = (affiliateCode, formValidationCallback) => {
    return asyncCall(VALIDATE_AFFILIATE_CODE, () => Api.validateAffiliateCode(affiliateCode), {
        success: ({ data: valid }) => {
            if (valid) {
                formValidationCallback();
            } else {
                formValidationCallback(`'${affiliateCode}' has been registered by other affiliates`);
            }
        },
        fail: () => {
            formValidationCallback(`'${affiliateCode}' has been registered by other affiliates`);
        },
    });
};

export const persistProfile = (profile) => {
    return asyncCall(PERSIST_PROFILE, () => Api.persistProfile(profile), {
        success: ({ data, dispatch }) => {
            dispatch({ type: 'UPDATE_AFFILIATE', payload: data });
        },
        notifyError: true,
    });
};

export const summaryFinished = () => {
    return (dispatch, getState) => {
        window.nonav = true;
        window.location.hash = '/';
    };
};

export function fetchEditProfileInitData() {
    return asyncCall(FETCH_EDIT_PROFILE_INITDATA, Api.fetchEditProfileInitData, { notifyError: true });
}

export const updateProfile = (profile) => {
    return asyncCall(UPDATE_PROFILE, () => Api.updateProfile(profile), {
        success: ({ data, dispatch }) => {
            dispatch({ type: 'UPDATE_AFFILIATE', payload: data });
            // window.location.hash = '/affiliate';
        },
        notifyError: true,
    });
};

export const regUpdateStep = (step) => ({
    type: REG_UPDATE_STEP,
    payload: step,
});

export const regUpdateAffiliate = (affiliate) => {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_AFFILIATE', payload: affiliate });
    };
};

export const regUpdateAcceptedTerms = (termKeys) => ({
    type: REG_UPDATE_ACCEPTED_TERMS,
    payload: termKeys,
});

export const regUpdateProfile = (profile) => ({
    type: REG_UPDATE_PROFILE,
    payload: profile,
});
export const regUpdatePayment = (payment) => ({
    type: REG_UPDATE_PAYMENT,
    payload: payment,
});

export const regSelectAffiliateType = (type) => ({
    type: REG_SELECT_AFFILIATE_TYPE,
    payload: type,
});

export const regUpdateIndividualPayment = (payment) => ({
    type: REG_UPDATE_INDIVIDUAL_PAYMENT,
    payload: payment,
});

export const regUpdateBusinessPayment = (payment) => ({
    type: REG_UPDATE_BUSINESS_PAYMENT,
    payload: payment,
});

export const regFinishRegistration = () => ({
    type: REG_FINISH_REGISTRATION,
    payload: true,
});
