import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { ContactFieldset } from '../components';
import { validateRRN, validateBankAccount } from '../actions';
import { regUpdatePayment } from '../../registration/actions';
import { TrackerContext } from '../../common';

class IndividualPayment extends Component {
    static contextType = TrackerContext;
    static propTypes = {
        affiliateType: PropTypes.oneOf(['INDIVIDUAL', 'OTHER_INDUSTRIES']),
        terms: PropTypes.array,
        bankNameAndCodes: PropTypes.array,
        validateRRN: PropTypes.func,
        validateBankAccount: PropTypes.func,
    };

    componentDidMount() {
        this.context.trackPageView({ action: 'enter_registration_payment_individual' });
    }

    validate = () => {
        return new Promise((resolve, reject) => {
            const { form } = this.props;
            form.validateFields((err, values) => {
                if (!err) {
                    resolve(this.normalizeFormData(values));
                } else {
                    reject(err);
                }
            });
        });
    };

    normalizeFormData = (values) => {
        const { affiliateType } = this.props;
        values.affiliateType = affiliateType;
        if (values.rrn) {
            values.rrn = values.rrn.raw;
        }
        values.address = values.address || '';
        values.mobile = values.mobile || '';
        values.contactName = values.name;
        return values;
    };

    getValue = () => {
        return this.normalizeFormData(this.props.form.getFieldsValue());
    };

    getData = (doNotCheck) => {
        return (doNotCheck ? Promise.resolve(this.getValue()) : this.validate()).then((payment) => {
            this.props.regUpdatePayment({ individual: payment });
            return { payment };
        });
    };

    render() {
        const { form, action, payment } = this.props;

        return (
            <div className="individual-payment">
                <Form layout="vertical" onSubmit={this.handleSubmit}>
                    <ContactFieldset form={form} data={payment} includeContactName={false} />
                </Form>
            </div>
        );
    }
}

const IntlIndividualPayment = injectIntl(IndividualPayment, { forwardRef: true });

const IndividualPaymentForm = Form.create()(IntlIndividualPayment);

const mapStateToProps = (state) => {
    const {
        registration: {
            payment: { data = {}, bankNameAndCodes },
            defaultValues,
        },
    } = state;
    const { affiliateType, ...payment } = data;

    return {
        affiliateType,
        payment: payment.individual || { ...defaultValues },
        bankNameAndCodes,
    };
};

const mapDispatchToProps = {
    validateRRN,
    validateBankAccount,
    regUpdatePayment,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(IndividualPaymentForm);
