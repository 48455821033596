import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Row, Col, Tabs } from 'antd';

import { WorkspacePage, CpRow, LastUpdatedDate } from '../../components';
import HomeIcon from 'react-feather/dist/icons/home';
import DownloadIcon from 'react-feather/dist/icons/download';

import { initReportStore, fetchReportDataDynamicWidgetDaily } from '../actions';
import { SummaryCard, TrendChart, DownloadButton } from '../components';
import { Consts } from '../../common';

const mapStateToProps = (state) => ({ ...state.report, ...state.subId });

const mapDispatchToProps = {
    initReportStore,
    fetchReportDataDynamicWidgetDaily,
};

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeReportKey: props.match.params.type || 'daily',
        };
    }

    componentDidMount() {
        // TODO: Optimize this part, save/reuse report data on store for different components (like dashboard)
        const { initReportStore } = this.props;
        if (initReportStore) {
            initReportStore();
        }

        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dateRange !== this.props.dateRange || prevProps.currentSubIds !== this.props.currentSubIds) {
            this.load();
        }
    }

    load = () => {
        if (this.state.activeReportKey === 'daily') {
            this.fetchDailyTrend();
        } else {
            this.fetchMonthlyTrend();
        }
    };

    fetchDailyTrend() {
        const { fetchReportDataDynamicWidgetDaily, dateRange, currentSubIds } = this.props;
        fetchReportDataDynamicWidgetDaily({ ...dateRange, currentSubIds });
    }

    fetchMonthlyTrend() {
        const { fetchReportDataMonthly, dateRange, currentSubIds } = this.props;
        fetchReportDataMonthly({ ...dateRange, currentSubIds });
    }

    onTabChange = (activeKey) => {
        this.setState({
            activeReportKey: activeKey,
        });
        const { history } = this.props;
        history.replace(`/affiliate/ws/report/trend/${activeKey}`);

        if (activeKey === 'daily') {
            this.fetchDailyTrend();
        }

        if (activeKey === 'monthly') {
            this.fetchMonthlyTrend();
        }
    };

    buildReportData(trendData, type = 'daily') {
        let dataSource = (trendData.units || []).map((x) => ({
            key: x.key,
            commission: x.commission,
            clicks: x.clickCount,
        }));

        return {
            lastUpdatedDate: trendData.lastUpdatedDate,
            summaryDataSource: trendData,
            dataSource,
            type,
        };
    }

    render() {
        const {
            intl,
            loading,
            currentSubIds,
            dateRange: { startDate, endDate },
        } = this.props;
        const metrics = [
            'impressionCount',
            'clickCount',
            'orderCount',
            'gmv',
            'commission',
            'ctr',
            'conversion',
            'eCPM',
        ];
        const daily = this.props.daily || {};
        const monthly = this.props.monthly || {};

        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_PERFORMANCE_REPORT' }) },
            { title: intl.formatMessage({ id: 'HEADER_REPORT_DYNAMIC_WIDGET' }) },
        ];

        let reportData;
        if (this.state.activeReportKey === 'daily') {
            reportData = this.buildReportData(daily, 'daily');
        } else {
            reportData = this.buildReportData(monthly, 'monthly');
        }

        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'HEADER_REPORT_DYNAMIC_WIDGET' })}
                withDateRangedReportPicker={true}
                withSubId={{ isMultiple: true }}
                loading={loading}>
                <CpRow>
                    <Row type="flex" align="middle">
                        <Col sm={12} xs={24}>
                            <LastUpdatedDate date={reportData.lastUpdatedDate} />
                        </Col>
                        <Col sm={12} xs={24}>
                            <Row type="flex" justify="end">
                                <Col className="action-group">
                                    <DownloadButton
                                        buttonProps={{ className: 'sm action' }}
                                        jobType={Consts.AsyncJobTypes.AffiliateDynamicWidgetDailyTrend}
                                        parameters={{
                                            subIds: currentSubIds,
                                            startDate: moment(startDate).format('YYYY-MM-DD'),
                                            endDate: moment(endDate).format('YYYY-MM-DD'),
                                        }}>
                                        {<DownloadIcon size={16} className="feathericon" style={{ marginRight: 5 }} />}
                                        {intl.formatMessage({ id: 'DOWNLOAD' })}
                                    </DownloadButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="mt24">
                        <SummaryCard metrics={metrics} dataSource={daily} style={{ marginBottom: 36 }} />
                        <div style={{ height: 450 }}>
                            <TrendChart metrics={metrics} dataSource={daily.units || []} type="daily" />
                        </div>
                    </div>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Report)));
