import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Form, Button, notification } from 'antd';
import { CardPage } from '../../components';
import {
    IndividualPayerInformationFieldset,
    CompanyInformationFieldset,
    ContactFieldset,
    BankAccountFieldset,
    DocumentFieldset,
} from '../components';
import { fetchIndividualPaymentForAffiliateTypeChange, updateOtherIndustriesPayment, validateRRN } from '../actions';
import { TrackerContext, Consts } from '../../common';
import MemberInfo from '../../registration/components/MemberInfo';
import Api from '../api';

const mapStateToProps = (state) => ({
    bankNameAndCodes: state.payment.bankNameAndCodes,
    data: state.payment.data,
    loading: state.payment.loading,
    memberInfo: state.payment.memberInfo,
    piiInputStatus: state.payment.piiInputStatus,
    affiliateType: state.affiliate.affiliateType,
    activated: state.affiliate.activated && state.config.maintainingEnabled !== true,
});

const mapDispatchToProps = {
    validateRRN,
    fetchIndividualPaymentForAffiliateTypeChange,
    updateOtherIndustriesPayment,
};

class ChangeBusinessTypeToOtherIndustries extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.state = { processing: false };
    }

    componentDidMount() {
        this.context.trackPageView({ action: 'change_business_type_to_other_industries' });

        const { form, fetchIndividualPaymentForAffiliateTypeChange } = this.props;

        fetchIndividualPaymentForAffiliateTypeChange &&
            fetchIndividualPaymentForAffiliateTypeChange().then((response) => {
                const { rCode, data } = response.data;
                if (rCode === '0') {
                    const { bankCode, account, accountOwner } = data.individual;

                    form.setFieldsValue({ bankCode, account, accountOwner });
                }
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { intl, form, affiliateType, history } = this.props;

        form.validateFields({}, (err, values) => {
            if (!err) {
                values.affiliateType = affiliateType;
                if (values.rrn) {
                    values.rrn = values.rrn.raw;
                }
                values.address = values.address || '';
                values.mobile = values.mobile || '';
                values.contactName = values.name;
                // exclude accountOwner
                const { brnFileList, bankbookFileList, accountOwner, ...payment } = values;

                this.setState({ processing: true }, () => {
                    Api.changeIndividualToOtherIndustries(
                        payment,
                        brnFileList && brnFileList[0].originFileObj,
                        bankbookFileList && bankbookFileList[0].originFileObj
                    )
                        .then((res) => {
                            this.context.trackEvent({ action: 'change_business_type_to_other_industries_complete' });
                            history.push('/affiliate');
                            window.location.reload();
                        })
                        .catch((err) => {
                            if (err && err.data && err.data.rMessage) {
                                const { rMessage } = err.data;
                                notification.warn({
                                    message: intl.formatMessage({ id: 'NOTICE' }),
                                    description: rMessage,
                                });
                            } else {
                                console.error(err);
                            }
                        })
                        .finally(() => {
                            this.setState({ processing: false });
                        });
                });
            }
        });
    };

    render() {
        const {
            intl,
            form,
            loading,
            activated,
            bankNameAndCodes,
            data,
            piiInputStatus,
            memberInfo,
            validateRRN,
        } = this.props;
        const { processing } = this.state;

        return (
            <div className="edit-other-industries-payment">
                <CardPage loading={loading}>
                    <Form layout="vertical" hideRequiredMark={false}>
                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <React.Fragment>
                                {(piiInputStatus === Consts.PiiInputStatus.Opened ||
                                    piiInputStatus === Consts.PiiInputStatus.Completed) && (
                                    <MemberInfo memberInfo={memberInfo} piiInputStatus={piiInputStatus} />
                                )}
                                <IndividualPayerInformationFieldset
                                    form={form}
                                    data={data}
                                    validateRRN={validateRRN}
                                    disabled={piiInputStatus !== Consts.PiiInputStatus.Opened}
                                />
                            </React.Fragment>
                        )}
                        <ContactFieldset form={form} data={data} disabled={!activated} includeContactName={false} />

                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <CompanyInformationFieldset form={form} data={data} disabled={!activated} />
                        )}

                        {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                            <React.Fragment>
                                <BankAccountFieldset
                                    type="BUSINESS"
                                    form={form}
                                    data={data}
                                    disabled={!activated}
                                    banks={bankNameAndCodes}
                                />

                                <DocumentFieldset form={form} disabled={!activated} />
                            </React.Fragment>
                        )}
                        {activated && (
                            <div className="button-bar align-center">
                                <Button
                                    size="large"
                                    type="primary"
                                    htmlType="button"
                                    loading={processing}
                                    onClick={this.handleSubmit}>
                                    {intl.formatMessage({ id: 'SAVE' })}
                                </Button>
                            </div>
                        )}
                    </Form>
                </CardPage>
            </div>
        );
    }
}

export default Form.create()(
    withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeBusinessTypeToOtherIndustries)))
);
