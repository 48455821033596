import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tag, Icon } from 'antd';

export default class CheckableTagList extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.any,
                name: PropTypes.string,
                removable: PropTypes.bool
            })
        ),
        selectedTags: PropTypes.array,
        onChange: PropTypes.func,
        onRemove: PropTypes.func
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.tags !== this.props.tags ||
            nextProps.selectedTags !== this.props.selectedTags ||
            nextProps.disabled !== this.props.disabled;
    }

    render() {
        const { disabled, tags, selectedTags, onChange, onRemove } = this.props;
        return (
            <div className="checkable-tags-input-tags">
                {
                    tags.map((tag, index) => (
                        <Tag.CheckableTag key={tag.id || tag.name || `tag${index}`} checked={selectedTags.find(t => t === tag.id)} onChange={checked => {
                            if (!disabled) {
                                onChange(tag, checked)
                            }
                        }} >
                            {tag.name}{tag.removable && <Icon className="remove-tag" type="cross" onClick={e => { e.stopPropagation(); onRemove(tag) }} />}
                        </Tag.CheckableTag>)
                    )
                }
            </div>
        );
    }
}

