import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Emotion } from '../../components';
import { TrackerContext } from '../../common';

export class AffiliateAutoApproved extends Component {
    static contextType = TrackerContext;
    componentDidMount() {
        this.context.trackPageView({ action: 'enter_auto_approved' });
    }

    handleOK = () => {
        const { onOk } = this.props;
        this.context.trackEvent({ action: 'auto_approved_confirm' });
        if (onOk) {
            onOk();
        }
    };

    render() {
        const { intl, name, affiliateCode } = this.props;
        return (
            <div className="confirmation align-center">
                <div className="emotion-line">
                    <Emotion type="sweatsmile" />
                </div>
                <h1 style={{ marginBottom: 12 }}>
                    <FormattedMessage id="PAYMENT_ALERT_GREETING" values={{ name }} />,{' '}
                    {intl.formatMessage({ id: 'PAYMENT_ALERT_TITLE_AUTO_APPROVED' })}
                </h1>

                <p className="ft-body ft-16">
                    <FormattedMessage
                        id="APPROVED_AFFILIATE_ID_IS"
                        values={{
                            name,
                            affiliateCode: <strong className="color-accent ft-bold">{affiliateCode}</strong>,
                        }}
                    />
                    <br />
                    {intl.formatMessage({ id: 'APPROVED_CREATE_A_LINK_NOW' })}
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    name: state.session.username,
    affiliateCode: state.affiliate.affiliateCode || 'kimkung',
});

export default connect(mapStateToProps)(withRouter(injectIntl(AffiliateAutoApproved)));
