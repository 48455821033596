import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import MediaQuery from 'react-responsive';

import { Spin } from 'antd';

import { useFetch } from '../../common';
import { MetricList, Metric, MetricSelect, Chart, } from '../../components';
import Api from '../api';

export default injectIntl(function ({ intl, style, extraFilters, primaryDimension, metrics }) {
    const [metric, setMetric] = useState(metrics[0]);
    const m = MetricList.find(item => item.id === metric);
    const [option, setOption] = useState(null);
    const [hasData, setHasData] = useState(false);
    const load = () => Api.makeReportApi('performance', primaryDimension)({
        page: {
            size: 10,
            pageNumber: 0,
        },
        filters: extraFilters,
        sorts: [{ column: metric, isDesc: true }]
    });
    const { loaded, doFetch, res, fetching } = useFetch(load, { autoLoad: false });

    const onChangeMetric = (value) => {
        setMetric(value);
    };

    useEffect(() => {
        doFetch();
    }, [metric, extraFilters]);

    useEffect(() => {
        if (res) {
            const { data: { content } } = res.data;
            if (content && content.length > 0) {
                content.reverse();

                setOption({
                    grid: {
                        left: '150',
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: Metric.makeChartTooltipFormatter([m], intl)
                    },
                    xAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: (value) => {
                                return Metric.formatChartValue(value, m, intl);
                            }
                        },
                    },

                    yAxis: [{
                        type: 'category',
                        data: content.map(item => item[primaryDimension]),
                        axisLabel: {
                            formatter: (value) => {
                                if (value.length > 20) {
                                    return value.substr(0, 20) + '...';
                                }
                                if (primaryDimension == 'subId' && !value) {
                                    value = '기본 값';
                                }
                                return value;
                            }
                        },
                    }],
                    series: [
                        {
                            name: intl.formatMessage({ id: MetricList.find(item => item.id === metric).key }),
                            type: 'bar',
                            itemStyle: {
                                color: '#8C50A3'
                            },
                            barWidth: 16,
                            data: content.map(item => item[metric])
                        },

                    ]
                });
                setHasData(true);
            } else {

                setOption(null);
                setHasData(false);
            }
        }
    }, [res])

    return (
        <Spin spinning={fetching}>
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', ...style }}>
                <div className="action-group">
                    <div className="action"><label>{intl.formatMessage({ id: 'METRIC' })}:</label><MetricSelect included={metrics} value={metric} onChange={onChangeMetric} /></div>
                </div>

                {loaded && <MediaQuery maxWidth={991}>
                    {
                        (matches) => {
                            if (option) {
                                if (matches) {
                                    option.grid.left = '10%';
                                    option.yAxis[0].axisLabel.show = false;

                                } else {

                                    option.yAxis[0].axisLabel.show = true;
                                }
                            }

                            return <Chart hasData={hasData} className="is-flex" option={option} />;
                        }
                    }
                </MediaQuery>
                }
            </div>
        </Spin>
    );
});