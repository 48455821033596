import React, { Component } from 'react'
import './PopupNotificationContent.css';

export default class PopupNotificationContent extends Component {
    constructor(props) {
        super(props);
        this.container = null;
    }

    componentDidMount() {
        // hanlde pure img notification
        let imgs = [...this.container.querySelectorAll('.notification-content-html-img img')];
        if (imgs.length > 0) {
            for (let img of imgs) {
                let mapName = img.getAttribute('usemap');
                if(mapName){
                    let map = this.container.querySelector(`map[name="${mapName.substr(1)}"]`);
                    if (map) {
                        if (img.getAttribute('width')) {
                            let scale = img.offsetWidth / img.getAttribute('width');
                            let areas = [].slice.call(map.querySelectorAll('area'));
                            for (let area of areas) {
                                let coords = area.getAttribute('coords');
                                let scaledCoords = coords.split(',').map(i => i * scale).join(',')
                                area.setAttribute('coords', scaledCoords);
                            }
                        } else {
                            const errorMessage = 'Must set the image width attriubte to the original size!<img width="440" height="355" ...>';
                            if (process.env.IS_PROD) {
                                console.error(errorMessage);
                            } else {
                                throw new Error(errorMessage);
                            }
                        }
                    }
                }
            }
        }

        //
    }

    render() {
        const { children } = this.props;
        return (
            <div className="popup-notification-content" ref={elem => this.container = elem}>
                {children}
            </div>
        );
    }
}
