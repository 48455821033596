import React from 'react';
import { useIntl } from 'react-intl';

export default function ({ value }) {
    const intl = useIntl();
    const { detailType: type, peerTrackingCode } = value || {};
    // COMMISSION,
    // BONUS_REFERRER, removed
    // BONUS_REFERRAL,
    // BONUS_GMV_GROWTH,
    // MANUAL,
    // ADMIN_MANUAL_BONUS
    // COMMISSION_CAP_DEDUCTION
    return <span>{intl.formatMessage({ id: `COMMISSION_TYPE_${type}` })}</span>;
}
