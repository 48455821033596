import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ProfileForm } from '../components';
import { regUpdateProfile, regSelectAffiliateType } from '../actions';
import { TrackerContext, Consts } from '../../common';

class CreateProfileW extends PureComponent {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.profileFormRef = React.createRef();
    }

    componentDidMount() {
        this.context.trackPageView({ action: 'enter_registration_profile' });
    }

    getData = (doNotCheck) => {
        const { current: inst } = this.profileFormRef || {};
        if (inst) {
            return (doNotCheck ? Promise.resolve(inst.getValue()) : inst.validate()).then((profile) => {
                const { regUpdateProfile, regSelectAffiliateType } = this.props;
                const { websites, apps, ...normalizedProfile } = profile;
                normalizedProfile.description =
                    websites && websites.length
                        ? websites[0].description
                        : apps && apps.length
                        ? apps[0].description
                        : '';
                // profile.urls = (websites || []).concat(apps || []).map(x => ({ url: x.name, quantity: x.quantity, description: profile.description }));
                normalizedProfile.websites = websites.map((x) => ({
                    name: x.name,
                    ownership: x.ownership,
                    description: normalizedProfile.description,
                }));
                normalizedProfile.apps = apps.map((x) => ({
                    name: x.name,
                    ownership: x.ownership,
                    description: normalizedProfile.description,
                }));
                // profile.topics = profile.topics.map((x) => x.id || x.name);
                regSelectAffiliateType(profile.affiliateType);
                regUpdateProfile(profile);
                return { profile: normalizedProfile };
            });
        }
        return Promise.reject('Profile cannot be persisted');
    };

    render() {
        const { affiliateType, profile, defaultValues, isReapply } = this.props;

        return (
            <ProfileForm
                action="create"
                isReapply={isReapply}
                defaultValues={defaultValues}
                {...profile}
                wrappedComponentRef={this.profileFormRef}
                affiliateType={affiliateType}
            />
        );
    }
}

const IntlCreateProfile = injectIntl(CreateProfileW, { forwardRef: true });
export default connect(
    (state) => ({
        profile: state.registration.profile,
        defaultValues: state.registration.defaultValues,
        isReapply:
            state.affiliate &&
            // state.affiliate.wasRejected &&
            [Consts.RegistrationStatus.Rejected, Consts.RegistrationStatus.Registering].includes(
                state.affiliate.regFinalStatus
            ),
    }),
    {
        regUpdateProfile,
        regSelectAffiliateType,
    },
    null,
    { forwardRef: true }
)(IntlCreateProfile);
