import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Spin } from 'antd';

import './CpRow.css';

export default class CpRow extends Component {
    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
        contentPadding: PropTypes.bool,
        contentCenter: PropTypes.bool,
        contentRelative: PropTypes.bool,
        contentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }

    render() {
        const { children, loading, className, style, contentPadding, contentCenter, contentRelative, contentWidth } = this.props;

        return (<div className={cx("cp-row", className)}>
            <div className={cx("cp-row-content", {
                "no-padding": contentPadding === false,
                "align-center": contentCenter,
                "position-relative": contentRelative
            })} style={{ maxWidth: contentWidth, ...style }}>
                <Spin spinning={!!loading} size="large" delay={250} className="cp-row-loading">
                    {children}
                </Spin>

            </div>
        </div>);
    }
}