import React from 'react';

import {
    FETCH_REGISTRATION_FLOW_INITDATA,
    PERSIST_PROFILE,
    FETCH_EDIT_PROFILE_INITDATA,
    UPDATE_PROFILE,
    REG_UPDATE_STEP,
    REG_SELECT_AFFILIATE_TYPE,
    REG_UPDATE_PROFILE,
    REG_UPDATE_PAYMENT,
    REG_FINISH_REGISTRATION,
} from './actionTypes.js';

import { begin, success, fail, Consts } from '../common';

const initialState = {
    tc: {
        showMessage: false,
        // consentItems: [
        //     {
        //         key: 'REGISTRATION_TC_V1',
        //         title: '서비스 이용약관',
        //     },
        //     {
        //         key: 'OPERATING_POLICY',
        //         title: '운영 정책',
        //     },
        //     {
        //         key: 'REGISTRATION_PS_V1',
        //         title: '개인정보 수집 및 이용',
        //     },
        //     {
        //         key: 'MEMBERSHIP_PROCESS',
        //         title: '회원 가입 절차',
        //     },
        // ],
        // consentItems: [
        //     {
        //         key: 'T&C',
        //         title: (
        //             <span>
        //                 <Link to="/help/terms" target="_blank">
        //                     Service T&C
        //                 </Link>
        //             </span>
        //         ),
        //         titleOnly: true,
        //     },
        //     {
        //         key: 'REGISTRATION_PS_V1',
        //         title: '개인정보 수집 및 이용',
        //     },
        // ],
    },
    profile: {
        data: {
            affiliateType: '',
            affiliateCode: '',
            websites: [],
            apps: [],
            description: '',
            topics: [],
            tcs: [],
        },
        topics: [],
    },
    payment: {
        data: { affiliateType: '' },
        bankNameAndCodes: [],
    },
    defaultValues: null,
    inRegFlow: false,
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case begin`${FETCH_REGISTRATION_FLOW_INITDATA}`:
        case begin`${PERSIST_PROFILE}`:
        case begin`${FETCH_EDIT_PROFILE_INITDATA}`:
        case begin`${UPDATE_PROFILE}`:
            return {
                ...state,
                loading: true,
            };
        case fail`${FETCH_REGISTRATION_FLOW_INITDATA}`:
        case fail`${PERSIST_PROFILE}`:
        case fail`${UPDATE_PROFILE}`:
            return {
                ...state,
                loading: false,
            };
        case success`${FETCH_REGISTRATION_FLOW_INITDATA}`: {
            const {
                initData: { bankNameAndCodes = [], categories = [] },
                memberInfo,
                piiInputStatus = Consts.PiiInputStatus.Disabled,
                regLastStep,
                profile,
                payment,
                reapplyCase,
                defaultValue: defaultValues,
            } = action.payload;

            const registrationState = { ...initialState, regLastStep, lastStep: regLastStep + 1 };
            const { formdata, customizedCategories } = normalizeProfileFormData(profile || {});

            registrationState.profile.data = formdata;
            registrationState.profile.topics = [...categories, ...customizedCategories];
            registrationState.payment.bankNameAndCodes = bankNameAndCodes;
            registrationState.payment.data = payment;
            registrationState.memberInfo = memberInfo || {};
            registrationState.reapplyCase = !!reapplyCase;
            registrationState.defaultValues = defaultValues || {};

            return {
                ...registrationState,
                piiInputStatus,
                loading: false,
                inRegFlow: true,
            };
        }
        case REG_FINISH_REGISTRATION:
            return {
                ...state,
                inRegFlow: false,
            };
        case REG_UPDATE_STEP: {
            return {
                ...state,
                lastStep: action.payload,
            };
        }
        case REG_UPDATE_PROFILE: {
            const { formdata, customizedCategories } = normalizeProfileFormData(action.payload);
            const topics = state.profile.topics;
            const newAddedTopics = customizedCategories.filter((x) => !topics.find((t) => t.id === x.id));

            return {
                ...state,
                profile: {
                    topics: [...state.profile.topics, ...newAddedTopics],
                    data: formdata,
                },
            };
        }
        case REG_UPDATE_PAYMENT: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    data: { ...state.payment.data, ...action.payload },
                },
            };
        }
        case REG_SELECT_AFFILIATE_TYPE: {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    data: {
                        ...state.payment.data,
                        affiliateType: action.payload,
                    },
                },
            };
        }
        case success`${PERSIST_PROFILE}`:
            return {
                ...state,
                stepIndex: 2,
                loading: false,
            };
        case success`${FETCH_EDIT_PROFILE_INITDATA}`:
            const { affiliate, categories } = action.payload;
            // const urls = [...affiliate.websites, ...affiliate.apps].map(x => ({ url: x.name, quantity: x.quantity, description: x.description }));
            const formdata = {
                affiliateCode: affiliate.affiliateCode,
                // urls,
                websites: (affiliate.websites || []).map((x) => ({
                    url: x.name,
                    ownership: x.ownership,
                    snapshot: x.snapshot,
                })),
                apps: (affiliate.apps || []).map((x) => ({
                    url: x.name,
                    ownership: x.ownership,
                    snapshot: x.snapshot,
                })),
                topics: [],
                description: '',
            };
            if (affiliate.websites && affiliate.websites.length) {
                formdata.description = affiliate.websites[0].description;
            } else if (affiliate.apps && affiliate.apps.length) {
                formdata.description = affiliate.apps[0].description;
            }
            // if (urls && urls.length) {
            //     formdata.description = urls[0].description;
            // }
            formdata.topics = (affiliate.topics || []).map((x) => parseInt(x.id, 10) || x.name);
            (affiliate.topics || []).forEach((x) => {
                if (x.topicType === 'CUSTOMIZED') {
                    categories.push({ id: x.name, name: x.name });
                }
            });

            return {
                ...state,
                profile: {
                    data: { ...formdata },
                    topics: categories,
                },
                loading: false,
            };
        case success`${UPDATE_PROFILE}`:
            return {
                ...state,
                loading: false,
            };
        default:
            break;
    }
    return state;
};

function normalizeProfileFormData(profile) {
    const formdata = {
        affiliateType: profile.affiliateType,
        affiliateCode: profile.affiliateCode,
        // urls: (profile.websites || []).concat(profile.apps || []).map(x => ({ url: x.name, quantity: x.quantity, description: x.description })),
        websites: (profile.websites || []).map((x) => ({ url: x.name, ownership: x.ownership, snapshot: x.snapshot })),
        apps: (profile.apps || []).map((x) => ({ url: x.name, ownership: x.ownership, snapshot: x.snapshot })),
        topics: [],
        description: '',
    };
    // if (formdata.urls && formdata.urls.length) {
    //     formdata.description = formdata.urls[0].description;
    // }
    if (profile.websites && profile.websites.length) {
        formdata.description = profile.websites[0].description;
    } else if (profile.apps && profile.apps.length) {
        formdata.description = profile.apps[0].description;
    }
    formdata.topics = (profile.topics || []).map((x) => parseInt(x.id, 10) || x.name);
    const customizedCategories = (profile.topics || [])
        .filter((x) => x.topicType === 'CUSTOMIZED')
        .map((x) => ({ id: x.name, name: x.name }));

    return { formdata, customizedCategories };
}
