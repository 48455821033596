import { FETCH_DASHBOARD_INIT_DATA, FETCH_BEST_11_PRODUCTS, FETCH_POPUP_NOTIFICATIONS } from './actionTypes';
import { begin, fail, success } from '../common';

const initialState = {
    categories: [],
    best11Products: [],
    dailyTrend: {},
    loading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case begin`${FETCH_DASHBOARD_INIT_DATA}`:
        case begin`${FETCH_BEST_11_PRODUCTS}`:
        case begin`${FETCH_POPUP_NOTIFICATIONS}`:
            return {
                ...state, loading: true
            };
        case fail`${FETCH_DASHBOARD_INIT_DATA}`:
        case fail`${FETCH_BEST_11_PRODUCTS}`:
        case fail`${FETCH_POPUP_NOTIFICATIONS}`:
            return {
                ...state, loading: false
            };
        case success`${FETCH_DASHBOARD_INIT_DATA}`:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case success`${FETCH_BEST_11_PRODUCTS}`:
            return {
                ...state,
                best11Products: action.payload.content,
                loading: false
            };
        case success`${FETCH_POPUP_NOTIFICATIONS}`:
            return {
                ...state,
                popups: action.payload
            }
        default:
            break;
    }
    return state;
}