import React from 'react';
import { notification } from 'antd';
import axios from 'axios';
import * as echarts from 'echarts/core';
import { auth } from './auth';

window.axios = axios;


export function setup(store) {
    axios.interceptors.request.use((config) => {
        config.headers.lang = (window.cpapp && window.cpapp.lang) || 'ko';
        //set token header
        if (/(?:^|\s)AFATK=([^;]+)(?:;|$)/.test(document.cookie)) {
            config.headers['x-token'] = RegExp.$1;
        }
        let currentSubId = sessionStorage.getItem('currentSubId');
        if (currentSubId) {
            config.headers['x-sub-id'] = currentSubId;
        }
        return config;
    });
    axios.interceptors.response.use((res) => {
        const { rCode, rMessage } = res.data;
        // session timeout
        if (rCode === '600') {
            store.dispatch({ type: 'SESSION_TIMEOUT' });
            store.dispatch({ type: 'UPDATE_AFFILIATE', payload: {} });
        } else if (rCode === '403') {
            return new Promise((resolve, reject) => {
                store.dispatch(auth({
                    onAuthSuccess: () => resolve(axios(res.config)),
                    onAuthCancelled: () => reject({}),
                }));
            });
        } else if(rCode === '429'){
            notification.warn({message: '에러', description: <div dangerouslySetInnerHTML={{__html: rMessage}} />})
            return Promise.reject({});
        }
        const contentType = res.headers['content-type'] || '';
        if (contentType.includes('json')) {
            if (rCode !== '0') {
                return Promise.reject(res);
            }
        }
        return res;
    }, err => {
        let notice = null;
        if (err && err.response && err.response.status >= 400) {
            notice = {
                message: 'Error',
                description: err.response.data || err.response.statusText
            };
        } else if (err && err.message) {
            notice = {
                message: 'Error',
                description: err.message
            };
        }
        if (notice) {
            notification.error(notice);
        }

        return Promise.reject((err && err.response) || {});
    });


    echarts.registerTheme('affiliate', {
        legend: {
            x: 'center',
            y: 'top',
            textStyle: {
                fontWeight: 'bold'
            },
        },
        tooltip: {
            axisPointer: {
                lineStyle: {
                    color: '#008C00'
                }
            },
        },
        categoryAxis: {
            axisLabel: {
                color: '#808286',
                fontWeight: 200,
                fontFamily: 'NotoSansCJKKr',

            },
            axisLine: {
                lineStyle: {
                    color: '#cfd0d2',
                }
            },
            axisTick: {
                show: false,
            }
        },
        valueAxis: {
            nameTextStyle: {
                fontSize: 12,
                fontFamily: 'NotoSansCJKKr',
                color: '#808286',
                // padding: [0, 68, 0, 0],
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                fontFamily: 'NotoSansCJKKr'
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#DFE0E2',
                    width: 1
                }
            },
        },
        line: {
            hoverAnimation: false,
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
            },
            emphasis: {
                itemStyle: {
                    borderWidth: 6,
                    borderColor: 'rgba(140, 80, 163, 0.2)',
                }
            }
        }
    });

}
