import React, { useState } from 'react';
import { Select } from 'antd';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';

import './FilterSelector.less';

export default injectIntl(function FitlerSelector(props) {
    const { intl, mode = 'multiple', allText, onChange } = props;
    const items = props.items || [];
    const [opened, setOpened] = useState(false);
    const [value, setValue] = useState(['all']);

    const maxTagPlaceholder = () => {
        let text;
        if (value.length === 1) {
            text = allText || 'All';
        } else if (value.length === 2) {
            text = items.find(x => x.id === value[1]).text;
        } else {
            text = intl.formatMessage({ id: 'N_SELECTED' }, { n: value.length - 1 });
        }
        return <div>{text}<span className={cx("expand-arrow", { opened })}>
            <ChevronDownIcon size={16} style={{ marginLeft: 4, verticalAlign: '-0.25rem' }} /></span>
        </div>
    }

    function handleSelectChange(value) {
        setValue(value);
        let newValue = value;
        if(Array.isArray(value) && value.length === 1 && value[0] === 'all') {
            newValue = [];
        }
        onChange && onChange(newValue);
    }

    function handleDropdownVisibleChange(visible) {
        setOpened(visible);
    }

    return (
        <Select className="filter-select light-select"
            size="small"
            style={{ ...props.style }}
            {...mode === 'multiple' ? { mode, maxTagCount: 0, maxTagPlaceholder } : null}            
            dropdownMatchSelectWidth={false}
            showSearch={false}
            dropdownClassName="filter-select-dropdown"
            value={value}
            onChange={handleSelectChange}
            onDropdownVisibleChange={handleDropdownVisibleChange}
        >
            <Select.Option key={'all'} value={'all'} {...(mode === 'multiple' ? { style: { display: 'none' } } : null)}>All</Select.Option>
            {
                items.map(item => <Select.Option key={item.id} value={item.id}>{item.text}</Select.Option>)
            }
        </Select>
    );
});