import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input } from 'antd';
import { injectIntl } from 'react-intl';
import { FilterInput } from '../../components';
import { requiredMessage, invalidMessage } from '../../common';

class ContactFieldset extends Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        data: PropTypes.shape({
            contactName: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
            address: PropTypes.string,
        }),
        includeContactName: PropTypes.bool,
    };

    render() {
        const {
            intl,
            form: { getFieldDecorator },
            data = {},
            disabled,
            includeContactName,
            mode = 'create'
        } = this.props;

        return (
            <fieldset>
                <legend>{intl.formatMessage({ id: 'PAYMENT_SECTION_CONTACT' })}</legend>
                <Row gutter={20}>
                    {includeContactName !== false ? (
                        <Col span={24} sm={12}>
                            <Form.Item label={intl.formatMessage({ id: 'NAME' })}>
                                {getFieldDecorator('contactName', {
                                    initialValue: data.contactName,
                                    rules: [
                                        {
                                            required: true,
                                            message: requiredMessage(intl, 'NAME'),
                                        },
                                    ],
                                })(<Input maxLength={30} disabled={disabled} />)}
                            </Form.Item>
                        </Col>
                    ) : null}
                    <Col span={24} sm={12}>
                        <Form.Item label={intl.formatMessage({ id: 'PAYMENT_PHONE_NUMBER' })}>
                            {getFieldDecorator('phone', {
                                initialValue: data.phone,
                                rules: [
                                    {
                                        required: true,
                                        message: requiredMessage(intl, 'PAYMENT_PHONE_NUMBER'),
                                    },
                                    {
                                        pattern: data.phone && data.phone.indexOf('*') > 0 ? /^010[\d\*]{8}$/ : /^010\d{8}$/,
                                        message: invalidMessage(intl, 'PAYMENT_PHONE_NUMBER'),
                                    },
                                ],
                            })(
                                <FilterInput
                                    displayInGroup
                                    filter={data.phone && data.phone.indexOf('*') > 0 ? /^[\d*]+$/ : /^\d+$/}
                                    maxLength={11}
                                    disabled={disabled}
                                    autoComplete="off"
                                    placeholder={intl.formatMessage({ id: 'PAYMENT_OMIT_MINUS_SYMBOL' })}
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={24} sm={12}>
                        <Form.Item label={intl.formatMessage({ id: 'PAYMENT_EMAIL' })}>
                            {getFieldDecorator('email', {
                                initialValue: data.email,
                                rules: [
                                    { required: true, message: requiredMessage(intl, 'PAYMENT_EMAIL') },
                                    { type: 'email', message: invalidMessage(intl, 'PAYMENT_EMAIL') },
                                ],
                            })(
                                <Input
                                    maxLength={100}
                                    disabled={disabled}
                                    autoComplete="off"
                                    placeholder={intl.formatMessage({ id: 'PAYMENT_EMAIL_PLACEHOLDER' })}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </fieldset>
        );
    }
}

export default injectIntl(ContactFieldset);
