import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cx from 'classnames';

export default connect((state) => ({ legalMessage: state.config.advertisementLegalMessage }))(
    injectIntl(function ({ intl, className, legalMessage }) {
        return (
            <div
                className={cx('mt1em ft-12 dim', className)}
                dangerouslySetInnerHTML={{
                    __html: legalMessage || intl.formatMessage({ id: 'AFFILIATE_FEE_NOTICE' }),
                }}></div>
        );
    })
);
