import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import cx from 'classnames';
import { injectIntl } from 'react-intl';

import './Confirm.css';


class Confirm extends Component {
    static propTypes = {
        title: PropTypes.string,
        description: PropTypes.node,
        contentAlignment: PropTypes.string,
        rs: PropTypes.object,
        width: PropTypes.number,
        noCancel: PropTypes.bool,
        mask: PropTypes.bool
    }

    render() {
        const { description, title, contentAlignment, rs, width, noCancel, mask, className, processing, intl, children, ...others } = this.props;
        return <Modal
            wrapClassName={cx("confirm-modal", className, { 'no-cancel': noCancel })}
            closable={false}
            maskClosable={false}
            width={width || 440}
            mask={mask}
            destroyOnClose={true}
            okText={rs ? rs.Ok : intl.formatMessage({ id: 'CONFIRM' })}
            cancelText={rs ? rs.Cancel : intl.formatMessage({ id: 'CANCEL' })}
            okButtonProps={{ size: 'large', loading: processing }}
            cancelButtonProps={{ size: 'large', disabled: processing }}
            {...others}
        >
            {
                !!title &&
                <h1 className="custom-title">{title}</h1>
            }
            {children ? children : <p className={`custom-content align-${contentAlignment || 'center'}`}>{description}</p>}
        </Modal>;
    }
}

export default injectIntl(Confirm)