import axios from 'axios';

export default class Api {
    static fetchSearchInitData() {
        return axios.get('/api/v1/search');
    }

    static searchProducts(request) {
        return axios.post('/api/v1/search', request);
    }

    static fetchSearchBarWidgetInstanceId() {
        return axios.get('/api/v1/search-bar/widget-instance-id');
    }

    static fetchLinkGenerationInitData(group, product) {
        return axios.post('/api/v1/banner/iframe/url', { group, product });
    }

    static generateBannerImage(group, product, options) {
        return axios.post('/api/v1/banner/gen', { group, product, options });
    }

    static fetchCategoryBannerInitData({ datasetId, size: { width, height } }) {
        return axios.post(`/api/v1/widgets/banners`, {
            datasetId,
            width,
            height,
        });
    }

    static fetchAllCategoryBanners() {
        return axios.get('/api/v1/widgets/banners');
    }

    static getRecommendedCategoriesForLinkToAnyPage() {
        return axios.get('/api/v1/url/instant/all');
    }

    static convertCoupangUrlToTrackingUrl(url) {
        let eUrl = window.encodeURIComponent(url);
        return axios.get(`/api/v1/url/any?coupangUrl=${eUrl}`);
    }

    static convertCategoryToTrackingUrl(categoryKey) {
        return axios.get(`/api/v1/url/instant/${categoryKey}`);
    }

    static getWidgetInstances(payload) {
        return axios.post('/api/v1/widgets/instances/search', payload);
    }

    static getWidgetInstancePageInitData(instanceId) {
        let instanceIdParam = instanceId ? `?instanceId=${instanceId}` : '';
        return axios.get(`/api/v1/widgets${instanceIdParam}`);
    }

    static getEvents(payload) {
        return axios.post('/api/v1/events', payload);
    }

    static getEvent(id) {
        return axios.get(`/api/v1/events/${id}`);
    }

    static getDatasetTypes() {
        return axios.get('/api/v1/widgets/datasets/types');
    }

    static getDatasets(type) {
        return axios.get(`/api/v1/widgets/datasets?dataType=${type}`);
    }

    static getWidgetInstance(id) {
        return axios.get(`/api/v1/widgets/instances/${id}`);
    }

    static saveWidgetInstance(widgetInstance) {
        return axios.post('/api/v1/widgets/instances', widgetInstance);
    }

    static enableWidgetInstancePassback(id, enabled) {
        return axios.post(`/api/v1/widgets/instances/${id}/passback`, { enabled });
    }

    static delelteWidgetInstance(id) {
        return axios.post(`/api/v1/widgets/instances/${id}/delete`);
    }

    static getWidgetTemplate(payload) {
        let widgetServer = window.cpapp.settings.widgetServerHost;
        const endsWithSlash = widgetServer.endsWith('/');
        widgetServer = endsWithSlash ? widgetServer : widgetServer + '/';
        return axios.post(`${widgetServer}widgetsPreview.html`, payload, {
            responseType: 'text',
        });
    }

    static getVideoBanners() {
        return axios.get(`/api/v1/widgets/videoBanners`);
    }

    static getVideoBanner(datasetId) {
        return axios.post(`/api/v1/widgets/videoBanners/${datasetId}`);
    }

    static persistDataset(payload) {
        return axios.post('/api/v1/widgets/datasets', payload);
    }

    static getDataset(datasetId) {
        return axios.get(`/api/v1/widgets/datasets/${datasetId}`);
    }

    static deleteDataset(datasetId) {
        return axios.get(`/api/v1/widgets/datasets/${datasetId}/delete`);
    }

    static getBestProducts(category) {
        return axios.post('/api/v1/search/best', {
            page: { pageNumber: 0, size: 36 },
            category,
        });
    }

    static getBestGoldboxProducts(limit) {
        return axios.get(`/api/v1/discovery/goldbox?limit=${limit}`);
    }

    static getBestFashionProducts(limit){
        return axios.get(`/api/v1/discovery/fashion?limit=${limit}`);
    }

    static getBestRocketFreshProducts(limit) {
        return axios.get(`/api/v1/discovery/rocket-fresh?limit=${limit}`);
    }

    static getBestRetailClearanceProducts(limit) {
        return axios.get(`/api/v1/discovery/retail-clearance?limit=${limit}`);
    }

    static getBestMostSoldProducts(limit, categoryId) {
        let url = `/api/v1/discovery/most-sold?limit=${limit}`;
        if (categoryId) {
            url += `&categoryId=${categoryId}`;
        }
        return axios.get(url);
    }

    static getBestMostConversionProducts(limit, categoryId) {
        let url = `/api/v1/discovery/most-conversion?limit=${limit}`;
        if (categoryId) {
            url += `&categoryId=${categoryId}`;
        }
        return axios.get(url);
    }

    static getBestMostDiscountedProducts(limit, categoryId) {
        let url = `/api/v1/discovery/most-discounted?limit=${limit}`;
        if (categoryId) {
            url += `&categoryId=${categoryId}`;
        }
        return axios.get(url);
    }

    static _displaySpecialSizeBanner(affiliateCode) {
        return [
            'AF0000001',
            'AF0006386',
            'AF3672557',
            'AF2069702',
            'AF4025660',
            'AF3779969',
            'AF3635217',
            'AF3184437',
            'AF3473628',
            'AF6276526',
            'AF0172360',
            'AF4180180',
            'AF3795771',
            'AF9826794',
            'AF3635217',
            'AF5586388',
            'AF9478386',
            'AF1305770',
            'AF5895564',
            'AF1814299',
        ].includes(affiliateCode);
    }

    static _isTargetAffiliate(affiliateCode) {
        if (
            [
                'AF0000001',
                'AF0006386',
                'AF3672557',
                'AF2069702',
                'AF4025660',
                'AF3779969',
                'AF3635217',
                'AF3184437',
                'AF3473628',
                'AF6276526',
                'AF0172360',
                'AF4180180',
                'AF3795771',
                'AF9826794',
                'AF3635217',
                'AF5586388',
                'AF9478386',
                'AF1305770',
                'AF5895564',
                'AF1814299',
                'AF5960047',
                'AF4978887',
                'AF4576674',
                'AF6710984',
                'AF6094916',
                'AF3208436',
                'AF7056084',
                'AF4531829',
                'AF1705883',
                'AF2928328',
                'AF2690143',
                'AF0663311',
            ].includes(affiliateCode)
        ) {
            return true;
        }
        const ones = affiliateCode.slice(-1) * 1;
        if (Date.now() < new Date(2021, 4, 17) && ones === 4) {
            return true;
        }
        if (Date.now() >= new Date(2021, 4, 17) && Date.now() < new Date(2021, 4, 24) && ones % 2 === 0) {
            return true;
        }
        if (Date.now() >= new Date(2021, 4, 24)) {
            return true;
        }
        return false;
    }
}
