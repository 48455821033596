import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Confirm from './Confirm';

export default function confirmHoc(WrappedComponent) {
    return class extends PureComponent {
        static propTypes = {
            closeFirstWhenClickOk: PropTypes.bool,
            onOk: PropTypes.func
        }

        state = {
            confirmVisible: false
        }

        showConfirm = () => {
            this.setState({ confirmVisible: true });
        }

        hideConfirm = () => {
            this.setState({ confirmVisible: false });
        }

        handleOk = () => {
            const { closeFirstWhenClickOk, onOk } = this.props;
            if (typeof onOk === 'function') {
                if (closeFirstWhenClickOk) {
                    this.hideConfirm();
                    onOk();
                } else {
                    Promise.all([onOk()]).then(() => {
                        this.hideConfirm();
                    });
                }
            } else {
                this.hideConfirm();
            }
        }

        render() {
            const { confirmOptions, closeFirstWhenClickOk, onOk, ...wrapComponentProps } = this.props;
            const confirmProps = { ...confirmOptions, visible: this.state.confirmVisible, onCancel: this.hideConfirm, onOk: this.handleOk };
            const finalWrapComponentProps = { ...wrapComponentProps, onClick: this.showConfirm };
            return (
                <React.Fragment>
                    <WrappedComponent {...finalWrapComponentProps} />
                    <Confirm {...confirmProps} />
                </React.Fragment>
            );
        }
    }
}