import UaParser from 'ua-parser-js';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import CoupangWebLog from 'coupang-web-log';
import { useSelector } from 'react-redux';

const TrackerContext = React.createContext();
const FirstOpenTimestamp = Date.now().toString();

const TrackerProvider = ({ children }) => {
    const history = useHistory();

    const ENV = window.cpapp.settings.profile;
    const SCHEMA_ID = ENV === 'production' ? 1387 : 800;
    const META = {
        schemaId: SCHEMA_ID,
        schemaVersion: 4,
    };
    const DOMAIN = 'affiliate';
    const device = new UaParser().getDevice();
    const affiliate = useSelector((state) => state.affiliate);
    const trackEvent = ({ action, value }) => {
        setTimeout(() => {
            CoupangWebLog.submit({
                meta: META,
                data: {
                    domain: DOMAIN,
                    logCategory: 'view',
                    logType: 'page',
                    pageName: 'affiliate',
                    eventName: action,
                    action,
                    // eslint-disable-next-line
                    value: value != undefined ? value.toString() : value,
                    affiliateCode: affiliate && affiliate.affiliateCode,
                    firstOpenTimestamp: FirstOpenTimestamp,
                },
            });
            window.gtag('event', action, {
                action,
                value: value != undefined ? value.toString() : value,
                affiliateCode: affiliate && affiliate.affiliateCode,
            });
        });
    };

    const trackPageView = useCallback(
        ({ action } = {}) => {
            try {
                setTimeout(() => {
                    CoupangWebLog.submit(
                        {
                            meta: META,
                            data: {
                                domain: DOMAIN,
                                logCategory: 'view',
                                logType: 'page',
                                pageName: 'affiliate',
                                action,
                                // value: JSON.stringify(affiliate),
                                affiliateCode: affiliate && affiliate.affiliateCode,
                                firstOpenTimestamp: FirstOpenTimestamp,
                            },
                        },
                        true
                    );

                    window.gtag('event', 'page_view', {
                        page_title: document.title,
                        page_location: window.location.href, // The full URL is required.
                        action,
                        affiliateCode: affiliate && affiliate.affiliateCode,
                    });
                });
            } catch (e) {
                console.error(e.message);
            }
        },
        [affiliate]
    );

    useEffect(() => {
        CoupangWebLog.init({
            platform: device.type === 'mobile' ? 'mweb' : 'web',
            service: 'coupang',
            mode: ENV === 'production' ? 'production' : 'develop',
            useAutoSendPageViewLog: false,
        });
        trackPageView();
    }, []);

    useEffect(() => {
        return history.listen(trackPageView);
    }, [trackPageView]);

    return <TrackerContext.Provider value={{ trackEvent, trackPageView }}>{children}</TrackerContext.Provider>;
};

export { TrackerContext, TrackerProvider };
