import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import UserIcon from 'react-feather/dist/icons/user';
import { Button } from 'antd';

import './LoginSignup.less';

export default class LoginSignup extends Component {
    static propTypes = {
        rs: PropTypes.object,
        onLogin: PropTypes.func,
        onSignup: PropTypes.func
    }

    state = {}

    onMenuClick = ({ key }) => {
        this.setState({ key });
    }

    render() {
        const { rs, onLogin, onSignup, children } = this.props;
        return (
            <div className="login-signup">
                <MediaQuery minWidth={992}>
                    {
                        (matches) => {
                            if (matches) {
                                return (
                                    <React.Fragment>
                                        {children}
                                        <Button className="btn-link" onClick={onLogin}>{rs && rs.Login ? rs.Login : 'Login'}</Button>
                                        <Button className="btn-link" onClick={onSignup}>{rs && rs.Signup ? rs.Signup : 'Signup'}</Button>
                                    </React.Fragment>
                                );
                            } else {
                                return <UserIcon className="user-toolbar-icon" onClick={onLogin} />;
                            }
                        }
                    }
                </MediaQuery>
            </div>);
    }
}