import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Row, Col, Tabs } from 'antd';

import { WorkspacePage, CpRow, LastUpdatedDate } from '../../components';
import HomeIcon from 'react-feather/dist/icons/home';
import DownloadIcon from 'react-feather/dist/icons/download';

import { initReportStore, fetchReportDataDaily, fetchReportDataMonthly } from '../actions';
import { SummaryCard, TrendChart, DownloadButton } from '../components';
import { Consts } from '../../common';

const mapStateToProps = (state) => ({ ...state.report, ...state.subId });

const mapDispatchToProps = {
    initReportStore,
    fetchReportDataDaily,
    fetchReportDataMonthly,
};

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeReportKey: props.match.params.type || 'daily',
        };
    }

    componentDidMount() {
        // TODO: Optimize this part, save/reuse report data on store for different components (like dashboard)
        const { initReportStore } = this.props;
        if (initReportStore) {
            initReportStore();
        }

        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.dateRange !== this.props.dateRange || prevProps.currentSubIds !== this.props.currentSubIds) {
            this.load();
        }
    }

    load = () => {
        if (this.state.activeReportKey === 'daily') {
            this.fetchDailyTrend();
        } else {
            this.fetchMonthlyTrend();
        }
    };

    fetchDailyTrend() {
        const { fetchReportDataDaily, dateRange, currentSubIds } = this.props;
        fetchReportDataDaily({ ...dateRange, currentSubIds });
    }

    fetchMonthlyTrend() {
        const { fetchReportDataMonthly, dateRange, currentSubIds } = this.props;
        fetchReportDataMonthly({ ...dateRange, currentSubIds });
    }

    onTabChange = (activeKey) => {
        this.setState({
            activeReportKey: activeKey,
        });
        const { history } = this.props;
        history.replace(`/affiliate/ws/report/trend/${activeKey}`);

        if (activeKey === 'daily') {
            this.fetchDailyTrend();
        }

        if (activeKey === 'monthly') {
            this.fetchMonthlyTrend();
        }
    };

    buildReportData(trendData, type = 'daily') {
        let dataSource = [];
        if (type === 'daily') {
            dataSource = (trendData.units || []).map((x) => ({
                key: x.key,
                commission: x.commission,
                clicks: x.clickCount,
            }));
        } else {
            dataSource = (trendData.units || []).map((x) => ({
                key: moment(x.key).format('YYYY-MM'),
                commission: x.commission,
                clicks: x.clickCount,
            }));
        }

        return {
            lastUpdatedDate: trendData.lastUpdatedDate,
            summaryDataSource: trendData,
            dataSource,
            type,
        };
    }

    render() {
        const {
            intl,
            loading,
            currentSubIds,
            dateRange: { startDate, endDate },
        } = this.props;
        const metrics = ['clickCount', 'orderCount', 'gmv', 'commission', 'conversion'];
        const daily = this.props.daily || {};
        const monthly = this.props.monthly || {};

        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_PERFORMANCE_REPORT' }) },
            { title: intl.formatMessage({ id: 'HEADER_REPORT_TREND' }) },
        ];

        let reportData;
        if (this.state.activeReportKey === 'daily') {
            reportData = this.buildReportData(daily, 'daily');
        } else {
            reportData = this.buildReportData(monthly, 'monthly');
        }

        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'HEADER_REPORT_TREND' })}
                subTitle={intl.formatMessage({id:'HEADER_REPORT_TREND_SUB_TITLE'})}
                withDateRangedReportPicker={true}
                withSubId={{ isMultiple: true }}
                loading={loading}>
                <CpRow>
                    <Tabs
                        className="affiliate dated-report"
                        onChange={this.onTabChange}
                        activeKey={this.state.activeReportKey}>
                        <Tabs.TabPane tab={intl.formatMessage({ id: 'RP_DAILY_TREND' })} key="daily">
                            <React.Fragment>
                                <Row type="flex" align="middle">
                                    <Col sm={12} xs={24}>
                                        <LastUpdatedDate date={reportData.lastUpdatedDate} />
                                    </Col>
                                    <Col sm={12} xs={24}>
                                        <Row type="flex" justify="end">
                                            <Col className="action-group">
                                                <DownloadButton
                                                    buttonProps={{ className: 'sm action' }}
                                                    jobType={Consts.AsyncJobTypes.AffiliateDailyTrend}
                                                    parameters={{
                                                        subIds: currentSubIds,
                                                        startDate: moment(startDate).format('YYYY-MM-DD'),
                                                        endDate: moment(endDate).format('YYYY-MM-DD'),
                                                    }}>
                                                    {
                                                        <DownloadIcon
                                                            size={16}
                                                            className="feathericon"
                                                            style={{ marginRight: 5 }}
                                                        />
                                                    }
                                                    {intl.formatMessage({ id: 'DOWNLOAD' })}
                                                </DownloadButton>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div style={{ marginTop: 20 }}>
                                    <SummaryCard metrics={metrics} dataSource={daily} style={{ marginBottom: 36 }} />
                                    <div style={{ height: 450 }}>
                                        <TrendChart metrics={metrics} dataSource={daily.units || []} type="daily" />
                                    </div>
                                </div>
                            </React.Fragment>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={intl.formatMessage({ id: 'RP_MONTHLY_TREND' })} key="monthly">
                            <Row type="flex" align="middle">
                                <Col span={24}>
                                    <LastUpdatedDate date={reportData.lastUpdatedDate} />
                                </Col>
                            </Row>
                            <div style={{ marginTop: 20 }}>
                                <SummaryCard metrics={metrics} dataSource={monthly} style={{ marginBottom: 36 }} />
                                <div style={{ height: 450 }}>
                                    <TrendChart metrics={metrics} dataSource={monthly.units || []} type="monthly" />
                                </div>
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Report)));
