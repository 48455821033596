import { FETCH_TAX_INVOICES, GET_INVOICE_APPROVAL_URL } from './actionTypes';

const initialState = {
    settlement: {
        total: 0,
        content: []
    },
    invoiceApprovalUrl: '',
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case `${FETCH_TAX_INVOICES}_BEGIN`:
        case `${GET_INVOICE_APPROVAL_URL}_BEGIN`:
            return {
                ...state,
                loading: true
            };
        case `${FETCH_TAX_INVOICES}_FAILED`:
        case `${GET_INVOICE_APPROVAL_URL}_FAILED`:
            return {
                ...state,
                loading: false
            };
        case `${FETCH_TAX_INVOICES}_SUCCESS`:
            return {
                ...state,
                loading: false,
                settlement: action.payload
            }
         case `${GET_INVOICE_APPROVAL_URL}_SUCCESS`:
            return {
                ...state,
                loading: false,
                invoiceApprovalUrl: action.payload
            };
        default:
            break;
    }
    return state;
}