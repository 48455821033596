import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import { ConsentItem } from '../../components';

class ConsentFieldset extends Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        consentRichFormat: PropTypes.bool,
        term: PropTypes.shape({
            title: PropTypes.string,
            content: PropTypes.string
        }).isRequired,
        accepted: PropTypes.bool
    };

    validateAccessPersonalInformationAccepted = (rule, value, callback) => {
        if (value) {
            callback();
        } else {
            callback(this.props.intl.formatMessage({ id: 'ACCEPT_TC_TO_CONTINUE' }));
        }
    }

    render() {
        const { intl, form, term, consentRichFormat, accepted } = this.props;
        const { getFieldDecorator } = form;

        const rs = {
            ViewDetail: intl.formatMessage({ id: 'VIEW_DETAIL' }),
            DetailTitle: intl.formatMessage({ id: 'PAYMENT_CONSENT_DETAIL_TITLE' }),
            OK: intl.formatMessage({ id: 'CONFIRM' })
        };

        return (
            <fieldset>
                <legend>
                    {intl.formatMessage({ id: 'PAYMENT_SECTION_ACQUISITION_OF_PI' })}
                </legend>
                {
                    <Form.Item>
                        {
                            getFieldDecorator(`piAccepted`, {
                                initialValue: accepted,
                                rules: [
                                    { validator: this.validateAccessPersonalInformationAccepted }
                                ]
                            })(<ConsentItem title={term.title} description={term.content} richFormat={consentRichFormat} rs={rs} />)}
                    </Form.Item>
                }
            </fieldset>
        );
    }
}

export default injectIntl(ConsentFieldset)

