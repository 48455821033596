import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Icon } from 'antd';
import { Emotion } from '../../components';
import { TrackerContext } from '../../common';

import './AffiliateUnderReview.css';

class AffiliateUnderReview extends Component {
    static contextType = TrackerContext;
    componentDidMount() {
        this.context.trackPageView({ action: 'enter_registration_reapply_completed' });
    }

    render() {
        const { intl, email } = this.props;
        return (
            <div className="confirmation align-center">
                <div className="emotion-line">
                    <Emotion type="sweatsmile" />
                </div>
                <h1>
                    <FormattedMessage id="REGISTRATION_AFFILIATE_REAPPLY" />
                </h1>
                <div className="ft-16">{intl.formatMessage({ id: 'REGISTRATION_AFFILIATE_REAPPLY_COMPLETED' })}</div>
                <p className="ft-16">
                    <FormattedMessage
                        id="REGISTRATION_AFFILIATE_REAPPLY_COMPLETED_NOTICE"
                        values={{
                            newLine: <br />,
                            email: (
                                <span className="color-accent email">
                                    <Icon type="mail" />
                                    &nbsp;{email}
                                </span>
                            ),
                        }}
                    />
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    email: state.session.email,
});

export default injectIntl(connect(mapStateToProps)(AffiliateUnderReview));
