import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Collapse, Badge, Spin } from 'antd';

import AlertIcon from './alert.svg';
import PromotionIcon from './promotion.svg';
import GeneralIcon from './news.svg';


import styles from './MessageList.module.less';
import { IntlDate } from '../Formatted';

function NotificationMessageIcon(props) {
    switch (props.type) {
        case 'GENERAL':
            return <img src={GeneralIcon} alt="" />;
        case 'PROMOTION':
            return <img src={PromotionIcon} alt="" />;
        case 'ALERT':
            return <img src={AlertIcon} alt="" />;
        default:
            return <img src={GeneralIcon} alt="" />;
    }
}

// const FAKE_MESSAGES = [
//     { receiverId: 5, messageType: 'GENERAL', title: 'GENERAL测试', content: '<p>亲，别紧张，只是一个普通消息的测试。这里是一个可以点击的链接：<a href="/affiliate">Click here!</a></p>', publishedDate: '2019-08-12 08:10:55', readFlag: false, readDate: null },
//     { receiverId: 4, messageType: 'ALERT', title: 'ALERT消息', content: '<p>亲，别紧张，只是一个警告消息的测试。', publishedDate: '2019-08-13 12:01:30', readFlag: false, readDate: null },
//     { receiverId: 3, messageType: 'PROMOTION', title: 'PROMOTION', content: '<p>This is just a test promotion message which could be longer, like 2 lines, just to see how it looks like</p>', publishedDate: '2019-08-13 12:01:30', readFlag: true, readDate: null },
//     { receiverId: 1, messageType: 'GENERAL', title: 'Very first message', content: '<p>This is a very first message which could be longer, like 2 lines, just to see how it looks like</p>', publishedDate: '2019-08-13 12:01:30', readFlag: true, readDate: null }
// ];

export default injectIntl(function MessageList(props) {
    const { intl, header, footer, messages = [], onMarkAsRead, loading } = props;

    return (
        <Spin spinning={loading}>
        <div className={styles.messages}>
            {header}
            <div className={styles.content}>
                {
                    messages.length > 0 ?
                    <Collapse bordered={false} accordion>
                    {
                        messages.map((item)=>{
                            const actions = [<div className={styles.date}><IntlDate value={item.publishedDate} /></div>];
                            if (!item.readFlag) {
                                actions.push(<a className="link-button hover-action" onClick={() => onMarkAsRead && onMarkAsRead(item)}>{intl.formatMessage({ id: 'NOTIFICATION_MARK_AS_READ' })}</a>);
                            } else {
                                actions.push(<span>&nbsp;</span>);
                            }
                            return (
                                    <Collapse.Panel showArrow={false} header={
                                        <div className={styles.title}>
                                            <NotificationMessageIcon type={item.messageType} />
                                            <Badge dot={!item.readFlag}>{item.title}</Badge>
                                        </div>
                                    } className={styles.panel}>
                                        <div className="ck-content" dangerouslySetInnerHTML={{ __html: item.content }} />
                                        <div className={styles.actions}>
                                            {actions}
                                        </div>
                                    </Collapse.Panel>
                            );
                        })
                    }
                    </Collapse>
                    : 
                    <div className={styles.empty}>{intl.formatMessage({ id: 'NOTIFICATION_EMPTY' })}</div>
                }
            </div>
            {footer}
        </div>
        </Spin>
    );

});
