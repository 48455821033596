import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import { WorkspacePage, CpRow, CardPage } from '../../components';
import { ProfileForm } from '../components';
import { fetchEditProfileInitData, updateProfile } from '../actions';

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.formInst = React.createRef();
    }

    componentDidMount() {
        const { fetchEditProfileInitData } = this.props;
        fetchEditProfileInitData();
    }

    updateProfile = () => {
        const { updateProfile, history } = this.props;
        if (this.formInst && this.formInst.current) {
            this.formInst.current.validate().then(profile => {
                updateProfile(profile).then(() => history.push('/affiliate'));
            });
        }
    }

    render() {
        const { intl, loading, activated, profile, inMaintainance } = this.props;
        const footer = activated && inMaintainance !== true ?
            <div className="align-center">
                <Button type="primary" size="large" onClick={this.updateProfile}>{intl.formatMessage({ id: 'SAVE' })}</Button>
            </div> : null;

        return (
            <WorkspacePage mini={true} withHome={true} className="edit-profile is-flex bg-grey" >
                <CpRow style={{ maxWidth: 840 }}>
                    <h1 className="page-title-secondary">{intl.formatMessage({ id: 'EDIT_PROFILE_TITLE' })}</h1>
                    <CardPage footer={footer} loading={loading}>
                        <ProfileForm action="edit" wrappedComponentRef={this.formInst}
                            disabled={!activated}
                            {...profile} />
                    </CardPage>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default connect(state => {
    return {
        profile: state.registration.profile,
        loading: state.registration.loading,
        activated: state.affiliate.activated && state.config.maintainingEnabled !== true
    };
}, {
    fetchEditProfileInitData,
    updateProfile
})(withRouter(injectIntl(EditProfile)));

