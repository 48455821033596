import { useState, useEffect } from 'react';
export default function(fetchApi, { autoLoad = true } = {}) {
    // first load
    const [timestamp, setTimestamp] = useState(autoLoad ? +new Date() : 0);

    const [state, setState] = useState({ loaded: false, fetching: false, res: undefined, data: undefined, error: undefined });

    useEffect(() => {
        if (timestamp) {
            let didCancel = false;
            setState({ ...state, fetching: true });

            (async () => {
                try {
                    const res = await fetchApi();
                    if (!didCancel) {
                        setState({ loaded: true, fetching: false, res, data: res.data.data, error: null });
                    }
                } catch (e) {
                    if (!didCancel) {
                        setState({ loaded: true, fetching: false, res: null, data: null, error: e });
                    }
                }
            })();
            return () => {
                didCancel = true;
            };
        }
    }, [timestamp]);
    return {
        ...state,
        doFetch: () => {
            setTimestamp(+new Date());
        },
    };
}
