
import { AUTH, AUTH_CANCELLED, AUTH_SUCCESS } from './actions';

export default function reducer(state = {
    visible: false,
}, action) {
    switch (action.type) {
        case AUTH:
            return {
                ...state,
                visible: true,
                onAuthSuccess: action.payload.onAuthSuccess,
                onAuthCancelled: action.payload.onAuthCancelled,
            };
        case AUTH_CANCELLED:
            return {
                ...state,
                visible: false,
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                visible: false,
                onAuthSuccess: null,
                onAuthCancelled: null,
            };
        default:
            return state;
    }
}