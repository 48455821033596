import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import MediaQuery from 'react-responsive';
import { Button, Modal, Form, Radio, DatePicker } from 'antd';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import { pickReportDateRange } from '../actions';
import './ReportDateRangePicker.css';

const PredefinedRanges = [
    { key: 'YESTERDAY', value: -1 },
    { key: 'ONE_WEEK', value: -7 },
    { key: '30_DAYS', value: -30 },
    { key: 'CUSTOMIZED', value: 0 },
];

export default Form.create()(
    injectIntl(
        connect((state) => ({ dateRange: state.report.dateRange }), { pickReportDateRange })(
            class DateRangedReportPicker extends Component {
                static propTypes = {
                    type: PropTypes.oneOf(['daily', 'monthly']),
                };
                constructor(props) {
                    super(props);
                    this.state = {
                        visible: false,
                        predefinedRange: null,
                        startDate: null,
                        endDate: null,
                        maxQueryDays: 90,
                        earliestQueryDays: 90,
                    };
                }

                disabledStartDate = (startDate) => {
                    let { maxQueryDays, earliestQueryDays, endDate } = this.state;
                    if (!startDate) {
                        return false;
                    }
                    const s = moment(startDate).format('YYYY-MM-DD');
                    return (
                        s >= moment().format('YYYY-MM-DD') ||
                        s <= moment().add({ days: -maxQueryDays }).format('YYYY-MM-DD') ||
                        s < moment(new Date(2018, 10, 1)).format('YYYY-MM-DD') ||
                        (endDate &&
                            (s >= moment(endDate).add({ days: 1 }).format('YYYY-MM-DD') ||
                                s <
                                    moment(endDate)
                                        .add({ days: -(earliestQueryDays - 1) })
                                        .format('YYYY-MM-DD')))
                    );
                };

                disabledEndDate = (endDate) => {
                    const { maxQueryDays, earliestQueryDays, startDate } = this.state;
                    if (!endDate) {
                        return false;
                    }
                    const s = moment(endDate).format('YYYY-MM-DD');
                    console.log(s, moment(startDate).format('YYYY-MM-DD'));
                    return (
                        s >= moment().format('YYYY-MM-DD') ||
                        s <= moment().add({ days: -maxQueryDays }).format('YYYY-MM-DD') ||
                        s < moment(new Date(2018, 10, 1)).format('YYYY-MM-DD') ||
                        (startDate &&
                            (s < moment(startDate).format('YYYY-MM-DD') ||
                                s >= moment(startDate).add({ days: earliestQueryDays }).format('YYYY-MM-DD')))
                    );
                };

                onPredefinedRangesChange = (e) => {
                    const value = e.target.value;
                    this.setState(
                        {
                            predefinedRange: value,
                        },
                        () => {
                            if (value) {
                                const { form } = this.props;
                                const startDate = moment().add({ day: value });
                                const endDate = moment().add({ day: -1 });

                                this.setState(
                                    {
                                        startDate,
                                        endDate,
                                    },
                                    () => {
                                        form.setFieldsValue({
                                            startDate,
                                            endDate,
                                        });
                                    }
                                );
                            }
                        }
                    );
                };

                onStartDateChange = (value) => {
                    this.setState({ startDate: value }, () => {
                        this.isSelectingPredefinedDateRange();
                    });
                };

                onEndDateChange = (value) => {
                    this.setState({ endDate: value }, () => {
                        this.isSelectingPredefinedDateRange();
                    });
                };

                isSelectingPredefinedDateRange = () => {
                    const { startDate, endDate } = this.state;
                    if (!startDate || !endDate) {
                        this.setState({ predefinedRange: 0 });
                    } else {
                        const diff = startDate.diff(endDate, 'days') - 1;
                        if (
                            PredefinedRanges.map((item) => item.value).includes(diff) &&
                            endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf() ===
                                moment()
                                    .add({ days: -1 })
                                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                                    .valueOf()
                        ) {
                            this.setState({ predefinedRange: diff });
                        } else {
                            this.setState({ predefinedRange: 0 });
                        }
                    }
                };

                openModal = () => {
                    const {
                        dateRange: { startDate, endDate },
                    } = this.props;
                    this.setState(
                        {
                            visible: true,
                            startDate,
                            endDate,
                        },
                        () => {
                            this.isSelectingPredefinedDateRange();
                        }
                    );
                };

                closeModal = () => {
                    this.setState({ visible: false }, () => {
                        const {
                            dateRange: { startDate, endDate },
                        } = this.props;
                        this.props.form.setFieldsValue({ startDate, endDate });
                    });
                };

                onOk = (e) => {
                    e.preventDefault();
                    const { form } = this.props;
                    form.validateFields((err, values) => {
                        if (!err) {
                            this.props.pickReportDateRange({ ...values });

                            this.setState({ visible: false });
                        }
                    });
                };

                componentDidMount() {
                    this.isSelectingPredefinedDateRange();
                }

                render() {
                    const {
                        intl,
                        form: { getFieldDecorator },
                        dateRange,
                    } = this.props;
                    const { visible, predefinedRange, earliestQueryDays } = this.state;
                    return (
                        <React.Fragment>
                            <div onClick={this.openModal}>
                                {intl.formatMessage({ id: 'DATE_RANGE' })}:{' '}
                                <span className="data-ranged-report-text">
                                    {dateRange.startDate.format('YYYY.MM.DD')}-{dateRange.endDate.format('YYYY.MM.DD')}
                                    <span className={cx('expand-arrow', { opened: visible })}>
                                        <ChevronDownIcon
                                            size={16}
                                            style={{ marginLeft: 4, verticalAlign: '-0.25rem' }}
                                        />
                                    </span>
                                </span>
                            </div>
                            <Modal
                                visible={visible}
                                closable={false}
                                width={500}
                                wrapClassName="modal"
                                title={null}
                                footer={null}>
                                <MediaQuery minWidth={576}>
                                    {(matches) => (
                                        <Form onSubmit={this.onOk} layout={matches ? 'inline' : 'horizontal'}>
                                            <div className="modal-title large-title">
                                                {intl.formatMessage({ id: 'DATE_RANGE' })}
                                            </div>
                                            <div className="modal-body">
                                                <div className="date-ranged-report-predefined-ragnes">
                                                    <Radio.Group
                                                        size="large"
                                                        onChange={this.onPredefinedRangesChange}
                                                        value={predefinedRange}>
                                                        {PredefinedRanges.map((item) => (
                                                            <Radio key={item.key} value={item.value}>
                                                                {intl.formatMessage({ id: item.key })}
                                                            </Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </div>
                                                <Form.Item>
                                                    {getFieldDecorator('startDate', {
                                                        initialValue: dateRange.startDate,
                                                        rules: [
                                                            {
                                                                required: 'true',
                                                                message: intl.formatMessage({
                                                                    id: 'MSG_START_DATE_IS_REQUIRED',
                                                                }),
                                                            },
                                                        ],
                                                    })(
                                                        <DatePicker
                                                            disabledDate={this.disabledStartDate}
                                                            showToday={false}
                                                            allowClear={false}
                                                            placeholder={intl.formatMessage({ id: 'START_DATE' })}
                                                            onChange={this.onStartDateChange}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item>
                                                    {getFieldDecorator('endDate', {
                                                        initialValue: dateRange.endDate,
                                                        rules: [
                                                            {
                                                                required: 'true',
                                                                message: intl.formatMessage({
                                                                    id: 'MSG_END_DATE_IS_REQUIRED',
                                                                }),
                                                            },
                                                        ],
                                                    })(
                                                        <DatePicker
                                                            disabledDate={this.disabledEndDate}
                                                            showToday={false}
                                                            allowClear={false}
                                                            placeholder={intl.formatMessage({ id: 'END_DATE' })}
                                                            onChange={this.onEndDateChange}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <div className="date-ranged-report-help">
                                                    *{' '}
                                                    {intl.formatMessage(
                                                        { id: 'ONLY_PROVIDE_REPORTS_FOR_UP_TO_N_DAYS' },
                                                        { n: earliestQueryDays }
                                                    )}{' '}
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <Button size="large" onClick={this.closeModal}>
                                                    {intl.formatMessage({ id: 'CANCEL' })}
                                                </Button>
                                                <Button type="primary" size="large" onClick={this.onOk}>
                                                    {intl.formatMessage({ id: 'OK' })}
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </MediaQuery>
                            </Modal>
                        </React.Fragment>
                    );
                }
            }
        )
    )
);
