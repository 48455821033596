import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Button } from 'antd';
import { Consts, TrackerContext } from '../../common';
import AffiliateRejectReasonView from './AffiliateRejectReasonView';

class AffiliateAlert extends PureComponent {
    static contextType = TrackerContext;
    render() {
        const { intl, history, affiliate, email, style } = this.props;
        const { activateStatus, regFinalStatus, deactivatedReason, rejectReason } = affiliate || {};
        const { trackEvent } = this.context;
        return (
            <React.Fragment>
                {activateStatus === Consts.ActivateStatus.Blocked && (
                    <Alert
                        style={style}
                        message="공지 사항"
                        description={
                            <div>
                                쿠팡 파트너스 약관 위배로 인해 회원님의 쿠팡 파트너스 계정이 탈퇴 처리되었습니다. 사유는
                                아래와 같습니다. <br />
                                {deactivatedReason}
                            </div>
                        }
                        type="warning"
                    />
                )}
                {activateStatus === Consts.ActivateStatus.Deactivated && (
                    <Alert
                        style={style}
                        message="공지 사항"
                        description={
                            <div>
                                이 계정은 탈퇴 절차가 진행 중인 계정입니다. 잔여수익금이 존재하는 경우 잔여수익금 지급이
                                완료 된 후 탈퇴 절차가 완료됩니다.
                            </div>
                        }
                        type="warning"
                    />
                )}
                {activateStatus === Consts.ActivateStatus.Activated &&
                    regFinalStatus === Consts.RegistrationStatus.Rejected && (
                        <Alert
                            style={style}
                            message={intl.formatMessage({ id: 'REGISTRATION_AFFILIATE_REJECTED_TITLE' })}
                            description={
                                <div>
                                    <div>
                                        {intl.formatMessage({ id: 'REGISTRATION_AFFILIATE_REJECTED_REFUSAL_FOLLOWS' })}
                                    </div>
                                    <AffiliateRejectReasonView reason={rejectReason} />
                                    <div className="mt05em">
                                        <Button
                                            onClick={() => {
                                                trackEvent({ action: 'reapply_button_clicked' });
                                                history.push('/reg');
                                            }}>
                                            {intl.formatMessage({ id: 'REAPPLY' })}
                                        </Button>
                                    </div>
                                </div>
                            }
                            type="warning"
                        />
                    )}
                {activateStatus === Consts.ActivateStatus.Activated &&
                    regFinalStatus === Consts.RegistrationStatus.Completed && (
                        <Alert
                            style={style}
                            description={
                                <FormattedMessage
                                    id="REGISTRATION_AFFILIATE_REAPPLY_UNDER_REVIEW"
                                    values={{
                                        email: <span className="ft-bold">{email}</span>,
                                    }}
                                />
                            }
                            type="warning"
                        />
                    )}
            </React.Fragment>
        );
    }
}

export default injectIntl(
    withRouter(
        connect((state) => ({
            affiliate: state.affiliate,
            email: state.config.email || state.session.email,
        }))(AffiliateAlert)
    )
);
