import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Button } from 'antd';
import { CardPage } from '../../components';
import { Consts } from '../../common';
import {
    IndividualPayerInformationFieldset,
    ContactFieldset,
    BankAccountFieldset,
    PiiCollectionAgreementFieldset,
} from '../components';
import {
    validateRRN,
    validateBankAccount,
    fetchEditIndividualPaymentInitData,
    updateIndividualPayment,
} from '../actions';
import MemberInfo from '../../registration/components/MemberInfo';
import PiiCollectionLaterTips from '../components/PiiCollectionLaterTips';

class EditIndividualPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: undefined,
        };
        this.bankAccountRef = React.createRef();
    }

    onNameChange = (e) => {
        this.setState({
            name: e,
        });
    };

    componentDidMount() {
        const { fetchEditIndividualPaymentInitData } = this.props;

        if (fetchEditIndividualPaymentInitData) {
            fetchEditIndividualPaymentInitData().then((response) => {
                const { rCode, data } = response.data;
                if (rCode === '0') {
                    this.setState({
                        name: data.individual.name,
                    });
                }
            });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, updateIndividualPayment, affiliateType } = this.props;
        form.validateFields({}, (err, values) => {
            if (!err) {
                values.affiliateType = affiliateType;
                if (values.rrn) {
                    values.rrn = values.rrn.raw;
                }
                values.address = values.address || '';
                values.mobile = values.mobile || '';
                // values.accountOwner = values.name;
                values.contactName = values.name;
                if (updateIndividualPayment) {
                    updateIndividualPayment(values);
                }
            }
        });
    };

    setBankAccountDirty() {
        const { current } = this.bankAccountRef;
        if (current) {
            const bankAccountInst = current.getWrappedInstance();
            bankAccountInst.setDirty();
        }
    }

    handleRRNVerified = () => {
        this.setBankAccountDirty();
    };

    render() {
        const {
            intl,
            form,
            loading,
            maintainingEnabled,
            bankNameAndCodes,
            data,
            validateRRN,
            validateBankAccount,
            memberInfo,
            piiInputStatus,
        } = this.props;

        return (
            <div className="edit-individual-payment">
                <CardPage loading={loading}>
                    {!loading && (
                        <Form layout="vertical" hideRequiredMark={false} onSubmit={this.handleSubmit}>
                            {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                                <React.Fragment>
                                    {(piiInputStatus === Consts.PiiInputStatus.Opened ||
                                        piiInputStatus === Consts.PiiInputStatus.Completed) && (
                                        <MemberInfo
                                            className="mb24"
                                            memberInfo={memberInfo}
                                            withNameSync={true}
                                            piiInputStatus={piiInputStatus}
                                        />
                                    )}
                                    <IndividualPayerInformationFieldset
                                        form={form}
                                        data={data}
                                        validateRRN={validateRRN}
                                        disabled={maintainingEnabled}
                                        disableRRN={piiInputStatus !== Consts.PiiInputStatus.Opened}
                                        rrnVerified={this.handleRRNVerified}
                                    />
                                </React.Fragment>
                            )}

                            <ContactFieldset
                                form={form}
                                data={data}
                                includeContactName={false}
                                disabled={maintainingEnabled}
                            />

                            {piiInputStatus !== Consts.PiiInputStatus.Disabled && (
                                <BankAccountFieldset
                                    type="INDIVIDUAL"
                                    ref={this.bankAccountRef}
                                    form={form}
                                    data={data}
                                    valid={true}
                                    disabled={maintainingEnabled}
                                    banks={bankNameAndCodes}
                                    validateBankAccount={validateBankAccount}
                                    useMemberName={true}
                                />
                            )}
                            {piiInputStatus === Consts.PiiInputStatus.Opened && (
                                <PiiCollectionAgreementFieldset form={form} />
                            )}

                            {piiInputStatus === Consts.PiiInputStatus.Disabled && (
                                <PiiCollectionLaterTips className="mb32" />
                            )}

                            {!maintainingEnabled && (
                                <div className="button-bar align-center">
                                    <Button size="large" type="primary" htmlType="button" onClick={this.handleSubmit}>
                                        {intl.formatMessage({ id: 'SAVE' })}
                                    </Button>
                                </div>
                            )}
                        </Form>
                    )}
                </CardPage>
            </div>
        );
    }
}

const EditIndividualPaymentForm = Form.create()(EditIndividualPayment);

const mapStateToProps = (state) => {
    return {
        bankNameAndCodes: state.payment.bankNameAndCodes,
        data: state.payment.data,
        loading: state.payment.loading,
        affiliateType: state.affiliate.affiliateType,
        memberInfo: state.payment.memberInfo,
        piiInputStatus: state.payment.piiInputStatus || Consts.PiiInputStatus.Disabled,
        activated: state.affiliate.activateStatus === Consts.ActivateStatus.Activated,
        maintainingEnabled: !!state.config.maintainingEnabled,
    };
};

const mapDispatchToProps = {
    validateRRN,
    validateBankAccount,
    fetchEditIndividualPaymentInitData,
    updateIndividualPayment,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditIndividualPaymentForm));
