import React from 'react';

import sweatsmile from './images/sweatsmile.png';
import closeup from './images/closeup.png';
import ops from './images/ops.png'

export default function Emotion(props) {
    const { type, width = 60 } = props;
    let img = null;
    switch (type) {
        case 'sweatsmile':
            img = sweatsmile;
            break;
        case 'closeup':
            img = closeup;
            break;
        case 'ops':
            img = ops;
            break;
        default:
            break;
    }
    return <img src={img} alt={type} width={width} />;
}