import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    BusinessPayerInformationFieldset,
    CompanyInformationFieldset,
    ContactFieldset,
    BankAccountFieldset,
    DocumentFieldset,
} from '../components';
import { validateBRN, validateBankAccount } from '../actions';
import { regUpdatePayment } from '../../registration/actions';
import { TrackerContext } from '../../common';

class BusinessPayment extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.state = {};
    }

    validate = () => {
        return new Promise((resolve, reject) => {
            const { form } = this.props;
            form.validateFields({}, (err, values) => {
                if (!err) {
                    resolve(this.normalizeFormData(values));
                } else {
                    reject(err);
                }
            });
        });
    };

    normalizeFormData = (values) => {
        const { affiliateType } = this.props;
        values.affiliateType = affiliateType;
        values.brn = values.brn.raw;
        values.address = values.address || '';
        const { accountOwner, ...payment } = values;
        return payment;
    };

    getValue = () => {
        return this.normalizeFormData(this.props.form.getFieldsValue());
    };

    getData = (doNotCheck) => {
        return (doNotCheck ? Promise.resolve(this.getValue()) : this.validate()).then((payment) => {
            this.props.regUpdatePayment({ business: payment });
            return { payment };
        });
    };

    componentDidMount() {
        const { payment, form } = this.props;
        const { hasBrcDoc, hasBankbookDoc } = payment || {};
        let brnFileList = [];
        let bankbookFileList = [];

        this.context.trackPageView({ action: 'enter_registration_payment_business' });

        if (hasBrcDoc) {
            brnFileList = [{ uid: '-1', status: 'done', url: `/api/v1/payment/image/brn` }];
        }
        if (hasBankbookDoc) {
            bankbookFileList = [{ uid: '-2', status: 'done', url: `/api/v1/payment/image/bank` }];
        }

        if (brnFileList.length || bankbookFileList.length) {
            form.setFieldsValue({ brnFileList, bankbookFileList });
        }
    }

    render() {
        const { form, action, bankNameAndCodes, validateBRN, validateBankAccount, payment } = this.props;

        return (
            <div className="business-payment">
                <Form layout="vertical" hideRequiredMark={false} onSubmit={(e) => e.preventDefault()}>
                    <BusinessPayerInformationFieldset form={form} data={payment} validateBRN={validateBRN} />

                    <CompanyInformationFieldset form={form} data={payment} />

                    <ContactFieldset form={form} data={payment} includeContactName={true} />

                    <BankAccountFieldset
                        type="BUSINESS"
                        data={payment}
                        form={form}
                        banks={bankNameAndCodes}
                        validateBankAccount={validateBankAccount}
                    />
                    <div className="mb24">
                        <DocumentFieldset form={form} />
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        registration: {
            payment: { data = {}, bankNameAndCodes },
            defaultValues,
        },
    } = state;
    const { affiliateType, ...payment } = data;

    return {
        affiliateType,
        payment: payment.business || { ...defaultValues },
        bankNameAndCodes,
    };
};

const mapDispatchToProps = {
    validateBRN,
    validateBankAccount,
    regUpdatePayment,
};

const IntlPayment = injectIntl(BusinessPayment, { forwardRef: true });
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Form.create()(IntlPayment));
