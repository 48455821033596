import axios from 'axios';

export default class Api {

    static fetchTaxInvoices({ pageNumber, size }) {
        return axios.post('/api/v1/report/tax', { pageNumber, size });
    }

    static getInvoiceUrl(settleMonth) {
        return axios.get(`/api/v1/report/invoice/${settleMonth}`);
    }

    static getDocStatuses() {
        return axios.get(`/api/v1/payment/docstatus`);
    }

    static uploadFiles({ brn, bank }) {
        let fd = new FormData();
        fd.append('BRN_IMAGE', brn);
        fd.append('BANK_IMAGE', bank);
        return axios.post(`/api/v1/payment/upload`, fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }


}