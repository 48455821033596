import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, Modal } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';

import './ConsentItem.css';

export default class ConsentItem extends Component {
    static propTypes = {
        value: PropTypes.bool,
        title: PropTypes.string,
        detailTitle: PropTypes.string,
        description: PropTypes.string,
        accepted: PropTypes.bool,
        richFormat: PropTypes.bool,
        rs: PropTypes.object,
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        const accepted = props.accepted || props.value || false;
        this.state = {
            accepted,
            detailViewed: accepted,
            detailVisible: false
        };
    }

    viewDetail = () => {
        this.setState({
            detailVisible: true
        });
    }

    onOk = () => {
        this.setState({
            detailViewed: true,
            detailVisible: false
        });
    }

    onCancel = () => {
        this.setState({
            detailViewed: true,
            detailVisible: false
        });
    }

    triggerAccepted(accepted) {
        const { onChange } = this.props;
        if (onChange) {
            onChange(accepted);
        }
    }

    onChange = (e) => {
        const checked = e.target.checked;
        this.setState({
            accepted: checked
        });
        this.triggerAccepted(checked);
    }

    onToggleAccept = () => {
        if (this.state.detailViewed) {
            this.setState((prevState, props) => {
                const nextState = {
                    ...prevState,
                    accepted: !prevState.accepted
                };
                this.triggerAccepted(!prevState.accepted);
                return nextState;
            });
        }
    }

    render() {
        const { title, detailTitle, description, richFormat, style, rs } = this.props;
        const dialogTitle = detailTitle || (rs && rs.DetailTitle ? rs.DetailTitle : 'Detail');
        return (
            <div className="consent-item" style={style} >
                <div className="inner-wrapper">
                    <Checkbox checked={this.state.accepted} onChange={this.onChange} disabled={!this.state.detailViewed} />
                    <span className="title" onClick={this.onToggleAccept}>{title}</span>
                    <Button className="btn-detail sm" onClick={this.viewDetail}>{rs && rs.ViewDetail ? rs.ViewDetail : 'Detail'}</Button>
                    <Modal
                        className="consent-detail-modal"
                        title={dialogTitle}
                        visible={this.state.detailVisible}
                        maskClosable={false}
                        onOk={this.onOk}
                        onCancel={this.onCancel}
                        footer={[
                            <Button key="submit" size="large" onClick={this.onOk}>
                                {rs && rs.OK ? rs.OK : 'CONFIRM'}
                            </Button>
                        ]}
                    >
                        <Scrollbars autoHide autoHeight autoHeightMin={300} autoHeightMax={400} renderThumbVertical={props => <div {...props} className="detail-scroll-thumb" />} >
                            {
                                richFormat ?
                                    <div className="detail-description" dangerouslySetInnerHTML={{ __html: description }}></div> :
                                    <p className="detail-description" >
                                        {description}
                                    </p>
                            }
                        </Scrollbars>
                    </Modal>
                </div>
            </div>
        );
    }
}