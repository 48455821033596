import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import HomeIcon from 'react-feather/dist/icons/home';
import { WorkspacePage, CpRow } from '../../components';
import { useFetch } from '../../common';
import FeatCheckerContext from '../../feat-checker/FeatCheckerContext';

import Api from '../api';

import './VideoBannerList.css';

export default injectIntl(function ({ intl }) {
    const { checkFeat } = useContext(FeatCheckerContext);
    const { fetching, res } = useFetch(Api.getVideoBanners);
    const list = res ? res.data.data : [];
    const breadcrumb = [
        {
            title: intl.formatMessage({ id: 'AFFILIATE_HOME' }),
            link: '/affiliate',
            icon: <HomeIcon size={14} />,
        },
        { title: intl.formatMessage({ id: 'VIDEO_BANNER' }) },
    ];

    useEffect(() => {
        checkFeat('video-banners');
        // eslint-disable-next-line
    }, []);
    return (
        <WorkspacePage
            className="video-banner"
            breadcrumb={breadcrumb}
            title={intl.formatMessage({ id: 'VIDEO_BANNER' })}
            loading={fetching}>
            <CpRow>
                <Row className="video-banner-list" type="flex" justify="start" gutter={24}>
                    {list.map((x) => (
                        <Col xs={12} sm={12} md={8} lg={6} key={x.datasetId}>
                            <Link to={`/affiliate/ws/video-banners/${x.datasetId}`} className="video-banner-item">
                                <img src={x.imageUrl} alt="" className="poster" />
                                <div className="title">{x.datasetName}</div>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </CpRow>
        </WorkspacePage>
    );
});
