import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';

import './DataTableInputFilter.css';

export default class DataTableInputFilter extends PureComponent {

    static propTypes = {
        inputProps: PropTypes.object,
        setSelectedKeys: PropTypes.func,
        selectedKeys: PropTypes.array,
        confirm: PropTypes.func,
        clearFilters: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            text: ''
        };
        this.inputRef = React.createRef();
    }

    handleTextChange = (e) => {
        const { setSelectedKeys } = this.props;
        const term = e.target.value.trim();
        this.setState({ text: term }, () => {
            setSelectedKeys([term]);
        });
    }

    handleReset = () => {
        const { clearFilters } = this.props;
        this.setState({ text: '' });
        clearFilters();
    }

    handleSearch = () => {
        const { confirm } = this.props;
        confirm();
    }

    focusInput() {
        const { current } = this.inputRef;
        if (current) {
            current.input.focus();
        }
    }

    selectInput() {
        const { current } = this.inputRef;
        if (current) {
            current.input.select();
        }
    }

    static contextTypes = {
        antLocale: PropTypes.object
    };

    render() {
        const { inputProps } = this.props;
        const { text } = this.state;
        const { DataTable: { FilterApply, FilterReset } = { FilterApply: 'Apply', FilterReset: 'Reset'} } = (this.context ? this.context.antLocale : {}) || {};
        return (
            <div className="dt-input-filter-drop">
                <div className="input-line">
                    <Input autoComplete="off" {...inputProps} ref={this.inputRef} value={text} onChange={this.handleTextChange} onPressEnter={this.handleSearch} />
                </div>
                <div className="buttons">
                    <Button size="small" icon="search" type="primary" onClick={this.handleSearch}>{FilterApply}</Button>
                    <Button size="small" onClick={this.handleReset}>{FilterReset}</Button>
                </div>
            </div>
        );
    }
}