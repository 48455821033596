export const GOTO_STEP = 'REGISTRATION/GOTO_STEP';
export const FETCH_REGISTRATION_INITDATA = 'REGISTRATION/FETCH_INIT_DATA';
export const FETCH_REGISTRATION_FLOW_INITDATA = 'REGISTRATION_FLOW/FETCH_INITDATA';
export const SUMMARY_FINISHED = 'REGISTRATION/SUMMARY_FINISHED';
export const UPDATE_PROFILE_FIELD = 'REGISTRATION/UPDATE_PROFILE_FIELD';
export const VALIDATE_AFFILIATE_CODE = 'REGISTRATION/VALIDATE_AFFILIATE_CODE';
export const PERSIST_PROFILE = 'REGISTRATION/PERSIST_PROFILE';

export const FETCH_EDIT_PROFILE_INITDATA = 'EDIT_PROFILE/FETCH_INIT_DATA';
export const UPDATE_PROFILE = 'EDIT_PROFILE/UPDATE_PROFILE';

export const REG_UPDATE_STEP = 'REGISTRATION/REG_UPDATE_STEP';
export const REG_UPDATE_ACCEPTED_TERMS = 'REGISTRATION/REG_UPDATE_ACCEPTED_TERMS';
export const REG_UPDATE_PROFILE = 'REGISTRATION/REG_UPDATE_PROFILE';
export const REG_UPDATE_PAYMENT = 'REGISTRATION/REG_UPDATE_PAYMENT';
export const REG_SELECT_AFFILIATE_TYPE = 'REGISTRATION/REG_SELECT_AFFILIATE_TYPE';
export const REG_UPDATE_INDIVIDUAL_PAYMENT = 'REGISTRATION/REG_UPDATE_INDIVIDUAL_PAYMENT';
export const REG_UPDATE_BUSINESS_PAYMENT = 'REGISTRATION/REG_UPDATE_BUSINESS_PAYMENT';

export const REG_FINISH_REGISTRATION = 'REGISTRATION/FINISH_REGISTRATION';

// export const REG_SAVE_TERMS = 'REGISTRATION/REG_SAVE_TERMS';
// export const REG_SAVE_PROFILE = 'REGISTRATION/REG_SAVE_PROFILE';
// export const REG_SAVE_AFFILIATE_TYPE = 'REGISTRATION/REG_SAVE_AFFILIATE_TYPE';
// export const REG_SAVE_INDIVIDUAL_PAYMENT = 'REGISTRATION/REG_SAVE_INDIVIDUAL_PAYMENT';
// export const REG_SAVE_BUSINESS_PAYMENT = 'REGISTRATION/REG_SAVE_BUSINESS_PAYMENT';
