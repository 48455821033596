import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { WorkspacePage, CpRow } from '../../components';
import { StatusHelper } from '../../common';
import { PayoutReportIndividual, PayoutReportBusiness, PayoutReportMixed } from '../components';

import HomeIcon from 'react-feather/dist/icons/home';

class PayoutReport extends PureComponent {

    render() {
        const { intl, affiliate, mixedTypeAffiliate } = this.props;

        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_EARNING_REPORT' }) },
            { title: intl.formatMessage({ id: 'HEADER_REPORT_PAYOUT' }) }
        ];

        let AffiliatePayout = null;
        if (mixedTypeAffiliate) {
            AffiliatePayout = <PayoutReportMixed />;
        } else {
            AffiliatePayout = StatusHelper.isBusinessAffiliate(affiliate) ? <PayoutReportBusiness /> : <PayoutReportIndividual />;
        }

        return (
            <WorkspacePage breadcrumb={breadcrumb} title={intl.formatMessage({ id: 'HEADER_REPORT_PAYOUT' })} subTitle={intl.formatMessage({ id: 'TAX_INVOICE_SUB_TITLE' })}>
                <CpRow>
                    {AffiliatePayout}
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    connect(state => ({
        affiliate: state.affiliate,
        mixedTypeAffiliate: state.config.mixedTypeAffiliate
    }))(
        PayoutReport
    )
)