import React, { useState, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import { Checkbox } from 'antd';

import { useFetch } from '../../common';
import ConsentModal from './ConsentModal';
import './ConsentLink.less';

function getConsentContent(key) {
    return axios.get(`/api/v1/configuration/content/${key}`);
}

export function ConsentLink({ rs, consent }) {
    const intl = useIntl();
    const [visible, setVisible] = useState(false);
    const { doFetch, res } = useFetch(() => getConsentContent(consent.key), { autoLoad: false });
    const openDetail = () => {
        doFetch();
        setVisible(true);
    };
    const closeDetail = () => {
        setVisible(false);
    };
    let content;
    if (res && res.data && Array.isArray(res.data.data)) {
        content = res.data.data[0].content;
    }

    return [
        <div key={consent.key} className="consent-link-item">
            <span>{consent.title}</span>
            {consent.action ? (
                <span
                    className="view-document"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}>
                    {consent.action}
                </span>
            ) : (
                <span
                    className="view-document"
                    onClick={(e) => {
                        e.preventDefault();
                        openDetail();
                    }}>
                    {intl.formatMessage({ id: 'VIEW_DOCUMENT' })}
                </span>
            )}
        </div>,
        <ConsentModal
            key={`${consent.key}_modal`}
            rs={rs}
            visible={visible}
            richFormat={true}
            title={consent.title}
            content={content}
            onOk={closeDetail}
            onCancel={closeDetail}
        />,
    ];
}

export default forwardRef(function ConsentLinks({ items, rs, disabled, value = [], onChange }, ref) {
    const [consentedItems, setConsentedItems] = useState(value || []);
    const onOneOfConsentedChange = (key, consented) => {
        const i = consentedItems.findIndex((item) => item === key);
        if (consented) {
            if (i === -1) {
                consentedItems.push(key);
            }
        } else {
            if (i > -1) {
                consentedItems.splice(i, 1);
            }
        }
        setConsentedItems([...consentedItems]);

        onChange && onChange(consentedItems);
    };
    return (
        <span className="consent-links">
            {(items || []).map((item, index) => (
                <React.Fragment key={item.key}>
                    <div className="consent-link">
                        <Checkbox
                            disabled={disabled}
                            checked={value.findIndex((z) => z === item.key) > -1}
                            onChange={(e) => onOneOfConsentedChange(item.key, e.target.checked)}>
                            <ConsentLink rs={rs} consent={item} />
                        </Checkbox>
                    </div>
                </React.Fragment>
            ))}
        </span>
    );
});
