import React from 'react';
import cx from 'classnames';
import CloseIcon from 'react-feather/dist/icons/x';

import './ChipList.less';

function Chip({ children, disabled, onRemove }) {
    const handleOnRemove = () => {
        if (!disabled && onRemove) {
            onRemove();
        }
    }
    return (
        <div className="chip">
            <div>{children}</div>
            <CloseIcon className={cx("chip-close", { 'disabled': disabled })} size={14} onClick={handleOnRemove} />
        </div>
    );
}

export default function ({ list = [], disabled, onRemove }) {
    const handleOnRemove = (item, index) => {
        if (onRemove) {
            onRemove(item, index);
        }
    };
    return (
        <div className="chip-list">
            {
                list.map((item, index) => <Chip key={item} disabled={disabled} onRemove={() => handleOnRemove(item, index)}>{item}</Chip>)
            }
        </div>
    );
}