import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';

class CoupangRanking extends Component {
    static propTypes = {
        value: PropTypes.any,
        onChange: PropTypes.func
    }

    COUPANG_RANKING = 'COUPANG_RANKING';

    RANKINGS = [
        { id: 1, name: 'COUPANG_RANKING_LOW_PRICE' },
        { id: 2, name: 'COUPANG_RANKING_HIGH_PRICE' },
        { id: 3, name: 'COUPANG_RANKING_SALES_ORDER' },
        { id: 4, name: 'COUPANG_RANKING_THE_LATEST' }
    ];

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || null
        };
    }

    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    componentWillMount() {
        const { intl } = this.props;
        this.COUPANG_RANKING = intl.formatMessage({ id: this.COUPANG_RANKING });
        this.RANKINGS.forEach(x => x.name = intl.formatMessage({ id: x.name }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.value !== nextProps.value;
    }

    onSelect = (value, option) => {
        this.setState({
            value
        });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { onChange, value, ...others } = this.props;
        return (
            <Select placeholder={this.COUPANG_RANKING} value={this.state.value} onSelect={this.onSelect} {...others}>
                {
                    this.RANKINGS.map(x => (<Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>))
                }
            </Select>);
    }
}

export default injectIntl(CoupangRanking)
