import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './SummaryCard.css';
import { MetricBox } from '../../components';

export default class SummaryCard extends Component {
    static propTypes = {
        metrics: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
        dataSource: PropTypes.object.isRequired,
    };

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.dataSource !== this.props.dataSource;
    }

    render() {
        const { metrics, dataSource, style, className } = this.props;

        return (
            <div className={cx('summary-panel', className)} style={style}>
                <div className="metric-list">
                    {metrics.map((metric) => {
                        let key;
                        let customLabel;
                        if (typeof metric === 'string') {
                            key = metric;
                        } else if (typeof metric === 'object') {
                            key = metric.key;
                            customLabel = metric.customLabel;
                        }
                        return <MetricBox key={key} customLabel={customLabel} metric={key} value={dataSource[key]} />;
                    })}
                </div>
            </div>
        );
    }
}
