import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, Button, Form, Input, notification } from 'antd';
import MailIcon from 'react-feather/dist/icons/mail';
import LockIcon from 'react-feather/dist/icons/lock';

import { authCancelled, authSuccess } from './actions';
import Api from './api';

import './Auth.css';

// auth popup window

export default Form.create()(
    injectIntl(
        connect(
            (state) => {
                const { visible, onAuthSuccess, onAuthCancelled } = state.auth;
                const { email } = state.session;
                return { visible, onAuthSuccess, onAuthCancelled, email };
            },
            {
                authCancelled,
                authSuccess,
            }
        )(
            class Auth extends PureComponent {
                state = {
                    loading: false,
                };

                onOk = (e) => {
                    e.preventDefault();
                    const { intl, form, authSuccess, onAuthSuccess } = this.props;
                    form.validateFields((err, values) => {
                        if (!err) {
                            this.setState({
                                loading: true,
                            });
                            Api.auth({ password: values.password })
                                .then((res) => {
                                    const {
                                        rCode,
                                        data: { isPass },
                                    } = res.data;
                                    if (rCode === '0') {
                                        if (isPass) {
                                            authSuccess();
                                            if (onAuthSuccess) {
                                                onAuthSuccess();
                                            }
                                        } else {
                                            notification.error({
                                                message: intl.formatMessage({ id: 'AUTH_FAILED' }),
                                                description: intl.formatMessage({ id: 'PASSWORD_INCORRECT' }),
                                            });
                                        }
                                    }
                                    this.setState({
                                        loading: false,
                                    });
                                })
                                .catch(() => {
                                    this.setState({
                                        loading: false,
                                    });
                                })
                                .finally(() => {
                                    form.setFieldsValue({ password: '' });
                                });
                        }
                    });
                };

                onCancel = () => {
                    const { onAuthCancelled, authCancelled } = this.props;
                    if (onAuthCancelled) {
                        onAuthCancelled();
                    }
                    authCancelled();
                };

                render() {
                    const {
                        intl,
                        form: { getFieldDecorator },
                        visible,
                        email,
                    } = this.props;
                    const { loading } = this.state;
                    const formItemLayout = {
                        labelCol: { span: 6 },
                        wrapperCol: { span: 17 },
                    };
                    return (
                        <Modal
                            visible={visible}
                            closable={false}
                            wrapClassName="modal auth-modal"
                            width={440}
                            title={null}
                            footer={null}>
                            <div className="modal-title large-title">회원정보 확인</div>
                            <Form onSubmit={this.onOk} layout="horizontal">
                                <div className="modal-body">
                                    <Form.Item
                                        label={
                                            <span>
                                                <MailIcon size={14} style={{ marginRight: 4, marginBottom: -2 }} />
                                                <span>E-mail</span>
                                            </span>
                                        }
                                        colon={false}
                                        {...formItemLayout}>
                                        <span className="ant-form-text">{email}</span>
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <span>
                                                <LockIcon size={14} style={{ marginRight: 4 }} />
                                                <span>비밀번호</span>
                                            </span>
                                        }
                                        colon={false}
                                        {...formItemLayout}>
                                        {getFieldDecorator('password', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'VAL_MSG_THIS_FIELD_IS_REQUIRED',
                                                    }),
                                                },
                                            ],
                                        })(<Input type="password" autocomplete="new-password" />)}
                                    </Form.Item>
                                </div>
                                <div className="modal-footer">
                                    <Button size="large" onClick={this.onCancel}>
                                        {intl.formatMessage({ id: 'CANCEL' })}
                                    </Button>
                                    <Button type="primary" size="large" htmlType="submit" loading={loading}>
                                        {intl.formatMessage({ id: 'CONFIRM' })}
                                    </Button>
                                </div>
                            </Form>
                        </Modal>
                    );
                }
            }
        )
    )
);
