import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import ProductList from './ProductList';

class BestProductList extends PureComponent {

    viewMore = () => {
        const { onAction } = this.props;
        if (onAction) {
            onAction('viewMoreBestProducts');
        }
    }

    render() {
        const { items, onAction } = this.props;
        const { intl } = this.props;
        return (
            <div className="best-product-list">

                <ProductList items={items.slice(0, 6)} onAction={onAction} />
                <div style={{ marginTop: 10 }}><Button ghost block onClick={this.viewMore}>{intl.formatMessage({ id: 'VIEW_MORE' })}</Button></div>
            </div >
        );
    }
}

export default injectIntl(BestProductList)
