import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl, useIntl } from 'react-intl';

import MediaQuery from 'react-responsive';

import { Dropdown, Menu, Tooltip } from 'antd';
import UserIcon from 'react-feather/dist/icons/user';
import { Consts } from '../../common';

import influencerBadge from './influencer.png';
import './UserProfileToolbar.less';

const { RegistrationStatus } = Consts;

const UserId = ({ id, affiliate, activated, simulated }) => {
    const intl = useIntl();
    let userStatus = 'default';
    
    if(RegistrationStatus.Approved === affiliate.regFinalStatus){
        userStatus = 'approved';
    } else if(RegistrationStatus.Rejected === affiliate.regFinalStatus){
        userStatus = 'rejected';
    } else if(RegistrationStatus.Completed === affiliate.regFinalStatus || affiliate.gmvThresholdPassed){
        userStatus = 'pending';
    }
    return id ? (
        <span className={cx('user-id', { 'user-simulated': simulated })}>
            <Tooltip placement="bottom" title={intl.formatMessage({id:`user_status_tip_text_${userStatus}`.toUpperCase()})}>
                <span className={cx('user-status-indicator', `user-status-${userStatus}`)} />
            </Tooltip>
            <span>
                ID: <span className={cx({ 'color-danger': !activated, 'line-through': !activated })}>{id}</span>
            </span>
            
        </span>
    ) : null;
};

class UserProfileToolbar extends Component {
    static propTypes = {
        title: PropTypes.string,
        id: PropTypes.string,
        menuItems: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                title: PropTypes.string,
                divider: PropTypes.bool,
            })
        ).isRequired,
        affiliate: PropTypes.object,
        activated: PropTypes.bool,
        simulated: PropTypes.bool,
        onMenuClick: PropTypes.func,
    };

    static defaultProps = {
        title: 'Title here',
        menuItems: [],
    };

    state = {
        dropdownVisible: false,
    };

    onMenuClick = (e) => {
        console.log(e);
        if (e.key !== '-2') {
            this.setState({
                dropdownVisible: false,
            });
            if (e.key === '-1') {
                // this.titleClicked();
            } else {
                const { onMenuClick } = this.props;
                if (onMenuClick) {
                    onMenuClick(e);
                }
            }
        }
    };

    get isAffiliateMember() {
        const { affiliate } = this.props;
        return (
            affiliate &&
            [RegistrationStatus.ProfileApproved, RegistrationStatus.Approved, RegistrationStatus.AutoApproved].includes(
                affiliate.regFinalStatus
            )
        );
    }

    titleClicked = () => {
        // hasMyAccount={registrationStatus === 'PAYMENT_COMPLETED' || registrationStatus === 'APPROVED'}
        const { onMenuClick } = this.props;
        if (this.isAffiliateMember && onMenuClick) {
            onMenuClick({ key: 'my-account' });
        }
    };

    handleVisibleChange = (flag) => {
        this.setState({ dropdownVisible: flag });
    };

    render() {
        const { intl, title, id, affiliate, activated, simulated, webAccessibleStatus, menuItems } = this.props;

        const { dropdownVisible } = this.state;

        return (
            <div className="user-toolbar">
                <div className="user-info">
                    <MediaQuery minWidth={1400}>
                        {(matches) => {
                            return (
                                <React.Fragment>
                                    {matches && (
                                        <React.Fragment>
                                            {affiliate && affiliate.affiliateGroup === Consts.AffiliateGroup.Influencer && <img className="affiliate-group-badge" src={influencerBadge} alt="" />}
                                            {affiliate && <UserId id={id} affiliate={affiliate} activated={activated} simulated={simulated} /> }
                                        </React.Fragment>
                                        )
                                    }
                                    <Dropdown
                                        overlay={
                                            <Menu onClick={this.onMenuClick}>
                                                
                                                {!matches && id && (
                                                    <Menu.Item className="user-info-in-dropdown" key={-2}>
                                                        {affiliate && affiliate.affiliateGroup === Consts.AffiliateGroup.Influencer && <div><img className="affiliate-group-badge" src={influencerBadge} alt="" /></div>}
                                                        {affiliate && <UserId id={id} affiliate={affiliate} activated={activated} simulated={simulated} />}
                                                        {/* <div className="user-email">{title}</div> */}
                                                    </Menu.Item>
                                                )}
                                                {!matches && id && <Menu.Divider />}

                                                {menuItems.map((x, idx) => (
                                                    <Menu.Item key={x.key}>{x.title}</Menu.Item>
                                                ))}
                                                {menuItems.length > 0 && <Menu.Divider />}
                                                {!webAccessibleStatus.blocked &&
                                                    affiliate &&
                                                    affiliate.activateStatus === Consts.ActivateStatus.Activated && (
                                                        <Menu.Item key="unsubscribe">
                                                            {intl.formatMessage({ id: 'UNSUBSCRIBE_TITLE' })}
                                                        </Menu.Item>
                                                    )}
                                                {!webAccessibleStatus.blocked &&
                                                    affiliate &&
                                                    affiliate.activateStatus === Consts.ActivateStatus.Activated && (
                                                        <Menu.Divider />
                                                    )}

                                                <Menu.Item key="logout">
                                                    {intl.formatMessage({ id: 'LOGOUT' })}
                                                </Menu.Item>
                                            </Menu>
                                        }
                                        trigger={[matches ? 'hover' : 'click']}
                                        visible={dropdownVisible}
                                        onVisibleChange={this.handleVisibleChange}>
                                        <span className="ant-dropdown-link">
                                            {matches ? (
                                                <span onClick={this.titleClicked}>{title}</span>
                                            ) : (
                                                <UserIcon className="user-toolbar-icon" />
                                            )}
                                        </span>
                                    </Dropdown>
                                    {/* {matches && <UserIcon style={{ marginLeft: 4 }} className="user-toolbar-icon" onClick={this.titleClicked} />} */}
                                </React.Fragment>
                            );
                        }}
                    </MediaQuery>
                </div>
            </div>
        );
    }
}

export default injectIntl(UserProfileToolbar);
