import { RegistrationStatus, AffiliateTypes } from './consts';


export function isAffiliateApproved(affiliate) {
    const { regFinalStatus } = affiliate || {};
    return affiliate &&
        (
            [RegistrationStatus.Approved, RegistrationStatus.ProfileApproved, RegistrationStatus.AutoApproved].includes(regFinalStatus)
        );
}

export function isAbroadAffiliate(affiliate) {
    return affiliate && affiliate.affiliateCode === 'AF3691347';
}

export function isBusinessAffiliate(affiliate) {
    return [AffiliateTypes.Company, AffiliateTypes.AdvertisingAgency].includes(affiliate.affiliateType);
}
