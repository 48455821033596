import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import ProductRow from './ProductRow';
import { ProductShape } from './Product';

import './ProductList.css';

export default function ProductList({ items = [], rowItemsCount, actions, onAction, showRankNo }) {
    const isLargeScreen = useMediaQuery({ minWidth: 1440 });
    const isMdScreen = useMediaQuery({ minWidth: 1024 });
    const isSmallScreen = useMediaQuery({ minWidth: 768 });
    // const isExtraSmallScreen = useMediaQuery({ minWidth: 576 });

    function buildRows(items, rowSize = 6) {
        let rows = [];
        for (let i = 0, len = items.length; i < len; i += rowSize) {
            rows.push(
                <ProductRow
                    key={i * rowSize} actions={actions}
                    rowSize={rowSize}
                    items={items.slice(i, i + rowSize)}
                    onAction={onAction}
                    showRankNo={showRankNo} />
            );
        }
        return rows;
    }

    let rowSize = 6;

    if (rowItemsCount) {
        rowSize = rowItemsCount;
    } else {
        if (isLargeScreen) {
            rowSize = 6;
        } else if (isMdScreen) {
            rowSize = 4;
        } else if (isSmallScreen) {
            rowSize = 3;
        } else {
            rowSize = 2;
        }
    }

    return (
        <div className="product-list">
            {
                buildRows(items, rowSize)
            }
        </div>
    );
}

ProductList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(ProductShape)),
    actions: PropTypes.array,
    onAction: PropTypes.func
};