import React, { Suspense, lazy, useState, useContext, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { HashRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { LocaleProvider, LocaleContext } from './components';
import Header from './header/views/Header';
import coupang_affiliate_logo from './common/coupangPartners.png';
import { Landing } from './landing';
import { GotoCoupangLogin, Registration } from './registration';
import { Affiliate } from './affiliate';
import { AffiliateRoute, TrackerProvider, ScrollToTop, UnderMaintainance } from './common';
import FeatCheckerContextProvider from './feat-checker/FeatCheckerContextProvider';
import { LoadingSpinner } from './components';

import { Auth } from './auth';

import './animate/animate.css';
import './antd/antd.customized.less';
import './ckeditor5/content-styles.css';
import './App.less';
import getStore from './store';

const Help = lazy(() => import('./help/Help'));
const Announcements = lazy(() => import('./announcements'));

const LandingRoute = ({ component: InputComponent, loggedIn, affiliate, ...rest }) => {
    const location = useLocation();
    const { config } = useSelector((state) => state);
    let target = '';
    let search = '';
    if (loggedIn) {
        const { regFinalStatus } = affiliate || {};

        if (!regFinalStatus && !window.sessionStorage.entrance) {
            // Not registered yet, and no entrance (not from login or signup button)
        }
        // else if (!StatusHelper.isAffiliateApproved(affiliate)) {
        //     target = '/reg';
        // }
        else if (affiliate) {
            target = '/affiliate';
        }
    }
    if (location.state && location.state.from) {
        const { pathname, search } = location.state.from;
        window.sessionStorage.setItem('returnUrl', JSON.stringify({ pathname, search }));
        window.location = config.loginUrl;
        return null;
    }
    if (window.sessionStorage.getItem('returnUrl')) {
        const o = JSON.parse(window.sessionStorage.getItem('returnUrl'));
        target = o.pathname;
        search = o.search;
        window.sessionStorage.removeItem('returnUrl');
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                !target ? (
                    <InputComponent {...props} />
                ) : (
                    <Redirect to={{ pathname: target, search: search, state: { from: props.location } }} />
                )
            }
        />
    );
};

function renderMaintainance(config) {
    if (config.maintainingEnabled) {
        return (
            <div style={{ margin: '40px auto 0px' }}>
                <UnderMaintainance style={{ maxWidth: 1440, width: '100%' }} />
            </div>
        );
    } else {
        return <Redirect to="/" />;
    }
}

function AppComponent() {
    const { config, affiliate, session } = useSelector((state) => state);
    const sessionState = {
        loggedIn: session.authenticated,
        regFinalStatus: affiliate && affiliate.regFinalStatus,
    };
    const { locale, localeResource, antLocaleResource } = useContext(LocaleContext);
    // test new feature
    // const newFeatMap = { "/help/faq@tab6@question25": "20190712" };
    //const newFeatMap = { events: '20200813' };
    const newFeatMap = {};
    return (
        <IntlProvider locale={locale} key={'intlProvider' + locale} messages={localeResource}>
            <div className={'lang-' + locale}>
                <ConfigProvider locale={antLocaleResource}>
                    <HashRouter hashType="noslash">
                        <ScrollToTop>
                            <TrackerProvider>
                                <FeatCheckerContextProvider newFeatMap={newFeatMap}>
                                    <div>
                                        <div className="header-container">
                                            <Header
                                                logo={{ image: coupang_affiliate_logo, text: 'Coupang Affiliate Logo' }}
                                            />
                                        </div>
                                        <div className="workspace-container">
                                            <div className="workspace">
                                                <div className="page-outlet">
                                                    <Suspense fallback={<LoadingSpinner />}>
                                                        <Switch>
                                                            <LandingRoute
                                                                exact
                                                                path="/"
                                                                component={Landing}
                                                                {...{ affiliate, loggedIn: sessionState.loggedIn }}
                                                            />
                                                            <Route path="/gotologin" component={GotoCoupangLogin} />
                                                            <Route
                                                                path="/undermaintain"
                                                                render={() => renderMaintainance(config)}
                                                            />
                                                            <Route path="/help" component={Help} />
                                                            <Route path="/announcements" component={Announcements} />
                                                            <AffiliateRoute
                                                                path="/reg"
                                                                component={Registration}
                                                                {...sessionState}
                                                                requireLogin
                                                            />
                                                            <AffiliateRoute
                                                                path="/affiliate"
                                                                component={Affiliate}
                                                                {...sessionState}
                                                                requireLogin
                                                            />
                                                            <Redirect from="*" to="/"></Redirect>
                                                        </Switch>
                                                    </Suspense>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Auth />
                                </FeatCheckerContextProvider>
                            </TrackerProvider>
                        </ScrollToTop>
                    </HashRouter>
                </ConfigProvider>
            </div>
        </IntlProvider>
    );
}

function App() {
    const [store, setStore] = useState();
    useEffect(() => {
        getStore().then((data) => {
            setStore(data);
        });
    }, []);
    return store ? (
        <Provider store={store}>
            <AppComponent />
        </Provider>
    ) : null;
}

export default function () {
    return (
        <LocaleProvider>
            <App />
        </LocaleProvider>
    );
}
