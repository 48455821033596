export default function DynamicWidgetCode({ widgetInstanceId, trackingCode, subId, width, height, children }) {
    const code = `
<script src="https://ads-partners.coupang.com/g.js"></script>
<script>
    new PartnersCoupang.G(${JSON.stringify({
        id: widgetInstanceId,
        trackingCode,
        subId,
        template: 'carousel',
        width,
        height,
    })});
</script>
`;
    return children(code);
}
