import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Spin } from 'antd';
import './CardPage.css';

export default class CardPage extends Component {
    static propTypes = {
        header: PropTypes.object,
        body: PropTypes.object,
        footer: PropTypes.object,
        size: PropTypes.string,
        loading: PropTypes.bool
    }

    render() {
        const { className, header, body, footer, size, children, loading = false, ...others } = this.props;

        return (
            <Spin spinning={loading} size="large" delay={250} className="page-loading">
                <div className={cx('cp-page', size, className)} {...others}>
                    <div className="cp-page-header">
                        {header}
                    </div>
                    <div className="cp-page-body">
                        {body ? body : children}
                    </div>
                    {footer ? (<div className="cp-page-footer">{footer}</div>) : null}
                </div>
            </Spin>
        );
    }
}