import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import { Table, Button } from 'antd';

import { WorkspacePage, CpRow, Confirm, IntlDate } from '../../components';
import HomeIcon from 'react-feather/dist/icons/home';

import { fetchTaxInvoices, openInvoiceApprovalWindow } from '../actions';

import './TaxInvoice.css';


class TaxInvoice extends Component {
    static propTypes = {
        fetchTaxInvoices: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        settlement: PropTypes.shape({
            total: PropTypes.number,
            content: PropTypes.arrayOf(PropTypes.object)
        }).isRequired
    }

    formatNumber = (value) => {
        return this.props.intl.formatNumber(value);
    }

    columns = [
        {
            titleKey: 'TAX_INVOICE_COL_BILL_NUMBER',
            dataIndex: 'invSeq'
        },
        {
            titleKey: 'TRACKING_CODE',
            dataIndex: 'trackingCode'
        },
        {
            titleKey: 'TAX_INVOICE_COL_ISSUE_DATE',
            dataIndex: 'issueDate',
            render: this.formatDate
        },
        {
            titleKey: 'TAX_INVOICE_COL_PAYMENT_DATE',
            dataIndex: 'payDate',
            render: this.formatDate
        },
        {
            titleKey: 'TAX_INVOICE_COL_TOTAL_AMOUNT',
            dataIndex: 'totalAmt',
            render: this.formatNumber
        },
        {
            titleKey: 'TAX_INVOICE_COL_VAT',
            dataIndex: 'vat',
            render: this.formatNumber
        },
        {
            titleKey: 'TAX_INVOICE_COL_AMOUNT_TO_PAY',
            dataIndex: 'payAmt',
            render: this.formatNumber
        },
        {
            titleKey: 'TAX_INVOICE_COL_STATUS',
            dataIndex: 'invStatus',
            align: 'center',
            render: (invStatus, record) => {
                const { intl } = this.props;
                switch (invStatus) {
                    case 'WAITING':
                        return <Button type="primary" ghost className="sm" onClick={() => this.openInvoiceApproveWindow(record.settleMonth)}>
                            {intl.formatMessage({ id: 'APPROVE' })}
                        </Button>;
                    case 'APPROVED':
                        return <span className="color-accent">{record.invStatusString || invStatus}</span>;
                    default:
                        return record.invStatusString || invStatus;
                }
            }
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                pageNumber: 1,
                pageSize: 20
            },
            pendingInvoice: null,
            showApprovalDialog: false
        };
        this.columns.forEach(col => {
            col.title = props.intl.formatMessage({ id: col.titleKey });
        });
    }

    componentWillMount() {
        const { fetchTaxInvoices } = this.props;
        if (fetchTaxInvoices) {
            fetchTaxInvoices({ pageNumber: this.state.pagination.pageNumber - 1, size: this.state.pagination.pageSize });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { settlement } = nextProps;
        if (settlement && this.state.pagination.pageNumber === 1 && Array.isArray(settlement.content)) {
            let pendingInvoice = settlement.content.find(x => x.settleMonth === moment().format('YYYYMM') && x.invStatus === 'WAITING');
            if (pendingInvoice) {
                this.setState({
                    pendingInvoice,
                    showApprovalDialog: true
                });
            }
        }
    }

    openInvoiceApproveWindow = (settleMonth) => {
        const { openInvoiceApprovalWindow } = this.props;
        if (openInvoiceApprovalWindow) {
            openInvoiceApprovalWindow(settleMonth);
        }
    }

    onPaginationToolChange = (pageNumber, pageSize) => {
        this.setState({
            pagination: {
                pageNumber,
                pageSize
            }
        });
    }

    onApprove = () => {
        this.setState({
            showApprovalDialog: false
        });
        this.openInvoiceApproveWindow(this.state.pendingInvoice.settleMonth);
    }

    onCancel = () => {
        this.setState({
            showApprovalDialog: false
        });
    }

    render() {
        const { intl, loading, settlement } = this.props;
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_SETTLEMENT_TAXINVOICE' }) }
        ];
        const rs = { Ok: intl.formatMessage({ id: 'OK' }), Cancel: intl.formatMessage({ id: 'CANCEL' }) };

        return (
            <WorkspacePage breadcrumb={breadcrumb} className="tax-invoice"
                title={intl.formatMessage({ id: 'HEADER_SETTLEMENT_TAXINVOICE' })}
                loading={loading}
            >
                <Confirm className="pending-invoice nowrap" description={
                    <div>
                        <div className="tip-message">
                            {intl.formatMessage({ id: 'TAX_INVOICE_PENDING_INVOICE_MSG' })}
                        </div>
                        <div className="align-left">
                            <span>{intl.formatMessage({ id: 'TAX_INVOICE_COL_BILL_NUMBER' })}: <span className="color-accent ft-bold ft-16">{(this.state.pendingInvoice || {}).invSeq}</span></span>
                        </div>
                        <div className="align-left">
                            <span>{intl.formatMessage({ id: 'TAX_INVOICE_COL_ISSUE_DATE' })}: <IntlDate value={(this.state.pendingInvoice || {}).issueDate} /></span>
                        </div>
                    </div>
                }
                    mask={true}
                    maskClosable={false}
                    visible={this.state.showApprovalDialog}
                    rs={rs}
                    onOk={this.onApprove}
                    onCancel={this.onCancel} />

                <CpRow>
                    <MediaQuery minWidth={992}>
                        {
                            (matches) => {
                                return (
                                    <Table className="settlemenet-report-table"
                                        rowKey="monthlySettlementPayoutId"
                                        columns={this.columns}
                                        scroll={matches ? {} : { x: '300%' }}
                                        dataSource={settlement ? settlement.content : []}
                                        onChange={this.onTableChange}
                                        pagination={{
                                            total: settlement ? settlement.total : 0,
                                            pageSize: this.state.pagination.pageSize,
                                            current: this.state.pagination.pageNumber,
                                            defaultCurrent: 1,
                                            onChange: this.onPaginationToolChange,
                                        }}
                                    />
                                );
                            }
                        }
                    </MediaQuery>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    connect((state) => ({ ...state.settlement }), {
        fetchTaxInvoices,
        openInvoiceApprovalWindow
    })(
        TaxInvoice
    )
)