import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import { ProtectedRoute, Footer, UnderMaintainance } from '../../common';
import { Dashboard } from '../../dashboard';
import { AffiliateAlert, ChangeBusinessTypeAlert } from '../components';

import {
    ProductSearch,
    ProductCategory,
    LinkGeneration,
    BestProducts,
    PerformantProducts,
    BannerGeneration,
    LinkToAnyPage,
    WidgetInstanceList,
    WidgetInstance,
    SearchBar,
    EventList,
    Event,
} from '../../productlink';

import {
    TrendReport,
    DynamicWidgetReport,
    PerformanceInsight,
    EarningReport,
    SettlementReport,
    SettlementDetails,
    PayoutReport,
    PayoutDetails,
    DownloadTasks,
} from '../../report';
import { OpenApi } from '../../tools';
import Notifications from './Notifications';

class AffiliateHome extends Component {
    render() {
        const {
            match,
            affiliate,
            config: { openApiEnabled },
        } = this.props;
        const affiliateActive = {
            condition: affiliate.activated,
            fallback: '/affiliate',
        };
        const openApiAllowed = {
            condition: openApiEnabled,
            fallback: '/affiliate',
        };

        return (
            <React.Fragment>
                <div className="affiliate-page">
                    <div className="affiliate-page-content">
                        <UnderMaintainance
                            style={{
                                width: '100%',
                                maxWidth: 1160,
                                margin: '40px auto 0px',
                            }}
                        />
                        <AffiliateAlert
                            style={{
                                width: '100%',
                                maxWidth: 1160,
                                margin: '40px auto 0px',
                            }}
                        />
                        <ChangeBusinessTypeAlert
                            style={{
                                width: '100%',
                                maxWidth: 1160,
                                margin: '40px auto 0px',
                            }}
                        />
                        <Switch>
                            <Route exact path={`${match.path}`} component={Dashboard} />
                            <ProtectedRoute
                                path={`${match.path}/link/:category?/:term?`}
                                component={ProductSearch}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/linkgeneration/:type?/:productId?/:itemId?`}
                                component={LinkGeneration}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/bestproducts`}
                                component={BestProducts}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/best/:group`}
                                component={PerformantProducts}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/link-to-any-page`}
                                exact
                                component={LinkToAnyPage}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/banner`}
                                exact
                                component={ProductCategory}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/banner/:category`}
                                component={BannerGeneration}
                                conditions={[affiliateActive]}
                            />
                            {/* <ProtectedRoute
                                path={`${match.path}/video-banners`}
                                exact
                                component={VideoBannerList}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/video-banners/:datasetId`}
                                exact
                                component={VideoBanner}
                                conditions={[affiliateActive]}
                            /> */}
                            <ProtectedRoute
                                path={`${match.path}/search-bar`}
                                component={SearchBar}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/dynamic-widgets`}
                                component={WidgetInstanceList}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/dynamic-widget/:instanceId?`}
                                component={WidgetInstance}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/events/:id`}
                                component={Event}
                                conditions={[affiliateActive]}
                            />
                            <ProtectedRoute
                                path={`${match.path}/events`}
                                component={EventList}
                                conditions={[affiliateActive]}
                            />

                            <Route path={`${match.path}/notifications`} component={Notifications} />
                            <Route path={`${match.path}/report/trend/:type?`} component={TrendReport} />
                            <Route path={`${match.path}/report/dynamic-widget`} component={DynamicWidgetReport} />
                            <Route path={`${match.path}/report/performance-insight`} component={PerformanceInsight} />
                            <Route path={`${match.path}/report/earning`} exact component={EarningReport} />
                            <Route path={`${match.path}/report/settlement`} exact component={SettlementReport} />
                            <Route
                                path={`${match.path}/report/settlement/:trackingCode/:settleMonth`}
                                component={SettlementDetails}
                            />
                            <Route
                                path={`${match.path}/report/payout/:trackingCode/:payoutMonth`}
                                component={PayoutDetails}
                            />
                            <Route path={`${match.path}/report/payout`} component={PayoutReport} />
                            <Route path={`${match.path}/report/download-tasks`} component={DownloadTasks} />
                            <ProtectedRoute
                                path={`${match.path}/tools/open-api`}
                                component={OpenApi}
                                conditions={[affiliateActive, openApiAllowed]}
                            />
                            {/* <Route path={`${match.path}/tools/chrome-ext`} component={ChromeExtension} /> */}
                        </Switch>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withRouter(
    connect((state) => ({
        affiliate: state.affiliate,
        config: state.config,
    }))(AffiliateHome)
);
