import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import FilterInput from '../FilterInput/FilterInput';
import './ResidentialRegisterNumber.css';

export default class ResidentialRegisterNumber extends Component {
    static propTypes = {
        value: PropTypes.shape({
            rrn: PropTypes.string,
            valid: PropTypes.bool,
        }),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        onVerify: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const { value } = this.props;
        this.state = { dirty: false, ...this.getStateByValue(value) };
    }

    getStateByValue(value) {
        const rrn = value ? value.rrn || '' : '';
        let { part1 = '', part2 = '' } = {};

        if (value && rrn) {
            value.raw = rrn.replace(/-/g, '');
            value.dirty = false;
        }

        if (~rrn.indexOf('-')) {
            [part1, part2] = rrn.split('-');
        } else {
            [part1, part2] = [rrn.substr(0, 6), rrn.substr(6, 7)];
        }

        return {
            part1,
            part2,
            valid: value ? value.valid : false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            const state = this.getStateByValue(nextProps.value);
            this.setState(state);
        }
    }

    triggerChange = (part1, part2, valid, dirty, message) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange({ rrn: `${part1}-${part2}`, raw: part1 + part2, valid, dirty, message });
        }
    };

    onPart1Change = (val) => {
        this.setState({
            part1: val,
            valid: false,
            dirty: true,
        });
        this.triggerChange(val, this.state.part2, false, true);
    };

    onPart2Change = (val) => {
        this.setState({
            part2: val,
            valid: false,
            dirty: true,
        });
        this.triggerChange(this.state.part1, val, false, true);
    };

    onPart1Keyup = (e) => {
        const caretPos = this.input1.selectionStart;
        if (caretPos === 6 && e.target.value.length === 6) {
            this.input2.focus();
        }
    };

    onPart2Keyup = (e) => {
        const caretPos = this.input2.selectionStart;
        const { keyCode } = e;
        if (caretPos === 0 && keyCode === 37) {
            this.input1.focus();
        }
    };

    onVerify = (e) => {
        const { onVerify } = this.props;
        if (typeof onVerify === 'function') {
            const { part1, part2 } = this.state;
            onVerify(part1 + part2)
                .then((msg) => {
                    this.setState({
                        valid: true,
                        dirty: false,
                    });
                    this.triggerChange(part1, part2, true, false, msg);
                })
                .catch((e) => {
                    this.setState({
                        valid: false,
                        dirty: false,
                    });
                    this.triggerChange(part1, part2, false, false, e);
                });
        }
    };

    setDirty(dirty) {
        this.setState({ dirty });
    }

    render() {
        const { rs, disabled } = this.props;

        return (
            <div className="rrn">
                <FilterInput
                    ref={(r) => (this.input1 = r)}
                    filter={/^[\d*]*$/}
                    autoComplete="off"
                    maxLength={6}
                    value={this.state.part1}
                    disabled={disabled}
                    onChange={this.onPart1Change}
                    onKeyUp={this.onPart1Keyup}
                />

                <span>-</span>

                <FilterInput
                    ref={(r) => (this.input2 = r)}
                    filter={/^[\d*]*$/}
                    type="password"
                    autoComplete="off"
                    maxLength={7}
                    value={this.state.part2}
                    disabled={disabled}
                    onChange={this.onPart2Change}
                    onKeyUp={this.onPart2Keyup}
                />

                <Button
                    style={{marginLeft:8}}
                    className="btn-rounded"
                    type="primary"
                    onClick={this.onVerify}
                    disabled={disabled || !this.state.dirty}>
                    {rs && rs.Verify ? rs.Verify : 'Verify'}
                </Button>
            </div>
        );
    }
}
