import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import { ConsentLinks } from '../../components';

export default function PiiCollectionAgreementFieldset({ form }) {
    const intl = useIntl();
    const terms = [
        { key: 'REGISTRATION_BANK', title: intl.formatMessage({ id: 'REGISTRATION_BANK' }) },
        { key: 'REGISTRATION_RRN', title: intl.formatMessage({ id: 'REGISTRATION_RRN' }) },
    ];
    return (
        <fieldset>
            <legend>{intl.formatMessage({ id: 'REGISTRATION_PII_COLLECTION' })}</legend>
            <p>{intl.formatMessage({ id: 'REGISTRATION_PII_COLLECTION_CONTENT' })}</p>

            <Form.Item label={intl.formatMessage({ id: 'REGISTRATION_MUST_AGREE' })}>
                {form.getFieldDecorator('allConsented', {
                    rules: [
                        {
                            validator: (_, value, callback) => {
                                return value && terms.length === value.length
                                    ? callback()
                                    : callback(intl.formatMessage({ id: 'PLEASE_AGREE_ALL' }));
                            },
                        },
                    ],
                })(<ConsentLinks items={terms} rs={{ Confirm: intl.formatMessage({ id: 'CONFIRM' }) }} />)}
            </Form.Item>
            <div style={{ marginBottom: 48 }}>
                {intl.formatMessage(
                    { id: 'REGISTRATION_DETAIL_FOR_PRIVACY' },
                    {
                        link: (
                            <a href="https://privacy.coupang.com/ko/center/coupang/" target="_blank">
                                {intl.formatMessage({ id: 'REGISTRATION_PRIVACY_POLICY' })}
                            </a>
                        ),
                    }
                )}
            </div>
        </fieldset>
    );
}
