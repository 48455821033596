import {
    FETCH_PAYMENT_FORM_INIT_DATA,
    SAVE_INDIVIDUAL_PAYMENT_INFO,
    SAVE_BUSINESS_PAYMENT_INFO,
    FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA,
    UPDATE_INDIVIDUAL_PAYMENT,
    FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA,
    UPDATE_BUSINESS_PAYMENT,
    FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA,
    UPDATE_OTHER_INDUSTRIES_PAYMENT,
    FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE,
    FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE,
} from './actionTypes';

import axios from 'axios';
import { asyncCall } from '../common';
import Api from './api';

export const navigateTo = (routePath) => {
    return (dispatch, getState) => {
        window.location.hash = routePath;
    };
};

export const fetchPaymentFormInitData = () => {
    return asyncCall(FETCH_PAYMENT_FORM_INIT_DATA, Api.fetchPaymentFormInitData, { notifyError: true });
};

export const validateRRN = (rrn) => {
    return (dispatch, getState) => {
        return axios.post(`/api/v1/verification/rrn`, {
            rrn,
        });
    };
};

export const validateBRN = (brn) => {
    return (dispatch, getState) => {
        return axios.post(`/api/v1/verification/brn/${brn}`);
    };
};

/**
 *
 * @param account spec fields include : {accountOwner, accountNumber, bankCode}
 * @returns {function(*, *)}
 */
export const validateBankAccount = (account) => {
    return (dispatch, getState) => {
        return Api.validateBankAccount(account);
    };
};

export const saveIndividualPaymentInfo = (paymentInfo) => {
    return asyncCall(SAVE_INDIVIDUAL_PAYMENT_INFO, () => Api.saveIndividualPayment(paymentInfo), {
        success: ({ dispatch, data }) => {
            dispatch({ type: 'UPDATE_AFFILIATE', payload: data });
            window.location.hash = 'affiliate';
        },
        notifyError: true,
    });
};

export const saveBusinessPaymentInfo = (paymentInfo) => {
    return asyncCall(SAVE_BUSINESS_PAYMENT_INFO, () => Api.saveBusinessPayment(paymentInfo), {
        success: ({ dispatch, data }) => {
            dispatch({ type: 'UPDATE_AFFILIATE', payload: data });
        },
        notifyError: true,
    });
};

export const fetchEditIndividualPaymentInitData = () => {
    return asyncCall(FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA, Api.fetchEditIndividualPaymentInitData, {
        notifyError: true,
    });
};

export const fetchEditBusinessPaymentInitData = () => {
    return asyncCall(FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA, Api.fetchEditBusinessPaymentInitData, {
        notifyError: true,
    });
};

export const fetchEditOtherIndustriesPaymentInitData = () => {
    return asyncCall(FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA, Api.fetchEditOtherIndustiresPaymentInitData, {
        notifyError: true,
    });
};

export const fetchIndividualPaymentForAffiliateTypeChange = () => {
    return asyncCall(FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE, Api.fetchEditIndividualPaymentInitData, {
        notifyError: true,
    });
};

export const fetchOtherIndustriesPaymentForAffiliateTypeChange = () => {
    return asyncCall(
        FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE,
        Api.fetchEditOtherIndustiresPaymentInitData,
        { notifyError: true }
    );
};

export const updateIndividualPayment = (payment) => {
    return asyncCall(UPDATE_INDIVIDUAL_PAYMENT, () => Api.updateIndividualPayment(payment), {
        success: ({ dispatch, data }) => {
            // dispatch({ type: 'UPDATE_AFFILIATE', payload: data });
            window.location.hash = 'affiliate';
        },
        notifyError: true,
    });
};

export const updateBusinessPayment = (payment) => {
    return asyncCall(UPDATE_BUSINESS_PAYMENT, () => Api.updateBusinessPayment(payment), {
        success: ({ dispatch, data }) => {
            // dispatch({ type: 'UPDATE_AFFILIATE', payload: data });
        },
        notifyError: true,
    });
};

export const updateOtherIndustriesPayment = (payment) => {
    return asyncCall(UPDATE_OTHER_INDUSTRIES_PAYMENT, () => Api.updateOtherIndustriesPayment(payment), {
        notifyError: true,
    });
};
