import React, { PureComponent } from 'react';

import PopupNotification from './PopupNotification';

import Api from '../api';

export default class PopupNotificationStack extends PureComponent {
    notifications = [];

    state = {
        currentNotification: null,
    };

    nextNotification = () => {
        if (this.notifications.length) {
            this.setState({
                currentNotification: this.notifications.pop(),
            });
        }
    };

    componentDidMount() {
        Api.fecthPopupNotifications().then((res) => {
            const { data } = res.data;
            if (data) {
                data.forEach((item) => {
                    this.notifications.push(() => <PopupNotification item={item} onHandled={this.nextNotification} />);
                });
            }
            this.nextNotification();
        });
    }

    render() {
        const { currentNotification } = this.state;
        const CurrentNotification = currentNotification;
        return currentNotification && <CurrentNotification onHandled={this.nextNotification} />;
    }
}
