import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { List, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Edit2 from 'react-feather/dist/icons/edit-2';
import { WorkspacePage, Image, CpRow, CardPage } from '../../components';
import { isAbroadAffiliate, Consts } from '../../common';

import bannerActive from './account-top-banner-active.svg';
import bannerInactive from './account-top-banner-inactive.svg';
import './MyAccount.css';

class MyAccount extends Component {
    items = [
        {
            type: 'EditProfile',
            titleKey: 'MY_ACCOUNT_PROFILE',
            subTitleKey: 'MY_ACCOUNT_PROFILE_SUBTITLE',
            action: (intl, affiliate) =>
                affiliate.activateStatus !== Consts.ActivateStatus.Activated ? (
                    <Button className="sm" onClick={this.editProfile}>
                        {intl.formatMessage({ id: 'LOOKUP' })}
                    </Button>
                ) : (
                    <Edit2 className="color-primary" onClick={this.editProfile} />
                ),
        },
        {
            type: 'EditPayment',
            titleKey: 'MY_ACCOUNT_PAYMENT',
            subTitleKey: 'MY_ACCOUNT_PAYMENT_SUBTITLE',
            action: (intl, affiliate) =>
                affiliate.activateStatus !== Consts.ActivateStatus.Activated ? (
                    <Button className="sm" onClick={this.editPayment}>
                        {intl.formatMessage({ id: 'LOOKUP' })}
                    </Button>
                ) : (
                    <Edit2 className="color-primary" onClick={this.editPayment} />
                ),
            condition: (affiliate) => {
                return !isAbroadAffiliate(affiliate);
            },
        },
        {
            type: 'SubIdManagement',
            titleKey: 'MY_ACCOUNT_SUB_ID_MANAGEMENT',
            subTitleKey: 'MY_ACCOUNT_SUB_ID_MANAGEMENT_SUBTITLE',
            action: (intl, affiliate) =>
                affiliate.activateStatus !== Consts.ActivateStatus.Activated ? (
                    <Button className="sm" onClick={this.manageSubIds}>
                        {intl.formatMessage({ id: 'LOOKUP' })}
                    </Button>
                ) : (
                    <Edit2 className="color-primary" onClick={this.manageSubIds} />
                ),
        },
        {
            type: 'Unsubscribe',
            titleKey: 'MY_ACCOUNT_UNSUBSCRIBE',
            subTitleKey: 'MY_ACCOUNT_UNSUBSCRIBE_SUBTITLE',
            action: (intl) => (
                <Button className="sm" onClick={this.unsubscribe}>
                    {intl.formatMessage({ id: 'CONTINUE' })}
                </Button>
            ),
            condition: (affiliate, maintainingEnabled) => {
                return affiliate.activated === true && maintainingEnabled !== true;
            },
        },
        // {
        //     type: 'ReActivate',
        //     titleKey: 'MY_ACCOUNT_REACTIVATE',
        //     subTitleKey: 'MY_ACCOUNT_REACTIVATE_SUBTITLE',
        //     action: (intl) => <ReActivate />,
        //     condition: (affiliate, maintainingEnabled) => {
        //         return affiliate.activateStatus === Consts.ActivateStatus.Deactivated && maintainingEnabled !== true;
        //     }
        // }
    ];

    constructor(props) {
        super(props);
        const { intl } = props;

        this.items.forEach((x) => {
            x.title = intl.formatMessage({ id: x.titleKey });
            x.subTitle = intl.formatMessage({ id: x.subTitleKey });
        });
    }

    editProfile = () => {
        const { history } = this.props;
        history.push('/affiliate/profile');
    };

    editPayment = () => {
        const { history } = this.props;
        history.push('/affiliate/payment');
    };

    manageSubIds = () => {
        const { history } = this.props;
        history.push('/affiliate/sub-ids');
    };

    unsubscribe = () => {
        const { history } = this.props;
        history.push('/affiliate/unsubscribe');
    };

    render() {
        const { intl, affiliate, maintainingEnabled } = this.props;
        const filteredItems = this.items.filter((x) => !x.condition || x.condition(affiliate, maintainingEnabled));

        return (
            <WorkspacePage mini={true} withHome={true} className="my-account is-flex bg-grey">
                <React.Fragment>
                    <div className="banner">
                        <Image src={affiliate.activated === false ? bannerInactive : bannerActive} />
                    </div>
                    <CpRow style={{ maxWidth: 840 }}>
                        <CardPage>
                            <List
                                dataSource={filteredItems}
                                renderItem={(item, index) => (
                                    <List.Item actions={[item.action(intl, affiliate)]}>
                                        <span className="title">{item.title}</span>
                                        {item.subTitle && <span className="sub-title">{item.subTitle}</span>}
                                    </List.Item>
                                )}
                            />
                        </CardPage>
                    </CpRow>
                </React.Fragment>
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    withRouter(
        connect(
            (state) => ({
                affiliate: state.affiliate,
                maintainingEnabled: state.config.maintainingEnabled,
            }),
            null
        )(MyAccount)
    )
);
