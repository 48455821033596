import React from 'react';
import cx from 'classnames';
import styles from './StepIndicator.module.less';

const StepIndicator = ({stepCount,current})=>{
    return (
        <div className={styles.root}>
            {
                Array.from({length:stepCount}, (_,index)=>{
                    return (
                    <React.Fragment key={index}>
                        <span className={cx(styles.indicator,{[styles.current]:current === index+1})}>{index+1}</span>
                        {index!==stepCount-1 && <span className={styles.divider}></span>}
                    </React.Fragment>
                    )
                })
            }
        </div>
    )
};

export default StepIndicator;