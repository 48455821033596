import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Drawer, Badge } from 'antd';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import MenuIcon from 'react-feather/dist/icons/menu';

import { Link } from 'react-router-dom';
import FeatCheckerContext from '../../feat-checker/FeatCheckerContext';

import './NavMenu.css';

export default class NavMenu extends Component {
    static contextType = FeatCheckerContext;
    static propTypes = {
        mode: PropTypes.oneOf(['PC', 'MOBILE']).isRequired,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                title: PropTypes.string,
                route: PropTypes.string,
                children: PropTypes.array,
            })
        ),
        activeMenuItem: PropTypes.string,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        items: [],
    };

    state = {
        drawerVisible: false,
    };

    onMenuClick = (e) => {
        console.log(e.selectedKeys);
        if (this.state.drawerVisible) {
            this.setState({
                drawerVisible: false,
            });
        }
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };
    onToggleDrawer = () => {
        this.setState((state) => ({
            drawerVisible: !state.drawerVisible,
        }));
    };

    render() {
        const { mode, items, activeMenuItem } = this.props;
        const { isUncheckedFeat } = this.context;
        const { drawerVisible } = this.state;
        const getMenuItem = (x) => {
            if (/^https?:\/\//.test(x.route)) {
                return (
                    <Menu.Item className="nav-menu-item" key={x.key}>
                        <a href={x.route} target="_blank" onClick={(e) => e.stopPropagation()}>
                            <Badge dot={isUncheckedFeat(x.key)}>{x.title}</Badge>
                        </a>
                    </Menu.Item>
                );
            }
            return (
                <Menu.Item className="nav-menu-item" key={x.key}>
                    <Link to={x.route}>
                        <Badge dot={isUncheckedFeat(x.key)}>{x.title}</Badge>
                    </Link>
                </Menu.Item>
            );
        };

        if (mode === 'PC') {
            return (
                <Menu mode="horizontal" className="nav-menu" selectedKeys={[activeMenuItem]} onClick={this.onMenuClick}>
                    {items
                        .filter((x) => x.title)
                        .map((x) => {
                            if (Array.isArray(x.children) && x.children.length) {
                                return (
                                    <Menu.SubMenu
                                        className="nav-submenu"
                                        key={x.key}
                                        title={
                                            <Badge dot={isUncheckedFeat(x.key)}>
                                                <span>
                                                    {x.title} <ChevronDownIcon size={16} />
                                                </span>
                                            </Badge>
                                        }>
                                        {x.children
                                            .filter((x) => x.title)
                                            .map((y) => {
                                                if (Array.isArray(y.children) && y.children.length) {
                                                    return (
                                                        <Menu.SubMenu
                                                            key={y.key}
                                                            className="nav-submenu"
                                                            title={
                                                                <Badge dot={isUncheckedFeat(y.key)}>{y.title}</Badge>
                                                            }>
                                                            {y.children.filter((x) => x.title).map(getMenuItem)}
                                                        </Menu.SubMenu>
                                                    );
                                                } else {
                                                    return getMenuItem(y);
                                                }
                                            })}
                                    </Menu.SubMenu>
                                );
                            } else {
                                return getMenuItem(x);
                            }
                        })}
                </Menu>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="hamburger-nav-menu-vertical">
                        <Badge dot={isUncheckedFeat('hamburger')}>
                            <MenuIcon onClick={this.onToggleDrawer} />
                        </Badge>
                    </div>
                    <Drawer
                        title={null}
                        width="68%"
                        className="hamburger-drawer"
                        placement="left"
                        closable={false}
                        onClose={this.onToggleDrawer}
                        visible={drawerVisible}>
                        <Menu className="nav-menu-vertical" selectedKeys={[activeMenuItem]} onClick={this.onMenuClick}>
                            {items
                                .filter((x) => x.title)
                                .map((x) => {
                                    if (Array.isArray(x.children) && x.children.length > 0) {
                                        return (
                                            <Menu.ItemGroup key={x.key} title={x.title}>
                                                {x.children
                                                    .filter((x) => x.title)
                                                    .map((y) => {
                                                        if (Array.isArray(y.children) && y.children.length) {
                                                            return (
                                                                <Menu.ItemGroup key={y.key} title={y.title}>
                                                                    {y.children
                                                                        .filter((z) => z.title)
                                                                        .map((z) => (
                                                                            <Menu.Item
                                                                                className="nav-menu-item"
                                                                                key={z.key}>
                                                                                <Link to={z.route}>
                                                                                    <Badge dot={isUncheckedFeat(z.key)}>
                                                                                        {z.title}
                                                                                    </Badge>
                                                                                </Link>
                                                                            </Menu.Item>
                                                                        ))}
                                                                </Menu.ItemGroup>
                                                            );
                                                        } else {
                                                            return (
                                                                <Menu.Item className="nav-menu-item" key={y.key}>
                                                                    <Link to={y.route}>
                                                                        <Badge dot={isUncheckedFeat(y.key)}>
                                                                            {y.title}
                                                                        </Badge>
                                                                    </Link>
                                                                </Menu.Item>
                                                            );
                                                        }
                                                    })}
                                            </Menu.ItemGroup>
                                        );
                                    } else {
                                        return (
                                            <Menu.Item className="nav-menu-item" key={x.key}>
                                                <Link to={x.route}>
                                                    <Badge dot={isUncheckedFeat(x.key)}>{x.title}</Badge>
                                                </Link>
                                            </Menu.Item>
                                        );
                                    }
                                })}
                        </Menu>
                    </Drawer>
                </React.Fragment>
            );
        }
    }
}
