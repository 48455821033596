import React from 'react';
import cx from 'classnames';

import facebookLogo from './facebook@2x.png';
import naverBlogLogo from './naver-blog@2x.png';

import './SnsSharer.less';

export default function ({ mode, className, title, description, imageUrl, link }) {
    if (imageUrl) {
        if (!/^https?:/.test(imageUrl)) {
            imageUrl = 'https:' + imageUrl;
        }
    }
    const shareToFacebook = () => {
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}&quote=${encodeURIComponent(
                title
            )}`
        );
    };

    const shareToNaverBlog = () => {
        window.open(
            `https://share.naver.com/web/shareView.nhn?url=${encodeURIComponent(link)}&title=${encodeURIComponent(
                title
            )}`
        );
    };

    return (
        <div className={cx('sns-sharer', className)}>
            <img src={facebookLogo} alt="" className="sns-sharer-button" onClick={shareToFacebook} />
            <img src={naverBlogLogo} alt="" className="sns-sharer-button" onClick={shareToNaverBlog} />
        </div>
    );
}
