/**
 * @see https://github.com/andyearnshaw/Intl.js
 */
import intlLocalesSupported from 'intl-locales-supported';
// import { addLocaleData } from 'react-intl';
import messages from './index';

// import 'react-intl/locale-data/en';
// import 'react-intl/locale-data/ko';

const supportLocales = Object.keys(messages);

// [NOTICE] To reduce js bundle size
// addLocaleData(require(`react-intl/locale-data/ko`));
// addLocaleData(require(`react-intl/locale-data/en`));

if (global.Intl) {
    if (!intlLocalesSupported(supportLocales)) {
        require('intl');
        Intl.NumberFormat = global.IntlPolyfill.NumberFormat;
        Intl.DateTimeFormat = global.IntlPolyfill.DateTimeFormat;
    }
} else {
    global.Intl = require('intl');
    require('intl/locale-data/jsonp/en');
    require('intl/locale-data/jsonp/ko');
}
