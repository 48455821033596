import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { MessageForm } from '../../components';

import coupangLogo from '../images/coupang-logo-light.svg';
import { TrackerContext } from '../../common';

class GotoCoupangLogin extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.state = {
            restSeconds: 7,
        };
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            if (this.state.restSeconds === 0) {
                this.gotoLogin();
                return;
            }
            this.setState({
                restSeconds: this.state.restSeconds - 1,
            });
        }, 1000);
    }

    gotoLogin = () => {
        const { loginUrl } = this.props;
        if (this.timer) {
            clearInterval(this.timer);
        }

        this.context.trackEvent({ action: 'login' });
        setTimeout(() => {
            window.location.replace(loginUrl);
        }, 150);
    };

    render() {
        const { intl } = this.props;
        const title = (
            <div>
                <div className="emotion-line">
                    <img src={coupangLogo} alt="coupang" style={{ width: 154, height: 44 }} />
                </div>
                <div>
                    <FormattedMessage id="GOTO_LOGIN_TITLE" values={{ newLine: <br /> }} />
                </div>
            </div>
        );

        const rs = { OK: intl.formatMessage({ id: 'MEMBER_AFFILIATE_JOIN_NOW' }) };
        const gotoLoginInSeconds = (
            <FormattedMessage
                id="GOTO_LOGIN_TIP"
                values={{
                    seconds: (
                        <span className="color-accent" style={{ fontSize: '1.5em', fontWeight: '500' }}>
                            {this.state.restSeconds}
                        </span>
                    ),
                }}
            />
        );

        return (
            <div className="is-flex bg-grey">
                <MessageForm
                    className="mt40 align-center"
                    size="normal"
                    title={title}
                    content={gotoLoginInSeconds}
                    rs={rs}
                    onOK={() => {
                        this.gotoLogin();
                    }}
                />
            </div>
        );
    }
}

export default injectIntl(
    connect((state) => ({
        ...state.config,
        ...state.session,
    }))(GotoCoupangLogin)
);
