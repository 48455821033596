import React from 'react';
import PropTypes from 'prop-types';
import Product, { ProductShape } from './Product';

import './ProductRow.css';


export default function ProductRow({ rowSize, items = [], actions, onAction, showRankNo }) {
    let allItems = [...items];
    // Fill the whole role with product-item, to prevent justify-content: space-between display issue when row has less items
    for (let i = 0; i < rowSize - items.length; i++) {
        allItems.push({ type: `place-holder-${i}-${Math.random()}` });
    }
    return (
        <div className="product-row">
            {
                allItems.map(x => <Product key={x.type + (x.vendorItemId || x.itemId || x.productId)} actions={actions} onAction={onAction} showRankNo={showRankNo} {...x} />)
            }
        </div>
    );
}

ProductRow.propTypes = {
    rowSize: PropTypes.number,
    items: PropTypes.arrayOf(
        PropTypes.shape(ProductShape)
    ),
    actions: PropTypes.array,
    onAction: PropTypes.func
};