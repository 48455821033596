import { asyncCall } from '../common';
import {
    FETCH_TOP5_UNREAD_MESSAGES,
    MARK_ALL_MESSAGES_AS_READ,
    MARK_MESSAGE_AS_READ,
    FETCH_ALL_MESSAGES,
    SET_PAGE_FOR_ALL_MESSAGES,
    SET_FILTER_FOR_ALL_MESSAGES,
    CLEAR_PAGE_FILTER_FOR_ALL_MESSAGES,
} from './actionTypes';

import Api from './api';

export const UNSUBSCRIBE = 'AFFILIATE/UNSUBSCRIBE';
export const unsubscribe = (reason, otherReasonText, payout) => {
    return asyncCall(
        UNSUBSCRIBE,
        () => {
            return Api.unsubscribe(reason, otherReasonText, payout);
        },
        {
            notifyError: true,
            success: ({ data, dispatch }) => {
                dispatch({ type: 'UPDATE_AFFILIATE', payload: data });
                window.location.hash = '/';
            },
        }
    );
};

export function resetAffiliate() {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_AFFILIATE', payload: null });
    };
}

export const FETCH_SUB_IDS = 'AFFILIATE/FETCH_SUB_IDS';
export const fetchSubIds = () => {
    return asyncCall(
        FETCH_SUB_IDS,
        () => {
            return Api.fetchSubIds();
        },
        { notifyError: false }
    );
};

export const ADD_SUB_ID = 'AFFILIATE/ADD_SUB_ID';
export const addSubId = (subId) => {
    return asyncCall(
        ADD_SUB_ID,
        () => {
            return Api.addSubId(subId);
        },
        { notifyError: false }
    );
};

export const SUB_ID_ADDED = 'AFFILIATE/SUB_ID_ADDED';
export const subIdAdded = (subId) => (dispatch) => dispatch({ type: SUB_ID_ADDED, payload: subId });
export const SUB_ID_DELETED = 'AFFILIATE/SUB_ID_DELETED';
export const subIdDeleted = (subId) => {
    return (dispatch) => dispatch({ type: SUB_ID_DELETED, payload: subId });
};

export const DELETE_SUB_ID = 'AFFILIATE/DELETE_SUB_ID';
export const deleteSubId = (subId) => {
    return asyncCall(
        DELETE_SUB_ID,
        () => {
            return Api.deleteSubId(subId);
        },
        { notifyError: true }
    );
};

export const SWITCH_SUB_ID = 'AFFILIATE/SWITCH_SUB_ID';
export const switchSubId = (subId) => {
    return (dispatch) => dispatch({ type: SWITCH_SUB_ID, payload: subId });
};

export const PICK_SUB_IDS = 'AFFILIATE/PICK_SUB_IDS';
export const pickSubIds = (subIds) => {
    return (dispatch) => dispatch({ type: PICK_SUB_IDS, payload: subIds });
};

export const fetchTop5UnreadMessages = () => {
    return asyncCall(FETCH_TOP5_UNREAD_MESSAGES, () => Api.fetchTop5UnreadMessages(), { notifyError: true });
};

export const fetchAllMessages = (store) => {
    const { page, filters } = store.getState().notifications;
    const requestFilters = [];
    if (Array.isArray(filters) && filters.length) {
        requestFilters.push({ column: 'messageType', operator: 'IN', value: filters });
    }
    const requestPayload = { page: { pageNumber: page, size: 10 }, filters: requestFilters };
    return asyncCall(FETCH_ALL_MESSAGES, () => Api.fetchAllMessages(requestPayload), { notifyError: true });
};

export const markMessageAsRead = (id) => {
    return asyncCall(MARK_MESSAGE_AS_READ, () => Api.markAsRead(id), { notifyError: true });
};

export const markAllMessagesAsRead = (id) => {
    return asyncCall(MARK_ALL_MESSAGES_AS_READ, () => Api.markAllAsRead(), { notifyError: true });
};

export const setMessageFilter = (filters) => {
    return (dispatch) => {
        dispatch({ type: SET_FILTER_FOR_ALL_MESSAGES, payload: filters });
    };
};

export const setMessagePage = (page) => {
    return (dispatch) => {
        dispatch({ type: SET_PAGE_FOR_ALL_MESSAGES, payload: page });
    };
};

export const clearPageAndFilter = () => {
    return (dispatch) => {
        dispatch({ type: CLEAR_PAGE_FILTER_FOR_ALL_MESSAGES });
    };
};
