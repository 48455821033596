import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'antd';
import CheckableTagList from './CheckableTagList';

import './CheckableTagsInput.css';

export default class CheckbableTagsInput extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        value: PropTypes.shape({
            selectedTags: PropTypes.array,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.any,
                    name: PropTypes.string
                })
            )
        }),
        onChange: PropTypes.func,
        rs: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            selectedTags: this.props.value.selectedTags || [],
            tags: this.props.value.tags || []
        };
    }

    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            const selectedTags = nextProps.value.selectedTags || [];
            const tags = nextProps.value.tags || [];
            this.setState({
                selectedTags,
                tags
            });
        }
    }

    triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue);
        }
    }

    toggleSelect = (tag, checked) => {
        const { tags, selectedTags } = this.state;
        const targetSelectedTags = checked ?
            [...selectedTags, tag.id] :
            selectedTags.filter(t => t !== tag.id);
        this.setState({ selectedTags: targetSelectedTags });
        this.triggerChange({ tags, selectedTags: targetSelectedTags });
    }

    removeCustomTag = (tag) => {
        const { tags, selectedTags } = this.state;
        const targetTags = tags.filter(x => x.id !== tag.id);
        const targetSelectedTags = selectedTags.filter(x => x !== tag.id);
        const newState = { tags: targetTags, selectedTags: targetSelectedTags };
        this.setState(newState);
        this.triggerChange(newState);
    }

    onInputChange = (e) => {
        this.setState({
            inputValue: e.target.value
        });
    }

    addNewTag = () => {
        this.setState((prevState, props) => {
            const potentialTag = prevState.inputValue.trim();
            if (potentialTag) {
                const existingTag = prevState.tags.find(t => t.name === potentialTag);
                if (!existingTag) {
                    const tags = [...prevState.tags, { id: potentialTag, name: potentialTag, removable: true }];
                    const selectedTags = [...prevState.selectedTags, potentialTag]
                    this.triggerChange({ tags, selectedTags });
                    return {
                        inputValue: '',
                        tags,
                        selectedTags
                    };
                } else if (prevState.selectedTags.find(t => t.id === potentialTag)) {
                    const selectedTags = [...prevState.selectedTags, potentialTag];
                    this.triggerChange({ selectedTags });
                    return {
                        inputValue: '',
                        selectedTags
                    };
                }
            }
            return prevState;
        });
    }

    render() {
        const { value, rs, onChange, disabled, ...others } = this.props;
        const { tags, selectedTags } = this.state;

        return (<div className="checkable-tags-input">
            <CheckableTagList disabled={disabled} tags={tags} selectedTags={selectedTags}
                onChange={(tag, checked) => this.toggleSelect(tag, checked)}
                onRemove={(tag) => this.removeCustomTag(tag)}
            />
            <div className="checkable-tags-input-container">
                <Input value={this.state.inputValue} suffix={<Button tabIndex="-1" className="btn-add sm" type="primary" ghost disabled={disabled} onClick={this.addNewTag}>{rs ? rs.Add : 'Add'}</Button>}
                    onChange={this.onInputChange}
                    onPressEnter={this.addNewTag}
                    onBlur={this.addNewTag}
                    disabled={disabled}
                    {...others}
                />
            </div>
        </div >);
    }
}