import React, { Component } from 'react';
import { injectIntl, FormattedNumber } from 'react-intl';
import { withRouter } from 'react-router';
import { Button, Typography, Tooltip, Icon } from 'antd';
import { WorkspacePage, CpRow, DataTable, Krw, YearMonth } from '../../components';
import HomeIcon from 'react-feather/dist/icons/home';
import Api from '../api';

class SettlementReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                pageNumber: 1,
                pageSize: 20,
            },
        };
    }

    viewDetails = ({ trackingCode, calMonth }) => {
        const { history } = this.props;
        history.push(`/affiliate/ws/report/settlement/${trackingCode}/${calMonth}`);
    };

    render() {
        const { intl } = this.props;
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_EARNING_REPORT' }) },
            { title: intl.formatMessage({ id: 'HEADER_REPORT_SETTLEMENT' }) },
        ];
        const columns = [
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_MONTH' }),
                dataIndex: 'calMonth',
                width: 80,
                render: (val) => <YearMonth value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'TRACKING_CODE' }),
                dataIndex: 'trackingCode',
                width: 110,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_PURCHASE' }),
                dataIndex: 'orderCount',
                render: (val) => <FormattedNumber value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_CANCEL' }),
                dataIndex: 'cancelCount',
                render: (val) => <FormattedNumber value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_PURCHASE_AMOUNT' }),
                dataIndex: 'purchaseAmt',
                align: 'right',
                render: (val) => <Krw value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_CANCEL_AMOUNT' }),
                dataIndex: 'cancelAmt',
                align: 'right',
                render: (val) => <Krw value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_TOTAL_AMOUNT' }),
                dataIndex: 'actualPaymentAmt',
                align: 'right',
                render: (val) => <Krw value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_COL_REVENUE' }),
                dataIndex: 'commission',
                align: 'right',
                render: (val) => <Krw value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'RP_COMMISSION_CAP' }),
                dataIndex: 'commissionCap',
                align: 'right',
                render: (val) => val < 0 ? '-' : <Krw value={val} />,
            },
            {
                title: intl.formatMessage({ id: 'SETTLEMENT_FINAL_REVENUE' }),
                dataIndex: 'finalRevenue',
                align: 'right',
                render: (val) => <Krw value={val} />,
            },
            {
                title: (
                    <div>
                        {intl.formatMessage({ id: 'SETTLEMENT_COL_SETTLEMENT_STATUS' })}
                        <Tooltip title={intl.formatMessage({ id: 'SETTLEMENT_STATUS_TIPS' })} trigger="click">
                            <Icon type="question-circle" style={{ marginLeft: '0.5em', fontSize: '1.2em' }} />
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'settleDetails',
                render: (val) => {
                    if (val) {
                        return val.status === 'SETTLED' ? (
                            <Typography.Text>{val.statusText}</Typography.Text>
                        ) : (
                            <Tooltip title={val.details}>
                                <Typography.Text type="danger">{val.statusText}</Typography.Text>
                            </Tooltip>
                        );
                    }
                    return null;
                },
            },
            {
                dataIndex: 'details',
                align: 'center',
                width: 80,
                render: (val, record) => {
                    return (
                        <Button disabled={val === 0} className="sm" onClick={() => this.viewDetails(record)}>
                            {this.props.intl.formatMessage({ id: 'VIEW_DETAILS' })}
                        </Button>
                    );
                },
            },
        ];

        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'HEADER_REPORT_SETTLEMENT' })}
                subTitle={intl.formatMessage({ id: 'SETTLEMENT_SUB_TITLE' })}>
                <CpRow>
                    <DataTable
                        className="settlemenet-report-table"
                        rowKey="monthlySettlementId"
                        columns={columns}
                        getData={Api.fetchSettlementReport}
                    />
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(withRouter(SettlementReport));
