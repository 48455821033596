import axios from 'axios';

export default class Api {
    static fetchDashboardInitData() {
        return axios.get('/api/v1/dashboard');
    }

    static fetchBest11Products(categoryId) {
        return axios.post('/api/v1/search/best', {
            page: { pageNumber: 0, size: 11 },
            categoryId
        });
    }

    static fecthPopupNotifications() {
        return axios.get('/api/v1/configuration/content/popup_notification').then(res => {
            const { data } = res.data;
            if (data) {
                data.forEach(item => {
                    item.content = JSON.parse(item.content);
                });
            }
            return res;
        });
    }

    static persistPopupAction(targetId) {
        return axios.post('/api/v1/action', { targetId, actionType: 'POPUP_CLICK' });
    }

    static fecthCommissionCapNotification() {
        return axios.get('/api/v1/earning/notification');
    }
}