import React, { Component } from 'react';
import { Breadcrumb, Icon, Spin } from 'antd';
import cx from 'classnames';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Link, withRouter } from 'react-router-dom';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import HomeIcon from 'react-feather/dist/icons/home';
import CpRow from '../Layout/CpRow';
import SubIdSelect from '../SubIdSelect/SubIdSelect';

import { ReportDateRangePicker } from '../../report/components';

import './WorkspacePage.less';

class WorkspacePage extends Component {
    static propTypes = {
        breadcrumb: PropTypes.arrayOf(PropTypes.shape({
            link: PropTypes.string,
            title: PropTypes.string,
            icon: PropTypes.any
        })),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        subTitle: PropTypes.node,
        help: PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.string
        }),
        withDateRange: PropTypes.bool,
        withSubId: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({ isMultiple: PropTypes.bool })]),
        titleActions: PropTypes.arrayOf(PropTypes.element)
    }

    gotoHome = () => {
        const { history } = this.props;
        history.push('/');
    }

    renderIcon = (breadcrumb) => {
        if (breadcrumb.icon) {
            if (typeof breadcrumb.icon === 'string') {
                return <Icon type={breadcrumb.icon} />;
            }
            return breadcrumb.icon;
        }
        return null;
    }


    render() {
        const { mini, className, breadcrumb, withHome, withSubId, withDateRangedReportPicker, title, subTitle, tips, loading = false, children, help, titleActions, titleActionsOnSameRow } = this.props;

        return (
            <div className={cx("ws-page", className, { mini })}>
                <CpRow className={cx("ws-header", { hidden: !breadcrumb && !title })}>
                    <MediaQuery minWidth={576}>
                        {
                            (matches) => {
                                return matches ? (<div className="ws-header-bar">
                                    <Breadcrumb className="ws-breadcrumb" separator={<ChevronRightIcon size={16} />}>
                                        {
                                            (breadcrumb || []).map(x => {
                                                const icon = this.renderIcon(x)
                                                return <Breadcrumb.Item key={x.title}>
                                                    {
                                                        icon && <span className="breadcrumb-icon">{icon}&nbsp;&nbsp;</span>
                                                    }
                                                    {
                                                        x.link ? <Link to={x.link}>{x.title}</Link> : x.title
                                                    }
                                                </Breadcrumb.Item>;
                                            })
                                        }
                                    </Breadcrumb>
                                </div>) : null;
                            }
                        }
                    </MediaQuery>
                    <h1 className={cx("ws-title", { "flex-direction-row": titleActionsOnSameRow })}>
                        <div>
                            {title} {subTitle && <span className="sub-title">{subTitle}</span>}
                            {tips && <div className="ws-title-tips">{tips}</div>}

                            {
                                help && !!help.text &&
                                <span className="ws-help-link">
                                    <Link to={help.link}>
                                        {help.text}
                                        <ChevronRightIcon size={16} style={{ verticalAlign: 'text-bottom', marginLeft: 8 }} />
                                    </Link>
                                </span>
                            }
                        </div>
                        <div className="ws-action-container">
                            <div className="ws-action-group">
                                {withDateRangedReportPicker && <div className="ws-action"><ReportDateRangePicker /></div>}
                                {withSubId && <div className="ws-action"><SubIdSelect mode={withSubId.isMultiple && 'multiple'} /></div>}
                                {
                                    (titleActions || []).map((x, index) => <div className="ws-action" key={`ws-action-${index}`}>{x}</div>)
                                }
                            </div>
                        </div>
                    </h1>
                </CpRow>
                <Spin spinning={loading} delay={250} wrapperClassName="page-spin-container" className="page-loading">
                    <div className="ws-body">
                        {children}
                    </div>
                </Spin>
                {withHome && <div className="ws-fab"><div className="ws-fab-button" onClick={this.gotoHome}><HomeIcon color="#AEB0B4" size={20} /></div></div>}
            </div>);
    }
}
export default withRouter(WorkspacePage)