import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { CardPage, Emotion, CpRow } from '../../components';
import { TrackerContext, trackEvent, trackPageView } from '../../common';

class AffiliateApproved extends Component {
    static contextType = TrackerContext;
    componentDidMount() {
        this.context.trackPageView({ action: 'enter_approved' });
    }

    handleOK = () => {
        const { onOk } = this.props;
        this.context.trackEvent({ action: 'approved_confirm' });
        if (onOk) {
            onOk();
        }
    };

    render() {
        const { intl, name, affiliateCode } = this.props;

        const Header = (
            <div>
                <div className="emotion-line">
                    <Emotion type="sweatsmile" />
                </div>
                <h1 style={{ marginBottom: 12 }}>
                    <FormattedMessage id="PAYMENT_ALERT_GREETING" values={{ name }} />,{' '}
                    {intl.formatMessage({ id: 'PAYMENT_ALERT_TITLE_APPROVED' })}
                </h1>
            </div>
        );
        const Body = (
            <p className="ft-body ft-16">
                <FormattedMessage
                    id="APPROVED_AFFILIATE_ID_IS"
                    values={{ name, affiliateCode: <strong className="color-accent ft-bold">{affiliateCode}</strong> }}
                />
                <br />
                {intl.formatMessage({ id: 'APPROVED_CREATE_A_LINK_NOW' })}
            </p>
        );
        const Footer = (
            <div className="button-bar align-center">
                <Button size="large" type="primary" onClick={this.handleOK}>
                    {intl.formatMessage({ id: 'CONFIRM' })}
                </Button>
            </div>
        );

        return (
            <CpRow className="is-flex bg-grey" style={{ maxWidth: 840 }}>
                <CardPage className="mt40 align-center" header={Header} body={Body} footer={Footer} />
            </CpRow>
        );
    }
}

const mapStateToProps = (state) => ({
    name: state.session.username,
    affiliateCode: state.affiliate.affiliateCode || 'kimkung',
});

export default injectIntl(connect(mapStateToProps)(withRouter(AffiliateApproved)));
