import axios from 'axios';

export default class Api {
    static getRegistrationInitData() {
        return axios.get('/api/v1/affiliate/registration');
    }

    static fetchRegistrationInitData() {
        return axios.get('/api/v1/affiliate');
    }

    static syncName() {
        return axios.post('/api/v1/payment/name-sync');
    }

    // static validateAffiliateCode(code) {
    //     return axios.get(`/api/v1/affiliate/code/${code}`);
    // }

    static persistProfile(profile) {
        return axios.post('/api/v1/affiliate', profile);
    }

    static fetchEditProfileInitData() {
        return axios.get('/api/v1/affiliate/edit');
    }

    static updateProfile(profile) {
        return axios.put('/api/v1/affiliate', profile);
    }

    static fetchRegistrationFlowInitData() {
        return axios.get('/api/v1/affiliate/registration');
    }

    static regSaveProfile(profile) {
        return axios.post('/api/v1/affiliate/registration/profile', profile);
    }

    static regSaveAffiliateType(type) {
        return axios.post(`/api/v1/affiliate/registration/affiliateType/${type}`);
    }

    static regSaveIndividualPayment(formData) {
        return axios.post(`/api/v1/affiliate/registration/payment/individual`, formData);
    }

    static regSaveBusinessPayment(formData) {
        const {
            consent,
            payment: { brnFileList, bankbookFileList, ...payment },
            profile,
        } = formData;
        const brnImage = brnFileList[0].originFileObj;
        const bankImage = bankbookFileList[0].originFileObj;
        let fd = new FormData();
        fd.append('CONSENT', JSON.stringify(consent));
        fd.append('PROFILE', JSON.stringify(profile));
        fd.append('PAYMENT', JSON.stringify(payment));

        // when reject and modifying, file could be null if user not select new file
        if (brnImage) {
            fd.append('BRN_IMAGE', brnImage);
        }
        if (bankImage) {
            fd.append('BANK_IMAGE', bankImage);
        }

        return axios.post(`/api/v1/affiliate/registration/payment/business`, fd, {
            headers: {
                'Content-Type': undefined,
            },
        });
    }

    static regSaveOtherIndustriesPayment(formData) {
        const {
            consent,
            payment: { brnFileList, bankbookFileList, ...payment },
            profile,
        } = formData;
        let fd = new FormData();
        if (brnFileList) {
            const brnImage = brnFileList[0].originFileObj;
            if (brnImage) {
                fd.append('BRN_IMAGE', brnImage);
            }
        }

        if (bankbookFileList) {
            const bankImage = bankbookFileList[0].originFileObj;
            if (bankImage) {
                fd.append('BANK_IMAGE', bankImage);
            }
        }
        fd.append('CONSENT', JSON.stringify(consent));
        fd.append('PROFILE', JSON.stringify(profile));
        fd.append('PAYMENT', JSON.stringify(payment));

        // when reject and modifying, file could be null if user not select new file

        return axios.post(`/api/v1/affiliate/registration/payment/otherIndustries`, fd, {
            headers: {
                'Content-Type': undefined,
            },
        });
    }

    static validateUrl(type, url) {
        return axios.post('/api/v1/verification/site', {
            type,
            url,
        });
    }
}
