import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, notification, Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import Api from '../api';

const DownloadButton = withRouter(injectIntl(function DownloadButton({ jobType, parameters, children, buttonProps, intl, history }) {
    const [loading, setLoading] = useState(false);

    const createDownloadTask = async () => {
        setLoading(true);
        try {
            const {data:res} = await Api.createDownloadJob(jobType, parameters);
            Modal.confirm({
                className: 'affiliate-confirm',
                title: intl.formatMessage({ id: 'NOTICE' }),
                content: <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'RP_DOWNLOAD_POPUP_CONTENT' }, {jobId: res.data.jobId}) }} />,
                width: 440,
                okText: intl.formatMessage({ id: 'CONFIRM' }),
                cancelText: intl.formatMessage({ id: 'CANCEL' }),
                okButtonProps: { size: 'large' },
                cancelButtonProps: { size: 'large' },
                onOk: () => {
                    history.push('/affiliate/ws/report/download-tasks');
                }
            });
        }
        catch (err) {
            notification.warn({ message: intl.formatMessage({ id: 'ERROR' }), description: (err.data && err.data.rMessage) || 'Failed to create download task' });
        };
        setLoading(false);
    }

    return <Button loading={loading} className="lg" onClick={createDownloadTask} {...buttonProps}>{children}</Button>;
}));

DownloadButton.propTypes = {
    jobType: PropTypes.string.isRequired,
    parameters: PropTypes.object,
    buttonProps: PropTypes.object
};

export default DownloadButton;