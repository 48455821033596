import React, { useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FocusSlider.less';

export default function ({ items = [], onClickItem }) {
    const history = useHistory();
    const notLargeDevice = useMediaQuery({ maxWidth: 991 });
    const imageCdnUrl = useSelector((state) => state.config.imageCdnUrl);
    const sliderRef = useRef(null);
    const settings = {
        autoplay: true,
        initialSlide: 0,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: notLargeDevice,

        beforeChange: (oldIndex, newIndex) => {
            setActiveIndex(newIndex);
        },
    };
    const [activeIndex, setActiveIndex] = useState(settings.initialSlide);
    const onClick = (link) => {
        if(typeof onClickItem === 'function'){
            onClickItem(link);
        } else {
            if (link.indexOf('source=home_banner') === -1) {
                if (link.indexOf('?') === -1) {
                    link += '?source=home_banner';
                } else {
                    link += '&source=home_banner';
                }
            }
            window.location.href = link;
        }
    };

    return (
        <div style={{ position: 'relative', transform: 'translateX(50%)' }}>
            <Slider className="focus-slider" ref={(s) => (sliderRef.current = s)} {...settings}>
                {items.map((item, index) => (
                    <div key={index}>
                        <a onClick={() => onClick(item.link)}>
                            <img alt="" src={`${imageCdnUrl}/${notLargeDevice ? item.mobileImage : item.image}`} />
                        </a>
                    </div>
                ))}
            </Slider>

            <div
                className="focus-slider-dots"
                onMouseEnter={() => {
                    sliderRef.current.slickPause();
                }}
                onMouseLeave={() => {
                    sliderRef.current.slickPlay();
                }}>
                {items.map((item, index) => {
                    return (
                        <a
                            key={index}
                            className={classnames({ active: activeIndex === index })}
                            onMouseEnter={() => {
                                sliderRef.current.slickGoTo(index);
                            }}>
                            {!notLargeDevice && <img alt="" src={`${imageCdnUrl}/${item.thumbnail}`} />}
                        </a>
                    );
                })}
            </div>
        </div>
    );
}
