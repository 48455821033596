import {
    FETCH_PAYMENT_FORM_INIT_DATA,
    SAVE_INDIVIDUAL_PAYMENT_INFO,
    SAVE_BUSINESS_PAYMENT_INFO,
    FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA,
    FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA,
    FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA,
    UPDATE_INDIVIDUAL_PAYMENT,
    UPDATE_BUSINESS_PAYMENT,
    UPDATE_OTHER_INDUSTRIES_PAYMENT,
    FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE,
    FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE,
} from './actionTypes';
import { begin, success, fail } from '../common';

function getInitialState() {
    const initialState = {
        enterPaymentLater:
            'localStorage' in window
                ? !!(
                      window.cpapp.affiliate &&
                      localStorage.getItem(`enterPaymentLater${window.cpapp.affiliate.affiliateId}`)
                  )
                : false,
        data: {
            affiliateType: '',
            businessType: '',
            affiliateId: null,
            rrn: '',
            brn: '',

            contactName: '',
            phone: '',
            mobile: '',
            email: '',
            address: '',
            bankCode: '',
            bankAccountNumber: '',
            bankAccountOwner: '',

            companyName: '',
            companyOwner: '',
        },
        memberInfo: {},
        piiInputStatus: undefined,
        bankNameAndCodes: [],
        terms: [],

        loading: false,
    };
    return initialState;
}
export default (state = getInitialState(), action) => {
    switch (action.type) {
        case begin`${FETCH_PAYMENT_FORM_INIT_DATA}`:
        case begin`${SAVE_INDIVIDUAL_PAYMENT_INFO}`:
        case begin`${SAVE_BUSINESS_PAYMENT_INFO}`:
        case begin`${FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA}`:
        case begin`${FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA}`:
        case begin`${FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA}`:
        case begin`${UPDATE_INDIVIDUAL_PAYMENT}`:
        case begin`${UPDATE_BUSINESS_PAYMENT}`:
        case begin`${UPDATE_OTHER_INDUSTRIES_PAYMENT}`:
        case begin`${FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE}`:
        case begin`${FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE}`:
            return {
                ...state,
                loading: true,
            };
        case fail`${FETCH_PAYMENT_FORM_INIT_DATA}`:
        case fail`${SAVE_INDIVIDUAL_PAYMENT_INFO}`:
        case fail`${SAVE_BUSINESS_PAYMENT_INFO}`:
        case fail`${FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA}`:
        case fail`${FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA}`:
        case fail`${FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA}`:
        case fail`${UPDATE_INDIVIDUAL_PAYMENT}`:
        case fail`${UPDATE_BUSINESS_PAYMENT}`:
        case fail`${UPDATE_OTHER_INDUSTRIES_PAYMENT}`:
        case fail`${FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE}`:
        case fail`${FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE}`:
            return {
                ...state,
                loading: false,
            };
        case success`${FETCH_PAYMENT_FORM_INIT_DATA}`:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case success`${SAVE_INDIVIDUAL_PAYMENT_INFO}`:
            return {
                ...state,
                loading: false,
            };
        case success`${SAVE_BUSINESS_PAYMENT_INFO}`:
            return {
                ...state,
                loading: false,
            };
        case success`${FETCH_EDIT_INDIVIDUAL_PAYMENT_INIT_DATA}`:
            return {
                data: {
                    ...action.payload.individual,
                },
                referrer: action.payload.referrer,
                terms: action.payload.terms,
                bankNameAndCodes: action.payload.bankNameAndCodes,
                memberInfo: action.payload.memberInfo,
                piiInputStatus: action.payload.piiInputStatus,
                loading: false,
            };
        case success`${FETCH_EDIT_BUSINESS_PAYMENT_INIT_DATA}`:
            return {
                data: {
                    ...action.payload.company,
                },
                referrer: action.payload.referrer,
                terms: action.payload.terms,
                bankNameAndCodes: action.payload.bankNameAndCodes,
                loading: false,
            };
        case success`${FETCH_EDIT_OTHER_INDUSTRIES_PAYMENT_INIT_DATA}`:
            return {
                data: {
                    ...action.payload.otherIndustries,
                },
                referrer: action.payload.referrer,
                memberInfo: action.payload.memberInfo,
                piiInputStatus: action.payload.piiInputStatus,
                terms: action.payload.terms,
                bankNameAndCodes: action.payload.bankNameAndCodes,
                loading: false,
            };
        case success`${FETCH_INDIVIDUAL_PAYMENT_FOR_BUSINESS_TYPE_CHANGE}`:
            return {
                data: {
                    ...action.payload.individual,
                    bankCode: '',
                    account: '',
                    accountOwner: '',
                    address: '',
                },
                memberInfo: action.payload.memberInfo,
                piiInputStatus: action.payload.piiInputStatus,
                terms: action.payload.terms,
                bankNameAndCodes: action.payload.bankNameAndCodes,
                loading: false,
            };
        case success`${FETCH_OTHER_INDUSTRIES_PAYMENT_FOR_BUSINESS_TYPE_CHANGE}`:
            return {
                data: {
                    ...action.payload.otherIndustries,
                    bankCode: '',
                    account: '',
                    accountOwner: '',
                    address: '',
                },
                memberInfo: action.payload.memberInfo,
                piiInputStatus: action.payload.piiInputStatus,
                terms: action.payload.terms,
                bankNameAndCodes: action.payload.bankNameAndCodes,
                loading: false,
            };
        case success`${UPDATE_INDIVIDUAL_PAYMENT}`:
        case success`${UPDATE_BUSINESS_PAYMENT}`:
        case success`${UPDATE_OTHER_INDUSTRIES_PAYMENT}`:
            return {
                ...state,
                loading: false,
            };
        default:
            break;
    }
    return state;
};
