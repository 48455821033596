import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Modal, Input, Button } from 'antd';

import { subIdAdded } from '../actions';
import Api from '../api';

export default Form.create()(
    injectIntl(
        connect(null, { subIdAdded })(
            class AddSubIdModal extends React.PureComponent {
                state = {
                    visible: false,
                    submitting: false,
                };
                open = () => {
                    this.props.form.resetFields();
                    this.setState({ visible: true });
                };
                onSubmit() {
                    const {
                        subIdAdded,
                        onSuccess,
                        intl,
                        form: { validateFields, setFields },
                    } = this.props;
                    validateFields((errors, values) => {
                        if (!errors) {
                            this.setState({ submitting: true });
                            Api.addSubId(values.subId)
                                .then((res) => {
                                    const { rCode, data } = res.data;
                                    if (rCode === '0') {
                                        subIdAdded(values.subId);
                                        this.setState({ visible: false });
                                        if (onSuccess) {
                                            onSuccess();
                                        }
                                    }
                                })
                                .catch((res) => {
                                    const { rCode, data } = res.data;
                                    if (rCode === '800') {
                                        setFields({
                                            subId: {
                                                errors: [new Error(intl.formatMessage({ id: 'SUB_ID_IS_EXISTED' }))],
                                            },
                                        });
                                    } else if (rCode === '801') {
                                        setFields({
                                            subId: {
                                                errors: [
                                                    new Error(
                                                        intl.formatMessage(
                                                            { id: 'SUB_ID_MAX_COUNT' },
                                                            { count: res.data.rMessage }
                                                        )
                                                    ),
                                                ],
                                            },
                                        });
                                    }
                                })
                                .finally(() => {
                                    this.setState({ submitting: false });
                                });
                        }
                    });
                }
                render() {
                    const {
                        children,
                        intl,
                        form: { getFieldDecorator },
                    } = this.props;
                    const { visible, submitting } = this.state;
                    return (
                        <React.Fragment>
                            <Modal
                                visible={visible}
                                closable={false}
                                wrapClassName="modal sub-id-delete-modal"
                                title={null}
                                footer={null}>
                                <div className="modal-title large-title">
                                    {intl.formatMessage({ id: 'SUB_ID_CREATE_MODAL_TITLE' })}
                                </div>
                                <div className="modal-body">
                                    <Form layout="vertical">
                                        <Form.Item label={intl.formatMessage({ id: 'SUB_ID' })}>
                                            {getFieldDecorator('subId', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({
                                                            id: 'VAL_MSG_THIS_FIELD_IS_REQUIRED',
                                                        }),
                                                    },
                                                    {
                                                        pattern: /^[0-9a-zA-Z]{8,20}$/,
                                                        message: intl.formatMessage({ id: 'SUB_ID_INVALID' }),
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    autoComplete="off"
                                                    size="large"
                                                    placeholder={intl.formatMessage({
                                                        id: 'SUB_ID_INPUT_PLACEHOLDER',
                                                    })}
                                                    maxLength={20}
                                                    minLength={8}
                                                />
                                            )}
                                        </Form.Item>
                                    </Form>
                                </div>
                                <div className="modal-footer">
                                    <Button size="large" onClick={() => this.setState({ visible: false })}>
                                        {intl.formatMessage({ id: 'CANCEL' })}
                                    </Button>
                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={submitting}
                                        onClick={() => this.onSubmit()}>
                                        {intl.formatMessage({ id: 'CONFIRM' })}
                                    </Button>
                                </div>
                            </Modal>
                            {children({ open: this.open })}
                        </React.Fragment>
                    );
                }
            }
        )
    )
);
