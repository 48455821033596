import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { Row, Col, Icon, Tooltip } from 'antd';
import MediaQuery from 'react-responsive';

import { CpRow, SearchBox, WorkspacePage, LastUpdatedDate } from '../../components';
import { SummaryCard, TrendChart, MonthlyAggregationPanel } from '../../report/components';

import { fetchDashboardInitData } from '../actions';
import * as productActionsExecutor from '../../productlink/productActionsExecutor';

import './Dashboard.css';
import { TrackerContext } from '../../common';

import PopupNotificationStack from '../components/PopupNotificationStack';
import FocusSlider from '../components/FocusSlider/FocusSlider';
import CommissionCapNotification from '../components/CommissionCapNotification/CommissionCapNotification';

const mapStateToProps = (state) => ({
    affiliateActivated: state.affiliate.activated && state.config.maintainingEnabled !== true,
    loading: state.dashboard.loading,
    categories: state.dashboard.categories,
    best11Products: state.dashboard.best11Products,
    dailyTrend: state.dashboard.dailyTrend,
    monthlyAggregation: state.dashboard.monthlyAggregation,
    popups: state.dashboard.popups,
    homeBanners: state.dashboard.homeBanners,
});

// TODO: Consider how to use one piece of data (or actions), product discovery would need categories & best 11 products as well
const mapDispatchToProps = {
    fetchDashboardInitData,
};

class Dashboard extends Component {
    static contextType = TrackerContext;

    constructor(props) {
        super(props);

        this.state = {
            bestProductsCategory: null,
        };
    }

    componentDidMount() {
        const { fetchDashboardInitData } = this.props;
        if (fetchDashboardInitData) {
            fetchDashboardInitData();
        }
    }

    onSearch = (category, term) => {
        this.context.trackEvent({ action: 'search', value: term });

        const { history } = this.props;
        history.push(`/affiliate/ws/link/${category || 0}/${term}`);
    };

    onExecuteProductAction = (action, product) => {
        const { history } = this.props;
        switch (action) {
            case 'generateLink':
                productActionsExecutor.generateLink('default', product, history);
                if (product.type && product.productId) {
                    this.context.trackEvent({ action: 'create_link', value: product.productId });
                }
                break;
            case 'viewDetail':
                productActionsExecutor.viewDetail(product);
                this.context.trackEvent({ action: 'view_product_detail', value: product.productId });
                break;
            case 'viewMoreBestProducts':
                productActionsExecutor.viewMoreBestProducts(this.state.bestProductsCategory, this.props.history);
                break;
            default:
                break;
        }
    };

    render() {
        const metrics = ['clickCount', 'orderCount', 'gmv', 'commission', 'conversion'];
        const {
            intl,
            affiliateActivated,
            loading,
            categories,
            // best11Products,
            dailyTrend = {},
            monthlyAggregation = {},
            homeBanners = [],
        } = this.props;
        const categoriesWithAll = [{ id: null, name: this.props.intl.formatMessage({ id: 'ALL' }) }, ...categories];

        return (
            <WorkspacePage loading={loading} className="dashboard">
                <CpRow>
                    <div className="main-area dashboard">
                        <CommissionCapNotification />
                        {affiliateActivated && (
                            <React.Fragment>
                                <div style={{ marginBottom: 20 }}>
                                    <Row>
                                        <Col span={24} className="align-center">
                                            <h1 className="search-title">
                                                {intl.formatMessage({ id: 'DASHBOARD_SEARCH_PRODUCTS' })}
                                            </h1>
                                            <div className="search-box-container">
                                                <MediaQuery minWidth={576}>
                                                    {(matches) => {
                                                        return (
                                                            <SearchBox
                                                                placeholder={
                                                                    matches
                                                                        ? intl.formatMessage({
                                                                              id: 'PRODUCT_LINK_SEARCH_PLACEHOLDER',
                                                                          })
                                                                        : ''
                                                                }
                                                                style={{ maxWidth: 640 }}
                                                                categories={categoriesWithAll}
                                                                onSearch={this.onSearch}
                                                            />
                                                        );
                                                    }}
                                                </MediaQuery>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                {homeBanners.length > 0 && (
                                    <div style={{ marginBottom: 60 }}>
                                        <FocusSlider items={homeBanners} />
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                        <Row>
                            <Col span={24} className="align-center" style={{ marginTop: 0, marginBottom: 60 }}>
                                <h1>{intl.formatMessage({ id: 'HEADER_REPORT_TREND' })}</h1>
                            </Col>
                        </Row>
                        <div style={{ position: 'relative' }}>
                            {monthlyAggregation && monthlyAggregation.lastUpdatedDate && (
                                <div className="monthly-last-update">
                                    <LastUpdatedDate date={monthlyAggregation.lastUpdatedDate} />
                                </div>
                            )}
                            <Row gutter={40}>
                                <Col lg={24} xl={16}>
                                    <h2>{intl.formatMessage({ id: 'DASHBOARD_DAILY_TREND' })}</h2>
                                    <div style={{ marginTop: 20 }}>
                                        <SummaryCard
                                            metrics={metrics}
                                            dataSource={dailyTrend}
                                            style={{ marginBottom: 36 }}
                                        />
                                        <div style={{ height: 450 }}>
                                            <TrendChart
                                                hideMetrics={true}
                                                metrics={metrics}
                                                dataSource={dailyTrend.units || []}
                                                type="daily"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={24} xl={8}>
                                    <Row>
                                        <Col span={24}>
                                            <h2 style={{ display: 'flex', alignItems: 'center' }}>
                                                {intl.formatMessage({ id: 'DASHBOARD_AGGREGATE_MONTH' })}
                                                <Tooltip
                                                    title="금일 날짜가 포함된 달의 실적 집계 입니다"
                                                    trigger="click">
                                                    <Icon
                                                        type="question-circle"
                                                        style={{ marginLeft: '0.5em', fontSize: '0.8em' }}
                                                    />
                                                </Tooltip>
                                            </h2>
                                        </Col>
                                    </Row>
                                    <MonthlyAggregationPanel
                                        rs={{ ViewFullReport: intl.formatMessage({ id: 'RP_VIEW_FULL_REPORT' }) }}
                                        metrics={metrics}
                                        dataSource={monthlyAggregation}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </CpRow>

                <PopupNotificationStack />
            </WorkspacePage>
        );
    }
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
