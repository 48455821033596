import React from 'react';
import { Input } from 'antd';

export default function AffiliateRejectReasonView({ reason }) {
    return reason && /^<\w+?/.test(reason) ? (
        <div className="ck-content clearfix" dangerouslySetInnerHTML={{ __html: reason }} />
    ) : (
        <Input.TextArea readOnly autoSize={{ minRows: 3 }} value={reason} style={{ background: 'transparent' }} />
    );
}
