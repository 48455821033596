import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Spin } from 'antd';
import ProductList from './ProductList';
import NoResult from './NoResult';
import { ProductShape } from './Product';

import './ProductSearchList.css';

// [Notice] Search API can't return pages after the 27th page, so we limit the max page here
const MaxTotalCount = 27 * 36;

export default function ProductSearchList({ 
    listWrapClassName,
    loading,
    header,
    items,
    page,
    pager,
    actions,
    onAction,
    rowItemsCount,
    total,
    onPaging,
    showRankNo }) {
        
    const limitTotal = total > MaxTotalCount ? MaxTotalCount : total;

    const handlePageChange = (page) => {
        onPaging(page);
    }

    return (
        <div className="product-search-list">
            {header && <div className="search-result-header">{header}</div>}

            <Spin spinning={loading === true ? true : false}>
                {
                    loading ? <div style={{ height: 80 }} />
                        : (items && items.length ?
                            <div className={listWrapClassName}>
                                <ProductList actions={actions} items={items} rowItemsCount={rowItemsCount} onAction={onAction} showRankNo={showRankNo} />
                            </div>
                            :
                            <NoResult style={{ minHeight: 120 }} />)
                }
            </Spin>

            {pager === false || (!items || !items.length) ? null :
                <div className="search-result-pager">
                    {limitTotal > 36 ? (
                        <Pagination
                            defaultCurrent={0}
                            current={page}
                            pageSize={36}
                            showLessItems={true}
                            total={limitTotal}
                            onChange={handlePageChange}
                        />
                    ) : null}
                </div>
            }
        </div>
    );
}

ProductSearchList.propTypes = {
    listWrapClassName: PropTypes.string,
    header: PropTypes.node,
    total: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.shape(ProductShape)),
    page: PropTypes.number,
    pager: PropTypes.bool,
    loading: PropTypes.bool,
    onPaging: PropTypes.func,
    onAction: PropTypes.func,
};