import React from 'react';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';
import { MetricList } from './Metric';


export default injectIntl(function ({ intl, value, onChange, included, disabledOptions = [] }) {
    const list = MetricList.filter(item => included.includes(item.id));
    return <Select size="small" dropdownMatchSelectWidth={false} className="light-select" value={value} onChange={onChange}>
        {
            list.map(item => <Select.Option key={item.id} value={item.id} disabled={disabledOptions.includes(item.id)}>{intl.formatMessage({ id: item.key })}</Select.Option>)
        }
    </Select>
});