import React, { useState, useEffect } from 'react';
import { Badge, Dropdown } from 'antd';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { useActions } from '../../common';
import { MessageList } from '../../components';
import { fetchTop5UnreadMessages, markMessageAsRead, markAllMessagesAsRead, fetchAllMessages } from '../../affiliate/actions';

import BellIcon from 'react-feather/dist/icons/bell';
import './Notification.less';


export default injectIntl(function Notification({ intl }) {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { isMobile } = useSelector(state => state.session);
    const { top5Messages, totalUnread, loadingTop5, allMessages } = useSelector(state => state.notifications);

    const store = useStore();
    const [
        fetchTop5UnreadMessagesAction,
        fetchAllMessagesAction,
        markMessageAsReadAction,
        markAllMessagesAsReadAction
    ] = useActions([fetchTop5UnreadMessages, fetchAllMessages, markMessageAsRead, markAllMessagesAsRead]);

    useEffect(() => {
        fetchTop5UnreadMessagesAction();
    }, []);

    function refresh() {
        fetchTop5UnreadMessagesAction();
        // Refetch all messages if page is opened
        if (Array.isArray(allMessages) && allMessages.length) {
            fetchAllMessagesAction(store);
        }
    }

    async function handleMarkAsRead({ receiverId }) {
        await markMessageAsReadAction(receiverId);
        refresh();
    }

    async function handleMarkAllAsRead() {
        await markAllMessagesAsReadAction();
        setDropdownVisible(false);
        refresh();
    }

    async function handleLinkClicked(e, receiverId, readFlag) {
        setDropdownVisible(false);
        if (!readFlag) {
            await markMessageAsReadAction(receiverId);
            refresh();
        }
    }

    return (
        <div className="notification-bell">
            <If condition={isMobile}>
                <Then>
                    <Badge count={totalUnread} style={{ backgroundColor: '#008C00' }}>
                        <Link style={{ color: '#3c3d3f' }} to="/affiliate/ws/notifications"><BellIcon size={isMobile ? 24 : 18} /></Link>
                    </Badge>
                </Then>
                <Else>
                    <Dropdown trigger={['click']}
                        onVisibleChange={visible => { setDropdownVisible(visible) }}
                        visible={dropdownVisible}
                        placement="bottomCenter"
                        overlayClassName="message-list-overlay"
                        getPopupContainer={(triggerNode) => document.getElementById('app-header')}
                        overlay={
                            <MessageList
                                layout='vertical'
                                limitHeight={true}
                                header={
                                    <header className="message-header">
                                        <div className="message-header-title">{intl.formatMessage({ id: 'NOTIFICATION_TITLE' })}</div>
                                        <a className="link-button" onClick={handleMarkAllAsRead} disabled={totalUnread === 0}>
                                            {intl.formatMessage({ id: 'NOTIFICATION_MARK_ALL_AS_READ' })}
                                        </a>
                                    </header>
                                }
                                footer={
                                    <footer className="message-footer">
                                        <Link className="link-button" to="/affiliate/ws/notifications" onClick={() => setDropdownVisible(false)}>
                                            {intl.formatMessage({ id: 'NOTIFICATION_VIEW_ALL' })}
                                        </Link>
                                    </footer>
                                }
                                loading={loadingTop5}
                                messages={top5Messages}
                                onMarkAsRead={handleMarkAsRead}
                                onLinkClicked={handleLinkClicked}
                            />
                        }>
                        <Badge count={totalUnread} style={{ backgroundColor: '#008C00' }}>
                            <BellIcon size={18} />
                        </Badge>
                    </Dropdown>
                </Else>
            </If>
        </div>
    );
});