import axios from 'axios';

export default class Api {
    static getLandingBanner() {
        return axios.get('/api/v1/configuration/content/LANDING_BANNER').then(res => {
            const { data } = res.data;
            if (data) {
                data.content = JSON.parse(data.content);
            }
            return res;
        });
    }

    static getHomeBanner(){
        return axios.get('/api/v1/home-banners');
    }
}