export default [
    { key: 'home', route: '/affiliate/ws', titleKey: 'HEADER_HOME', condition: (affiliate) => affiliate },
    {
        key: 'productlink',
        route: '',
        titleKey: 'HEADER_PRODUCT_LINK',
        children: [
            {
                key: 'link',
                route: '/affiliate/ws/link',
                titleKey: 'HEADER_PL_LINK',
            },
            {
                key: 'link-to-any-page',
                route: '/affiliate/ws/link-to-any-page',
                titleKey: 'LINK_TO_ANY_PAGE',
            },
            {
                key: 'dynamic-widgets',
                route: '/affiliate/ws/dynamic-widgets',
                titleKey: 'HEADER_PL_DYNAMIC_BANNER',
            },
            {
                key: 'banner',
                route: '/affiliate/ws/banner',
                titleKey: 'HEADER_PL_BANNER',
                condition: (affiliate, config) => {
                    return !!config.widgetBannerEnabled;
                },
            },
            // {
            //     key: 'video-banners',
            //     route: '/affiliate/ws/video-banners',
            //     titleKey: 'VIDEO_BANNER',
            // },
            {
                key: 'search-bar',
                route: '/affiliate/ws/search-bar',
                titleKey: 'HEADER_PL_SEARCH_BAR',
            },
            {
                key: 'events',
                route: '/affiliate/ws/events',
                titleKey: 'HEADER_PL_EVENT_LIST',
            },
            {
                key: 'managedynamicwidget',
                route: '/affiliate/ws/dynamic-widget',
                visible: false,
                parentKey: 'dynamic-widgets',
                condition: (affiliate, config) => {
                    return !!config.widgetEnabled;
                },
            },
            {
                key: 'bestproducts',
                route: '/affiliate/ws/bestproducts',
                visible: false,
                parentKey: 'link',
            },
            {
                key: 'performantproducts',
                route: '/affiliate/ws/best',
                visible: false,
                parentKey: 'link',
            },
        ],
        condition: (affiliate, config) => {
            return affiliate && affiliate.activated !== false && config.maintainingEnabled !== true;
        },
    },
    {
        key: 'tools',
        route: '',
        titleKey: 'HEADER_TOOLS',
        children: [
            {
                key: 'open-api',
                route: '/affiliate/ws/tools/open-api',
                titleKey: 'HEADER_TOOLS_OPEN_API',
                condition: (affiliate, config) => {
                    return (
                        affiliate &&
                        affiliate.activated &&
                        // affiliate.regFinalStatus === RegistrationStatus.Approved &&
                        config.openApiEnabled
                    );
                },
            },
            // {
            //     key: 'chrome-ext',
            //     route: '/affiliate/ws/tools/chrome-ext',
            //     titleKey: 'HEADER_TOOLS_CHROME_EXTENSION',
            // },
        ],
        condition: (affiliate) => affiliate && affiliate.activated,
    },
    {
        key: 'report',
        route: '',
        titleKey: 'HEADER_REPORT',
        children: [
            {
                key: 'performance',
                route: '',
                titleKey: 'HEADER_PERFORMANCE_REPORT',
                children: [
                    {
                        key: 'trend',
                        route: '/affiliate/ws/report/trend',
                        titleKey: 'HEADER_REPORT_TREND',
                    },
                    {
                        key: 'dynamicWidget',
                        route: '/affiliate/ws/report/dynamic-widget',
                        titleKey: 'HEADER_REPORT_DYNAMIC_WIDGET',
                    },
                    {
                        key: 'performance-insight',
                        route: '/affiliate/ws/report/performance-insight',
                        titleKey: 'HEADER_REPORT_PERFORMANCE_INSIGHT',
                    },
                ],
            },

            {
                key: 'earnings',
                route: '',
                titleKey: 'HEADER_EARNING_REPORT',
                children: [
                    {
                        key: 'earning',
                        route: '/affiliate/ws/report/earning',
                        titleKey: 'HEADER_REPORT_EARNING',
                    },
                    {
                        key: 'settlement',
                        route: '/affiliate/ws/report/settlement',
                        titleKey: 'HEADER_REPORT_SETTLEMENT',
                    },
                    {
                        key: 'payout',
                        route: '/affiliate/ws/report/payout',
                        titleKey: 'HEADER_REPORT_PAYOUT',
                    },
                ]
            },
            {
                key: 'notifications',
                route: '/affiliate/ws/notifications',
                visible: false,
                parentKey: 'report',
            },
            {
                key: 'download-tasks',
                route: '/affiliate/ws/report/download-tasks',
                titleKey: 'HEADER_REPORT_DOWNLOAD_REPORT',
            },
        ],
        condition: (affiliate) => affiliate,
    },
    {
        key: 'tp',
        loginRequired: false,
        route: '',
        titleKey: 'HEADER_TP',
        children: [
            {
                key: 'terms',
                route: '/help/terms',
                titleKey: 'HEADER_HELP_TERMS',
            },
            {
                key: 'operating-policy',
                route: '/help/operating-policy',
                titleKey: 'HEADER_HELP_OPERATING_POLICY',
            },
            {
                key: 'privacy-policy',
                route: 'https://privacy.coupang.com/ko/center/coupang/',
                titleKey: 'HEADER_HELP_PRIVACY_POLICY',
            },
        ],
    },
    {
        key: 'help',
        loginRequired: false,
        route: '',
        titleKey: 'HEADER_HELP',
        children: [
            //    { key: 'privacy-policy', route: '/help/privacy-policy' },
            {
                key: 'usage-guide',
                titleKey: 'HEADER_HELP_USAGE_GUIDE',
                route: '',
                children: [
                    {
                        key: 'partners-guide',
                        titleKey: 'HEADER_HELP_PARTNERS_GUIDE',
                        route: '/help/partners-guide',
                    },
                    {
                        key: 'tag-usage-guide',
                        titleKey: 'HEADER_HELP_USAGE_GUIDE_TAG',
                        route: '/help/tag-usage-guide',
                    },
                    {
                        key: 'open-api-help',
                        titleKey: 'HEADER_HELP_OPEN_API',
                        route: '/help/open-api',
                    },
                ],
            },
            {
                key: 'voc',
                route: '',
                titleKey: 'HEADER_HELP_VOC',
                children: [
                    {
                        key: 'voc_create',
                        route: '/help/voc/create',
                        titleKey: 'HEADER_HELP_VOC_CREATE',
                    },
                    {
                        key: 'voc_history',
                        route: '/help/voc/history',
                        titleKey: 'HEADER_HELP_VOC_HISTORY',
                    },
                ],
                condition: (affiliate) => affiliate,
            },
            { key: 'faq', route: '/help/faq', titleKey: 'HEADER_HELP_FAQ' },
        ],
    },
    {
        key: 'announcements',
        loginRequired: false,
        route: '/announcements',
        titleKey: 'HEADER_ANNOUNCEMENTS',
    },
];

// export const UserMenuItems = [
//     {
//         key: 'user-edit-profile', route: '', titleKey: 'HEADER_EDIT_PROFILE',
//     },
//     {
//         key: 'user-enter-payment', route: '', titleKey: 'HEADER_ENTER_PAYMENT',
//     },
//     {
//         key: 'user-edit-payment', route: '', titleKey: 'HEADER_EDIT_PAYMENT',
//     },

// ];

// export const UserMenuLogoutItem = { key: 'logout', route: '', titleKey: 'LOGOUT' };

// export const UserExtraMenuItems = [
//     {
//         key: 'user-unsubscribe'
//     }
// ];
