import React from 'react';

export default function({payStatus,payStatusString}){
    let className = null;
    switch(payStatus){
        case 'CARRY_OVER':
            className='color-danger';
            break;
        case 'PAID':
            className='color-accent';
            break;
        default: 
            break;
    }

    return <span className={className}>{payStatusString}</span>
}