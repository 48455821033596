import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input } from 'antd';
import { injectIntl } from 'react-intl';
import { requiredMessage } from '../../common';

class CompanyInformationFieldset extends Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        validateBRN: PropTypes.func,
        disabled: PropTypes.bool,
        data: PropTypes.shape({
            companyOwner: PropTypes.string,
            companyName: PropTypes.string,
            address: PropTypes.string,
            businessType: PropTypes.string,
            subBusinessType: PropTypes.string,
        }),
    };

    render() {
        const {
            intl,
            form: { getFieldDecorator },
            data = {},
            disabled,
            modificationTips,
        } = this.props;

        return (
            <fieldset>
                <legend>{intl.formatMessage({ id: 'PAYMENT_SECTION_COMPANY_INFORMATION' })}</legend>
                {modificationTips && (
                    <Row>
                        <Col span={24}>{modificationTips}</Col>
                    </Row>
                )}
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item label={intl.formatMessage({ id: 'PAYMENT_COMPANY_REPRESENTATIVE_NAME' })}>
                            {getFieldDecorator('companyOwner', {
                                initialValue: data.companyOwner,
                                rules: [
                                    {
                                        required: true,
                                        message: requiredMessage(intl, 'PAYMENT_COMPANY_REPRESENTATIVE_NAME'),
                                    },
                                ],
                            })(<Input maxLength={50} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={intl.formatMessage({ id: 'PAYMENT_COMPANY_NAME' })}>
                            {getFieldDecorator('companyName', {
                                initialValue: data.companyName,
                                rules: [
                                    {
                                        required: true,
                                        message: requiredMessage(intl, 'PAYMENT_COMPANY_NAME'),
                                    },
                                ],
                            })(<Input maxLength={100} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={intl.formatMessage({ id: 'PAYMENT_ADDRESS' })}>
                    {getFieldDecorator('address', {
                        initialValue: data.address,
                        rules: [
                            {
                                required: true,
                                message: requiredMessage(intl, 'PAYMENT_ADDRESS'),
                            },
                        ],
                    })(<Input placeholder={intl.formatMessage({ id: 'PAYMENT_ADDRESS_TIP' })} maxLength={300} disabled={disabled} />)}
                </Form.Item>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item label={intl.formatMessage({ id: 'PAYMENT_COMPANY_BUSINESS_TYPE' })}>
                            {getFieldDecorator('businessType', {
                                initialValue: data.businessType,
                                rules: [
                                    {
                                        required: true,
                                        message: requiredMessage(intl, 'PAYMENT_COMPANY_BUSINESS_TYPE'),
                                    },
                                ],
                            })(<Input maxLength={50} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={intl.formatMessage({ id: 'PAYMENT_COMPANY_SUB_BUSINESS_TYPE' })}>
                            {getFieldDecorator('subBusinessType', {
                                initialValue: data.subBusinessType,
                                rules: [
                                    {
                                        required: true,
                                        message: requiredMessage(intl, 'PAYMENT_COMPANY_SUB_BUSINESS_TYPE'),
                                    },
                                ],
                            })(<Input maxLength={50} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
            </fieldset>
        );
    }
}

export default injectIntl(CompanyInformationFieldset);
