import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import HomeIcon from 'react-feather/dist/icons/home';
import { Tabs } from 'antd';

import { useFetch } from '../../common';
import { WorkspacePage, CpRow, LastUpdatedDate, DimensionList } from '../../components';
import Api, { buildDataTableFilterFromReportCondition } from '../api';
import { ReportDataTable, PerformanceInsightRankChart, SummaryCard } from '../components';

export default connect((state) => ({ ...state.report.dateRange, ...state.subId }))(
    injectIntl(function ({ intl, startDate, endDate, currentSubIds }) {
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_PERFORMANCE_REPORT' }) },
            { title: intl.formatMessage({ id: 'HEADER_REPORT_PERFORMANCE_INSIGHT' }) },
        ];
        const extraFilters = useMemo(
            () => buildDataTableFilterFromReportCondition({ startDate, endDate, currentSubIds }),
            [startDate, endDate, currentSubIds]
        );

        const [activeKey, setActiveKey] = useState('product');

        const onTabChange = (key) => {
            setActiveKey(key);
        };

        const PerformanceInsightSummary = useCallback(() => {
            const [summary, setSummary] = useState({});
            const { res, doFetch } = useFetch(
                () => Api.fetchPerformanceInsightSummary({ startDate, endDate, currentSubIds }),
                { autoLoad: false }
            );
            useEffect(() => {
                doFetch();
            }, [startDate, endDate, currentSubIds]);

            useEffect(() => {
                if (res) {
                    const { data } = res.data;
                    setSummary(data);
                }
            }, [res]);

            return (
                <React.Fragment>
                    <LastUpdatedDate date={summary.lastUpdatedDate} />
                    <div style={{ marginTop: 20 }}>
                        <SummaryCard
                            metrics={['clickCount', 'orderCount', 'gmv', 'commission', 'conversion']}
                            dataSource={summary}
                            style={{ marginBottom: 36 }}
                        />
                    </div>
                </React.Fragment>
            );
        }, [startDate, endDate, currentSubIds]);

        return (
            <WorkspacePage
                breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'HEADER_REPORT_PERFORMANCE_INSIGHT' })}
                withDateRangedReportPicker={true}
                withSubId={{ isMultiple: true }}
                loading={false}>
                <CpRow>
                    <PerformanceInsightSummary />
                    <Tabs activeKey={activeKey} onChange={onTabChange}>
                        <Tabs.TabPane
                            key="product"
                            tab={intl.formatMessage({
                                id: DimensionList.find((z) => z.id === 'product').key,
                            })}></Tabs.TabPane>
                        <Tabs.TabPane
                            key="linkType"
                            tab={intl.formatMessage({
                                id: DimensionList.find((z) => z.id === 'linkType').key,
                            })}></Tabs.TabPane>
                        <Tabs.TabPane
                            key="traceId"
                            tab={intl.formatMessage({
                                id: DimensionList.find((z) => z.id === 'traceId').key,
                            })}></Tabs.TabPane>
                        <Tabs.TabPane
                            key="subId"
                            tab={intl.formatMessage({
                                id: DimensionList.find((z) => z.id === 'subId').key,
                            })}></Tabs.TabPane>
                    </Tabs>
                    {activeKey === 'product' && (
                        <ReportDataTable
                            reportName="performance"
                            rowKey="productId"
                            primaryDimension="product"
                            metrics={['quantity', 'gmv', 'commission']}
                            extraFilters={extraFilters}
                        />
                    )}
                    {activeKey === 'linkType' && (
                        <PerformanceInsightRankChart
                            style={{ height: 450 }}
                            primaryDimension="linkType"
                            metrics={['clickCount', 'orderCount', 'gmv', 'commission']}
                            extraFilters={extraFilters}
                        />
                    )}
                    {activeKey === 'traceId' && (
                        <ReportDataTable
                            reportName="performance"
                            rowKey="traceId"
                            primaryDimension="traceId"
                            hidePrimaryDimensionColumn={true}
                            secondaryDimensions={['subId', 'linkType', 'linkModule', 'platform', 'pageType', 'pageKey']}
                            metrics={['clickCount', 'orderCount', 'gmv', 'commission']}
                            extraFilters={extraFilters}
                        />
                    )}
                    {activeKey === 'subId' && (
                        <PerformanceInsightRankChart
                            style={{ height: 450 }}
                            primaryDimension="subId"
                            metrics={['clickCount', 'orderCount', 'gmv', 'commission', 'conversion']}
                            extraFilters={extraFilters}
                        />
                    )}
                </CpRow>
            </WorkspacePage>
        );
    })
);
