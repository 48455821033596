import React, { Component } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import FilterInput from '../FilterInput/FilterInput';
import './BusinessRegisterNumber.css';

export default class ResidentialRegisterNumber extends Component {
    static propTypes = {
        value: PropTypes.shape({
            brn: PropTypes.string,
            valid: PropTypes.bool
        }),
        onChange: PropTypes.func,
        onVerify: PropTypes.func,
        disabled: PropTypes.bool
    };

    constructor(props) {
        super(props);
        const { value } = this.props;
        this.state = {
            dirty: false,
            ...this.getStateByValue(value)
        };
    }

    getStateByValue(value) {
        const brn = value ? (value.brn || '') : '';
        let { part1 = '', part2 = '', part3 = '' } = {};

        if (value && brn) {
            value.raw = brn.replace(/-/g, '');
            value.dirty = false;
        }

        if (~brn.indexOf('-')) {
            [part1, part2, part3] = brn.split('-');
        } else {
            [part1, part2, part3] = [brn.substr(0, 3), brn.substr(3, 2), brn.substr(5, 5)];
        }

        return {
            part1,
            part2,
            part3,
            valid: value ? value.valid : false
        };
    }

    componentWillReceiveProps(nextProps) {
        if ('value' in nextProps) {
            const { value } = nextProps;
            const state = this.getStateByValue(value);
            this.setState(state);
        }
    }

    triggerChange = (part1, part2, part3, valid, dirty, message) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange({ brn: `${part1}-${part2}-${part3}`, raw: part1 + part2 + part3, valid, dirty, message });
        }
    }

    onPart1Change = val => {
        this.setState({
            part1: val,
            valid: false,
            dirty: true
        });
        this.triggerChange(val, this.state.part2, this.state.part3, false, true);
    }

    onPart2Change = val => {
        this.setState({
            part2: val,
            valid: false,
            dirty: true
        });
        this.triggerChange(this.state.part1, val, this.state.part3, false, true);
    }

    onPart3Change = val => {
        this.setState({
            part3: val,
            valid: false,
            dirty: true
        });
        this.triggerChange(this.state.part1, this.state.part2, val, false, true);
    }

    onPart1Keyup = (e) => {
        const caretPos = this.input1.selectionStart;
        if (caretPos === 3 && e.target.value.length === 3) {
            this.input2.focus();
        }
    }

    onPart2Keyup = (e) => {
        const caretPos = this.input2.selectionStart;
        const { keyCode } = e;
        if (caretPos === 2 && e.target.value.length === 2) {
            this.input3.focus();
        } else if (caretPos === 0 && keyCode === 37) {
            this.input1.focus();
        }
    }

    onPart3Keyup = (e) => {
        const caretPos = this.input3.selectionStart;
        const { keyCode } = e;
        if (caretPos === 0 && keyCode === 37) {
            this.input2.focus();
        }
    }

    onVerify = (e) => {
        const { onVerify } = this.props;
        if (onVerify) {
            const { part1, part2, part3 } = this.state;
            onVerify(`${part1}${part2}${part3}`).then(() => {
                this.setState({
                    valid: true,
                    dirty: false
                });
                this.triggerChange(part1, part2, part3, true, false);
            }).catch(msg => {
                this.setState({
                    valid: false,
                    dirty: false
                });
                this.triggerChange(part1, part2, part3, false, false, msg);
            });
        }
    }

    render() {
        const { rs, disabled } = this.props;

        return (
            <div className="brn">

                <FilterInput filter={/^[\d*]*$/} ref={r => this.input1 = r} autoComplete="off" maxLength={3} value={this.state.part1} disabled={disabled}
                    onChange={this.onPart1Change}
                    onKeyUp={this.onPart1Keyup}
                />

                <span>-</span>

                <FilterInput filter={/^[\d*]*$/} ref={r => this.input2 = r} autoComplete="off" maxLength={2} value={this.state.part2} disabled={disabled}
                    onChange={this.onPart2Change}
                    onKeyUp={this.onPart2Keyup}
                    style={{ width: 96 }}
                />

                <span>-</span>

                <FilterInput filter={/^[\d*]*$/} ref={r => this.input3 = r} autoComplete="off" maxLength={5} value={this.state.part3} disabled={disabled}
                    onChange={this.onPart3Change}
                    onKeyUp={this.onPart3Keyup}
                />

                <Button style={{marginLeft: 8}} className="btn-rounded" type="primary" onClick={this.onVerify} disabled={!this.state.dirty || disabled}>{rs && rs.Verify ? rs.Verify : 'Verify'}</Button>
            </div>
        );
    }
}