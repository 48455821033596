import {
    FETCH_SUB_IDS,
    ADD_SUB_ID,
    DELETE_SUB_ID,
    SWITCH_SUB_ID,
    PICK_SUB_IDS,
    SUB_ID_ADDED,
    SUB_ID_DELETED,
} from './actions';
import {
    FETCH_TOP5_UNREAD_MESSAGES,
    FETCH_ALL_MESSAGES,
    MARK_ALL_MESSAGES_AS_READ,
    MARK_MESSAGE_AS_READ,
    SET_PAGE_FOR_ALL_MESSAGES,
    SET_FILTER_FOR_ALL_MESSAGES,
    CLEAR_PAGE_FILTER_FOR_ALL_MESSAGES,
} from './actionTypes';
import { begin, success, fail } from '../common';

const getInitialState = () => (window.cpapp.affiliate ? { ...window.cpapp.affiliate } : null);

const reducer = (state = getInitialState(), action) => {
    let newState = {};
    switch (action.type) {
        case 'UPDATE_AFFILIATE': {
            if (!action.payload) {
                return null;
            }
            newState = {
                ...action.payload,
            };
            break;
        }
        default:
            break;
    }

    if (newState.regFinalStatus) {
        return {
            ...newState,
        };
    }
    return state;
};

export default reducer;

const deleteStateSubId = (state, action) => {
    let _list = state.list.filter((item) => item !== action.payload);
    let _currentSubId = state.currentSubId;
    let temp = sessionStorage.getItem('currentSubId');
    if (temp) {
        if (temp === action.payload) {
            sessionStorage.removeItem('currentSubId');
            _currentSubId = undefined;
        }
    }
    return { list: _list, currentSubId: _currentSubId };
};

export function subIdReducer(
    state = { list: [], currentSubId: sessionStorage.getItem('currentSubId'), currentSubIds: [] },
    action
) {
    switch (action.type) {
        case begin`${FETCH_SUB_IDS}`:
            return { ...state, loading: true };
        case fail`${FETCH_SUB_IDS}`:
            return { ...state, loading: false };
        case success`${FETCH_SUB_IDS}`:
            return { ...state, loading: false, list: action.payload || [] };

        case begin`${ADD_SUB_ID}`:
            return { ...state, adding: true };
        case fail`${ADD_SUB_ID}`:
            return { ...state, adding: false };
        case success`${ADD_SUB_ID}`:
            return { ...state, adding: false, list: [...state.list, action.payload] };

        case begin`${DELETE_SUB_ID}`:
            return { ...state, deleting: true };
        case fail`${DELETE_SUB_ID}`:
            return { ...state, deleting: false };
        case success`${DELETE_SUB_ID}`:
            return { ...state, deleting: false, ...deleteStateSubId(state, action) };

        case SUB_ID_ADDED:
            return { ...state, list: [...state.list, action.payload] };
        case SUB_ID_DELETED:
            return { ...state, ...deleteStateSubId(state, action) };

        case SWITCH_SUB_ID:
            if (action.payload) {
                sessionStorage.setItem('currentSubId', action.payload);
            } else {
                sessionStorage.removeItem('currentSubId');
            }
            return { ...state, currentSubId: action.payload };
        case PICK_SUB_IDS:
            return { ...state, currentSubIds: action.payload };
        default:
            break;
    }
    return state;
}

const NOTIFICATIONS_INITIAL_STATE = {
    top5Messages: [],
    totalUnread: 0,
    messages: [],
    totalMessages: 0,
    page: 0,
    filters: [],
    loadingTop5: false,
    loadingAll: false,
};

export function notificationReducer(state = NOTIFICATIONS_INITIAL_STATE, action) {
    switch (action.type) {
        case begin`${FETCH_TOP5_UNREAD_MESSAGES}`:
            return { ...state, loadingTop5: true };
        case fail`${FETCH_TOP5_UNREAD_MESSAGES}`:
            return { ...state, loadingTop5: false };
        case success`${FETCH_TOP5_UNREAD_MESSAGES}`:
            const {
                messages,
                count: { unreadCount },
            } = action.payload || {};
            return { ...state, loadingTop5: false, top5Messages: messages, totalUnread: unreadCount };
        case begin`${FETCH_ALL_MESSAGES}`:
            return { ...state, loadingAll: true };
        case fail`${FETCH_ALL_MESSAGES}`:
            return { ...state, loadingAll: false };
        case success`${FETCH_ALL_MESSAGES}`:
            const { content: allMessages, total: totalMessages } = action.payload || {};
            return { ...state, loadingAll: false, allMessages, totalMessages };
        case begin`${MARK_MESSAGE_AS_READ}`:
            return { ...state, loadingTop5: true, loadingAll: true };
        case fail`${MARK_MESSAGE_AS_READ}`:
        case success`${MARK_MESSAGE_AS_READ}`:
            return { ...state, loadingTop5: false, loadingAll: false };
        case begin`${MARK_ALL_MESSAGES_AS_READ}`:
            return { ...state, loadingTop5: true, loadingAll: true };
        case fail`${MARK_ALL_MESSAGES_AS_READ}`:
        case success`${MARK_ALL_MESSAGES_AS_READ}`:
            return { ...state, loadingTop5: false, loadingAll: false };
        case SET_PAGE_FOR_ALL_MESSAGES:
            return { ...state, page: action.payload };
        case SET_FILTER_FOR_ALL_MESSAGES:
            return { ...state, filters: action.payload, page: 0 };
        case CLEAR_PAGE_FILTER_FOR_ALL_MESSAGES:
            return { ...state, filters: [], page: 0, allMessages: [] };
        default:
            break;
    }
    return state;
}
