import React from 'react';
// import Animate from 'rc-animate';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, Col, Icon } from 'antd';
import './FormItem.css';
export default class FormItem extends Form.Item {
    static propTypes = {
        hasFeedback: PropTypes.bool,
        colon: PropTypes.bool,
        helpInComponnet: PropTypes.bool
    }

    static defaultProps = {
        hasFeedback: true,
        prefixCls: 'ant-form',
        colon: true,
    };

    renderLabel(prefixCls) {
        const { label, labelCol, colon, id } = this.props;
        const context = this.context;
        const required = this.isRequired();
        const labelColClassName = classNames(
            `${prefixCls}-item-label`,
            labelCol && labelCol.className,
        );
        const labelClassName = classNames({
            [`${prefixCls}-item-required`]: required,
            'cp-required-indicator': true
        });
        let labelChildren = label;
        // Keep label is original where there should have no colon
        const haveColon = colon && !context.vertical;
        // Remove duplicated user input colon
        if (haveColon && typeof label === 'string' && label.trim() !== '') {
            labelChildren = label.replace(/[：|:]\s*$/, '');
        }
        return label ? (
            <Col {...labelCol} className={labelColClassName} key="label">
                <label
                    htmlFor={id || this.getId()}
                    className={labelClassName}
                    title={typeof label === 'string' ? label : ''}
                    onClick={this.onLabelClick}
                >
                    {labelChildren}
                </label>
                {required ? <span className="cp-required-indicator"></span> : null}
            </Col>
        ) : null;
    }

    renderValidateWrapper(prefixCls, children, help, extra) {
        const { validateStatus: vs, hasFeedback, helpInComponent } = this.props;
        const onlyControl = this.getOnlyControl;
        const validateStatus = (vs === undefined && onlyControl) ?
            this.getValidateStatus() :
            vs;
        let classes = `${prefixCls}-item-control`;
        if (validateStatus) {
            classes = classNames(`${prefixCls}-item-control`, {
                'help-in-component': helpInComponent,
                'has-feedback': hasFeedback === undefined || hasFeedback || validateStatus === 'validating',
                // 'has-success': validateStatus === 'success',
                // 'has-warning': validateStatus === 'warning',
                'has-error': validateStatus === 'error',
                'is-validating': validateStatus === 'validating',
            });
        }
        let iconType = '';
        switch (validateStatus) {
            case 'warning':
            case 'error':
                iconType = 'exclamation-circle';
                break;
            default:
                iconType = '';
                break;
        }
        const icon = (hasFeedback && iconType) ?
            <span className={`${prefixCls}-item-children-icon`}>
                <Icon className={classNames("feedback-icon", validateStatus)} type={iconType} theme={iconType === 'loading' ? 'outlined' : 'filled'} />
            </span> : null;

        if (helpInComponent) {
            return (
                <div className={classes}>
                    <span className={`${prefixCls}-item-children`}>{React.cloneElement(children, { formItemHelp: <div className="custom-help-line">{help}{icon}</div> })}</span>
                    {extra}
                </div>
            );
        } else {
            return (
                <div className={classes}>
                    <span className={`${prefixCls}-item-children`}>{children}</span>
                    {help}{icon}
                    {extra}
                </div>
            );
        }
    }
    // renderChildren() {
    //     const { children } = this.props;
    //     return [
    //         this.renderLabel(),
    //         this.renderWrapper(
    //             this.renderValidateWrapper(
    //                 children,
    //                 this.renderHelp(),
    //                 this.renderExtra(),
    //             ),
    //         ),
    //     ];
    // }
}