import React, { Component } from 'react';
import { Button, Input, Row, Col, Form, notification } from 'antd';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HomeIcon from 'react-feather/dist/icons/home';
import LinkIcon from 'react-feather/dist/icons/external-link';
import { Switch, Case, When } from 'react-if';
import { TrackerContext } from '../../common';
import { WorkspacePage, CpRow, Copy2Clipboard } from '../../components';
import Image1 from '../images/open-api-image-1@2x.png';
import Image2 from '../images/open-api-image-2@2x.png';

import Api from '../api';
import { Consts } from '../../common';
import './OpenApi.css';

const { RegistrationStatus } = Consts;

class OpenApi extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hmacStatus: props.hmacStatus,
            hmacKeys: props.hmacKeys || {
                accessKey: null,
                secretKey: null,
            },
        };
    }

    handleGenerate = () => {
        this.setState({ loading: true }, () => {
            this.context.trackEvent({ action: 'apply_open_api_credentials' });
            Api.applyApiKeys()
                .then((res) => {
                    const { hmacStatus, hmacKeys } = res.data.data;
                    this.setState({ hmacStatus, hmacKeys });
                })
                .catch((err) => {
                    if (err && err.data) {
                        const { rCode, rMessage } = err.data;
                        const { intl } = this.props;
                        let msg = rMessage;
                        if (rCode === '801') {
                            msg = intl.formatMessage({ id: 'MSG_OPEN_API_KEYS_APPLIED_FAILED' });
                        }
                        notification.warn({ message: intl.formatMessage({ id: 'NOTICE' }), description: msg });
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    };

    componentDidMount() {
        this.setState({ loading: true }, () => {
            Api.getOpenApiKeys()
                .then((res) => {
                    const { hmacStatus, hmacKeys = {} } = res.data.data;
                    this.setState({ hmacStatus, hmacKeys });
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        });
    }

    showActiveFragment = () => {
        const { intl } = this.props;
        const { hmacKeys } = this.state;
        return (
            <React.Fragment>
                <h2>{intl.formatMessage({ id: 'OPEN_API_ACTIVE_TITLE' })}</h2>
                <p
                    className="ft-body"
                    dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'OPEN_API_ACTIVE_MSG' }) }}></p>
                <Form>
                    <Form.Item label={'Access Key'} colon={false}>
                        {
                            <Input
                                className="isolate-button"
                                size="large"
                                disabled
                                placeholder={null}
                                value={hmacKeys.accessKey}
                                suffix={
                                    <Copy2Clipboard content={hmacKeys.accessKey} popOverTimeout={1000}>
                                        <Button className="lg">{intl.formatMessage({ id: 'COPY' })}</Button>
                                    </Copy2Clipboard>
                                }
                            />
                        }
                    </Form.Item>
                    <Form.Item label="Secret Key" colon={false}>
                        <Input
                            className="isolate-button"
                            size="large"
                            disabled
                            placeholder={null}
                            value={hmacKeys.secretKey}
                            suffix={
                                <Copy2Clipboard content={hmacKeys.secretKey} popOverTimeout={1000}>
                                    <Button className="lg">{intl.formatMessage({ id: 'COPY' })}</Button>
                                </Copy2Clipboard>
                            }
                        />
                    </Form.Item>
                </Form>
            </React.Fragment>
        );
    };

    showMessageFragment = (titleId, messageId, showGenButton) => {
        const { intl, maintainingEnabled, affiliate } = this.props;
        const disableGenerate =
            maintainingEnabled || !affiliate || affiliate.regFinalStatus !== RegistrationStatus.Approved;
        return (
            <React.Fragment>
                <h2>{intl.formatMessage({ id: titleId })}</h2>
                <p className="ft-14 fw-300 mb40">{intl.formatMessage({ id: messageId })}</p>
                <When condition={showGenButton}>
                    <div className="align-center">
                        <Button type="primary" className="lg" disabled={disableGenerate} onClick={this.handleGenerate}>
                            {intl.formatMessage({ id: 'OPEN_API_BTN_GENERATE' })}
                        </Button>
                    </div>
                </When>
            </React.Fragment>
        );
    };

    render() {
        const { intl } = this.props;
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'HEADER_TOOLS_OPEN_API' }) },
        ];

        const { loading, hmacStatus } = this.state;

        return (
            <WorkspacePage
                className="open-api"
                breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'HEADER_TOOLS_OPEN_API' })}
                subTitle={''}
                loading={loading}>
                <CpRow>
                    <Row>
                        <Col span={24} className="align-center">
                            <div className="align-left open-api-banner">
                                <Row type="flex" align="middle">
                                    <Col xs={24} sm={24} md={24} lg={24} xl={16} className="keys-description">
                                        <p>{intl.formatMessage({ id: 'OPEN_API_BANNER_TIP' })}</p>
                                        <p className="color-accent">
                                            {intl.formatMessage({ id: 'OPEN_API_BANNER_HELP_LINK' })}
                                            <Link to="/help/open-api">
                                                <LinkIcon className="va-tb ml05em" color="#808286" size={18} />
                                            </Link>
                                        </p>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                        <Row gutter={32} style={{ padding: 32 }}>
                                            <Col span={12} className="keys-image align-right">
                                                <img src={Image1} alt="" style={{ marginRight: 32 }} />
                                            </Col>
                                            <Col span={12} className="keys-image align-left">
                                                <img src={Image2} alt="" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </CpRow>
                <CpRow className="code-generation-container is-flex bg-grey">
                    <div className="pane">
                        <Switch>
                            <Case condition={hmacStatus === 'Active'}>{this.showActiveFragment()}</Case>
                            <Case condition={hmacStatus === 'Inactive'}>
                                {this.showMessageFragment('OPEN_API_INACTIVE_TITLE', 'OPEN_API_INACTIVE_MSG', true)}
                            </Case>
                            <Case condition={hmacStatus === 'Blocked'}>
                                {this.showMessageFragment('OPEN_API_BLOCKED_TITLE', 'OPEN_API_BLOCKED_MSG', false)}
                            </Case>
                            <Case condition={hmacStatus === 'Deleted'}>
                                {this.showMessageFragment('OPEN_API_DELETED_TITLE', 'OPEN_API_DELETED_MSG', false)}
                            </Case>
                        </Switch>
                    </div>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(
    connect((state) => ({ maintainingEnabled: state.config.maintainingEnabled, affiliate: state.affiliate }))(OpenApi)
);
