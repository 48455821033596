import axios from 'axios';

export default class Api {
    static fetchPaymentFormInitData() {
        return axios.get('/api/v1/payment');
    }

    static saveIndividualPayment(payment) {
        return axios.post('/api/v1/payment/individual', payment);
    }

    static saveBusinessPayment(payment) {
        return axios.post('/api/v1/payment/company', payment);
    }

    static fetchEditIndividualPaymentInitData() {
        return axios.get('/api/v1/payment/individual');
    }

    static fetchEditBusinessPaymentInitData() {
        return axios.get('/api/v1/payment/company');
    }

    static fetchEditOtherIndustiresPaymentInitData() {
        return axios.get('/api/v1/payment/otherIndustries');
    }

    static updateIndividualPayment(payment) {
        return axios.put('/api/v1/payment/individual', payment);
    }

    static updateBusinessPayment(payment) {
        return axios.put('/api/v1/payment/company', payment);
    }

    static updateOtherIndustriesPayment(payment) {
        const { brnFileList, bankbookFileList, allConsented, ...rest } = payment;

        let fd = new FormData();
        fd.append('PAYMENT', JSON.stringify(rest));

        if (brnFileList) {
            const brnImage = brnFileList[0].originFileObj;
            if (brnImage) {
                fd.append('BRN_IMAGE', brnImage);
            }
        }
        if (bankbookFileList) {
            const bankImage = bankbookFileList[0].originFileObj;
            if (bankImage) {
                fd.append('BANK_IMAGE', bankImage);
            }
        }

        return axios.post(`/api/v1/payment/otherIndustries`, fd, {
            headers: {
                'Content-Type': undefined,
            },
        });
    }

    static validateBankAccount({ useMemberName, account, bankCode }) {
        return axios.post('/api/v1/verification/bankaccount', { useMemberName, account, bankCode });
    }

    static changeOtherIndustriesToIndividual(payment) {
        return axios.post('/api/v1/affiliateTypeChange/toIndividual', payment);
    }

    static changeIndividualToOtherIndustries(payment, brnImage, bankImage) {
        let fd = new FormData();
        fd.append('PAYMENT', JSON.stringify(payment));

        // when reject and modifying, file could be null if user not select new file
        if (brnImage) {
            fd.append('BRN_IMAGE', brnImage);
        }
        if (bankImage) {
            fd.append('BANK_IMAGE', bankImage);
        }

        return axios.post(`/api/v1/affiliateTypeChange/toOtherIndustries`, fd, {
            headers: {
                'Content-Type': undefined,
            },
        });
    }
}
