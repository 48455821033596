import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Row, Col, Button, Checkbox } from 'antd';
import { useMediaQuery } from 'react-responsive';

import HomeIcon from 'react-feather/dist/icons/home';
import LinkIcon from 'react-feather/dist/icons/link';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import ChevronsDownIcon from 'react-feather/dist/icons/chevrons-down';

import { CpRow, WorkspacePage, Steps, SearchBox, Copy2Clipboard } from '../../components';
import { ProductSearchList, TopProducts } from '../components';
import * as productActionsExecutor from '../productActionsExecutor';
import SnsSharer from '../../components/SnsSharer/SnsSharer';
import { TrackerContext, useAction } from '../../common';
import { searchProduct } from '../actions';
import TopProductsConfig from '../TopProductsConfig';

import productlink_caution from '../images/productlink-caution@2x.jpg';
import productlink_caution_mobile from '../images/productlink-caution-mobile@2x.jpg';

import './ProductSearch.css';

function Slogan({ searchTerm }) {
    const intl = useIntl();

    return searchTerm ? (
        <div className="ft-sub-headline-bold link-tip">
            <FormattedMessage
                id="PRODUCT_LINK_STEP2_SLOGAN"
                values={{
                    createLinkButton: (
                        <span>
                            <br />
                            <Button className="md btn-sample-create-link">
                                <LinkIcon size={16} /> {intl.formatMessage({ id: 'PRODUCT_LINK_GENERATION' })}
                            </Button>
                        </span>
                    ),
                }}
            />
            <div className="ft-body">{intl.formatMessage({ id: 'PRODUCT_LINK_STEP2_SUB_SLOGAN' })}</div>
        </div>
    ) : (
        <div className="ft-sub-headline-bold">
            <span>{intl.formatMessage({ id: 'PRODUCT_LINK_STEP1_SLOGAN' })}</span>
        </div>
    );
}

function SearchResultHeader({ total, onDeliveryFilterChange }) {
    const intl = useIntl();
    const deliveryTypeImages = useSelector((state) => state.config.deliveryTypeImages);
    const [filterByRocket, setFilterByRocket] = useState(false);
    const [filterByCoupangGlobal, setFilterByCoupangGlobal] = useState(false);
    const [filterByRocketFresh, setFilterByRocketFresh] = useState(false);

    const handleOnFilterByRocket = (e) => {
        setFilterByRocket(e.target.checked);
        triggerOnChange(e.target.checked, filterByRocketFresh, filterByCoupangGlobal);
    };

    const handleOnFilterByCoupangGlobal = (e) => {
        setFilterByCoupangGlobal(e.target.checked);
        triggerOnChange(filterByRocket, filterByRocketFresh, e.target.checked);
    };

    const handleOnFilterByRocketFresh = (e) => {
        setFilterByRocketFresh(e.target.checked);
        triggerOnChange(filterByRocketFresh, e.target.checked, filterByCoupangGlobal);
    };

    const triggerOnChange = (filterByRocket, filterByRocketFresh, filterByCoupangGlobal) => {
        const deliveryTypes = [];
        if (filterByRocket) {
            deliveryTypes.push('ROCKET');
        }
        if (filterByRocketFresh) {
            deliveryTypes.push('ROCKET_FRESH');
        }
        if (filterByCoupangGlobal) {
            deliveryTypes.push('COUPANG_GLOBAL');
        }
        onDeliveryFilterChange && onDeliveryFilterChange(deliveryTypes);
    };

    return (
        <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
                <span className="search-result-total">
                    <strong>{total}</strong> {intl.formatMessage({ id: 'SEARCH_RESULTS' })}
                </span>
                <span className="dim">{intl.formatMessage({ id: 'COUPANG_RANKING' })}</span>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} className="delivery-filter">
                <Checkbox className="sm mr1em" checked={filterByRocket} onChange={handleOnFilterByRocket}>
                    <img src={deliveryTypeImages.ROCKET} alt="rocket" />
                </Checkbox>
                <Checkbox className="sm mr1em" checked={filterByRocketFresh} onChange={handleOnFilterByRocketFresh}>
                    <img src={deliveryTypeImages.ROCKET_FRESH} alt="rocket fresh" />
                </Checkbox>
                <Checkbox className="sm" checked={filterByCoupangGlobal} onChange={handleOnFilterByCoupangGlobal}>
                    <img src={deliveryTypeImages.COUPANG_GLOBAL} alt="coupang global" />
                </Checkbox>
            </Col>
        </Row>
    );
}

export default function ProductSearch() {
    const intl = useIntl();
    const history = useHistory();
    const { trackEvent } = useContext(TrackerContext);
    const categories = useSelector((state) => state.config.categories);
    const categoriesWithAll = [{ id: null, name: intl.formatMessage({ id: 'ALL' }) }, ...categories];

    const { term: urlTerm, category: urlCategory } = useParams();
    const [searchTerm, setSearchTerm] = useState(urlTerm);
    const [searchCategory, setSearchCategory] = useState(parseInt(urlCategory, 10) || null);
    const [deliveryTypeFitler, setDeliveryTypeFilter] = useState([]);
    const [page, setPage] = useState(0);
    const [viewMoreDimension, setViewMoreDimension] = useState(false);

    const products = useSelector((state) => state.productlink.products) || { total: 0, items: [] };
    const { sharing } = products || {};
    const loading = useSelector((state) => state.productlink.loading);

    const searchProductAction = useAction(searchProduct);

    useEffect(() => {
        if (searchTerm) {
            searchProductAction({
                category: searchCategory,
                term: searchTerm,
                deliveryTypes: deliveryTypeFitler,
                page,
            });
        }
        // eslint-disable-next-line
    }, [searchTerm, searchCategory, deliveryTypeFitler, page]);

    useEffect(() => {
        if (urlTerm !== searchTerm) {
            setSearchTerm(urlTerm || '');
        }

        const categoryFromUrl = parseInt(urlCategory, 10) || null;
        if (categoryFromUrl !== searchCategory) {
            setSearchCategory(categoryFromUrl);
        }
    }, [urlCategory, urlTerm]);

    const handleOnSearch = (category, term) => {
        trackEvent({ action: 'search', value: term });

        setSearchCategory(category);
        setSearchTerm(term);
        setPage(0);

        history.replace(`/affiliate/ws/link/${category || 0}/${term}`);
    };

    const handleDeliveryFilterChange = (deliveryTypes) => {
        setDeliveryTypeFilter(deliveryTypes);
    };

    const handleOnPaging = (page) => {
        setPage(page - 1);
    };

    const handleViewMoreDimension = () => {
        setViewMoreDimension(true);
    };

    const onExecuteProductAction = (action, product) => {
        switch (action) {
            case 'generateLink':
                productActionsExecutor.generateLink(undefined, product, history);
                trackEvent({ action: 'create_link', value: product.productId });
                break;
            case 'viewDetail':
                productActionsExecutor.viewDetail(product);
                trackEvent({ action: 'view_product_detail', value: product.productId });
                break;
            default:
                break;
        }
    };

    const breadcrumb = [
        { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
        { title: intl.formatMessage({ id: 'PRODUCT_LINK' }) },
    ];
    const steps = [
        intl.formatMessage({ id: 'PRODUCT_LINK_STEP1' }),
        intl.formatMessage({ id: 'PRODUCT_LINK_STEP2' }),
        intl.formatMessage({ id: 'PRODUCT_LINK_STEP3' }),
    ];

    const isDeviceLessThan991 = useMediaQuery({ maxWidth: 991 });
    const isDeviceGreaterThan576 = useMediaQuery({ minWidth: 576 });

    const TopProductsMemo = useMemo(() => {
        return (
            <div className="performant-products-section">
                {TopProductsConfig.filter((x) => !x.lazy).map((x) => (
                    <TopProducts
                        key={x.group}
                        group={x.group}
                        title={intl.formatMessage({ id: x.title })}
                        viewMoreLink={`/affiliate/ws/best/${x.group}`}
                        fetchApi={x.fetchApi}
                    />
                ))}
                {!viewMoreDimension && (
                    <Row className="mt20" type="flex" align="middle" justify="center">
                        <span className="clickable ft-16 color-primary" onClick={handleViewMoreDimension}>
                            {intl.formatMessage({ id: 'VIEW_MORE_DIMENSION' })}
                        </span>
                        <ChevronsDownIcon className="clickable" color="#346aff" onClick={handleViewMoreDimension} />
                    </Row>
                )}
                {viewMoreDimension &&
                    TopProductsConfig.filter((x) => x.lazy).map((x) => (
                        <TopProducts
                            key={x.group}
                            group={x.group}
                            title={intl.formatMessage({ id: x.title })}
                            viewMoreLink={`/affiliate/ws/best/${x.group}`}
                            fetchApi={x.fetchApi}
                        />
                    ))}
            </div>
        );
    }, [viewMoreDimension]);

    return (
        <WorkspacePage
            className="product-discovery-search"
            breadcrumb={breadcrumb}
            title={intl.formatMessage({ id: 'PRODUCT_LINK' })}
            subTitle={''}
            loading={loading}>
            <CpRow className="sections">
                <section className="search-bar-banner align-center">
                    <Slogan searchTerm={searchTerm} />
                    <div className="link-generation-steps-indicator">
                        <Steps size="small" current={searchTerm ? 1 : 0} steps={steps} />
                    </div>
                </section>

                {!searchTerm && (
                    <section className="link-caution">
                        <img src={isDeviceLessThan991 ? productlink_caution_mobile : productlink_caution} alt="" />
                    </section>
                )}

                <section className="search-bar align-center">
                    <SearchBox
                        placeholder={
                            isDeviceGreaterThan576 ? intl.formatMessage({ id: 'PRODUCT_LINK_SEARCH_PLACEHOLDER' }) : ''
                        }
                        style={{ maxWidth: 640 }}
                        categories={categoriesWithAll}
                        category={searchCategory}
                        term={searchTerm}
                        onSearch={handleOnSearch}
                    />
                    {searchTerm && products.items && products.items.length > 0 && (
                        <Row type="flex" align="middle" justify="center" className="mt20">
                            <Col span={24} className="align-center">
                                <FormattedMessage
                                    id="PRODUCT_SEARCH_SHARE_SEARCH_RESULTS"
                                    values={{ keyword: searchTerm }}
                                />
                                <span className="srp-buttons ml1em">
                                    <Button
                                        shape="circle"
                                        href={`https://www.coupang.com/np/search?q=${encodeURIComponent(searchTerm)}`}
                                        target="_blank">
                                        <ExternalLinkIcon size={16} />
                                    </Button>
                                    <Copy2Clipboard content={products && products.shortUrl}>
                                        <Button shape="circle">
                                            <LinkIcon size={16} />
                                        </Button>
                                    </Copy2Clipboard>
                                    <SnsSharer
                                        className="ml1em"
                                        title={sharing.title}
                                        description={sharing.description}
                                        imageUrl={sharing.imageUrl}
                                        link={products.shortUrl}
                                    />
                                </span>
                            </Col>
                        </Row>
                    )}
                </section>

                {searchTerm && (
                    <section className="section-product-list">
                        <ProductSearchList
                            header={
                                <SearchResultHeader
                                    total={products.total}
                                    onDeliveryFilterChange={handleDeliveryFilterChange}
                                />
                            }
                            total={products.total}
                            items={products.items}
                            page={page + 1}
                            loading={loading}
                            onPaging={handleOnPaging}
                            onAction={onExecuteProductAction}
                        />
                    </section>
                )}

                {!searchTerm && TopProductsMemo}
            </CpRow>
        </WorkspacePage>
    );
}
