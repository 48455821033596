
function tagged(strings, keys, postfix) {
    let result = strings[0];
    for (let i = 0; i < keys.length; i++) {
        result += keys[i] + strings[i + 1];
    }
    return result + postfix;
}

export function begin(strings, ...keys) {
   return tagged(strings, keys, '_BEGIN');
}

export function success(strings, ...keys) {
    return tagged(strings, keys, '_SUCCESS');
}

export function fail(strings, ...keys) {
    return tagged(strings, keys, '_FAILED');
}
