import React from 'react'
import MetricLabel from './MetricLabel';
import MetricValue from './MetricValue';

export default function ({ customLabel, metric, value }) {
    return (
        <div className="metric-box">
            <MetricLabel customLabel={customLabel} metric={metric} />
            <MetricValue metric={metric} value={value} />
        </div>
    );
}