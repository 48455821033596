const MetricList = [
    { id: 'impressionCount', key: 'RP_IMPRESSION_COUNT', options: { style: 'decimal' } },
    { id: 'clickCount', key: 'RP_CLICK_COUNT', options: { style: 'decimal' } },
    { id: 'orderCount', key: 'RP_ORDER_COUNT', options: { style: 'decimal' } },
    { id: 'orderPaymentAmt', key: 'RP_ORDER_PAYMENT_AMT', options: { style: 'currency', currency: 'KRW' } },
    { id: 'cancelCount', key: 'RP_CANCEL_COUNT', options: { style: 'decimal' } },
    { id: 'cancelAmt', key: 'RP_CANCEL_AMT', options: { style: 'currency', currency: 'KRW' } },

    { id: 'referralCount', key: 'RP_REFERRAL_COUNT', options: { style: 'decimal' } },
    { id: 'gmv', key: 'RP_GMV', options: { style: 'currency', currency: 'KRW' } },
    { id: 'commission', key: 'RP_COMMISSION', options: { style: 'currency', currency: 'KRW' }, displaySetting: {} },
    { id: 'finalCommission', key: 'RP_FINAL_COMMISSION', options: { style: 'currency', currency: 'KRW' }, displaySetting: {} },
    { id: 'ctr', key: 'RP_CTR', options: { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 } },
    {
        id: 'conversion',
        key: 'RP_CONVERSION',
        options: { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 },
    },
    { id: 'eCPM', key: 'RP_E_CPM', options: { style: 'decimal' } },
    { id: 'quantity', key: 'RP_QUANTITY', options: { style: 'decimal' } },
    { id: 'actualPaymentAmt', key: 'RP_ACTUAL_PAYMENT_AMT', options: { style: 'currency', currency: 'KRW' } },

    { id: 'accumulatedPayoutAmt', key: 'RP_ACCUMULATED_PAYOUT_AMT', options: { style: 'currency', currency: 'KRW' } },
    { id: 'commissionCap', key: 'RP_COMMISSION_CAP', options: { style: 'currency', currency: 'KRW' } },
    { id: 'manualBonus', key: 'RP_MANUAL_BONUS', options: { style: 'currency', currency: 'KRW' } },
];

const Metric = {
    formatChartValue(value, metric, intl) {
        if (metric.options.style === 'currency') {
            return intl.formatNumber(value, { style: 'decimal' });
        }
        return intl.formatNumber(value, metric.options);
    },
    makeChartTooltipFormatter(metrics, intl) {
        return (params) => {
            let s = [];
            s.push(params[0].name);
            params.forEach((item) => {
                s.push(
                    `${item.marker}${item.seriesName}: ${Metric.formatChartValue(
                        item.value || null,
                        metrics[item.seriesIndex],
                        intl
                    )}`
                );
            });
            return s.join('<br />');
        };
    },
};

export { MetricList, Metric };
