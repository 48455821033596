import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';

class UnderMaintainance extends PureComponent {
    render() {
        const { config: { maintainingMessage }, style } = this.props;
        
        return (
            maintainingMessage ?
                <Alert style={style}
                    message="공지 사항"
                    description={
                        <div dangerouslySetInnerHTML={{ __html: maintainingMessage }}></div>
                    }
                    type="warning"
                /> :
                null
        );
    }
}

export default connect(state => ({ config: state.config }))(UnderMaintainance)
