import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Spin, Icon, Button } from 'antd';
import ProductList from './ProductList';
import { useFetch, TrackerContext } from '../../common';
import * as productActionsExecutor from '../productActionsExecutor';
import TopProductsConfig from '../TopProductsConfig';
import { Copy2Clipboard } from '../../components';

import LinkIcon from 'react-feather/dist/icons/link';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';

import './TopProducts.less';

export default function TopProducts({ group, title, fetchApi, viewMoreLink }) {
    const intl = useIntl();
    const history = useHistory();
    const { trackEvent } = useContext(TrackerContext);

    const [products, setProducts] = useState([]);
    const [shortUrl, setShortUrl] = useState('');
    const { res, error, fetching } = useFetch(() => fetchApi(6));
    const TopProductConfig = TopProductsConfig.find((x) => x.group === group);

    useEffect(() => {
        if (res && res.data && res.data.data) {
            const { products = [], shortUrl } = res.data.data;
            setProducts(products);
            setShortUrl(shortUrl);
        }

        if (error) {
            console.error(error);
        }
    }, [res, error]);

    const handleExecuteProductAction = (action, product) => {
        switch (action) {
            case 'generateLink':
                productActionsExecutor.generateLink(group, product, history);
                trackEvent({ action: 'create_link', value: product.productId });
                break;
            case 'viewDetail':
                productActionsExecutor.viewDetail(product);
                trackEvent({ action: 'view_product_detail', value: product.productId });
                break;
            default:
                break;
        }
    };

    return (
        <div className="top-products">
            <Spin spinning={fetching}>
                <div className="header">
                    <h2>
                        {title}
                        {TopProductConfig && TopProductConfig.coupangLink && (
                            <span className="button-bar ml1em mr1em">
                                <Button
                                    className="icon-btn"
                                    shape="circle"
                                    href={TopProductConfig.coupangLink}
                                    target="blank">
                                    <ExternalLinkIcon size={16} />
                                </Button>
                                <Copy2Clipboard content={shortUrl}>
                                    <Button className="icon-btn" shape="circle">
                                        <LinkIcon size={16} />
                                    </Button>
                                </Copy2Clipboard>
                            </span>
                        )}
                    </h2>
                    <Link className="view-more" to={viewMoreLink}>
                        [{intl.formatMessage({ id: 'VIEW_MORE' })}]
                    </Link>
                </div>
                {error ? (
                    <div className="is-flex-center">
                        <Icon
                            type="exclamation-circle"
                            className="mr05em"
                            style={{ fontSize: '1.5em', color: '#f56112' }}
                        />
                        {(error.data && error.data.rMessage) || intl.formatMessage({ id: 'MSG_FAILED_TO_LOAD_DATA' })}
                    </div>
                ) : (
                    <ProductList items={products} onAction={handleExecuteProductAction} />
                )}
            </Spin>
        </div>
    );
}

TopProducts.propTypes = {
    title: PropTypes.string.isRequired,
    fetchApi: PropTypes.func,
    viewMoreLink: PropTypes.string,
};
