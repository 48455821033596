import React, { useState, useEffect, useContext } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { Modal, Icon, Input, Row, Col, Empty, notification } from 'antd';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import CheckIcon from 'react-feather/dist/icons/check-circle';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import { useFetch, TrackerContext } from '../../common';
import { SearchBox } from '../../components';
import ProductSearchList from './ProductSearchList';
import DeliveryTypesFilter from './DeliveryTypesFilter';
import * as productActionExecutor from '../productActionsExecutor';
import Api from '../api';

import './CustomDataset.less';

const MAX_DATASET_PRODUCTS_AMOUNT = 20;
const isTouchDevice = 'ontouchstart' in document;

function DatasetProducts({ products, onRemove }) {
    const hasProduct = Array.isArray(products) && products.length > 0;
    return (
        <React.Fragment>
            <div className={cx('dataset-product-list', { 'flex-center': !hasProduct })}>
                {hasProduct ? (
                    (products || []).map((x, index) => (
                        <div
                            className={cx('dataset-product', { mobile: isTouchDevice })}
                            key={x.productId + '-' + x.vendorItemId + '-' + index}>
                            <img src={x.image} alt="" />
                            <span className="remove-btn" onClick={() => onRemove && onRemove(x, index)}>
                                <Icon
                                    type="close-circle"
                                    theme="twoTone"
                                    twoToneColor="#8c50a3"
                                    style={{ fontSize: 20 }}
                                />
                            </span>
                        </div>
                    ))
                ) : (
                    <Empty />
                )}
            </div>
            {hasProduct && (
                <div className="align-center ft-12 mt05em">{`${products.length} / ${MAX_DATASET_PRODUCTS_AMOUNT}`}</div>
            )}
        </React.Fragment>
    );
}

export default function CustomDataset({ onOk, datasetId, visible, ...others }) {
    const intl = useIntl();
    const categories = useSelector((state) => state.config.categories);
    const deliveryTypeImages = useSelector((state) => state.config.deliveryTypeImages);
    const { trackEvent } = useContext(TrackerContext);
    const allCategories = [{ id: null, name: intl.formatMessage({ id: 'ALL' }) }, ...categories];
    const [datasetName, setDatasetName] = useState('');
    const [category, setCategory] = useState(null);
    const [term, setTerm] = useState('');
    const [deliveryTypesFilter, setDeliveryTypesFilter] = useState(null);
    const [page, setPage] = useState(1);
    const [searching, setSearching] = useState(false);
    const [saving, setSaving] = useState(false);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const { res, error, doFetch: fetchDataset } = useFetch(() => {
        if (datasetId && visible) {
            return Api.getDataset(datasetId);
        }
        return Promise.resolve();
    });

    useEffect(() => {
        fetchDataset();
    }, [visible]);

    useEffect(() => {
        if (res) {
            const {
                data: { datasetName, items },
            } = res.data;
            setDatasetName(datasetName);
            setSelectedProducts(items);
        }
    }, [res, error]);

    function handleDeliveryTypesFilterChange(filters) {
        setDeliveryTypesFilter(filters);
    }

    function handleSearch(category, term) {
        setCategory(category);
        setTerm(term);

        doSearch(category, term, page);
    }

    function doSearch(category, term, page) {
        setSearching(true);
        Api.searchProducts({
            categoryId: category,
            filter: term,
            deliveryTypes: deliveryTypesFilter,
            page: { pageNumber: page - 1, size: 12 },
        })
            .then((res) => {
                setSearchedProducts(res.data.data);
            })
            .catch((err) => {
                if (err.data && err.data.rMessage) {
                    notification.warn({ message: intl.formatMessage({ id: 'ERROR' }), description: err.data.rMessage });
                } else {
                    console.error(err);
                }
            })
            .finally(() => {
                setSearching(false);
            });
    }

    function handlePaging(page) {
        setPage(page);
        doSearch(category, term, page);
    }

    function handleProductAction(action, product) {
        const { productId, itemId: productItemId, vendorItemId: productVendorItemId, image } = product;
        if (action === 'select') {
            if (selectedProducts && selectedProducts.length >= MAX_DATASET_PRODUCTS_AMOUNT) {
                return;
            }
            setSelectedProducts([...selectedProducts, { productId, productItemId, productVendorItemId, image }]);
        }
        if (action === 'goto_sdp') {
            productActionExecutor.viewDetail(product);
            trackEvent({ action: 'view_product_detail', value: product.productId });
        }
    }

    function handleAfterClose() {
        setDatasetName('');
        setTerm('');
        setSearchedProducts([]);
        setSelectedProducts([]);
    }

    function handleRemove({ productId, productItemId, productVendorItemId }, index) {
        // const index = (selectedProducts || []).findIndex(x => x.productId === productId && x.productItemId === productItemId && x.productVendorItemId === productVendorItemId);
        // if (index > -1) {
        selectedProducts.splice(index, 1);
        setSelectedProducts([...selectedProducts]);
        // }
    }

    function handleOk() {
        const items = selectedProducts.map((x) => ({
            productId: x.productId,
            productItemId: x.productItemId,
            productVendorItemId: x.productVendorItemId,
            image: /\/(image\/.+)/.exec(x.image)[1],
            link: x.link,
        }));

        const promise = onOk && onOk({ datasetId, datasetName: datasetName.trim(), items });

        setSaving(true);
        Promise.all([promise]).finally(() => {
            setSaving(false);
        });
    }

    const isMdScreen = useMediaQuery({ minWidth: 992 });
    const isSmScreen = useMediaQuery({ maxWidth: 768 });

    return (
        <Modal
            title={intl.formatMessage({ id: 'WIDGET_INST_DATASET_MANAGEMENT' })}
            wrapClassName="custom-dataset"
            className="affiliate-modal"
            width={isMdScreen ? 960 : ''}
            maskClosable={false}
            destroyOnClose={true}
            afterClose={handleAfterClose}
            okButtonProps={{
                loading: searching || saving,
                disabled: !datasetName || !datasetName.trim() || !selectedProducts || !selectedProducts.length,
            }}
            onOk={handleOk}
            visible={visible}
            {...others}>
            <Row gutter={40} type={!isSmScreen ? 'flex' : ''}>
                <Col md={24} lg={12} className={cx({ 'right-border': isMdScreen })}>
                    <div className="search-section align-center">
                        <SearchBox
                            placeholder={intl.formatMessage({ id: 'PRODUCT_LINK_SEARCH_PLACEHOLDER' })}
                            style={{ maxWidth: 580 }}
                            categories={allCategories}
                            category={category}
                            term={term}
                            onSearch={handleSearch}
                        />
                        {deliveryTypeImages && (
                            <DeliveryTypesFilter
                                deliveryTypeImages={deliveryTypeImages}
                                onChange={handleDeliveryTypesFilterChange}
                            />
                        )}
                    </div>
                    {term && (
                        <ProductSearchList
                            listWrapClassName="search-result-scroll-container"
                            actions={[
                                {
                                    name: 'select',
                                    title: intl.formatMessage({ id: 'SELECT' }),
                                    disabled: selectedProducts.length >= MAX_DATASET_PRODUCTS_AMOUNT,
                                    icon: <CheckIcon size={16} />,
                                },
                                {
                                    name: 'goto_sdp',
                                    title: intl.formatMessage({ id: 'PRODUCT_LINK_GOTO' }),
                                    icon: <ExternalLinkIcon size={16} />,
                                },
                            ]}
                            total={searchedProducts.total}
                            items={searchedProducts.products}
                            page={page}
                            pager={true}
                            loading={searching}
                            onPaging={handlePaging}
                            onAction={handleProductAction}
                            rowItemsCount={isSmScreen ? 2 : 3}
                        />
                    )}
                </Col>
                <Col md={24} lg={12}>
                    <fieldset className={cx({ mt40: !isMdScreen })}>
                        <Row className="mb20" type="flex" align="middle">
                            <Col lg={6} md={4} sm={24} xs={24}>
                                {intl.formatMessage({ id: 'WIDGET_INST_DATASET_NAME' })}:
                            </Col>
                            <Col lg={18} md={20} sm={24} xs={24}>
                                <Input
                                    size="large"
                                    maxLength={50}
                                    value={datasetName}
                                    onChange={(e) => setDatasetName(e.target.value)}
                                    style={{ maxWidth: 450 }}
                                />
                            </Col>
                        </Row>
                        <DatasetProducts products={selectedProducts} onRemove={handleRemove} />
                    </fieldset>
                </Col>
            </Row>
        </Modal>
    );
}
