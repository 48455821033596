import React from 'react';
// import ReactECharts from 'echarts-for-react';    // import all
import * as echarts from 'echarts/core';
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { Empty } from 'antd';

import {BarChart, LineChart} from 'echarts/charts';

import {LegendComponent, TooltipComponent, DataZoomComponent, GridComponent} from 'echarts/components';
import {
    CanvasRenderer,
    // SVGRenderer,
  } from 'echarts/renderers';

echarts.use([BarChart,LineChart, LegendComponent, TooltipComponent, DataZoomComponent, GridComponent, CanvasRenderer]);

export default function ({ theme = 'affiliate', hasData, option, ...rest }) {
    return hasData ? <ReactEchartsCore echarts={echarts} option={option} theme={theme} style={{ height: '100%' }} {...rest} /> : <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>;
}