import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import MediaQuery from 'react-responsive';

import { Select } from 'antd';
import BestProductCarousel from './BestProductCarousel';
import BestProductList from './BestProductList';
import categoriesBlackList from '../BestProductsCategoryBlackList';

import './Best11Products.css';

class Best11Products extends Component {
    static propTypes = {
        category: PropTypes.number,
        categories: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            })
        ),
        products: PropTypes.arrayOf(PropTypes.object),
        onCategoryChange: PropTypes.func,
        onExecuteProductAction: PropTypes.func,
    };

    render() {
        const { intl, categories = [], category, products, onCategoryChange, onExecuteProductAction } = this.props;

        const filteredCategories = categories.filter(x => !categoriesBlackList.has(x.id));

        return (
            <section className="best-11-products">
                <div className="header">
                    <h2>
                        {intl.formatMessage({
                            id: 'PRODUCT_LINK_BEST_PRODUCTS',
                        })}
                    </h2>
                    <Select
                        className="best-products-categories"
                        style={{ width: 164 }}
                        value={category}
                        onChange={onCategoryChange}>
                        {filteredCategories.map(x => (
                            <Select.Option key={x.id} value={x.id}>
                                {x.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <MediaQuery minWidth={1440}>
                    {a => {
                        if (a) {
                            return (
                                <MediaQuery minWidth={768}>
                                    {b => {
                                        return (
                                            <BestProductCarousel
                                                items={products}
                                                pageSize={a ? 6 : b ? 4 : 2}
                                                onAction={onExecuteProductAction}
                                            />
                                        );
                                    }}
                                </MediaQuery>
                            );
                        } else {
                            return <BestProductList items={products} onAction={onExecuteProductAction} />;
                        }
                    }}
                </MediaQuery>
            </section>
        );
    }
}

export default withRouter(injectIntl(Best11Products))
