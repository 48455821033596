export const Entrance = {
    Signup: 'signup',
    Login: 'login',
};

export const RegistrationStatus = {
    Registering: 'REGISTERING',
    Completed: 'COMPLETED',
    ProfileApproved: 'PROFILE_APPROVED',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    AutoApproved: 'AUTO_APPROVED',
};

export const AffiliateTypes = {
    Individual: 'INDIVIDUAL',
    OtherIndustries: 'OTHER_INDUSTRIES',
    AdvertisingAgency: 'ADVERTISING_AGENCY',
    Company: 'COMPANY',
};

export const AffiliateGroup = {
    Influencer: 'INFLUENCER'
};

export const PiiInputStatus = {
    Disabled: 'DISABLED',
    Opened: 'OPENED',
    Completed: 'COMPLETED',
};

export const ActivateStatus = {
    Activated: 'ACTIVATED',
    Deactivated: 'DEACTIVATED',
    Blocked: 'BLOCKED',
};

export const AsyncJobStatus = {
    Init: 'INIT',
    Running: 'RUNNING',
    Succeed: 'SUCCEED',
    Failed: 'FAILED',
};

export const AsyncJobTypes = {
    AffiliateDynamicWidgetDailyTrend: 'AFFILIATE_DYNAMIC_BANNER_DAILY_TREND',
    AffiliateDailyTrend: 'AFFILIATE_DAILY_TREND',
    AffiliateList: 'ADMIN_AFFILIATE_LIST',
    AffiliatePerformance: 'ADMIN_AFFILIATE_PERFORMANCE',
    MonthlySettlement: 'ADMIN_MONTHLY_SETTLEMENT',
    MonthlyPayout: 'ADMIN_MONTHLY_PAYOUT',
    MonthlySettlementDetails: 'ADMIN_MONTHLY_SETTLEMENT_DETAILS',
};

// export const LegacyRegistrationStatus = {
//     Unkonwn: 'UNKNOWN',
//     ProfileCompleted: 'PROFILE_COMPLETED',
//     PaymentCompleted: 'PAYMENT_COMPLETED',
//     Approved: 'APPROVED', //profile approved
//     Rejected: 'REJECTED', //profile rejected
// }

// export const LegacyPaymentStatus = {
//     Unknown: 'UNKNOWN',
//     PaymentCompleted: 'PAYMENT_COMPLETED',
//     PaymentWaiting: 'PAYMENT_WAITING',
//     PaymentApproved: 'PAYMENT_APPROVED',
//     PaymentRejected: 'PAYMENT_REJECTED'
// }
