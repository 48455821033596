import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import cx from 'classnames';
import RightIcon from 'react-feather/dist/icons/chevron-right';

import './MonthlyAggregationPanel.css';
import { MetricLabel, MetricValue } from '../../components';

export default class MonthlyAggregationPanel extends Component {
    static propTypes = {
        rs: PropTypes.shape({
            ViewFullReport: PropTypes.string
        }),
        metrics: PropTypes.arrayOf(PropTypes.string).isRequired,
        dataSource: PropTypes.object.isRequired
    }

    render() {
        const { className, style, rs, metrics, dataSource } = this.props;
        return (
            <div className={cx("monthly-aggregation-panel", className)} style={style}>
                {
                    metrics.map(key => (
                        <Row gutter={24} key={key} className="item-line">
                            <Col span={6} className="item-key">
                                <MetricLabel metric={key} />
                            </Col>
                            <Col span={18} className="item-value align-right">
                                <MetricValue metric={key} value={dataSource[key]} />
                            </Col>
                        </Row>
                    ))
                }
                <Row>
                    <Col span={24}>
                        <hr />
                    </Col>
                </Row>
                <Row className="item-line">
                    <Col span={24} className="align-right">
                        <a href="#/affiliate/ws/report/trend/daily" className="full-report-link">
                            {rs ? rs.ViewFullReport : 'View full report'}
                            <RightIcon size={20} />
                        </a>
                    </Col>
                </Row>
            </div>);
    }
}