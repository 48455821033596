import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import RegistrationFlow from './RegistrationFlow';
import { Consts } from '../../common';

const { RegistrationStatus } = Consts;

class Registration extends Component {
    render() {
        const { affiliate, inRegFlow } = this.props;
        const { regFinalStatus, activateStatus } = affiliate || {};

        if (
            !inRegFlow && [RegistrationStatus.Approved, RegistrationStatus.AutoApproved, RegistrationStatus.Completed].includes(
                regFinalStatus
            )
        ) {
            return <Redirect to="/affiliate" />;
        }

        //deactivated affiliate
        if (!inRegFlow && activateStatus && activateStatus !== Consts.ActivateStatus.Activated) {
            return <Redirect to="/affiliate" />;
        }

        

        return <RegistrationFlow />;
    }
}

export default connect((state) => ({
    affiliate: state.affiliate,
    inRegFlow: state.registration.inRegFlow,
}))(Registration);
