import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConsentItem from './ConsentItem';

export default class ConsentList extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            title: PropTypes.string,
            detailTitle: PropTypes.sring,
            description: PropTypes.string
        })),
        rs: PropTypes.object
    }

    static defaultProps = {
        items: []
    }

    constructor(props) {
        super(props);
        this.mapPropItemsToState();
    }

    mapPropItemsToState() {
        const { value, items = [] } = this.props;
        const fitems = items.map(item => ({
            key: item.key,
            accepted: (value || []).indexOf(item.key) > -1
        }));

        if (this.items) {
            for (let item of fitems) {
                let s = this.items.find(x => x.key === item.key);
                if (s) {
                    item.accepted = s.accepted;
                }
            }
        }
        return fitems;
    }

    triggerChange(consentItems) {
        const { onChange } = this.props;
        if (onChange) {
            onChange(consentItems.filter(x => x.accepted).map(x => x.key));
        }
    }

    onConsentItemChange = (accepted, key) => {
        const items = this.mapPropItemsToState();
        const ci = items.find(item => item.key === key);
        if (ci) {
            ci.accepted = accepted;
        }
        this.items = items;
        this.triggerChange(items);
    }

    render() {
        const { items, rs, value } = this.props;
        this.items = this.mapPropItemsToState();

        return (
            <div>
                {
                    items.map(item => (
                        <ConsentItem key={item.title}
                            accepted={(value || []).indexOf(item.key) > -1}
                            title={item.title}
                            detailTitle={item.detailTitle}
                            description={item.description}
                            richFormat={item.richFormat}
                            rs={rs}
                            onChange={accepted => this.onConsentItemChange(accepted, item.key)} />
                    ))
                }
            </div>
        );

    }
}