import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import HomeIcon from 'react-feather/dist/icons/home';
import { WorkspacePage, CpRow } from '../../components';
import Api from '../api';

import './ProductCategory.css';


class ProductCategory extends Component {

    state = {
        loading: false,
        categories: []
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            Api.fetchAllCategoryBanners().then(res => {
                this.setState({ categories: res.data.data || [] });
            }).finally(() => {
                this.setState({ loading: false });
            });
        });
    }

    render() {
        const { intl } = this.props;
        window.intl = intl;
        const breadcrumb = [
            { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
            { title: intl.formatMessage({ id: 'PRODUCT_BANNER' }) }
        ];
        const { categories, loading } = this.state;

        return (
            <WorkspacePage className="product-category" breadcrumb={breadcrumb}
                title={intl.formatMessage({ id: 'PRODUCT_BANNER' })}
                subTitle={intl.formatMessage({ id: 'PRODUCT_BANNER_SUBTITLE' })}
                loading={loading} >
                <CpRow>
                    <h3 className="page-title">
                        {intl.formatMessage({ id: 'PRODUCT_BANNER_SELECT_CATEGORY' })}
                    </h3>

                    <Row className="product-category-list">
                        {
                            categories.map(x => (
                                <Col xs={12} sm={12} md={6} lg={4} key={x.datasetId}>
                                    <Link to={`/affiliate/ws/banner/${x.datasetId}`}>{x.datasetName}</Link>
                                </Col>))
                        }
                    </Row>
                </CpRow>
            </WorkspacePage>
        );
    }
}

export default injectIntl(ProductCategory)