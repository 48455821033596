import React, {useEffect, useState} from 'react';
import { Row, Col, Form, Alert, Button } from 'antd';
import { useIntl } from 'react-intl';
import { Consts, useFetch } from '../../common';
import Api from '../api';

export default function MemberInfo({ memberInfo = {}, withNameSync, piiInputStatus, ...rest }) {
    const intl = useIntl();
    const {doFetch, res, error} = useFetch(()=>Api.syncName(),{ autoLoad: false });
    const [nameSyncStatus, setNameSyncStatus] = useState();
    const [nameSyncErrorMessage,setNameSyncErrorMessage] = useState();
    useEffect(() => {
        if (res &&res.data) {
            const {
                rCode,
            } = res.data;
            if(rCode==='0'){
                setNameSyncStatus('success');
            } 
        } else if(error && error.data){
            setNameSyncStatus('error');
            setNameSyncErrorMessage(error.data.rMessage);
        }

    }, [res, error]);
    return (
        <div {...rest}>
        <Row gutter={16}>
            <Col md={piiInputStatus !== Consts.PiiInputStatus.Completed ? (withNameSync? 24: 12) : 24} sm={24}>
                <Form.Item 
                    label={intl.formatMessage({ id: 'NAME' })}
                    help={withNameSync?intl.formatMessage({id: 'NAME_SYNC_HELP'}):null}>
                    <div style={{display:'flex', alignItems: 'center'}}>
                        <span className="ant-form-text" style={{flex:'auto'}}>{memberInfo.name}</span>
                        {withNameSync && memberInfo.realNameVerified && <Button size="small" className="btn-rounded" onClick={doFetch}>{intl.formatMessage({id: 'NAME_CHANGE'})}</Button>}
                    </div>
                    {nameSyncStatus === 'success' && <Alert className="mt20 mb20" message={intl.formatMessage({id: 'NAME_SYNC_SUCCESS'})} type="success" />}
                    {nameSyncStatus === 'error' && <Alert className="mt20 mb20" message={nameSyncErrorMessage} type="error" />}
                </Form.Item>
            </Col>
            {piiInputStatus !== Consts.PiiInputStatus.Completed && (
                <Col span={withNameSync?24:12}>
                    <Form.Item label={intl.formatMessage({ id: 'BIRTHDAY' })} className="is-marginless">
                        <span className="ant-form-text">{memberInfo.birthDate}</span>
                    </Form.Item>
                </Col>
            )}
        </Row>
        </div>
    );
}
