import moment from 'moment';

import {
    INIT_REPORT_STORE,
    FETCH_REPORT_DATA_DAILY,
    FETCH_REPORT_DATA_MONTHLY,
    FETCH_REPORT_DATA_DYNAMIC_WIDGET_DAILY,
    FETCH_REPORT_DATA_SETTLEMENT,
    PICK_REPORT_DATE_RANGE,
} from './actions';

const initialState = {
    // 3 monthly data in total, each view have 30 days data
    daily: {
        key: '',
        orderCount: null,
        clickCount: null,
        gmv: null,
        commission: null,
        units: [],
    },
    // 12 month data
    monthly: {
        orderCount: null,
        clickCount: null,
        gmv: null,
        commission: null,
        units: [],
    },
    dailyFetched: false,
    monthlyFetched: false,
    settlement: {
        content: [],
        total: 0,
    },
    dateRange: {
        startDate: moment().add({ days: -1 }),
        endDate: moment().add({ days: -1 }),
    },
    loading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case INIT_REPORT_STORE:
            return {
                ...initialState,
            };
        case `${FETCH_REPORT_DATA_DAILY}_BEGIN`:
        case `${FETCH_REPORT_DATA_MONTHLY}_BEGIN`:
        case `${FETCH_REPORT_DATA_DYNAMIC_WIDGET_DAILY}_BEGIN`:
        case `${FETCH_REPORT_DATA_SETTLEMENT}_BEGIN`:
            return {
                ...state,
                loading: true,
            };
        case `${FETCH_REPORT_DATA_DAILY}_FAILED`:
        case `${FETCH_REPORT_DATA_MONTHLY}_FAILED`:
        case `${FETCH_REPORT_DATA_DYNAMIC_WIDGET_DAILY}_FAILED`:
        case `${FETCH_REPORT_DATA_SETTLEMENT}_FAILED`:
            return {
                ...state,
                loading: false,
            };
        case `${FETCH_REPORT_DATA_DAILY}_SUCCESS`:
            return {
                ...state,
                dailyFetched: true,
                loading: false,
                daily: action.payload,
            };
        case `${FETCH_REPORT_DATA_MONTHLY}_SUCCESS`:
            return {
                ...state,
                monthlyFetched: true,
                loading: false,
                monthly: action.payload,
            };
        case `${FETCH_REPORT_DATA_DYNAMIC_WIDGET_DAILY}_SUCCESS`:
            return {
                ...state,
                dailyFetched: true,
                loading: false,
                daily: action.payload,
            };
        case `${FETCH_REPORT_DATA_SETTLEMENT}_SUCCESS`:
            return {
                ...state,
                loading: false,
                settlement: action.payload,
            };
        case PICK_REPORT_DATE_RANGE:
            return {
                ...state,
                dateRange: action.payload,
            };
        default:
            break;
    }
    return state;
};
