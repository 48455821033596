import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { injectIntl } from 'react-intl';

class Copy2Clipboard extends PureComponent {
    static propTypes = {
        content: PropTypes.string,
        popOverText: PropTypes.string,
        popOverTimeout: PropTypes.number,
        onCopied: PropTypes.func,
    }

    constructor(props) {
        super(props);
        this.state = {
            popOverVisible: false
        };
    }

    handleOnCopy = () => {
        this.setState({ popOverVisible: true });
        setTimeout(() => {
            this.setState({ popOverVisible: false });
        }, this.props.popOverTimeout || 2000);
        if (this.props.onCopied) {
            this.props.onCopied();
        }
    }

    handlePopoverVisibleChange = (visible) => {
        this.setState({ popOverVisible: visible });
    }

    render() {
        const { children, content, intl, popOverText } = this.props;
        const popText = popOverText || intl.formatMessage({ id: 'COPIED' });
        const promptMessage = intl.formatMessage({ id: 'PRESS_META_C_TO_COPY' }, { key: '{key}' });

        return (<CopyToClipboard onCopy={this.handleOnCopy} text={content} options={{ message: promptMessage }}>
            <Popover visible={this.state.popOverVisible} content={popText} onVisibleChange={this.handlePopoverVisibleChange} trigger="click">
                {children}
            </Popover>
        </CopyToClipboard>);
    }
}

export default injectIntl(Copy2Clipboard)