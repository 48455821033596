import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

export default function useAction(action, deps) {
    const dispatch = useDispatch();
    return useMemo(() => {
        return (...params) => action(...params)(dispatch);
    }, deps ? [dispatch, ...deps] : deps);
}

export function useActions(actions, deps) {
    const dispatch = useDispatch();
    return useMemo(() => {
        if (Array.isArray(actions)) {
            return actions.map(a => bindActionCreators(a, dispatch));
            // return actions.map(a => (...params) => a(...params)(dispatch));
        }
        return bindActionCreators(actions, dispatch)
    }, deps ? [dispatch, ...deps] : deps)
}