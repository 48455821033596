import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Row, Col, Form } from 'antd';
import { PictureCardUploader, TipText } from '../../components';

import './DocumentFieldset.css';


class DocumentFieldset extends Component {
    static propTypes = {
        form: PropTypes.object,
        onFileSelected: PropTypes.func,
        disabled: PropTypes.bool
    }

    checkFile = (rule, value, callback) => {
        const { intl } = this.props;
        const [file] = value || [];
        if (file && file.originFileObj) {
            if (file.originFileObj.size > 5242880) {
                callback(intl.formatMessage({ id: 'FILE_SIZE_IS_TOO_LARGE' }));
            } else {
                callback();
            }
        } else if (file && file.url && file.url.startsWith('/api/')) {
            // file already uploaded
            callback();
        }
        else {
            callback(intl.formatMessage({ id: 'CHOOSE_A_IMAGE_FILE' }));
        }
    }

    render() {
        const { intl, form: { getFieldDecorator }, disabled } = this.props;

        return (
            <fieldset className="payment-document-fieldset">
                <legend>{intl.formatMessage({ id: 'PAYMENT_UPLOAD_DOCUMENT' })}</legend>
                {
                    !disabled &&
                    <TipText className="mb16">{intl.formatMessage({ id: 'UPLOAD_DOCUMENTS_TIPS' })}</TipText>
                }
                <Row type="flex" justify="space-between" className="uploader-row mb32">
                    <Col>
                        <Form.Item colon={false} label={intl.formatMessage({ id: 'UPLOAD_BUSINESS_DOC' })}>
                            {getFieldDecorator('brnFileList', {
                                valuePropName: 'fileList',
                                rules: [{ validator: this.checkFile }]
                            })(
                                <PictureCardUploader disabled={disabled} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item colon={false} label={intl.formatMessage({ id: 'UPLOAD_BANK_BOOK' })}>
                            {getFieldDecorator('bankbookFileList', {
                                valuePropName: 'fileList',
                                rules: [{ validator: this.checkFile }]
                            })(
                                <PictureCardUploader disabled={disabled} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </fieldset>
        );
    }
}

export default injectIntl(DocumentFieldset)