export const AUTH = 'Auth';
export function auth({ onAuthSuccess, onAuthCancelled }) {
    return {
        type: AUTH,
        payload: { onAuthSuccess, onAuthCancelled },
    };
}

export const AUTH_CANCELLED = 'AUTH_CANCELLED';
export function authCancelled() {
    return {
        type: AUTH_CANCELLED,
    };
}

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export function authSuccess() {
    return {
        type: AUTH_SUCCESS,
    };
}