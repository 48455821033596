import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { MessageForm, Emotion } from '../../components';

export default function MemberBlockedAlert(){

    const intl = useIntl();
    const history = useHistory();
    const title = (
        <div>
            <div className="emotion-line">
                <Emotion type="ops" />
            </div>
            <div>{intl.formatMessage({ id: 'MEMBER_BLOCKED_TITLE' })}</div>
        </div>
    );
    const rs = { OK: intl.formatMessage({ id: 'GO_BACK' }) };
    const gotoLanding = ()=>{
        history.push('/');
    };

    return (
        <div className="is-flex bg-grey">
            <MessageForm
                className="mt40 align-center"
                title={title}
                size="normal"
                content={intl.formatMessage({ id: 'MEMBER_BLOCKED_TIP' })}
                rs={rs}
                onOK={gotoLanding}
            />
        </div>
    );
}