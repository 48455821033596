import moment from 'moment';
import axios from 'axios';

const buildDataTableFilterFromReportCondition = ({ startDate, endDate, currentSubIds }) => {
    const filters = [
        {
            column: 'startDate',
            operator: 'EQUAL',
            value: [moment(startDate).format('YYYY-MM-DD')],
        },
        {
            column: 'endDate',
            operator: 'EQUAL',
            value: [moment(endDate).format('YYYY-MM-DD')],
        },
    ];
    if (currentSubIds && currentSubIds.length) {
        filters.push({
            column: 'subIds',
            operator: 'IN',
            value: currentSubIds,
        });
    }
    return filters;
};
export default class Api {
    static fetchReportDataDaily(conditions) {
        return axios.post('/api/v1/report/daily', { filters: buildDataTableFilterFromReportCondition(conditions) });
    }

    static fetchReportDataMonthly(conditions) {
        return axios.post('/api/v1/report/monthly', { filters: buildDataTableFilterFromReportCondition(conditions) });
    }

    static fetchReportDataDynamicWidgetDaily(conditions) {
        return axios.post('/api/v1/report/dynamic-banner', {
            filters: buildDataTableFilterFromReportCondition(conditions),
        });
    }

    static fetchReportDataSettlement({ pageNumber, size }) {
        return axios.post('/api/v1/report/settlement', { pageNumber, size });
    }

    // static getReportDataDailyDownloadUrl({ startDate, endDate, currentSubIds }) {
    //     return `/api/v1/report/download?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}` + (currentSubIds.length > 0 ? `&subIds=${currentSubIds.join(',')}` : '');
    // }

    static fetchEarningSummary() {
        return axios.get(`/api/v1/earning/summary`);
    }

    static fetchSettlementReport(request) {
        return axios.post('/api/v1/report/settlements', request);
    }

    static fetchSettlementDetailsReport(request) {
        return axios.post('/api/v1/report/settlements/details', request);
    }

    static fetchPayoutReport(request) {
        return axios.post('/api/v1/report/payouts', request);
    }

    static fetchPayoutDetailsReport(request) {
        return axios.post('/api/v1/report/payouts/details', request);
    }

    static getSettlementDetailsDownloadUrl(settleMonth, trackingCode) {
        return `/api/v1/report/settlements/details/download?month=${settleMonth}&trackingCode=${trackingCode}`;
    }

    static fetchPerformanceInsightSummary({ startDate, endDate, currentSubIds }) {
        return axios.post('/api/v1/performance/summary', {
            filters: buildDataTableFilterFromReportCondition({ startDate, endDate, currentSubIds }),
        });
    }

    static makeReportApi(reportName, dimension) {
        return function (payload) {
            return axios.post(`/api/v1/${reportName}/${dimension}`, payload);
        };
    }

    static fetchReferralReportSummary() {
        return axios.get(`/api/v1/referral/summary`);
    }

    static createDownloadJob(reportType, parameters) {
        return axios.post(`/api/v1/downloads/async/reports/${reportType}/create`, parameters);
    }

    static getAsyncDownloadJobs(payload) {
        return axios.post('/api/v1/downloads/async/reports/list', payload);
    }

    static deleteDownloadJob(id) {
        return axios.post(`/api/v1/downloads/async/reports/${id}/delete`);
    }

    static retryDownloadJob(id) {
        return axios.post(`/api/v1/downloads/async/reports/${id}/regen`);
    }

    static getAsyncDownloadUrl(id) {
        return `/api/v1/downloads/async/reports/${id}/download`;
    }

    static getCodeByIds({ traceId, subId }) {
        return axios.get(`/api/v1/performance/copyUrl?subId=${subId}&traceId=${traceId}`);
    }
}

export { buildDataTableFilterFromReportCondition };
