import React, { Component } from 'react';
import { FormattedNumber, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class CurrencyLabel extends Component {
    static propTypes = {
        value: PropTypes.number
    }

    shouldComponentUpdate(nextProps) {
        return this.props.value !== nextProps.value || this.props.children !== nextProps.children;
    }

    render() {
        const { intl, value, children } = this.props;
        let v = value || children;
        return (
            <span className="currency-label">
                <FormattedNumber value={v || 0} /><span className="currency-code">{intl.formatMessage({ id: 'CURRENCY_CODE_WON' })}</span>
            </span>
        );
    }
}

export default injectIntl(CurrencyLabel)