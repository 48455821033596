import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { injectIntl } from 'react-intl';


class ChangeBusinessTypeAlert extends PureComponent {
    render() {
        const { intl, affiliate, affiliateTypeChange, style } = this.props;
        const allowChangeBusinessType = affiliateTypeChange && (affiliate.affiliateType === affiliateTypeChange.from);

        return (
            allowChangeBusinessType ?
                <Alert style={style}
                    message="공지 사항"
                    description={<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'CHANGE_AFFILIATE_TYPE_ALERT' }) }}></div>}
                    type="warning"
                />
                : null
        );
    }
}

export default injectIntl(
    connect(state => ({ affiliate: state.affiliate, affiliateTypeChange: state.config.affiliateTypeChange }))(
        ChangeBusinessTypeAlert
    )
)
