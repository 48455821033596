import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ProductShape } from './Product';

export default class ProductPreview extends Component {
    static propTypes = {
        ...ProductShape,
        imageCdnUrl: PropTypes.string,
        url: PropTypes.string,
        border: PropTypes.bool,
        rs: PropTypes.object
    }

    static defaultProps = {
        border: true
    }

    static COUPANT_BRAND = `image/affiliate/v1/coupang.png`;

    render() {
        const { border, imageCdnUrl, image, title, deliveryBadgeImage, url, rs } = this.props;

        const productPreviewStyle = {
            height: '240px',
            width: '120px',
            padding: '4px 4px 12px 4px',
            border: '1px solid transparent',
            display: 'inline-block',
            position: 'relative',
            marginBottom: '30px'
        };
        if (border) {
            productPreviewStyle.borderColor = '#dfe0e2';
        } else {
            productPreviewStyle.borderColor = 'transparent';
        }

        return (
            <div>
                <style>{`
                 .product-preview {
                    box-sizing: border-box;
                    height: 240px;
                    width: 120px;
                    padding: 4px 4px 12px 4px;
                    border: 1px solid transparent;
                    display: inline-block;
                    position: relative;
                    text-align: center;
                }
                
                .product-preview.border {
                    border-color: #dfe0e2;
                }
                
                .product-preview .coupang-brand {
                    margin-bottom: 8px;
                }

                .product-preview .product-picture {
                    height: 110px;
                    width: 110px;
                    margin-bottom: 8px;
                }
                
                .product-preview .product-picture img {
                    height: 100%;
                    width: 100%;
                }
                
                .product-preview .product-description {
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0px 12px;
                }
                
                .product-preview .delivery-badge {
                    height: 14px;
                    text-align: center;
                }
                
                .product-preview .delivery-badge img {
                    height: 100%;
                }
                
                .product-preview .button-bar {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 12px;
                    text-align: center;
                }
                .product-preview .button-bar .shopping-btn { 
                    font-size: 12px;  
                    font-weight: 300;  
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    padding: 0px 15px;
                    background-color: #346aff;
                    color: white;
                    border-radius: 100px;
                    text-decoration: none;
                }
                .product-preview .button-bar .shopping-btn:hover {
                    box-shadow: 0 3px 12px 0 rgba(45, 77, 167, 0.3);
                }
                `}</style>

                <div className={cx("product-preview", { border })}>
                    <div className="coupang-brand">
                        <div>
                            <img src={`${imageCdnUrl}/${ProductPreview.COUPANT_BRAND}`} alt="Coupang" />
                        </div>
                    </div>
                    <div className="product-picture">
                        <img src={image} alt="product" />
                    </div>
                    <div className="product-meta">
                        <div className="product-description">
                            {title || '　'}
                        </div>
                        <div className="delivery-badge">
                            {deliveryBadgeImage && <img src={deliveryBadgeImage} alt="badge" />}
                        </div>
                    </div>
                    <div className="button-bar">
                        <a className="shopping-btn" href={url}>
                            {rs && rs.Shopping ? rs.Shopping : 'Shopping'}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}