import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Button } from 'antd';

import { useActions } from '../../common';
import { subIdDeleted } from '../actions';
import Api from '../api';

export default function DeleteSubIdModal({ subId, onSuccess, children }) {
    const intl = useIntl();
    const [visible, setVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const open = () => {
        setVisible(true);
    };
    const [actSubIdDeleted] = useActions([subIdDeleted]);
    const onSubmit = (subId) => {
        setSubmitting(true);
        Api.deleteSubId(subId)
            .then((res) => {
                const { rCode, data } = res.data;
                if (rCode === '0') {
                    actSubIdDeleted(subId);
                    setVisible(false);
                    if (onSuccess) {
                        onSuccess();
                    }
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
    return (
        <React.Fragment>
            <Modal
                visible={visible}
                closable={false}
                wrapClassName="modal sub-id-delete-modal"
                width={440}
                title={null}
                footer={null}>
                <div className="modal-title large-title">{intl.formatMessage({ id: 'SUB_ID_DELETE_MODAL_TITLE' })}</div>
                <div className="modal-body">
                    {intl.formatMessage(
                        { id: 'SUB_ID_DELETE_MODAL_CONTENT' },
                        {
                            subId: <span className="text-bold">{subId}</span>,
                            br: <br />,
                        }
                    )}
                </div>
                <div className="modal-footer">
                    <Button size="large" onClick={() => setVisible(false)}>
                        {intl.formatMessage({ id: 'CANCEL' })}
                    </Button>
                    <Button type="primary" size="large" loading={submitting} onClick={() => onSubmit(subId)}>
                        {intl.formatMessage({ id: 'CONFIRM' })}
                    </Button>
                </div>
            </Modal>
            {children({ open })}
        </React.Fragment>
    );
}
