import Api from './api';

export default [
    {
        group: 'goldbox',
        title: 'TOP_PRODUCTS_GOLDBOX',
        fetchApi: Api.getBestGoldboxProducts,
        lazy: false,
        coupangLink: 'https://www.coupang.com/np/goldbox',
        categoryFilter: false
    },
    {
        group: 'fashion',
        title: 'TOP_PRODUCTS_FASHION',
        fetchApi: Api.getBestFashionProducts,
        lazy: false,
        coupangLink: undefined,
        categoryFilter: false
    },
    {
        group: 'rocket-fresh',
        title: 'TOP_PRODUCTS_ROCKET_FRESH',
        fetchApi: Api.getBestRocketFreshProducts,
        lazy: false,
        coupangLink: 'https://www.coupang.com/np/categories/393760',
        categoryFilter: false
    },
    // {
    //     group: 'retail-clearance',
    //     title: 'TOP_PRODUCTS_RETAIL_CLEARANCE',
    //     fetchApi: Api.getBestRetailClearanceProducts,
    //     lazy: true,
    //     coupangLink: 'https://www.coupang.com/np/promotion/49112',
    //     categoryFilter: false
    // },
    {
        group: 'most-conversion',
        title: 'TOP_PRODUCTS_MOST_CONVERSION',
        fetchApi: Api.getBestMostConversionProducts,
        lazy: true,
        coupangLink: false,
        categoryFilter: false
    },
    {
        group: 'most-discounted',
        title: 'TOP_PRODUCTS_MOST_DISCOUNTED',
        fetchApi: Api.getBestMostDiscountedProducts,
        lazy: true,
        coupangLink: false,
        categoryFilter: false
    },
    {
        group: 'most-sold',
        title: 'TOP_PRODUCTS_MOST_SOLD',
        fetchApi: Api.getBestMostSoldProducts,
        lazy: true,
        coupangLink: false,
        categoryFilter: false
    }
];