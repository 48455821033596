import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Carousel } from 'antd';
import IconLeft from 'react-feather/dist/icons/chevron-left';
import IconRight from 'react-feather/dist/icons/chevron-right';
import ArrowRight from 'react-feather/dist/icons/arrow-right';
import Product, { ProductShape } from './Product';

import './BestProductCarousel.css';

class BestProductCarousel extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape(ProductShape)),
        pageSize: PropTypes.number,
        onAction: PropTypes.func
    }

    static defaultProps = {
        pageSize: 6,
    }

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        };
    }

    get totalPages() {
        return Math.ceil(((this.props.items || []).length + 1) / this.props.pageSize);
    }

    prev = () => {
        this.carouselRef.prev();


        // this.setState((prevState) => ({
        //     page: prevState.page === 0 ? this.totalPages - 1 : prevState.page - 1
        // }));
    }

    next = () => {
        this.carouselRef.next();
        // this.setState((prevState) => ({
        //     page: prevState.page + 1
        // }));
    }

    viewMore = () => {
        const { onAction } = this.props;
        if (onAction) {
            onAction('viewMoreBestProducts');
        }
    }

    renderViewMore() {
        const { intl } = this.props;
        return <div className="product-item view-more" key="view-more">
            <div onClick={this.viewMore}>
                <ArrowRight size={60} />
                <br />
                <div>{intl.formatMessage({ id: 'VIEW_MORE' })}</div>
            </div>
        </div>;
    }

    buildCarouselPage(items, page, lastPage) {
        const { onAction } = this.props;

        let carouselItems = items.map(x => <Product key={x.type + (x.itemId || x.productId)} onAction={onAction} {...x} />)

        if (lastPage) {
            carouselItems.push(this.renderViewMore());
        }

        return (
            <div className="best-product-carousel-page" key={page}>
                {
                    carouselItems
                }
            </div>);
    }

    buildCarouselPages = (items) => {
        let pages = [];
        let { pageSize } = this.props;
        for (let i = 0, j = 1, len = items.length + 1; i < len; i += pageSize, j++) {
            let products = items.slice(i, i + pageSize);
            pages.push(this.buildCarouselPage(products, j, j === this.totalPages));
        }
        return pages;
    }

    render() {
        const { items } = this.props;

        return (
            <div className="best-products-carousel">
                {
                    this.totalPages > 1 &&
                    <Button className="page-navigator previous-page" shape="circle" onClick={this.prev}>
                        <IconLeft />
                    </Button>
                }
                {
                    this.totalPages > 1 &&
                    <Button className="page-navigator next-page" shape="circle" onClick={this.next}>
                        <IconRight />
                    </Button>
                }

                {
                    items && items.length ?
                        <Carousel dots={false} ref={r => this.carouselRef = r} >
                            {this.buildCarouselPages(items)}
                        </Carousel> : null
                }
            </div>
        );
    }
}

export default injectIntl(BestProductCarousel)