import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage,injectIntl } from 'react-intl';
import { Redirect, withRouter } from 'react-router';
import { If, Then, Else, Switch, Case } from 'react-if';
import { Row, Col, Button, Icon, notification, Modal, Typography } from 'antd';

import { CpRow, CardPage, Wizard as StepWizard, Steps, StepIndicator } from '../../components';
import Member2AffiliateAlert from './Member2AffiliateAlert';
import MemberBlockedAlert from './MemberBlockedAlert';
import CreateProfile from './CreateProfile';
import { IndividualPayment, BusinessPayment, OtherIndustriesPayment } from '../../payment';
import AffiliateAutoApproved from './AffiliateAutoApproved';
import AffiliateUnderReview from './AffiliateUnderReview';
import AffiliateVerification from './AffiliateRealNameAuthentication';
import { Consts, TrackerContext } from '../../common';

import { fetchRegistrationFlowInitData, regUpdateStep, regUpdateAffiliate, regFinishRegistration } from '../actions';
import Api from '../api';
import './RegistrationFlow.less';

const { AffiliateTypes, RegistrationStatus } = Consts;

class RegistrationFlow extends Component {
    static contextType = TrackerContext;
    constructor(props) {
        super(props);
        const { affiliate } = props;

        this.state = {
            step: 1,
            affiliateType: (affiliate && affiliate.affiliateType) || AffiliateTypes.Company,
            gotoJoin: !affiliate && window.sessionStorage.entrance === Consts.Entrance.Login,
            signUpSuccessful: false,
        };
        this.currentStepRef = React.createRef();
        this.registrationDataRef = React.createRef({});
    }

    getInitData() {
        const { fetchRegistrationFlowInitData } = this.props;
        if (fetchRegistrationFlowInitData) {
            fetchRegistrationFlowInitData();
        }
    }

    componentDidMount() {
        if (!this.state.gotoJoin && !this.props.memberBlocked) {
            this.getInitData();
        }
    }

    submit = async () => {
        const { affiliateType } = this.state;
        if (affiliateType === AffiliateTypes.Individual) {
            return Api.regSaveIndividualPayment(this.registrationDataRef.current).then((res) => {
                this.context.trackEvent({ action: 'individual_payment_complete' });

                return res;
            });
        } else if (affiliateType === AffiliateTypes.Company) {
            return Api.regSaveBusinessPayment(this.registrationDataRef.current).then((res) => {
                this.context.trackEvent({ action: 'business_payment_complete' });
                return res;
            });
        } else if (affiliateType === AffiliateTypes.OtherIndustries) {
            return Api.regSaveOtherIndustriesPayment(this.registrationDataRef.current).then((res) => {
                this.context.trackEvent({ action: 'other_industries_payment_complete' });
                return res;
            });
        }
    };

    previousStep = async () => {
        if (this.wizard) {
            // Currently we have 3 steps, only payment step can go back
            this.context.trackEvent({ action: 'payment_go_back' });
            if (this.currentStepRef.current) {
                if (typeof this.currentStepRef.current.getData === 'function') {
                    const data = await this.currentStepRef.current.getData(true);
                    this.registrationDataRef.current = { ...this.registrationDataRef.current, ...data };
                }
            }
            this.wizard.previousStep();
            window.scrollTo(0, 0);
        }
    };

    nextStep = async () => {
        const { step } = this.state;
        // Final
        if (this.currentStepRef.current) {
            try {
                if (typeof this.currentStepRef.current.getData === 'function') {
                    const data = await this.currentStepRef.current.getData();
                    this.registrationDataRef.current = { ...this.registrationDataRef.current, ...data };
                }
                if (step + 1 === this.wizard.props.children.length) {
                    try {
                        this.setState({ stepLoading: true });
                        const res = await this.submit();
                        window.store.dispatch({ type: 'UPDATE_AFFILIATE', payload: res.data.data });
                        this.setState({signUpSuccessful: true});
                    } catch (err) {
                        if (err.status === 200) {
                            const { rCode } = err.data;
                            if (rCode !== '403' && rCode !== '499') {
                                notification.error({
                                    message: '에러',
                                    description: err.data ? err.data.rMessage : err.data,
                                });
                            }
                        }
                        throw err; // throw error here, then should not go next step
                    } finally {
                        this.setState({ stepLoading: false });
                    }
                } 
                else {
                    this.wizard.nextStep();
                    window.scrollTo(0, 0);
                }
                
            } catch (err) {
                console.log(err);
            }
        }
    };

    confirm = () => {
        const { affiliate, history } = this.props;
        if (affiliate && affiliate.regFinalStatus === Consts.RegistrationStatus.AutoApproved) {
            this.context.trackEvent({ action: 'auto_approved_confirm' });
        } else if (affiliate && affiliate.regFinalStatus === Consts.RegistrationStatus.Completed) {
            this.context.trackEvent({ action: 'reapply_confirm' });
        }
        // else {
        //     this.context.trackEvent({ action: 'confirm_registration' });
        //     regFinishRegistration && regFinishRegistration(); // To fix AFFILIATE-1412
        //     // window.nonav = true;
        // }
        history.replace('/affiliate');
    };

    handleStepChange = ({ activeStep }) => {
        this.setState({ step: activeStep });
    };

    handleGotoJoin = () => {
        this.setState({ gotoJoin: false }, () => {
            this.getInitData();
        });
    };

    subTitleMap = {
        [AffiliateTypes.Individual]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL',
        [AffiliateTypes.AdvertisingAgency]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL_ADVERTISING',
        [AffiliateTypes.OtherIndustries]: 'PAYMENT_SELECT_TYPE_INDIVIDUAL_OTHERS',
        [AffiliateTypes.Company]: 'PAYMENT_SELECT_TYPE_CORPORATION',
    };

    onChangeAffiliateType = (v) => {
        this.setState({ affiliateType: v });
    };

    isFirstStep = () => {
        return this.state.step === 1;
    };

    isLastStep = () => {
        return this.wizard && this.state.step === this.wizard.props.children.length;
    };

    render() {
        const {
            intl,
            username,
            affiliate,
            registration: { payment, reapplyCase, memberInfo },
            loading,
            maintainingEnabled,
            memberBlocked,
        } = this.props;

        const { step, stepLoading, gotoJoin, affiliateType, signUpSuccessful } = this.state;

        if (maintainingEnabled) {
            return <Redirect to="/undermaintain" />;
        }

        if(memberBlocked) {
            return <MemberBlockedAlert />
        }

        if (gotoJoin) {
            return <Member2AffiliateAlert onGotoJoin={this.handleGotoJoin} />;
        }

        const shouldShowPreviousButton = !this.isFirstStep() && !this.isLastStep();
        const shouldShowNextButton =
            !this.isLastStep() &&
            memberInfo &&
            (affiliateType === AffiliateTypes.Company ||
                (affiliateType !== AffiliateTypes.Company &&
                    !memberInfo.underage &&
                    !memberInfo.foreigner &&
                    memberInfo.realNameValidated));
        return (
            <CpRow className="registration-flow is-flex bg-grey" style={{ maxWidth: 840 }} loading={loading}>
                <Row>
                    <h1 className="page-title-secondary">
                        {intl.formatMessage({ id: 'REGISTRATION_TITLE' })}
                        {step === 2 && affiliateType && (
                            <span className="text-secondary">
                                {intl.formatMessage({ id: this.subTitleMap[affiliateType] })}
                            </span>
                        )}
                    </h1>
                </Row>
                <Row type="flex" justify="center" className="mb16" style={{marginTop: -2}}>
                    <Col span={6}>
                        <StepIndicator stepCount={3} current={step} />
                    </Col>
                </Row>
                <Row>
                    <CardPage>
                            <div>
                                <StepWizard
                                    className="step-wizard"
                                    initialStep={step}
                                    isLazyMount={true}
                                    ref={(a) => (this.wizard = a)}
                                    onStepChange={this.handleStepChange}>
                                    <AffiliateVerification
                                        reapplyCase={reapplyCase}
                                        wrappedComponentRef={this.currentStepRef}
                                        affiliateType={affiliateType}
                                        onChangeAffiliateType={this.onChangeAffiliateType}
                                    />
                                    
                                    <Switch>
                                        <Case condition={affiliateType === AffiliateTypes.Individual}>
                                            <IndividualPayment action="create" wrappedComponentRef={this.currentStepRef} />
                                        </Case>
                                        <Case
                                            condition={
                                                affiliateType === AffiliateTypes.Company ||
                                                affiliateType === AffiliateTypes.AdvertisingAgency
                                            }>
                                            <BusinessPayment action="create" wrappedComponentRef={this.currentStepRef} />
                                        </Case>
                                        <Case condition={affiliateType === AffiliateTypes.OtherIndustries}>
                                            <OtherIndustriesPayment action="create" wrappedComponentRef={this.currentStepRef} />
                                        </Case>
                                    </Switch>
                                    
                                    <CreateProfile
                                        ref={this.currentStepRef}
                                        affiliateType={affiliateType}
                                    />
                                    
                                    <If
                                        condition={
                                            !!affiliate && affiliate.regFinalStatus === RegistrationStatus.AutoApproved
                                        }>
                                        <Then>
                                            <AffiliateAutoApproved />
                                        </Then>
                                        <Else>
                                            <AffiliateUnderReview />
                                        </Else>
                                    </If>
                                </StepWizard>

                                <div className="buttons-bar align-center">
                                    {shouldShowPreviousButton && (
                                        <div>
                                            <Button size="large" onClick={this.previousStep}>
                                                <Icon type="arrow-left" />
                                                {intl.formatMessage({ id: 'WIZARD_PREVIOUS' })}
                                            </Button>
                                        </div>
                                    )}

                                    {shouldShowNextButton && (
                                        <div>
                                            <Button
                                                size="large"
                                                type="primary"
                                                onClick={this.nextStep}
                                                loading={stepLoading}>
                                                {intl.formatMessage({ id: 'WIZARD_NEXT' })}
                                                <Icon type="arrow-right" />
                                            </Button>
                                        </div>
                                    )}
                                    
                                </div>
                            </div>
                    </CardPage>
                </Row>
                {affiliate && 
                <Modal className="sign-up-successful-modal" visible={signUpSuccessful} title={null} footer={null} closable={false}>
                    <div>
                        <h4 className="title">
                            <FormattedMessage id="PAYMENT_ALERT_GREETING" values={{ name: username }} />,{' '}
                            {intl.formatMessage({ id: 'PAYMENT_ALERT_TITLE_AUTO_APPROVED' })}
                        </h4>

                        <div className="align-center mb24">
                            <FormattedMessage
                                id="APPROVED_AFFILIATE_ID_IS"
                                values={{
                                    name: username,
                                    affiliateCode: <strong className="color-accent ft-bold">{affiliate.affiliateCode}</strong>,
                                }}
                            />
                            <br />
                            {intl.formatMessage({ id: 'APPROVED_CREATE_A_LINK_NOW' })}
                        </div>
                        <div className="dark-grey-box mb24">
                            {intl.formatMessage({id:'REGISTRATION_PII_COLLECTION_LATER_TIPS'}, {br: <br />})}
                        </div>
                        <div className="align-center">
                            <Button size="large" type="primary" onClick={this.confirm} loading={stepLoading}>
                                {intl.formatMessage({ id: 'CONFIRM' })}
                            </Button>
                        </div>
                    </div>
                </Modal>
                }
            </CpRow>
        );
    }
}

export default injectIntl(
    withRouter(
        connect(
            (state) => ({
                username: state.session.username,
                affiliate: state.affiliate,
                registration: state.registration,
                maintainingEnabled: state.config.maintainingEnabled,
                loading: state.registration.loading,
                memberBlocked: state.config.memberBlocked,
            }),
            { fetchRegistrationFlowInitData, regUpdateStep, regUpdateAffiliate, regFinishRegistration }
        )(RegistrationFlow)
    )
);
