import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';

import './ConsentModal.css';

export default class ConsentModal extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        content: PropTypes.string,
        richFormat: PropTypes.bool,
        visible: PropTypes.bool,
        onOk: PropTypes.func,
        onCancel: PropTypes.func,
        rs: PropTypes.shape({
            Confirm: PropTypes.string,
        }),
    };

    render() {
        const { title, content, visible, onOk, onCancel, richFormat, rs } = this.props;
        let resources = rs || { Confirm: 'CONFIRM' };
        return (
            <Modal
                className="consent-detail-modal"
                title={title || 'Detail'}
                visible={visible}
                maskClosable={false}
                onOk={onOk}
                onCancel={onCancel}
                footer={[
                    <Button key="submit" size="large" onClick={onOk}>
                        {resources.Confirm}
                    </Button>,
                ]}>
                <Scrollbars
                    autoHide
                    autoHeight
                    autoHeightMin={300}
                    autoHeightMax={400}
                    renderThumbVertical={(props) => <div {...props} className="detail-scroll-thumb" />}>
                    {richFormat ? (
                        <div
                            className="detail-description ck-content"
                            dangerouslySetInnerHTML={{ __html: content }}></div>
                    ) : (
                        <p className="detail-description">{content}</p>
                    )}
                </Scrollbars>
            </Modal>
        );
    }
}
