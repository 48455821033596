import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { MessageForm, Emotion } from '../../components';
import { TrackerContext } from '../../common';

import './AffiliateRejected.css';

class AffiliateRejected extends Component {
    static contextType = TrackerContext;
    componentDidMount() {
        this.context.trackPageView({ action: 'enter_rejected' });
    }

    handleOK = () => {
        const { onOK } = this.props;
        this.context.trackEvent({ action: 'rejected_reapply' });
        if (onOK) {
            onOK();
        }
    };

    render() {
        const { intl, rejectReason } = this.props;
        const title = (
            <div>
                <div className="emotion-line">
                    <Emotion type="ops" />
                </div>
                <div>{intl.formatMessage({ id: 'REGISTRATION_AFFILIATE_REJECTED_TITLE' })}</div>
            </div>
        );
        const rs = { OK: intl.formatMessage({ id: 'REAPPLY' }) };
        return (
            <div className="is-flex bg-grey">
                <MessageForm
                    className="mt40 align-center"
                    size="normal"
                    title={title}
                    content={
                        <div style={{ overflow: 'hidden' }}>
                            <div className="affiliate-rejected-content">
                                {intl.formatMessage({ id: 'REGISTRATION_AFFILIATE_REJECTED_REFUSAL_FOLLOWS' })}
                                <div className="affiliate-rejected-help-box">
                                    <Input.TextArea
                                        readOnly
                                        autosize={{ minRows: 3 }}
                                        value={rejectReason}></Input.TextArea>
                                </div>
                            </div>
                        </div>
                    }
                    rs={rs}
                    onOK={this.handleOK}
                />
            </div>
        );
    }
}

export default injectIntl(
    withRouter(
        connect((state) => ({ rejectReason: state.affiliate.rejectReason || state.affiliate.paymentRejectReason }))(
            AffiliateRejected
        )
    )
);
