import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from 'antd';

export default class LoadingImage extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    onImageLoaded = (e) => {
        this.setState({
            loading: false
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.src !== this.props.src) {
            this.setState({
                loading: true
            });
        }
    }

    render() {
        const { src, alt, className, ...others } = this.props;

        return <span>
            {this.state.loading ? <Icon type="loading" style={{ fontSize: 28 }} /> : null}
            {<img src={src} alt={alt} onLoad={this.onImageLoaded} className={cx(className, { 'hidden': this.state.loading })} {...others} />}
        </span>;
    }
}