import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Input, Select, Button } from 'antd';
import SearchIcon from 'react-feather/dist/icons/search';
import './SearchBox.css';

export default class SearchBox extends Component {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.any,
            name: PropTypes.string
        })),
        disabled: PropTypes.bool,
        onSearch: PropTypes.func,
        term: PropTypes.string,
        placeholder: PropTypes.string
    }

    constructor(props) {
        super(props);
        let categories = props.categories || [];

        this.state = {
            term: props.term || '',
            category: props.category || (categories[0] && categories[0].id),
            alerting: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if ('category' in nextProps && 'term' in nextProps) {
            this.setState({
                term: nextProps.term,
                category: nextProps.category
            });
        }
    }

    onChange = (e) => {
        this.setState({
            term: e.target.value
        });
    }

    onCategorySelected = (value, option) => {
        this.setState({
            category: value
        });
    }

    componentDidUpdate() {
        this.updateInputLeftPadding();
    }

    onSearch = () => {
        if (this.state.term) {
            const { onSearch } = this.props;
            if (onSearch) {
                onSearch(this.state.category, this.state.term);
            }
        } else {
            this.setState({ alerting: true });
        }
    }

    updateInputLeftPadding() {
        if (this.selectRef && this.selectRef.rcSelect && this.selectRef.rcSelect.rootRef) {
            const selectContainer = this.selectRef.rcSelect.rootRef;
            const selectWidth = selectContainer.offsetWidth;

            if (this.inputRef && this.inputRef.input) {
                this.inputRef.input.style.paddingLeft = `${selectWidth + 2}px`;
            }
        }
    }

    onAnimationEnd = (event) => {
        if (event.target === this.containerRef) {
            this.setState({ alerting: false });
        }
    }

    render() {
        let categories = this.props.categories || [];
        const { placeholder, className, style, disabled } = this.props;

        return (
            <div onAnimationEnd={this.onAnimationEnd} className={cx('animated', { shake: this.state.alerting })} ref={r => this.containerRef = r}>
                {/* <Input placeholder={placeholder} disabled={disabled} className={cx("search-box", className)} style={{ width: 640, ...style }} ref={r => this.inputRef = r} */}
                <Input placeholder={placeholder} disabled={disabled} className={cx("search-box", className)} style={{ ...style }} ref={r => this.inputRef = r}
                    prefix={
                        <Select ref={r => this.selectRef = r} className="search-category"
                            dropdownClassName="search-category"
                            dropdownMatchSelectWidth={false}
                            value={this.state.category}
                            disabled={disabled}
                            onSelect={this.onCategorySelected}>
                            {
                                categories.map(x => (<Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>))
                            }
                        </Select>}
                    suffix={<Button className="search-button" type="primary" onClick={this.onSearch} disabled={disabled}><SearchIcon size={18} /></Button>}
                    size="large" onPressEnter={this.onSearch} value={this.state.term} onChange={this.onChange} />
            </div>
        );
    }
}