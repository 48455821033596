import React, { Component } from 'react';
import { Steps } from 'antd';
import CheckIcon from 'react-feather/dist/icons/check';

import './RegistrationSteps.css';

export default class RegistrationSteps extends Component {
    customDot = (dot, { status, index }) => {
        return (
            <span className="ant-steps-icon-dot">
                {
                    status === 'process' ? <CheckIcon size={12} color="white" /> : null
                }
            </span>
        );
    }

    render() {
        const { currentStep } = this.props;
        return (
            <Steps className="reg-steps" size="small" current={currentStep - 1} progressDot={this.customDot}>
                {
                    ['약관동의', '프로필 입력', '사업 유형 선택', '결제정보 입력', '가입 완료'].map(x => <Steps.Step key={x} title={x} />)
                }
            </Steps>
        );
    }
}