import {
    SEARCH_PRODUCT,
    FETCH_LINK_GENERATION_FORM_INITDATA,
    FETCH_BEST_11_PRODUCTS,
    FETCH_BEST_ROCKET_PRODUCTS,
    GENERATE_BANNER_IMAGE,
    FETCH_CATEGORY_BANNER_INITDATA
} from './actionTypes';
import { begin, success, fail } from '../common';

const initialState = {
    categories: [],
    deliveryTypeImages: {},
    best11Products: [],
    bestProducts: {
        total: 0,
        items: []
    },
    products: {
        total: 0,
        items: [],
        shortUrl: null,
        sharing: null
    },
    currentProduct: null,
    link: {
        shortUrl: '',
        html: '',
        bannerImage: '',
        iframeUrl: '',
        iframeUrlNoBorder: ''
    },
    banner: {
        shortUrl: '',
        imageName: ''
    },
    loading: false
};

export default (state = initialState, action) => {

    switch (action.type) {
        case begin`${SEARCH_PRODUCT}`:
        case begin`${FETCH_LINK_GENERATION_FORM_INITDATA}`:
        case begin`${FETCH_BEST_11_PRODUCTS}`:
        case begin`${FETCH_BEST_ROCKET_PRODUCTS}`:
        case begin`${GENERATE_BANNER_IMAGE}`:
        case begin`${FETCH_CATEGORY_BANNER_INITDATA}`:
            return {
                ...state,
                loading: true
            };
        case fail`${SEARCH_PRODUCT}`:
        case fail`${FETCH_LINK_GENERATION_FORM_INITDATA}`:
        case fail`${FETCH_BEST_11_PRODUCTS}`:
        case fail`${FETCH_BEST_ROCKET_PRODUCTS}`:
        case fail`${GENERATE_BANNER_IMAGE}`:
        case fail`${FETCH_CATEGORY_BANNER_INITDATA}`:
            return {
                ...state,
                loading: false
            };
        case success`${SEARCH_PRODUCT}`:
            const { payload } = action;
            return {
                ...state,
                products: {
                    total: payload ? payload.total : 0,
                    items: payload ? payload.products : [],
                    shortUrl: payload && payload.shortUrl,
                    sharing: payload.sharing
                },
                loading: false
            };
        case success`${FETCH_LINK_GENERATION_FORM_INITDATA}`:
            const { shortUrl, iframeHtmlTagBorderOff, iframeHtmlTagBorderOn, title, description, imageUrl, } = action.payload;
            const flResult = {
                ...state,
                link: {
                    shortUrl,

                    iframeHtmlTagBorderOff,
                    iframeHtmlTagBorderOn,
                    title, description, imageUrl,
                },
                loading: false
            };
            if (action.payload.product) {
                flResult.currentProduct = action.payload.product;
            }
            return flResult;
        case success`${FETCH_BEST_11_PRODUCTS}`:
            return {
                ...state,
                loading: false,
                best11Products: action.payload
            };
        case success`${FETCH_BEST_ROCKET_PRODUCTS}`:
            const { categories, products } = action.payload
            const bpResult = {
                ...state,
                bestProducts: { items: products.content, total: products.total },
                loading: false
            };
            if (categories && categories.length) {
                bpResult.categories = categories;
            }
            return bpResult;
        case success`${GENERATE_BANNER_IMAGE}`:
            return { ...state, loading: false, link: { ...state.link, imageHtmlTag: action.payload } };
        case success`${FETCH_CATEGORY_BANNER_INITDATA}`:
            return { ...state, loading: false, banner: action.payload };
        default:
            break;
    }

    return state;
};