import React, { Component } from 'react';

// let _assetroot = '';

export default class Image extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.src !== this.props.src || nextProps.alt !== this.props.alt;
    }

    render() {
        const { assetroot, src, alt, ...otherProps } = this.props;
        let finalSrc;
        if (src.startsWith('data:image')) {
            finalSrc = `${src}`;
        } else {
            finalSrc = `${assetroot || ''}${src}`;
        }
        return (<img src={finalSrc} alt={alt} {...otherProps} />);
    }
}

// export const configAssetroot = (root) => {
//     _assetroot = root;
// }