import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Alert } from 'antd';
import AlertCircle from 'react-feather/dist/icons/alert-circle';
import Mail from 'react-feather/dist/icons/mail';
import './PaymentModificationTips.css';

class PaymentModificationTips extends Component {
    render() {
        const { intl } = this.props;
        return (
            <div className="company-modification-tips">
                <div className="notice color-accent">
                    <AlertCircle />
                    &nbsp;&nbsp;
                    {intl.formatMessage({ id: 'CIM_ALERT_TITLE' })}
                </div>
                <Alert
                    message={
                        <div>
                            <span className="sequence color-accent">1</span>
                            <span className="content">{intl.formatMessage({ id: 'CIM_ALERT_STEP1' })}</span>
                        </div>
                    }
                    type="success"
                />
                <Alert
                    message={
                        <div>
                            <span className="sequence color-accent">2</span>
                            <span className="content">{intl.formatMessage({ id: 'CIM_ALERT_STEP2' })}</span>
                        </div>
                    }
                    type="success"
                />
            </div>
        );
    }
}

export default injectIntl(PaymentModificationTips);
