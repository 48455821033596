import React, { useState } from 'react';
import { Select, Button, Icon, notification } from 'antd';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import CustomDataset from './CustomDataset';
import { Confirm } from '../../components';
import './CustomDatasetDropdown.less';


const { Option } = Select;

// const MOCKED_ITEMS = [
//     { datasetId: 1001, datasetName: 'Top 10 Java books' },
//     { datasetId: 1002, datasetName: 'Top 10 Golang books' },
//     { datasetId: 1003, datasetName: 'Best efficiency devices for programmers' },
// ];


let keepOpen = false;

export default injectIntl(function CustomDatasetDropdown({ className, dropdownClassName, intl, datasets, onSaveDataset, onDeleteDataset, ...others }) {
    const [processing, setProcessing] = useState(false);
    const [open, setOpen] = useState(false);
    const [manageDatasetVisible, setManageDatasetVisible] = useState(false);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [currentDataset, setCurrentDataset] = useState(null);
    const [deleteTipMessage, setDeleteTipMessage] = useState('');

    const preventDefault = e => {
        e.preventDefault();
    }

    const handleDropdownVisibleChange = (open) => {
        if (keepOpen) {
            keepOpen = false;
        } else {
            setOpen(open || manageDatasetVisible || deleteConfirmVisible);
        }
    }

    const handleEdit = (e, dataset) => {
        e.stopPropagation();
        keepOpen = true;
        setCurrentDataset(dataset);
        setManageDatasetVisible(true);
    }

    const handleAdd = (e) => {
        e.preventDefault();
        setCurrentDataset(null);
        setManageDatasetVisible(true);
    }

    const handleDeleteConfirm = (e, dataset) => {
        e.stopPropagation();
        keepOpen = true;
        setDeleteConfirmVisible(true);
        setCurrentDataset(dataset);
    }

    const handleDelete = () => {
        const deletePromise = onDeleteDataset && onDeleteDataset(currentDataset && currentDataset.datasetId);
        setProcessing(true);
        Promise.all([deletePromise]).then(() => {
            setDeleteConfirmVisible(false);
        }).catch(err => {
            if (err && err.data && err.data.rMessage) {
                setDeleteTipMessage(err.data.rMessage);
            }
        }).finally(() => {
            setProcessing(false);
        });
    }

    const handleSave = (selectedProducts) => {
        const promise = onSaveDataset && onSaveDataset(selectedProducts);
        return Promise.all([promise]).then(() => {
            setManageDatasetVisible(false);
        }).catch(err => {
            if (err.data && err.data.rMessage) {
                notification.warn({ message: intl.formatMessage({ id: 'ERROR' }), description: err.data.rMessage });
            } else {
                console.error('Failed to save dataset', err);
            }
        });
    }

    return (
        <React.Fragment>
            <Select className={cx('custom-dataset-dropdown', className)}
                dropdownClassName={cx('custom-dataset-dropdown-menu')}
                dropdownMatchSelectWidth={true}
                optionLabelProp="label"
                onDropdownVisibleChange={handleDropdownVisibleChange}
                open={open}
                {...others}
                dropdownRender={menu => {
                    return (
                        <div>
                            {menu}
                            <div className="align-center" style={{ margin: '1em 0px' }}>
                                <Button size="small" className="sm" type="primary" disabled={datasets && datasets.length >= 20} onMouseDown={preventDefault} onClick={handleAdd}>
                                    {intl.formatMessage({ id: 'ADD' })}
                                </Button>
                            </div>
                        </div>
                    );
                }}
            >
                {
                    (datasets || []).map(x =>
                        <Option key={x.datasetId} value={x.datasetId} label={x.datasetName}>
                            <div className="dataset-item">
                                <div className="dataset-item-name">{x.datasetName} </div>
                                <div className="dataset-item-actions">
                                    <Icon type="edit" onClick={e => handleEdit(e, x)} />
                                    <Icon type="delete" onClick={e => handleDeleteConfirm(e, x)} />
                                </div>
                            </div>
                        </Option>
                    )
                }
            </Select >
            <CustomDataset visible={manageDatasetVisible} datasetId={currentDataset && currentDataset.datasetId} onOk={handleSave} onCancel={() => setManageDatasetVisible(false)} />
            <Confirm
                title={intl.formatMessage({ id: 'WIDGET_INST_DATASET_DELETE_CONFIRM_TITLE' })}
                description={
                    <div>
                        {
                            intl.formatMessage({ id: 'WIDGET_INST_DATASET_DELETE_CONFIRM' }, { name: currentDataset && currentDataset.datasetName })
                        }
                        {deleteTipMessage && <p className="color-danger">{deleteTipMessage}</p>}
                    </div>
                }
                contentAlignment="left"
                destroyOnClose={true}
                processing={processing}
                visible={deleteConfirmVisible}
                onCancel={e => setDeleteConfirmVisible(false)}
                onOk={handleDelete}
                afterClose={() => setDeleteTipMessage('')}
            />
        </React.Fragment>
    );
});
