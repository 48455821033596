import React from 'react';
import { Row, Col, Button } from 'antd';
import { injectIntl } from 'react-intl';
import HomeIcon from 'react-feather/dist/icons/home';
import { WorkspacePage, CpRow } from '../../components';

import AffiliateShareIcon from '../images/affiliate-share.svg';
import ChromeIcon from '../images/chrome@2x.png';
import ChromeExtIcon from '../images/chrome-ext@2x.png';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import AffiliateChromeExtensionBanner from '../images/affiliate-chrome-extension@2x.png';
import  { AffiliateFeeNotice } from '../../common';

import './ChromeExtension.less';


export default injectIntl(function ChromeExtension({ intl }) {
    const breadcrumb = [
        { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
        { title: intl.formatMessage({ id: 'HEADER_TOOLS_CHROME_EXTENSION' }) },
    ];

    return (
        <WorkspacePage className="chrome-extension"
            breadcrumb={breadcrumb}
            title={intl.formatMessage({ id: 'HEADER_TOOLS_CHROME_EXTENSION' })}>
            <CpRow>
                        <div className="align-left chrome-ext-banner">
                            <Row type="flex" align="middle">
                                <Col xs={24} sm={24} md={24} lg={16} xl={16} className="keys-description">
                                    <p className="ft-bold">
                                        크롬에서 파트너스 확장 프로그램을 설치하고 쿠팡에서 골드박스, 다양한 상품 및 기획전 등 다양한 페이지의 링크를 편리하게 생성하고 SNS에 직접 공유하세요.                                 
                                    </p>
                                    <AffiliateFeeNotice />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} className="keys-image">
                                    <img src={AffiliateChromeExtensionBanner} alt="" />
                                </Col>
                            </Row>
                        </div>
            </CpRow>
            <CpRow className="code-generation-container is-flex bg-grey">
                <div className="pane">
                    <Row type="flex" align="middle" gutter={32}>
                        <Col xs={24} sm={24} md={24} lg={12} className="download-item">
                            <div className="title">{intl.formatMessage({ id: 'CHROME_EXT_INSTALL_CHROME' })}</div>
                            <div>
                                <Button size="large" href="https://www.google.com/chrome" target="_blank">
                                    <img src={ChromeIcon} alt="" />{intl.formatMessage({ id: 'CHROME_EXT_DOWNLOAD_CHROME' })}<ExternalLinkIcon size={20} color="#346aff" />
                                </Button>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} className="download-item">
                            <div className="title"><img src={AffiliateShareIcon} alt="" style={{ marginRight: 8 }} />{intl.formatMessage({ id: 'CHROME_EXT_INSTALL_EXENTION' })}</div>
                            <div>
                                <Button size="large" href="https://chrome.google.com/webstore/detail/%EC%BF%A0%ED%8C%A1-%ED%8C%8C%ED%8A%B8%EB%84%88%EC%8A%A4/caglfngmfonigcbedhobbpggelncacii" target="_blank">
                                    <img src={ChromeExtIcon} alt="" />{intl.formatMessage({ id: 'CHROME_EXT_DOWNLOAD_EXTENSION' })}<ExternalLinkIcon size={20} color="#346aff" />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </CpRow>
        </WorkspacePage>
    );
});