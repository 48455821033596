import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import { Radio, Button } from 'antd';
import Copy2Clipboard from '../Copy2Clipboard/Copy2Clipboard';
import UnselectableInput from '../UnselectableInput/UnselectableInput';
import LinkIcon from 'react-feather/dist/icons/link';

import './HTMLCodeViewer.css';

class HTMLCodeViewer extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string,
                title: PropTypes.string,
            })
        ),
        option: PropTypes.string,
        title: PropTypes.string,
        titleTip: PropTypes.any,
        html: PropTypes.string,
        button: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        onOptionChange: PropTypes.func,
        bottomChild: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    };

    copyHtmlToClipboard = () => {
        if (typeof this.props.onCopyCode === 'function') {
            this.props.onCopyCode.call(null);
        }
    };

    render() {
        const {
            intl,
            options,
            option,
            title = 'HTML',
            titleTip,
            html,
            onOptionChange,
            wrapperClassName,
            optionsClassName,
            textClassName,
            bottomChild,
        } = this.props;

        const button = this.props.button || (
            <React.Fragment>
                {' '}
                <LinkIcon size={16} />
                {intl.formatMessage({ id: 'LINK_GENERATION_COPY_HTML' })}
            </React.Fragment>
        );

        return (
            <div className={cx('html-code-viewer', wrapperClassName)}>
                <h2>
                    <span className="title">{title}</span>&nbsp;&nbsp;
                    {titleTip && <span className="color-accent title-tip">{titleTip}</span>}
                </h2>
                {options && options.length && (
                    <div className={cx('align-center', optionsClassName)}>
                        <Radio.Group size="large" onChange={onOptionChange} value={option}>
                            {(options || []).map((x) => (
                                <Radio key={x.key} value={x.key}>
                                    {x.title}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                )}

                <div className="code-container">
                    {/* <Input.TextArea className={cx("code", textClassName)}
                        value={html}
                        readOnly /> */}
                    <UnselectableInput.TextArea value={html} className={cx('code', textClassName)} />
                </div>

                <div className="align-center">
                    <Copy2Clipboard content={html} popOverText={intl.formatMessage({ id: 'COPIED_DISCLAIMER' })}>
                        <Button size="large" onClick={this.copyHtmlToClipboard}>
                            {button}
                        </Button>
                    </Copy2Clipboard>
                    {bottomChild}
                </div>
            </div>
        );
    }
}

export default injectIntl(HTMLCodeViewer);
