import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, Checkbox, Radio, Alert, Typography } from 'antd';
import { Consts } from '../../common';
import { TipText, ConsentLinks } from '../../components';
import MemberInfo from '../components/MemberInfo';
import MarketingConsentSubfrom from '../components/MarketingConsentSubform';
import styles from './AffiliateRealNameAuthentication.module.less';

const AffiliateRealNameAuthentication = forwardRef(
    ({ form, reapplyCase, affiliateType = Consts.AffiliateTypes.Individual, onChangeAffiliateType }, ref) => {
        const intl = useIntl();

        const memberInfo = useSelector((state) => state.registration.memberInfo);

        const [confirmed, setConfirmed] = useState(false);
        const changeAffiliateType = (v) => {
            onChangeAffiliateType(v);
        };
        useImperativeHandle(ref, () => ({
            getData: () => {
                return new Promise((resolve, reject) => {
                    if (affiliateType !== Consts.AffiliateTypes.Company) {
                        if (!memberInfo || memberInfo.underage || memberInfo.foreigner) {
                            return reject();
                        }
                    }
                    form.validateFields((err, values) => {
                        if (!err) {
                            resolve({
                                affiliateType,
                                consent: {
                                    marketingPiCollection:
                                        Array.isArray(values.marketingPiCollection) &&
                                        values.marketingPiCollection.length > 0,
                                    marketingEmail:
                                        Array.isArray(values.marketingEmail) && values.marketingEmail.length > 0,
                                },
                            });
                        } else {
                            reject();
                        }
                    });
                });
            },
        }));

        return (
            <Form layout="vertical">
                <fieldset>
                    <legend>{intl.formatMessage({ id: 'REGISTRATION_PROFILE_AF_SELECT' })}</legend>
                </fieldset>
                <Form.Item className="affiliate-type">
                    <div className="mb16 mt8">
                        <TipText>{intl.formatMessage({ id: 'REGISTRATION_PROFILE_AF_SELECT_TIPS' })}</TipText>
                    </div>
                    <Radio.Group
                        className={styles.affiliateTypeRadioGroup}
                        size="large"
                        value={affiliateType}
                        disabled={reapplyCase}
                        onChange={(e) => changeAffiliateType(e.target.value)}>
                        <Radio value={Consts.AffiliateTypes.Individual}>
                            {intl.formatMessage({ id: 'REGISTRATION_PROFILE_AF_INDIVIDUAL' })}
                        </Radio>
                        <Radio value={Consts.AffiliateTypes.Company}>
                            {intl.formatMessage({ id: 'REGISTRATION_PROFILE_AF_COMPANY' }, { br: <br /> })}
                        </Radio>
                        {/* <Radio value="ADVERTISING_AGENCY">{intl.formatMessage({ id: 'REGISTRATION_PROFILE_AF_ADVERTISING_AGENCY' })}</Radio> */}
                        <Radio value={Consts.AffiliateTypes.OtherIndustries}>
                            {intl.formatMessage({ id: 'REGISTRATION_PROFILE_AF_OTHER_INDUSTRIES' }, { br: <br /> })}
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                {affiliateType !== Consts.AffiliateTypes.Company && memberInfo && (
                    <React.Fragment>
                        {memberInfo.realNameValidated && (
                            <React.Fragment>
                                <div className="mb16">
                                    <Typography.Text>
                                        {intl.formatMessage({ id: 'REGISTRATION_REAL_NAME_AUTHENTICATION_TIPS_1' })}
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text strong={true}>
                                        {intl.formatMessage({ id: 'REGISTRATION_REAL_NAME_AUTHENTICATION_TIPS_2' })}
                                    </Typography.Text>
                                </div>
                                <MemberInfo memberInfo={memberInfo} />
                            </React.Fragment>
                        )}

                        {!memberInfo.realNameValidated && (
                            <Alert
                                type="error"
                                message={intl.formatMessage(
                                    { id: 'REGISTRATION_WITHOUT_REAL_NAME_AUTHENTICATION' },
                                    {
                                        link: (
                                            <a href={memberInfo.realNameValidationUrl}>
                                                {intl.formatMessage({
                                                    id: 'REGISTRATION_GOTO_REAL_NAME_AUTHENTICATION',
                                                })}
                                            </a>
                                        ),
                                    }
                                )}
                                className="mb24"
                            />
                        )}
                        {memberInfo.realNameValidated &&
                            (memberInfo.foreigner ? (
                                <Alert
                                    type="error"
                                    message={intl.formatMessage({
                                        id: 'REGISTRATION_FOREIGNER_USER_CANNOT_JOIN',
                                    })}
                                    className="mb24"
                                />
                            ) : (
                                memberInfo.underage && (
                                    <Alert
                                        type="error"
                                        message={intl.formatMessage({
                                            id: 'REGISTRATION_UNDERAGE_USER_CANNOT_JOIN',
                                        })}
                                        className="mb24"
                                    />
                                )
                            ))}

                        {memberInfo.realNameValidated && !memberInfo.underage && !memberInfo.foreigner && (
                            <React.Fragment>
                                <Form.Item>
                                    {form.getFieldDecorator('confirmed', {
                                        rules: [
                                            {
                                                validator: (_, value, callback) => {
                                                    return value
                                                        ? callback()
                                                        : callback(
                                                              intl.formatMessage({
                                                                  id: 'REGISTRATION_PLEASE_CONFIRM_IT_IS_YOUR_IDENTITY',
                                                              })
                                                          );
                                                },
                                            },
                                        ],
                                    })(
                                        <Checkbox checked={confirmed} onChange={(e) => setConfirmed(e.target.checked)}>
                                            {intl.formatMessage({ id: 'REGISTRATION_IDENTITY_CONFIRM' })}
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                <Form.Item>
                    {form.getFieldDecorator('tc', {
                        rules: [
                            {
                                validator: (_, value, callback) => {
                                    return value && 1 === value.length
                                        ? callback()
                                        : callback(intl.formatMessage({ id: 'PLEASE_AGREE_ALL' }));
                                },
                            },
                        ],
                    })(
                        <ConsentLinks
                            items={[
                                {
                                    key: 'T&C',
                                    title: intl.formatMessage({ id: 'REGISTRATION_TC_V1' }),
                                    action: (
                                        <Link to="/help/terms" target="_blank">
                                            {intl.formatMessage({ id: 'VIEW_DOCUMENT' })}
                                        </Link>
                                    ),
                                },
                            ]}
                            rs={{ Confirm: intl.formatMessage({ id: 'CONFIRM' }) }}
                        />
                    )}
                </Form.Item>

                <Form.Item>
                    {form.getFieldDecorator('ps', {
                        rules: [
                            {
                                validator: (_, value, callback) => {
                                    return value && 1 === value.length
                                        ? callback()
                                        : callback(intl.formatMessage({ id: 'PLEASE_AGREE_ALL' }));
                                },
                            },
                        ],
                    })(
                        <ConsentLinks
                            items={[
                                {
                                    key: 'REGISTRATION_PS_V1',
                                    title: intl.formatMessage({ id: 'REGISTRATION_PS_V1' }),
                                },
                            ]}
                            rs={{ Confirm: intl.formatMessage({ id: 'CONFIRM' }) }}
                        />
                    )}
                </Form.Item>

                <MarketingConsentSubfrom form={form} />
            </Form>
        );
    }
);

export default Form.create()(AffiliateRealNameAuthentication);
