import React from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Divider, Typography } from 'antd';
import HomeIcon from 'react-feather/dist/icons/home';
import moment from 'moment';
import cx from 'classnames';
import { useFetch } from '../../common';
import { WorkspacePage, CpRow, LastUpdatedDate, MetricValue, MetricList, MetricLabel } from '../../components';
import Api from '../api';
import styles from './EarningReport.module.less';

const MetricView = ({weighted, metric, value, customLabel})=>{
  return (
    <div className={cx(styles.metricBox, 'is-flex-row', {[styles.weighted]:weighted})}>
      <span className={cx(styles.metric, 'is-flex')}>
        <MetricLabel metric={metric} customLabel={customLabel} />
      </span>
      {value < 0 && metric ==='commissionCap' ? '-' : <MetricValue metric={metric} value={value} className={styles.metricValue} />}
    </div>
  );
}

export default function () {
  const intl = useIntl();
  const breadcrumb = [
    { title: intl.formatMessage({ id: 'AFFILIATE_HOME' }), link: '/affiliate', icon: <HomeIcon size={14} /> },
    { title: intl.formatMessage({ id: 'HEADER_EARNING_REPORT' }) },
    { title: intl.formatMessage({ id: 'HEADER_REPORT_EARNING' }) },
  ];
  const { data = {}, fetching } = useFetch(() => Api.fetchEarningSummary());
  return (
    <WorkspacePage
      breadcrumb={breadcrumb}
      title={intl.formatMessage({ id: 'HEADER_REPORT_EARNING' })}
      loading={fetching}
    >
      <CpRow>
        {
          
          <React.Fragment>
            <LastUpdatedDate date={data.lastUpdatedDate} />
            <div className="mt24">
              <div className={cx(styles.box, 'align-center', styles.topBox)}>
                <h3>{intl.formatMessage({id:'RP_ALL_TIME_ACCUMULATED_PAYOUT_AMT'})}</h3>
                <MetricValue metric="accumulatedPayoutAmt" value={data.accumulatedPayoutAmt} />
              </div>
              <div className={styles.container}>
                {
                  ['currentMonth', 'previousMonth'].map(k=>(
                    <div key={k}>
                      <div className={styles.box}>
                        <h4>{intl.formatMessage({id:'RP_EARNING_MONTH'}, {month: data[k] && data[k].month ? moment(data[k].month).month() + 1 : '-'})}</h4>
                        <Divider />
                        {
                          [
                            {metric:'clickCount'}, {metric:'orderCount', customLabel: intl.formatMessage({id:'RP_ORDER_COUNT_2'})}, {metric:'cancelCount'}, {metric:'orderPaymentAmt'}, {metric:'cancelAmt'}, {metric:'actualPaymentAmt', customLabel:intl.formatMessage({id:'RP_ACTUAL_PAYMENT_AMT_2'}), weighted:true},
                            <Divider dashed={true} />,
                            {metric:'commission', customLabel: intl.formatMessage({id:'RP_COMMISSION_2'})}, {metric:'commissionCap'}, {metric:'manualBonus'}, {metric:'finalCommission', weighted:true}
                          ].map(item=> item && item.metric ? <MetricView key={item.metric} weighted={item.weighted} metric={item.metric} value={data[k] && data[k][item.metric]} customLabel={item.customLabel} /> : item)
                        }
                        {
                          k === 'previousMonth' && <div className="align-right ft-12 mt24"><Typography.Text type="secondary">{intl.formatMessage({id:'RP_EARNING_SUMMARY_TIPS'})}</Typography.Text></div>
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </React.Fragment>
        }
      </CpRow>
    </WorkspacePage>
  );
}
