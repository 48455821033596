import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import LinesEllipsis from 'react-lines-ellipsis';
import LinkIcon from 'react-feather/dist/icons/link';
import ExternalLinkIcon from 'react-feather/dist/icons/external-link';
import { CurrencyLabel } from '../../common';

import './Product.less';

export const ProductShape = {
    rankNo: PropTypes.number,
    type: PropTypes.string,
    productId: PropTypes.number,
    itemId: PropTypes.number,
    vendorItemId: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    originPrice: PropTypes.number,
    discountRate: PropTypes.number,
    salesPrice: PropTypes.number,
    deliveryBadgeImage: PropTypes.string,
    actions: PropTypes.array
};

class Product extends Component {
    static parse(obj) {
        return {
            ...obj,
            productId: Number(obj.productId),
            itemId: Number(obj.itemId),
            vendorItemId: Number(obj.vendorItemId),
            originPrice: Number(obj.originPrice),
            discountRate: Number(obj.discountRate),
            salesPrice: Number(obj.salesPrice),
        };
    }
    static propTypes = {
        ...ProductShape,
        onAction: PropTypes.func
    };

    isTouchDevice = 'ontouchstart' in document;

    shouldComponentUpdate(nextProps) {
        const { image, title, discountRate, originPrice, salesPrice, deliveryBadgeImage, actions } = this.props;
        const { image: nimage,
            title: ntitle,
            discountRate: ndiscountRate,
            originPrice: noriginPrice,
            salesPrice: nsalesPrice,
            deliveryBadgeImage: ndeliveryBadgeImage,
            actions: nActions } = nextProps;

        return nimage !== image ||
            ntitle !== title ||
            ndiscountRate !== discountRate ||
            noriginPrice !== originPrice ||
            nsalesPrice !== salesPrice ||
            ndeliveryBadgeImage !== deliveryBadgeImage ||
            nActions !== actions;
    }

    executeAction = (action) => {
        const { type, itemId, productId, vendorItemId, image, title, discountRate, originPrice, salesPrice, deliveryBadgeImage, onAction } = this.props;
        if (onAction) {
            onAction(action, { type, itemId, productId, vendorItemId, image, title, discountRate, originPrice, salesPrice, deliveryBadgeImage });
        }
    }

    generateLink = () => {
        this.executeAction('generateLink');
    }

    viewDetail = () => {
        this.executeAction('viewDetail');
    }

    handleAction = (action, disabled) => {
        if (!disabled) {
            this.executeAction(action);
        }
    }

    render() {
        const { intl, showRankNo, rankNo, image, title, discountRate, originPrice, salesPrice, deliveryBadgeImage, actions } = this.props;
        const actionsCustomized = actions && actions.length;

        return (
            <div className={cx('product-item', { 'as-placeholder': !image || !title })}>
                {
                    image &&
                    <div className="product-picture">
                        {
                            showRankNo && rankNo && <span className="rank-no">{rankNo}</span>
                        }
                        <img src={image} alt="product" />
                        {
                            this.isTouchDevice
                                ?
                                <React.Fragment>
                                    {
                                        actionsCustomized ?
                                            (
                                                <div className={cx("product-actions", { "mobile": this.isTouchDevice })}>
                                                    {actions.map(x => (<div className={cx("product-action", { disabled: x.disabled })} key={x.name} onClick={() => this.handleAction(x.name, x.disabled)}>{x.icon || x.title}</div>))}
                                                </div>
                                            ) :
                                            <div>
                                                <div className="icon-btn btn-open-detail" onClick={this.viewDetail}><ExternalLinkIcon size={16} /></div>
                                                <div className="icon-btn btn-generate-link" onClick={this.generateLink}><LinkIcon size={16} /></div>
                                            </div>
                                    }

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {
                                        actionsCustomized ?
                                            (
                                                <div className="product-actions">
                                                    {actions.map(x => (<Button key={x.name} disabled={x.disabled} onClick={() => this.handleAction(x.name)}>{x.title}</Button>))}
                                                </div>
                                            ) :
                                            <div>
                                                <Button className="hover-btn btn-open-detail" onClick={this.viewDetail}>
                                                    <ExternalLinkIcon size={16} />{intl.formatMessage({ id: 'PRODUCT_LINK_GOTO' })}
                                                </Button>
                                                <Button className="hover-btn btn-generate-link" onClick={this.generateLink}>
                                                    <LinkIcon size={16} />{intl.formatMessage({ id: 'PRODUCT_LINK_GENERATION' })}
                                                </Button>
                                            </div>
                                    }
                                </React.Fragment>
                        }
                    </div>
                }
                {
                    title &&
                    <div className="product-meta" onClick={this.viewDetail}>
                        <div className="product-description">
                            <LinesEllipsis text={title} maxLine="2" />
                        </div>
                        <div className="product-price">
                            {
                                discountRate ? <div className="discount">{intl.formatNumber((discountRate || 0) / 100, { style: 'percent' })} <CurrencyLabel value={originPrice} /></div> : null
                            }
                            <div className="sale-price">
                                <CurrencyLabel value={salesPrice} />
                                {
                                    deliveryBadgeImage &&
                                    <span className="delivery-badge">
                                        <img src={deliveryBadgeImage} alt="badge" />
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div >
        );
    }
}

export default injectIntl(Product)